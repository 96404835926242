import React from "react";
import { Route, Switch } from "react-router-dom";
import { NavBar } from "./common/navbar";
import { lazyRetry } from "./common/utils";
import { Account } from "./core/account";
import { AlertsProvider } from "./core/alerts/components/AlertProvider";

const DefaultRoutes = lazyRetry(() => import("./core/routes"));

const Main: React.FC = () => {
  return (
    <AlertsProvider active>
      <>
        <div className="app-grid">
          <div className="app__main-navbar">
            <NavBar />
          </div>

          <div className="app__main-content">
            <Switch>
              <Route path="/account">
                <Account />
              </Route>

              <DefaultRoutes />
            </Switch>
          </div>
        </div>
      </>
    </AlertsProvider>
  );
};

export default Main;
