import classNames from "classnames";
import moment, { Moment } from "moment";
import React, { ChangeEvent, forwardRef, LegacyRef, useEffect, useRef, useState } from "react";
import { Button } from "~/common/forms/button";

interface Props {
  selected?: Date;
  maxDate?: Date;
  minDate?: Date;
  onChange?: (date: Date) => void;
  onClear?: () => void;
}

const weekDays = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];
const months = moment.monthsShort();

const Calendar = forwardRef<HTMLDivElement, Props>((props: Props, ref: LegacyRef<HTMLDivElement>) => {
  const { maxDate, minDate, onChange, onClear, selected } = props;

  const datesRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const [boxRect, setBoxRect] = useState<{
    width: number;
    height: number;
  } | null>(null);

  const [yearsRange, setYearsRange] = useState<number[]>([]);
  const [inputValue, setInputValue] = useState("");

  const [inputError, setInputError] = useState(false);
  const [editState, setEditState] = useState<"date" | "year" | "month">("date");

  const [date, setDate] = useState(selected ? moment(selected) : moment());

  if (minDate && !moment(minDate).isValid()) {
    throw new Error("invalid min date");
  }

  const buildYearsRange = (initial: number) => {
    const range = [];
    const endYear = initial + 23;
    let last = endYear;
    if (maxDate) {
      const fullYear = maxDate.getFullYear();
      last = fullYear < endYear ? fullYear : endYear;
    }

    // eslint-disable-next-line no-plusplus
    for (let i = initial; i <= last; i++) {
      range.push(i);
    }

    setYearsRange(range);
  };

  const setYear = (year: number) => {
    setDate(date.year(year));
    setEditState("month");
  };

  const setMonth = (month: string) => {
    setDate(date.month(month));
    setEditState("date");
  };

  const stopPropagation = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
  };

  const add = () => {
    switch (editState) {
      case "date":
        const nextMonth = moment(date).add(1, "months");
        if (maxDate && nextMonth.isAfter(maxDate)) return;
        setDate(nextMonth);
        break;
      case "year":
        const initial = yearsRange[yearsRange.length - 1] + 1;
        buildYearsRange(initial);
        break;
      default:
        break;
    }
  };

  const subtract = () => {
    switch (editState) {
      case "date":
        const prevMonth = moment(date).subtract(1, "months");
        if (minDate && prevMonth.isBefore(minDate, "month")) return;
        setDate(prevMonth);
        break;
      case "year":
        const initial = yearsRange[0] - 24;
        buildYearsRange(initial);
        break;
      default:
        break;
    }
  };

  const dayClassName = (day: Moment): string => {
    let dayStyles = "cursor-pointer hover:bg-blue-900 hover:text-white";
    const today = moment();

    if (selected && day.isSame(selected, "date")) {
      dayStyles = "bg-light-blue-900 text-white";
    } else if ((minDate && day.isBefore(minDate)) || (maxDate && day.isSameOrAfter(maxDate))) {
      dayStyles = "text-grey-400 cursor-default pointer-events-none";
    } else if (day.isSame(today, "date")) {
      dayStyles = "border-light-blue-900";
    } else if (!day.isSame(date, "month")) {
      dayStyles = "text-grey-600";
    }

    return classNames(
      "border",
      !day.isSame(today, "date") && "border-transparent",
      "font-semibold",
      "rounded",
      "day-button",
      "text-center",
      "inline-block",
      "focus:outline-none",
      "p-2",
      dayStyles
    );
  };

  const startOfMonth = moment(date).startOf("month");
  const firstDayCalendar = startOfMonth.startOf("week");
  const endOfMonth = moment(date).endOf("month");
  const lastDayCalendar = endOfMonth.endOf("week");
  const calendarDays: Moment[] = [];
  while (firstDayCalendar < lastDayCalendar) {
    calendarDays.push(moment(firstDayCalendar));
    firstDayCalendar.add(1, "days");
  }

  while (calendarDays.length < 42) {
    calendarDays.push(moment(firstDayCalendar));
    firstDayCalendar.add(1, "days");
  }

  const formatInputValue = (evt: ChangeEvent<HTMLInputElement>) => {
    let { value } = evt.target as HTMLInputElement;

    if (value.length > 10) {
      value = value.slice(0, 10);
    }

    if (value.length === 10) {
      const inputDate = moment(value, "DD/MM/YYYY");
      const isBeforeMinDate = minDate && inputDate.isBefore(minDate);
      const isValid = inputDate.isValid() && !isBeforeMinDate;

      setInputError(!isValid);
      if (isValid) setDate(inputDate);
      setInputValue(isValid ? value : "");
    } else {
      setInputValue("");
    }
  };

  useEffect(() => {
    const minYear = minDate ? minDate.getFullYear() : 0;
    const startYear = date.year() - 11;
    const start = minYear > startYear ? minYear : startYear;
    buildYearsRange(start);
  }, [date]);

  useEffect(() => {
    if (selected) setInputValue(moment(selected).format("DD/MM/YYYY"));
    else setInputValue("");
  }, [selected]);

  useEffect(() => {
    if (!boxRect && datesRef.current) {
      const { width, height } = datesRef.current.getBoundingClientRect();
      setBoxRect({ width, height });
    }
  }, [datesRef.current]);

  return (
    <div ref={ref} className="bg-white shadow-lg p-6 rounded space-y-6" onClick={stopPropagation}>
      <div className="flex items-center justify-between">
        <div className="flex-1 flex items-center justify-between">
          <button
            data-testid="prev-month-btn"
            type="button"
            className={classNames("text-blue-900 focus:outline-none", editState === "month" && "hidden")}
            onClick={subtract}
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
              <path strokeLinecap="round" strokeLinejoin="round" d="M11 17l-5-5m0 0l5-5m-5 5h12" />
            </svg>
          </button>

          <button
            onClick={() => {
              if (editState === "year") setEditState("date");
              else setEditState("year");
            }}
            className="flex-1 text-center font-semibold underline"
          >
            {editState === "date" && date.format("MMMM YYYY")}
            {editState === "year" && `${yearsRange[0]}-${yearsRange[yearsRange?.length - 1]}`}
            {editState === "month" && date.format("YYYY")}
          </button>

          <button data-testid="next-month-btn" type="button" className={classNames("text-blue-900 focus:outline-none", editState === "month" && "hidden")} onClick={add}>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
              <path strokeLinecap="round" strokeLinejoin="round" d="M13 7l5 5m0 0l-5 5m5-5H6" />
            </svg>
          </button>
        </div>
      </div>

      {editState === "date" && (
        <div className="flex flex-col items-center" ref={datesRef}>
          <div className="grid grid-cols-7 grid-rows-7 gap-y-2 gap-x-5 w-max">
            {weekDays.map((day) => (
              <span key={`week-day-${day}`} className=" text-center text-grey-600 font-semibold">
                {day}
              </span>
            ))}

            {calendarDays.map((day) => (
              <button
                aria-label={day.format("YYYY-MM-DD")}
                key={day.toISOString()}
                type="button"
                onClick={() => onChange && onChange(day.toDate())}
                className={dayClassName(day)}
                disabled={maxDate && day.isAfter(maxDate)}
              >
                {day.format("D")}
              </button>
            ))}
          </div>

          <form
            onSubmit={(evt) => {
              evt.preventDefault();
              if (!inputError && inputValue && onChange) onChange(date.toDate());
              else if (!inputValue) {
                setInputError(true);
              }
            }}
            className="flex items-center gap-5 mt-6"
          >
            <Button variant="btn-secondary" disabled={minDate && moment().isBefore(minDate)} onClick={() => onChange && onChange(moment().toDate())}>
              Today
            </Button>

            <div style={{ width: "170px" }}>
              <div className={classNames("form-input relative", inputError && "error")}>
                <input ref={inputRef} className="w-full" placeholder="DD/MM/YYYY" defaultValue={inputValue} type="text" onInput={formatInputValue} />

                {inputValue.length > 0 && (
                  <button type="button" onClick={() => onClear && onClear()} className="absolute right-5">
                    <svg xmlns="http://www.w3.org/2000/svg" width={22} height={22} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
      )}

      {editState === "year" && (
        <div style={{ width: boxRect?.width, height: boxRect?.height }} className="flex flex-col items-center">
          <div className="grid grid-cols-4 gap-5 w-full">
            {yearsRange?.map((year) => (
              <button
                key={year}
                onClick={() => setYear(year)}
                className={classNames(
                  "rounded font-semibold",
                  year !== date.year() && "hover:bg-blue-900 hover:text-white",
                  year === date.year() && "bg-light-blue-900 text-white"
                )}
              >
                {year}
              </button>
            ))}
          </div>
        </div>
      )}

      {editState === "month" && (
        <div style={{ width: boxRect?.width, height: boxRect?.height }}>
          <div className="grid grid-cols-4 gap-5 w-full">
            {months.map((m) => (
              <button
                key={m}
                onClick={() => setMonth(m)}
                className={classNames(
                  "rounded font-semibold",
                  m !== date.format("MMM") && "hover:bg-blue-900 hover:text-white",
                  m === date.format("MMM") && "bg-light-blue-900 text-white"
                )}
              >
                {m}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
});

export default Calendar;
