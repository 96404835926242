import { createContext } from "react";

interface State {
  linkModalOpen: boolean;
  linkText: string;
  linkUrl: string;
}

interface ContextState {
  editor: State;
  setEditor: (val: State) => void;
}

const defaultState: ContextState = {
  editor: {
    linkModalOpen: false,
    linkText: "",
    linkUrl: "",
  },
  setEditor: () => {},
};

const EditorContext = createContext<ContextState>(defaultState);

export default EditorContext;
