import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { NavLink, Redirect, Route, Switch, useHistory, useParams } from "react-router-dom";
import { useDocumentTitle } from "~/common/hooks";
import { useAuth } from "~/core/auth";
import LoginCode from "./LoginCode";
import LoginEmail from "./LoginEmail";

type LoginLogosURL = "default" | "danone" | "totalenergies" | "agriprove" | "loveto" | "ridgegroup" | "regeneration";

const loginLogos = {
  default: "/vectors/new-logo.svg",
  danone: "https://res.cloudinary.com/duvfftskw/image/upload/w_160,c_scale/v1640614231/danone_tildk5.png",
  totalenergies: "https://res.cloudinary.com/duvfftskw/image/upload/w_160,c_scale/v1640614523/totalenergies_ynrso8.png",
  agriprove: "https://res.cloudinary.com/duvfftskw/image/upload/w_160,c_scale/v1623673114/AgriProveLogoHiRes_acdsfm.jpg",
  loveto: "https://res.cloudinary.com/duvfftskw/image/upload/w_160,c_scale/v1648799367/LTBBG_cffiut.png",
  ridgegroup: "https://res.cloudinary.com/duvfftskw/image/upload/w_160,c_scale/v1652172152/rcc-logo_mkinjm.png",
  regeneration: "https://res.cloudinary.com/duvfftskw/image/upload/v1672743997/image_klnzcv.png",
};

const backgroundUrls: Partial<Record<LoginLogosURL, string>> = {
  default: "login-wizard.png",
  regeneration: "regeneration-login.jpg",
};

const Login: React.FC = () => {
  const { t } = useTranslation();
  useDocumentTitle(t("Login"));
  const { brand } = useParams<{ brand: LoginLogosURL }>();

  const [options, setLoginOptions] = useState({
    backgroundUrl: "",
    logoURL: "",
    showFooter: false,
  });

  const { logoURL, showFooter } = options;
  const [email, setEmail] = useState("");
  const { isLoggedIn } = useAuth();
  const history = useHistory();

  useEffect(() => {
    if (isLoggedIn) history.push("/account/confirmation");
  }, [isLoggedIn]);

  useEffect(() => {
    switch (brand) {
      case "danone":
      case "agriprove":
      case "totalenergies":
      case "loveto":
      case "ridgegroup":
      case "regeneration":
        setLoginOptions({
          backgroundUrl: backgroundUrls[brand] ?? backgroundUrls.default!,
          logoURL: loginLogos[brand],
          showFooter: true,
        });
        break;
      default:
        setLoginOptions({
          backgroundUrl: backgroundUrls.default!,
          logoURL: loginLogos.default,
          showFooter: false,
        });
        break;
    }
  }, [brand]);

  const handleEmail = (value: string) => {
    setEmail(value);
    history.push("./code");
  };

  return (
    <div className={classNames("h-screen overflow-hidden flex justify-center items-center", brand === "regeneration" ? "bg-regeneration" : "bg-grey-50")}>
      <div className="p-7 flex-1 min-w-max flex justify-center">
        <div className="flex flex-col justify-between gap-10 w-full max-w-2xl">
          <div>
            <NavLink to={brand ? `/${brand}/auth/login` : "/auth/login"}>
              <img src={logoURL} alt="Logo" style={{ width: brand === "regeneration" ? "260px" : "100px" }} />
            </NavLink>
          </div>

          <div className="p-8 bg-white drop-shadow-2xl border border-grey-200">
            <Switch>
              <Route exact path={["/auth", "/:brand/auth"]}>
                <Redirect to="auth/login" />
              </Route>
              <Route path={["/auth/login", "/:brand/auth/login"]}>
                <LoginEmail onSubmit={handleEmail} />
              </Route>
              <Route path={["/auth/code", "/:brand/auth/code"]}>
                <LoginCode email={email} />
              </Route>
            </Switch>
          </div>

          <Trans parent="div" className="text-grey-600" i18nKey="By continuing, you agree to Cecil's Terms of Service and Privacy policy">
            <div>View Cecil&apos;s</div>
            <a target="_blank" href="https://www.cecil.earth/terms-of-service" rel="noopener noreferrer">
              Terms of Service
            </a>
            <a target="_blank" href="https://www.cecil.earth/privacy-policy-navigation" rel="noopener noreferrer">
              Privacy policy
            </a>
          </Trans>
        </div>
      </div>

      <div
        className="h-screen w-6/12"
        style={{
          backgroundImage: `url('/vectors/login-background.svg')`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      />

      {showFooter && (
        <div className="fixed bottom-7 right-7 text-white font-sans flex items-center gap-4">
          <Trans parent="span">Powered by</Trans>
          <img alt="Light logo" src="/vectors/new-logo-light.svg" width={60} />
        </div>
      )}
    </div>
  );
};

export default Login;
