import classNames from "classnames";
import React, { ReactNode } from "react";
import { NavLink } from "react-router-dom";
import { CounterBadge } from "~/common/containers";

interface ItemProps {
  icon: ReactNode;
  label: string;
  path: string;
  counter?: number;
  labelClassName?: string;
  type?: "link" | "external" | "divider";
}

const NavBarItem: React.FC<ItemProps> = (props) => {
  const { icon, path, label, counter, labelClassName, type } = props;

  const isActive = (match: any, location: any) => {
    if (match) {
      const navSearch = new URLSearchParams(path.replace(location.pathname, "").replace("?replace=true&", ""));
      let isRealMatch = true;
      navSearch.forEach((value, key) => {
        if (!location.search.includes(`${key}=${value}`)) isRealMatch = false;
      });
      return isRealMatch;
    }
    return false;
  };

  return (
    <NavLink
      title={label}
      activeClassName="navlink-active"
      className="flex items-center hover:no-underline px-6 py-2 hover:bg-grey-100 text-blue-900"
      to={type === "external" ? { pathname: path } : path}
      target={type === "external" ? "_blank" : "_self"}
      style={{ height: "32px" }}
      isActive={isActive}
    >
      <div className="flex-1 flex items-center gap-4">
        <div className="navbar-icon-container">{icon}</div>
        <div className={classNames("font-bold", labelClassName)}>{label}</div>
      </div>
      {counter && counter > 0 ? (
        <div className="text-sm">
          <CounterBadge variant="red" counter={counter} width={20} height={22} border />
        </div>
      ) : null}
    </NavLink>
  );
};

export default NavBarItem;
