import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { projectTypeColour } from "~/helpers";
import { useProjectTypes } from "../hooks";
import { Project } from "../models";

type Props = {
  project: Project;
  className?: string;
  labelClassName?: string;
  showColor?: boolean;
};

const ProjectTypeLabel: React.FC<Props> = (props: Props) => {
  const { className, project, labelClassName, showColor = true } = props;
  const { projectTypes } = useProjectTypes();
  const [name, setName] = useState("--");
  const [colour, setColour] = useState("#F7F7F7");

  useEffect(() => {
    if (projectTypes) {
      const projectType = projectTypes.find((pType) => pType.id === project.projectTypeId);
      if (!projectType) return;

      const typeColour = projectTypeColour(projectType.id!);
      setName(projectType?.name ?? "--");
      setColour(typeColour);
    }
  }, [projectTypes, project.id]);

  return (
    <div className={classNames(className, "flex items-center")}>
      {showColor && <div className="rounded-full mr-2" style={{ backgroundColor: colour, width: "10px", height: "10px" }} />}
      <div className={labelClassName}>{name}</div>
    </div>
  );
};

export default ProjectTypeLabel;
