import classNames from "classnames";
import React, { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { sortContacts } from "~/core/projects";
import { listNotEmpty } from "~/common/utils";
import { displayName } from "../utils";
import ContactAvatar from "./ContactAvatar";
import { AssignedUser, LegacyPositionType } from "../models";

interface Props {
  assignedUsers: AssignedUser[];
  maxContacts?: number;
}

const ContactsColumn: React.FC<Props> = ({ assignedUsers, maxContacts = 5 }) => {
  const { t } = useTranslation();
  const contacts = useMemo(() => sortContacts(assignedUsers), [assignedUsers]);
  const contactsCounter = `+ ${contacts.length - maxContacts}`;

  return (
    <>
      {listNotEmpty(contacts) ? (
        <div className="flex items-center space-x-2 w-max cursor-default" onClick={(e) => e.stopPropagation()}>
          <div className="flex -space-x-3">
            {contacts.slice(0, maxContacts).map((contact: AssignedUser, i: number) => {
              const name = displayName(contact);
              const tooltip = (
                <div className="flex flex-col items-center">
                  <span className={contact.deactivatedAt || "font-semibold"}>{name}</span>
                  {contact.deactivatedAt && <span>{t("Contact deactivated", { ns: "contacts" })}</span>}
                </div>
              );
              const z = `z-${maxContacts - i}0`;
              const isOwner = contact?.assignedOwnerAt;

              const ownerProps = {
                active: !!isOwner,
                tooltipContent: (
                  <div className="flex flex-col items-center">
                    <Trans ns="projects" parent="div" className="font-semibold">
                      Project owner
                    </Trans>
                    <span>{name}</span>
                    {contact.deactivatedAt && <span>{t("Contact deactivated", { ns: "contacts" })}</span>}
                  </div>
                ),
              };

              return (
                <div data-testid="contact-avatars" className={classNames(z, contact.deactivatedAt && "deactivated-contact")} key={`${contact.id}`}>
                  <ContactAvatar
                    name={name}
                    deactivatedContact={!!contact.deactivatedAt}
                    avatarType={(contact.positionType || "internal") as LegacyPositionType}
                    tooltipContent={tooltip}
                    {...(isOwner && { ...ownerProps })}
                  />
                </div>
              );
            })}
          </div>
          {contacts.length > maxContacts && <div className="w-9 flex items-center text-grey-600 font-bold text-base">{contactsCounter}</div>}
        </div>
      ) : (
        <div>--</div>
      )}
    </>
  );
};

export default ContactsColumn;
