import moment, { Moment } from "moment-timezone";
import { lazy } from "react";
import { BaseStage } from "~/core/projects/models";
import { currencies, ScreenTypes } from "./models";
import { AutocompleteOption } from "./selectables";

const KEY = "force-refresh";
export const lazyRetry = (componentImport: any) =>
  lazy(async () => {
    const forceRefresh = JSON.parse(window.localStorage.getItem(KEY) || "false");

    try {
      const component = await componentImport();
      window.localStorage.setItem(KEY, "false");

      return component;
    } catch (error) {
      if (!forceRefresh) {
        window.localStorage.setItem(KEY, "true");
        return window.location.reload();
      }
      throw error;
    }
  });

export const getCurrencyMaxDigits = (currencyCode: string): number => {
  return currencies.find((c) => c.code === currencyCode)?.decimalDigits ?? 2;
};

export const priceToInt = (amount?: number, currencyCode?: string): number | null => {
  if (!amount || !currencyCode) return null;

  const currencyDigits = getCurrencyMaxDigits(currencyCode);
  return Math.round(amount * 10 ** currencyDigits);
};

export const intToPrice = (amount?: number, currencyCode?: string): number | null => {
  if (!amount || !currencyCode) return null;

  const currencyDigits = getCurrencyMaxDigits(currencyCode);
  return amount / 10 ** currencyDigits;
};

export const formatPrice = (amount: number, currency: string, defaultValue = "--"): string => {
  if (!amount || !currency) return defaultValue;

  const convertedValue = intToPrice(amount, currency);
  const { locale } = Intl.NumberFormat().resolvedOptions();
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency,
  }).format(convertedValue as number);
};

export const countDecimals = (num: number): number => {
  const text = num.toString();
  const index = text.indexOf(".");
  return index === -1 ? 0 : text.length - index - 1;
};

export const getDecimalSeparator = (): string => {
  const n = 1.1;
  return n.toLocaleString().substring(1, 2);
};

export const intToString = (value: number | string): string => {
  const num = Number(value);

  if (num < 1000) {
    return num.toString();
  }

  const si = [
    { v: 1e3, s: "K" },
    { v: 1e6, s: "M" },
    { v: 1e9, s: "B" },
    { v: 1e12, s: "T" },
    { v: 1e15, s: "P" },
    { v: 1e18, s: "E" },
  ];

  let i;

  for (i = si.length - 1; i > 0; i -= 1) {
    if (num >= si[i].v) {
      break;
    }
  }

  const numValue = (num / si[i].v).toFixed(2).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, "$1");

  const suffix = si[i].s;

  return `${numValue} ${suffix}`;
};

export function calcPercentage(percent: number, total: number = 100): number {
  let percentage = +((percent / 100) * total).toFixed(2);

  percentage = percentage > total ? total : percentage;

  return percentage;
}

export function formatDate(date?: string | Moment | Date | null, defaultValue?: string, withTime?: boolean): string {
  if (!date) return defaultValue || "";

  let dateFormat = "D MMM, YY";
  if (withTime) {
    dateFormat = `${dateFormat} [at] h:mm a`;
  }

  return moment(date).format(dateFormat);
}

export const listNotEmpty = (list?: Array<any>): boolean => {
  return (list?.length ?? 0) > 0;
};

export const objectsDiff = (o1: any, o2: any) => {
  return Object.keys(o2).reduce((diff, key) => {
    if (o1[key] === o2[key]) return diff;
    return {
      ...diff,
      [key]: o2[key],
    };
  }, {} as any);
};

export const isArrayEqual = (a: string[], b: string[]): boolean => {
  if (a.length !== b.length) return false;
  const uniqueValues = new Set([...a, ...b]);
  // eslint-disable-next-line no-restricted-syntax
  for (const v of uniqueValues) {
    const aCount = a.filter((e) => e === v).length;
    const bCount = b.filter((e) => e === v).length;
    if (aCount !== bCount) return false;
  }
  return true;
};

export const objectToUrlParams = (obj: any): string | null => {
  if (obj && Object.keys(obj).length > 0 && obj.constructor === Object) {
    return Object.keys(obj)
      .map((key) => `${key}=${encodeURIComponent(obj[key])}`)
      .join("&");
  }

  return null;
};

export const getLang = () => localStorage.getItem("i18nextLng") || "en-AU";
export const getMeasurement = () => localStorage.getItem("measurement") || "hectares";

export const isValidEmail = (email = ""): boolean => {
  const valid =
    email.trim().length === 0 ||
    /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i.test(email);
  return valid;
};

export const isValidURL = (url: string): boolean => {
  let httpUrl = url;
  if (!url.startsWith("http")) {
    httpUrl = `https://${httpUrl}`;
  }

  const reg = new RegExp("((http|https)://)(www.)?[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)");
  return !!httpUrl.match(reg);
};

const getScreenTypes = (): ScreenTypes => {
  const viewportWidth = window.innerWidth || document.documentElement.clientWidth;

  const tablet = viewportWidth >= 768 && viewportWidth <= 1024;
  const mobile = viewportWidth < 768;

  return {
    tablet,
    mobile,
    desktop: !tablet && !mobile,
  };
};

export const screenDetection = (): {
  isTouchDevice: boolean;
  isTablet: boolean;
  isMobile: boolean;
  isResponsive: ScreenTypes;
  screenType: ScreenTypes;
} => {
  const { userAgent } = navigator;
  const isAndroid = Boolean(userAgent.match(/Android/i));
  const isIos = Boolean(userAgent.match(/iPhone|iPad/i));
  const isWindows = Boolean(userAgent.match(/IEMobile/i));
  const { mobile, tablet, desktop } = getScreenTypes();
  const isTouchDevice = isAndroid || isIos || isWindows;
  const isMobile = isTouchDevice && mobile;
  const isTablet = isTouchDevice && tablet;
  const isResponsive: ScreenTypes = {
    mobile: !isTouchDevice && mobile,
    tablet: !isTouchDevice && tablet,
    desktop: !isTouchDevice && desktop,
  };

  return {
    isMobile,
    isTablet,
    isResponsive,
    isTouchDevice,
    screenType: { mobile, tablet, desktop },
  };
};

export type PromiserResolver<T> = Promise<[T, any]>;
export const promiser = async <T>(promise: Promise<T>): PromiserResolver<T> => {
  try {
    const data = await promise;
    return [data, null];
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    return [{} as T, error];
  }
};

export const formatNumber = (value: number | bigint): string => {
  return new Intl.NumberFormat(getLang()).format(value);
};

function preventDefault(e: Event) {
  e.preventDefault();
}

const keys = { 37: 1, 38: 1, 39: 1, 40: 1 };
function preventDefaultForScrollKeys(e: Event) {
  const code = (e as any).keyCode;
  if (Object.getOwnPropertyDescriptor(keys, code)) {
    preventDefault(e);
    return false;
  }
  return true;
}

export const disableScroll = () => {
  const wheelEvent = "onwheel" in document.createElement("div") ? "wheel" : "mousewheel";

  window.addEventListener("DOMMouseScroll", preventDefault, false); // older FF
  window.addEventListener(wheelEvent, preventDefault, { passive: false }); // modern desktop
  window.addEventListener("touchmove", preventDefault, { passive: false }); // mobile
  window.addEventListener("keydown", preventDefaultForScrollKeys, false);
};

export const enableScroll = () => {
  const wheelEvent = "onwheel" in document.createElement("div") ? "wheel" : "mousewheel";

  window.removeEventListener("DOMMouseScroll", preventDefault, false);
  window.removeEventListener(wheelEvent, preventDefault);
  window.removeEventListener("touchmove", preventDefault);
  window.removeEventListener("keydown", preventDefaultForScrollKeys, false);
};

export const dateFromNow = (date: string) => {
  const d = moment(date);

  return moment(d, "DD MMM, YY").calendar(null, {
    lastDay: "[Yesterday]",
    sameDay: "[Today]",
    lastWeek: "D MMM, YY",
    sameElse: "D MMM, YY",
  });
};

export const getISODate = (date?: Date | string | null) => {
  let isoDate = null;
  if (date) isoDate = `${moment(date).format("YYYY-MM-DDT00:00:00.000")}Z`;

  return isoDate;
};

export const TIMEZONES = [
  "Africa/Abidjan",
  "Africa/Accra",
  "Africa/Addis_Ababa",
  "Africa/Algiers",
  "Africa/Asmera",
  "Africa/Bamako",
  "Africa/Bangui",
  "Africa/Banjul",
  "Africa/Bissau",
  "Africa/Blantyre",
  "Africa/Brazzaville",
  "Africa/Bujumbura",
  "Africa/Cairo",
  "Africa/Casablanca",
  "Africa/Ceuta",
  "Africa/Conakry",
  "Africa/Dakar",
  "Africa/Dar_es_Salaam",
  "Africa/Djibouti",
  "Africa/Douala",
  "Africa/El_Aaiun",
  "Africa/Freetown",
  "Africa/Gaborone",
  "Africa/Harare",
  "Africa/Johannesburg",
  "Africa/Juba",
  "Africa/Kampala",
  "Africa/Khartoum",
  "Africa/Kigali",
  "Africa/Kinshasa",
  "Africa/Lagos",
  "Africa/Libreville",
  "Africa/Lome",
  "Africa/Luanda",
  "Africa/Lubumbashi",
  "Africa/Lusaka",
  "Africa/Malabo",
  "Africa/Maputo",
  "Africa/Maseru",
  "Africa/Mbabane",
  "Africa/Mogadishu",
  "Africa/Monrovia",
  "Africa/Nairobi",
  "Africa/Ndjamena",
  "Africa/Niamey",
  "Africa/Nouakchott",
  "Africa/Ouagadougou",
  "Africa/Porto-Novo",
  "Africa/Sao_Tome",
  "Africa/Tripoli",
  "Africa/Tunis",
  "Africa/Windhoek",
  "America/Adak",
  "America/Anchorage",
  "America/Anguilla",
  "America/Antigua",
  "America/Araguaina",
  "America/Argentina/La_Rioja",
  "America/Argentina/Rio_Gallegos",
  "America/Argentina/Salta",
  "America/Argentina/San_Juan",
  "America/Argentina/San_Luis",
  "America/Argentina/Tucuman",
  "America/Argentina/Ushuaia",
  "America/Aruba",
  "America/Asuncion",
  "America/Bahia",
  "America/Bahia_Banderas",
  "America/Barbados",
  "America/Belem",
  "America/Belize",
  "America/Blanc-Sablon",
  "America/Boa_Vista",
  "America/Bogota",
  "America/Boise",
  "America/Buenos_Aires",
  "America/Cambridge_Bay",
  "America/Campo_Grande",
  "America/Cancun",
  "America/Caracas",
  "America/Catamarca",
  "America/Cayenne",
  "America/Cayman",
  "America/Chicago",
  "America/Chihuahua",
  "America/Coral_Harbour",
  "America/Cordoba",
  "America/Costa_Rica",
  "America/Creston",
  "America/Cuiaba",
  "America/Curacao",
  "America/Danmarkshavn",
  "America/Dawson",
  "America/Dawson_Creek",
  "America/Denver",
  "America/Detroit",
  "America/Dominica",
  "America/Edmonton",
  "America/Eirunepe",
  "America/El_Salvador",
  "America/Fort_Nelson",
  "America/Fortaleza",
  "America/Glace_Bay",
  "America/Godthab",
  "America/Goose_Bay",
  "America/Grand_Turk",
  "America/Grenada",
  "America/Guadeloupe",
  "America/Guatemala",
  "America/Guayaquil",
  "America/Guyana",
  "America/Halifax",
  "America/Havana",
  "America/Hermosillo",
  "America/Indiana/Knox",
  "America/Indiana/Marengo",
  "America/Indiana/Petersburg",
  "America/Indiana/Tell_City",
  "America/Indiana/Vevay",
  "America/Indiana/Vincennes",
  "America/Indiana/Winamac",
  "America/Indianapolis",
  "America/Inuvik",
  "America/Iqaluit",
  "America/Jamaica",
  "America/Jujuy",
  "America/Juneau",
  "America/Kentucky/Monticello",
  "America/Kralendijk",
  "America/La_Paz",
  "America/Lima",
  "America/Los_Angeles",
  "America/Louisville",
  "America/Lower_Princes",
  "America/Maceio",
  "America/Managua",
  "America/Manaus",
  "America/Marigot",
  "America/Martinique",
  "America/Matamoros",
  "America/Mazatlan",
  "America/Mendoza",
  "America/Menominee",
  "America/Merida",
  "America/Metlakatla",
  "America/Mexico_City",
  "America/Miquelon",
  "America/Moncton",
  "America/Monterrey",
  "America/Montevideo",
  "America/Montreal",
  "America/Montserrat",
  "America/Nassau",
  "America/New_York",
  "America/Nipigon",
  "America/Nome",
  "America/Noronha",
  "America/North_Dakota/Beulah",
  "America/North_Dakota/Center",
  "America/North_Dakota/New_Salem",
  "America/Ojinaga",
  "America/Panama",
  "America/Pangnirtung",
  "America/Paramaribo",
  "America/Phoenix",
  "America/Port-au-Prince",
  "America/Port_of_Spain",
  "America/Porto_Velho",
  "America/Puerto_Rico",
  "America/Punta_Arenas",
  "America/Rainy_River",
  "America/Rankin_Inlet",
  "America/Recife",
  "America/Regina",
  "America/Resolute",
  "America/Rio_Branco",
  "America/Santa_Isabel",
  "America/Santarem",
  "America/Santiago",
  "America/Santo_Domingo",
  "America/Sao_Paulo",
  "America/Scoresbysund",
  "America/Sitka",
  "America/St_Barthelemy",
  "America/St_Johns",
  "America/St_Kitts",
  "America/St_Lucia",
  "America/St_Thomas",
  "America/St_Vincent",
  "America/Swift_Current",
  "America/Tegucigalpa",
  "America/Thule",
  "America/Thunder_Bay",
  "America/Tijuana",
  "America/Toronto",
  "America/Tortola",
  "America/Vancouver",
  "America/Whitehorse",
  "America/Winnipeg",
  "America/Yakutat",
  "America/Yellowknife",
  "Antarctica/Casey",
  "Antarctica/Davis",
  "Antarctica/DumontDUrville",
  "Antarctica/Macquarie",
  "Antarctica/Mawson",
  "Antarctica/McMurdo",
  "Antarctica/Palmer",
  "Antarctica/Rothera",
  "Antarctica/Syowa",
  "Antarctica/Troll",
  "Antarctica/Vostok",
  "Arctic/Longyearbyen",
  "Asia/Aden",
  "Asia/Almaty",
  "Asia/Amman",
  "Asia/Anadyr",
  "Asia/Aqtau",
  "Asia/Aqtobe",
  "Asia/Ashgabat",
  "Asia/Atyrau",
  "Asia/Baghdad",
  "Asia/Bahrain",
  "Asia/Baku",
  "Asia/Bangkok",
  "Asia/Barnaul",
  "Asia/Beirut",
  "Asia/Bishkek",
  "Asia/Brunei",
  "Asia/Calcutta",
  "Asia/Chita",
  "Asia/Choibalsan",
  "Asia/Colombo",
  "Asia/Damascus",
  "Asia/Dhaka",
  "Asia/Dili",
  "Asia/Dubai",
  "Asia/Dushanbe",
  "Asia/Famagusta",
  "Asia/Gaza",
  "Asia/Hebron",
  "Asia/Hong_Kong",
  "Asia/Hovd",
  "Asia/Irkutsk",
  "Asia/Jakarta",
  "Asia/Jayapura",
  "Asia/Jerusalem",
  "Asia/Kabul",
  "Asia/Kamchatka",
  "Asia/Karachi",
  "Asia/Katmandu",
  "Asia/Khandyga",
  "Asia/Krasnoyarsk",
  "Asia/Kuala_Lumpur",
  "Asia/Kuching",
  "Asia/Kuwait",
  "Asia/Macau",
  "Asia/Magadan",
  "Asia/Makassar",
  "Asia/Manila",
  "Asia/Muscat",
  "Asia/Nicosia",
  "Asia/Novokuznetsk",
  "Asia/Novosibirsk",
  "Asia/Omsk",
  "Asia/Oral",
  "Asia/Phnom_Penh",
  "Asia/Pontianak",
  "Asia/Pyongyang",
  "Asia/Qatar",
  "Asia/Qostanay",
  "Asia/Qyzylorda",
  "Asia/Rangoon",
  "Asia/Riyadh",
  "Asia/Saigon",
  "Asia/Sakhalin",
  "Asia/Samarkand",
  "Asia/Seoul",
  "Asia/Shanghai",
  "Asia/Singapore",
  "Asia/Srednekolymsk",
  "Asia/Taipei",
  "Asia/Tashkent",
  "Asia/Tbilisi",
  "Asia/Tehran",
  "Asia/Thimphu",
  "Asia/Tokyo",
  "Asia/Tomsk",
  "Asia/Ulaanbaatar",
  "Asia/Urumqi",
  "Asia/Ust-Nera",
  "Asia/Vientiane",
  "Asia/Vladivostok",
  "Asia/Yakutsk",
  "Asia/Yekaterinburg",
  "Asia/Yerevan",
  "Atlantic/Azores",
  "Atlantic/Bermuda",
  "Atlantic/Canary",
  "Atlantic/Cape_Verde",
  "Atlantic/Faeroe",
  "Atlantic/Madeira",
  "Atlantic/Reykjavik",
  "Atlantic/South_Georgia",
  "Atlantic/St_Helena",
  "Atlantic/Stanley",
  "Australia/Adelaide",
  "Australia/Brisbane",
  "Australia/Broken_Hill",
  "Australia/Currie",
  "Australia/Darwin",
  "Australia/Eucla",
  "Australia/Hobart",
  "Australia/Lindeman",
  "Australia/Lord_Howe",
  "Australia/Melbourne",
  "Australia/Perth",
  "Australia/Sydney",
  "Europe/Amsterdam",
  "Europe/Andorra",
  "Europe/Astrakhan",
  "Europe/Athens",
  "Europe/Belgrade",
  "Europe/Berlin",
  "Europe/Bratislava",
  "Europe/Brussels",
  "Europe/Bucharest",
  "Europe/Budapest",
  "Europe/Busingen",
  "Europe/Chisinau",
  "Europe/Copenhagen",
  "Europe/Dublin",
  "Europe/Gibraltar",
  "Europe/Guernsey",
  "Europe/Helsinki",
  "Europe/Isle_of_Man",
  "Europe/Istanbul",
  "Europe/Jersey",
  "Europe/Kaliningrad",
  "Europe/Kiev",
  "Europe/Kirov",
  "Europe/Lisbon",
  "Europe/Ljubljana",
  "Europe/London",
  "Europe/Luxembourg",
  "Europe/Madrid",
  "Europe/Malta",
  "Europe/Mariehamn",
  "Europe/Minsk",
  "Europe/Monaco",
  "Europe/Moscow",
  "Europe/Oslo",
  "Europe/Paris",
  "Europe/Podgorica",
  "Europe/Prague",
  "Europe/Riga",
  "Europe/Rome",
  "Europe/Samara",
  "Europe/San_Marino",
  "Europe/Sarajevo",
  "Europe/Saratov",
  "Europe/Simferopol",
  "Europe/Skopje",
  "Europe/Sofia",
  "Europe/Stockholm",
  "Europe/Tallinn",
  "Europe/Tirane",
  "Europe/Ulyanovsk",
  "Europe/Uzhgorod",
  "Europe/Vaduz",
  "Europe/Vatican",
  "Europe/Vienna",
  "Europe/Vilnius",
  "Europe/Volgograd",
  "Europe/Warsaw",
  "Europe/Zagreb",
  "Europe/Zaporozhye",
  "Europe/Zurich",
  "Indian/Antananarivo",
  "Indian/Chagos",
  "Indian/Christmas",
  "Indian/Cocos",
  "Indian/Comoro",
  "Indian/Kerguelen",
  "Indian/Mahe",
  "Indian/Maldives",
  "Indian/Mauritius",
  "Indian/Mayotte",
  "Indian/Reunion",
  "Pacific/Apia",
  "Pacific/Auckland",
  "Pacific/Bougainville",
  "Pacific/Chatham",
  "Pacific/Easter",
  "Pacific/Efate",
  "Pacific/Enderbury",
  "Pacific/Fakaofo",
  "Pacific/Fiji",
  "Pacific/Funafuti",
  "Pacific/Galapagos",
  "Pacific/Gambier",
  "Pacific/Guadalcanal",
  "Pacific/Guam",
  "Pacific/Honolulu",
  "Pacific/Johnston",
  "Pacific/Kiritimati",
  "Pacific/Kosrae",
  "Pacific/Kwajalein",
  "Pacific/Majuro",
  "Pacific/Marquesas",
  "Pacific/Midway",
  "Pacific/Nauru",
  "Pacific/Niue",
  "Pacific/Norfolk",
  "Pacific/Noumea",
  "Pacific/Pago_Pago",
  "Pacific/Palau",
  "Pacific/Pitcairn",
  "Pacific/Ponape",
  "Pacific/Port_Moresby",
  "Pacific/Rarotonga",
  "Pacific/Saipan",
  "Pacific/Tahiti",
  "Pacific/Tarawa",
  "Pacific/Tongatapu",
  "Pacific/Truk",
  "Pacific/Wake",
  "Pacific/Wallis",
];

export const getTimezoneOptions = (): AutocompleteOption[] => {
  const timezoneOptions = TIMEZONES.map((timeZone) => {
    // Get time offset
    const GMT = moment().tz(timeZone).format("Z");

    // Get the standar long name of the timezone
    const longName = new Date()
      .toLocaleDateString(getLang(), {
        day: "2-digit",
        timeZone,
        timeZoneName: "long",
      })
      .slice(4);

    const location = timeZone.split("/").pop()?.replace("_", " ");

    let label = `(GMT${GMT})`;

    if (!longName.startsWith("GMT")) {
      label = `${label} ${longName}`;
      if (location) label = `${label} - ${location}`;
    } else {
      label = `${label} ${location}`;
    }

    return {
      label,
      value: timeZone,
      meta: Number(GMT.replace(":", "")),
    };
  });

  return timezoneOptions.sort((a, b) => a.meta - b.meta);
};

export const createObjectWithValues = <T>(obj: string[], value: T): Record<string, T> => {
  return obj.reduce(
    (prev, curr) => ({
      ...prev,
      [curr]: value,
    }),
    {} as Record<string, T>
  );
};

export const countStageDays = (stage: BaseStage): string => {
  if (!stage.startedAt) return "";

  const now = moment();
  const diff = now.diff(stage.startedAt, "days");
  if (diff > 31 * 12) {
    const years = now.diff(stage.startedAt, "years");
    return `${years} year${years > 1 ? "s" : ""}`;
  }
  if (diff > 31) {
    const months = now.diff(stage.startedAt, "months");
    return `${months} month${months > 1 ? "s" : ""}`;
  }

  const days = diff === 1 ? "day" : "days";
  return diff === 0 ? "Today" : `${diff.toString()} ${days}`;
};
