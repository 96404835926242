/* eslint-disable react/destructuring-assignment */
import classNames from "classnames";
import React, { ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import Select, { components as c } from "react-select";
import AsyncSelect from "react-select/async";
import Creatable from "react-select/creatable";
import { ExclusionFilter } from "~/common/forms/exclusion-filter";
import { AutocompleteProps, Variant } from "../models";
import { customStyles } from "./AutocompleteStyles";

const components = {
  IndicatorSeparator: () => <span />,
  MultiValueLabel: (props: any) => <div className="multi-value-label">{props.data.label}</div>,
  DropdownIndicator: () => {
    return (
      <div className="mr-2">
        <svg xmlns="http://www.w3.org/2000/svg" width={22.03} height={22.03} fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
        </svg>
      </div>
    );
  },
};

function htmlLabel(label: string, id?: string, required?: boolean, readOnly?: boolean, className?: string): ReactNode {
  return (
    <label htmlFor={id ?? ""} className={classNames("label-area", className, required && !readOnly && "required")}>
      {label}
    </label>
  );
}

const Description: React.FC<{ text?: string }> = ({ text }) => {
  if (!text) return null;

  return <div className="text-base font-sans text-grey-800 mt-2">{text}</div>;
};

const ErrorMessage: React.FC<{ errorMessage?: string }> = ({ errorMessage }) => {
  if (!errorMessage) return null;

  return <div className="text-red-900 font-sans font-semibold text-base">{errorMessage}</div>;
};

function Autocomplete(props: AutocompleteProps) {
  const { t } = useTranslation();
  const {
    description,
    value,
    asyncSelect = false,
    isClearable = true,
    hasError = false,
    required = false,
    blurInputOnSelect = true,
    exclusionFilterName = "",
    exclusionFilterValue = false,
    customComponents,
    className,
    menuWidth,
    selectedOption,
    errorMessage,
    variant,
    readOnly,
    readOnlyComponent,
    menuIsOpen,
    asModal = true,
    isFieldGrid = true,
    placeholder = t("Select an option"),
    onExclusionChange,
    ...parentProps
  } = props;

  const [isOpen, setIsOpen] = useState<boolean>(!!menuIsOpen);

  const values = typeof value === "string" ? value?.split(",")?.map((val) => props.options?.find((o) => o.value === val)) ?? [] : value;

  const commonProps = {
    components: { ...components, ...customComponents },
    styles: customStyles({
      menuWidth,
      hasError,
      variant,
    }),
    className: "react-select-container",
    classNamePrefix: variant === Variant.SECONDARY ? "react-select-secondary" : "react-select",
    isClearable,
    blurInputOnSelect,
    menuIsOpen: isOpen,
    onMenuOpen: () => {
      setIsOpen(true);
    },
    onMenuClose: () => {
      setIsOpen(false);
    },
  } as AutocompleteProps;

  const withProps = {
    ...parentProps,
    ...commonProps,
  } as AutocompleteProps;

  const label = props.label && htmlLabel(props.label, props.inputId, required, readOnly, props.labelClassName);

  const wrapperClassName = classNames(isFieldGrid && "field-grid", className);

  let selectComponent: any = <Select {...withProps} placeholder={placeholder} value={values} />;
  let readOnlyValues: any = readOnlyComponent || "value";
  if (props.creatable) {
    selectComponent = <Creatable {...withProps} placeholder={placeholder} value={values} />;
    readOnlyValues = readOnlyComponent || values;
  } else if (asyncSelect) {
    selectComponent = <AsyncSelect {...withProps} placeholder={placeholder} value={selectedOption} />;
    readOnlyValues = readOnlyComponent || selectedOption;
  }

  return (
    <>
      {asModal && isOpen && (
        <div
          className="fixed z-50 top-0 left-0 h-full w-full "
          onClick={() => {
            setIsOpen(false);
          }}
        />
      )}

      <div className={wrapperClassName}>
        <div className="flex items-center justify-between">
          {label}

          <div>
            {exclusionFilterName && onExclusionChange && <ExclusionFilter defaultValue={exclusionFilterValue} name={exclusionFilterName} onChange={onExclusionChange} />}
          </div>
        </div>

        {readOnly ? (
          <div className="field-area">{readOnlyValues}</div>
        ) : (
          <>
            {selectComponent}
            <ErrorMessage errorMessage={errorMessage} />
            <Description text={description} />
          </>
        )}
      </div>
    </>
  );
}

export default Autocomplete;
