import axios, { AxiosRequestConfig } from "axios";
import { retrieveToken } from "~/core/auth";
import { getStoredOrg } from "../core/org";
import { NotFoundError, ServerError, UnauthorizedError } from "./http/utils";

declare module "axios" {
  interface AxiosInstance {
    request<T = any>(config: AxiosRequestConfig): Promise<T>;
    get<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>;
    delete<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>;
    head<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>;
    post<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>;
    put<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>;
    patch<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>;
  }
}

axios.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    return retrieveToken().then((response: any) => {
      const options = {
        ...config,
        headers: {
          ...config.headers,
        },
      };

      const orgId = getStoredOrg();
      if (orgId) {
        options.headers["Cecil-Active-Org-ID"] = orgId;
      }

      if (!config.url?.startsWith("https://cecil-api-file-uploads")) {
        options.headers.Authorization = `Bearer ${response.accessToken}`;
      }

      return options;
    });
  },
  (error) => {
    // @TODO handle error globally maybe?
    // eslint-disable-next-line no-console
    console.error(error);
  }
);

axios.interceptors.response.use(
  (response) => response.data,
  (error) => {
    if (error.code === "login_required" && !window.location.href.includes("/auth")) {
      window.location.href = "/auth/login";
      return;
    }

    // TODO: find a way to use this error types with redux/toolkit async thunks
    const { response, config } = error;

    if (config) {
      const errorMessage = `${config.method?.toUpperCase() || "GET"} ${config.url ?? ""}
      Cecil-Active-Org-ID: ${config.headers["Cecil-Active-Org-ID"] ?? ""}
      Body: ${JSON.stringify(config.body ?? {})}
      Message: ${error.message}`;

      let err: Error;
      switch (response?.status) {
        case 401:
          err = new UnauthorizedError(errorMessage);
          break;
        case 404:
          err = new NotFoundError(errorMessage);
          break;
        case 500:
          err = new ServerError(errorMessage);
          break;
        default:
          err = error;
          break;
      }

      // eslint-disable-next-line no-console
      console.error(err);
    } else {
      // eslint-disable-next-line no-console
      console.error(new Error("api client error"));
    }
  }
);

export default axios;
