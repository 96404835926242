import classNames from "classnames";
import React, { CSSProperties, ReactNode, useEffect, useRef, useState, MouseEvent } from "react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "~/common/containers/tooltip";
import { TooltipPosition } from "~/common/containers/tooltip/models";
import { useToast } from "~/common/toasts";

interface Props {
  text?: string | ReactNode | null;
  delay?: number;
  style?: CSSProperties;
  showTooltip?: boolean;
  forceShowTooltip?: boolean;
  copy?: boolean;
  tooltipPosition?: TooltipPosition;
  tooltipStyles?: CSSProperties;
}

export const TruncateText: React.FC<Props> = ({
  text,
  delay = 800,
  style,
  copy,
  forceShowTooltip = false,
  tooltipPosition = "TOP",
  tooltipStyles,
  showTooltip = true,
}) => {
  const { t } = useTranslation();
  const textRef = useRef<HTMLDivElement | null>(null);
  const [isShown, setIsShown] = useState(false);
  const [active, setActive] = useState(false);
  const tooltipTimeout = useRef<number>(0);

  const { toastSuccess } = useToast();

  useEffect(() => {
    const element = textRef?.current!;
    setActive(forceShowTooltip || (element.scrollWidth > element.clientWidth && showTooltip));
  }, [text]);

  const onCopyReference = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();

    if (!copy) return;
    if (typeof text === "string") {
      navigator.clipboard.writeText(text);
      toastSuccess({ message: t("Copied to clipboard") });
      setIsShown(false);
    }
  };

  const handleOnMouseEnter = () => {
    clearTimeout(tooltipTimeout.current!);
    tooltipTimeout.current = window.setTimeout(() => {
      setIsShown(true);
    }, delay);
  };

  const handleOnMouseLeave = () => {
    clearTimeout(tooltipTimeout.current!);
    tooltipTimeout.current = window.setTimeout(() => {
      setIsShown(false);
    }, 150);
  };

  useEffect(() => {
    return () => {
      clearTimeout(tooltipTimeout.current);
    };
  }, []);

  return (
    <>
      <div
        className={classNames("whitespace-nowrap overflow-hidden truncate", copy && active && "underline")}
        style={style}
        ref={textRef}
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
      >
        {text}
      </div>

      <Tooltip
        className="break-all"
        style={{
          maxWidth: "280px",
          height: "auto",
          zIndex: 100,
          ...tooltipStyles,
        }}
        visible={active && isShown}
        objRef={textRef}
        position={tooltipPosition}
      >
        <div onMouseEnter={handleOnMouseEnter} onMouseLeave={handleOnMouseLeave} className="flex items-center">
          <div className={classNames("flex flex-col gap-4", copy && "cursor-pointer")} onClick={onCopyReference}>
            {copy ? (
              <div className="flex items-center gap-4 text-grey-400">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M6.66666 13.3333H4.99999C4.07952 13.3333 3.33333 12.5871 3.33333 11.6667V5C3.33333 4.07953 4.07952 3.33333 4.99999 3.33333H11.6667C12.5871 3.33333 13.3333 4.07953 13.3333 5V6.66667M8.33333 16.6667H15C15.9205 16.6667 16.6667 15.9205 16.6667 15V8.33333C16.6667 7.41286 15.9205 6.66667 15 6.66667H8.33333C7.41285 6.66667 6.66666 7.41286 6.66666 8.33333V15C6.66666 15.9205 7.41285 16.6667 8.33333 16.6667Z"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <span className="flex-1 text-grey-200">{text}</span>
              </div>
            ) : (
              <span className="flex-1">{text}</span>
            )}
          </div>
        </div>
      </Tooltip>
    </>
  );
};
