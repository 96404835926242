import moment from "moment";
import { createInstance, OptimizelyProvider } from "@optimizely/react-sdk";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import "mapbox-gl/dist/mapbox-gl.css";
import React from "react";
import ReactDOM from "react-dom";
import { IntercomProvider } from "react-use-intercom";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import App from "./App";
import AuthProvider from "./core/auth/components/AuthProvider";

const dsn = process.env.REACT_APP_SENTRY_DSN ?? "";
const env = process.env.REACT_APP_ENV;
const intercom = process.env.REACT_APP_INTERCOM_ID as string;

if (env !== "local") {
  Sentry.init({
    dsn,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    environment: env,
    release: process.env.REACT_APP_SENTRY_RELEASE || "822cd8f1",
  });
}

let optimizely;
if (env !== "production") {
  optimizely = createInstance({ sdkKey: "VSEi53P1cak72ts6L3mqG" });
} else {
  optimizely = createInstance({ sdkKey: "2Dgnnpw6g14iJLTDnB5uz" });
}

// set moment locales
moment.locale("fr", {
  months: "janvier_février_mars_avril_mai_juin_juillet_août_septembre_octobre_novembre_décembre".split("_"),
  monthsShort: "janv._févr._mars._avr._mai._juin._juil._août._sept._oct._nov._déc.".split("_"),
  monthsParseExact: true,
  weekdays: "dimanche_lundi_mardi_mercredi_jeudi_vendredi_samedi".split("_"),
  weekdaysShort: "dim._lun._mar._mer._jeu._ven._sam.".split("_"),
  weekdaysMin: "Di_Lu_Ma_Me_Je_Ve_Sa".split("_"),
  weekdaysParseExact: true,
  longDateFormat: {
    LT: "HH:mm",
    LTS: "HH:mm:ss",
    L: "DD/MM/YYYY",
    LL: "D MMMM YYYY",
    LLL: "D MMMM YYYY HH:mm",
    LLLL: "dddd D MMMM YYYY HH:mm",
  },
  calendar: {
    sameDay: "[Aujourd’hui à] LT",
    nextDay: "[Demain à] LT",
    nextWeek: "dddd [à] LT",
    lastDay: "[Hier à] LT",
    lastWeek: "dddd [dernier à] LT",
    sameElse: "L",
  },
  relativeTime: {
    future: "dans %s",
    past: "il y a %s",
    s: "quelques secondes",
    m: "une minute",
    mm: "%d minutes",
    h: "une heure",
    hh: "%d heures",
    d: "un jour",
    dd: "%d jours",
    M: "un mois",
    MM: "%d mois",
    y: "un an",
    yy: "%d ans",
  },
  dayOfMonthOrdinalParse: /\d{1,2}(er|e)/,
  ordinal: (number) => {
    return number + (number === 1 ? "er" : "e");
  },
  meridiemParse: /PD|MD/,
  isPM: (input) => input.charAt(0) === "M",
  // In case the meridiem units are not separated around 12, then implement
  // this function (look at locale/id.js for an example).
  // meridiemHour : function (hour, meridiem) {
  //     return /* 0-23 hour, given meridiem token and hour 1-12 */ ;
  // },
  meridiem: (hours) => (hours < 12 ? "PD" : "MD"),
  week: {
    dow: 1, // Monday is the first day of the week.
    doy: 4, // Used to determine first week of the year.
  },
});

moment.locale("es", {
  months: "Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre".split("_"),
  monthsShort: "Ene_Feb_Mar_Abr_May_Jun_Jul_Ago_Sep_Oct_Nov_Dic".split("_"),
  monthsParseExact: true,
  weekdays: "Domingo_Lunes_Martes_Miércoles_Jueves_Viernes_Sábado".split("_"),
  weekdaysShort: "Dom_Lun_Mar_Mie_Jue_Vie_Sab".split("_"),
  weekdaysMin: "Do_Lu_Ma_Mi_Ju_Vi_Sa".split("_"),
  weekdaysParseExact: true,
  longDateFormat: {
    LT: "HH:mm",
    LTS: "HH:mm:ss",
    L: "DD/MM/YYYY",
    LL: "D MMMM YYYY",
    LLL: "D MMMM YYYY HH:mm",
    LLLL: "dddd D MMMM YYYY HH:mm",
  },
  calendar: {
    sameDay: "[Hoy a las] LT",
    nextDay: "[Mañana a] LT",
    nextWeek: "dddd [a] LT",
    lastDay: "[Ayer a las] LT",
    lastWeek: "dddd [último en] LT",
    sameElse: "L",
  },
  relativeTime: {
    future: "dentro de %s",
    past: "hace %s",
    s: "algunos segundos",
    m: "un minuto",
    mm: "%d minutos",
    h: "una hora",
    hh: "%d horas",
    d: "un día",
    dd: "%d días",
    M: "un mes",
    MM: "%d meses",
    y: "un año",
    yy: "%d años",
  },
  dayOfMonthOrdinalParse: /\d{1,2}(er|e)/,
  ordinal: (number) => {
    let sufix: string;
    switch (number) {
      case 1:
      case 3:
        sufix = "ro";
        break;
      case 2:
        sufix = "do";
        break;
      case 7:
      case 10:
        sufix = "mo";
        break;
      case 8:
        sufix = "vo";
        break;
      case 9:
        sufix = "no";
        break;
      default:
        sufix = "to";
        break;
    }
    return number + sufix;
  },
  meridiemParse: /PD|MD/,
  isPM: (input) => input.charAt(0) === "M",
  // In case the meridiem units are not separated around 12, then implement
  // this function (look at locale/id.js for an example).
  // meridiemHour : function (hour, meridiem) {
  //     return /* 0-23 hour, given meridiem token and hour 1-12 */ ;
  // },
  meridiem: (hours) => (hours < 12 ? "AM" : "PM"),
  week: {
    dow: 1, // Monday is the first day of the week.
    doy: 4, // Used to determine first week of the year.
  },
});

ReactDOM.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary>
      <OptimizelyProvider optimizely={optimizely}>
        <IntercomProvider appId={intercom}>
          <AuthProvider>
            <App />
          </AuthProvider>
        </IntercomProvider>
      </OptimizelyProvider>
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
