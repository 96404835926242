import classNames from "classnames";
import React, { ReactNode, useState } from "react";
import { Icon } from "~/common/icon";

type Props = {
  title?: string;
  titleClassName?: string;
  isOpen?: boolean;
  children?: ReactNode;
  className?: string;
  closable?: boolean;
};

const Section: React.FC<Props> = (props: Props) => {
  const { children, title = "", titleClassName = "", className = "page-container", isOpen = false, closable = true } = props;

  const [open, setOpen] = useState(isOpen);

  return (
    <section className={className}>
      <header className={classNames("flex justify-between cursor-pointer w-full", titleClassName)} onClick={() => closable && setOpen(!open)}>
        <h5>{title}</h5>

        {closable && <Icon width={24} height={24} name="caret-down" className={classNames("transition-transform transform", open && "rotate-180")} />}
      </header>

      {open && children}
    </section>
  );
};

export default Section;
