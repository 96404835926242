import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { MapTotal } from "~/common/map";
import theme from "~/theme";

interface Props {
  numberOfProjects: number;
  projectArea: number;
  totalProjectsWithoutLocation?: number;
  showProjectWithoutLocations?: boolean;
  onViewAllUnMarked?: () => void;
}

const MapSummary: React.FC<Props> = ({ numberOfProjects, projectArea, totalProjectsWithoutLocation, showProjectWithoutLocations = true, onViewAllUnMarked }) => {
  const { t } = useTranslation();

  const showMessage = showProjectWithoutLocations && !!totalProjectsWithoutLocation;

  return (
    <div style={{ maxHeight: "190px", width: "282px" }} className=" bg-white transform shadow rounded p-6 divide-y divide-solid divide-grey-200 flex flex-col">
      <div className="flex flex-col space-y-6">
        <MapTotal title={t("Projects")} total={numberOfProjects} color={theme.colors.green[900]} />
        <MapTotal title={t("Project area", { ns: "projects" })} total={projectArea} color={theme.colors.pink[75]} suffix={t("ha")} />
      </div>

      {showMessage && (
        <div className="flex items-center space-x-6 space-y-6 mt-6">
          <div className="flex-1 text-orange-500">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
            </svg>
          </div>

          <div className="text-base">
            <div>
              <Trans ns="projects" i18nKey="You have some additional project without marker" count={totalProjectsWithoutLocation}>
                You have <span className="font-bold">{{ totalProjectsWithoutLocation }}</span> additional project without markers
              </Trans>
            </div>

            <span onClick={onViewAllUnMarked} className="underline text-light-blue-900 cursor-pointer">
              <Trans ns="projects">View all unmarked projects</Trans>
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default MapSummary;
