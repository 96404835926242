import classNames from "classnames";
import React, { ReactNode } from "react";
import { useDocumentTitle } from "~/common/hooks";
import "./PageTitle.css";

type Props = {
  documentTitle?: string;
  children?: ReactNode;
  cta?: ReactNode;
  subNav?: ReactNode;
  border?: boolean;
};

const PageTitle: React.FC<Props> = ({ children, documentTitle, cta, subNav, border = true }) => {
  if (documentTitle) {
    useDocumentTitle(documentTitle);
    if (!children) children = documentTitle;
  }

  return (
    <>
      <div className="header-wrapper" />
      <header className="header">
        <div className={classNames("header-content", border && "border-b-2 border-grey-200")}>
          <div className="header-container">
            <div className="header-title">
              {typeof children === "string" ? <h2 className="text-blue-900">{children}</h2> : children}

              <div className="header-cta">{cta}</div>
            </div>
            <div className="header-nav">{subNav}</div>
          </div>
        </div>
      </header>
    </>
  );
};

export default PageTitle;
