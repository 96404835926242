export const FIRST_LOGIN_KEY = "cecil.first.login";

export type AuthContextType = {
  isLoggedIn: boolean;
  accessToken: string;
  loading: boolean;
  logOut: () => void;
};

export type RedirectContextType = {
  redirectTo: string;
};
