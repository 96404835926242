import classNames from "classnames";
import React from "react";
import { CounterBadgeProps, CounterBadgeVariantClassNames } from "../models";
import "./CounterBadge.css";

export const CounterBadge: React.FC<CounterBadgeProps> = ({ counter, variant, active = true, border = false, width = 26, height = 24 }) => {
  const counterStr = counter && counter > 99 ? "99+" : counter;

  const variantClassName = !active ? CounterBadgeVariantClassNames.deactive : CounterBadgeVariantClassNames[variant];

  return (
    <>
      {counterStr ? (
        <div style={{ minWidth: `${width}px`, height: `${height}px` }} className={classNames("badge", variantClassName, border && "border-2 border-white")}>
          {counterStr}
        </div>
      ) : null}
    </>
  );
};
