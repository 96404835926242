import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { authOtp, authToken } from "~/core/auth";
import { ToastNotification, ToastType } from "~/common/toasts/models";
import "./LoginCode.css";
import { FormInput } from "~/common/forms/input";

interface Props {
  onSubmit?: Function;
  email: string;
}

function LoginCode(props: Props) {
  const { onSubmit, email } = props;
  const { t } = useTranslation();
  const [codeError, setCodeError] = useState<ToastNotification | null>(null);
  const [code, setCode] = useState("");
  const [intentCount, setIntentCount] = useState<number>(0);
  const [disabledCodeBtn, setDisableCodeBtn] = useState(false);
  const history = useHistory();

  async function resendCode(type: ToastType) {
    try {
      setDisableCodeBtn(true);
      setTimeout(() => setDisableCodeBtn(false), 45000);
      await authOtp(email);

      setCodeError({
        type,
        message: t("Please check your inbox. We've sent you a new code!"),
      });

      setCode("");
      setTimeout(() => {
        document.querySelector<HTMLElement>("input[name=code-0]")?.focus();
      }, 100);
    } catch (e) {
      // @TODO ask Rory for a message if OPT fails
    }
  }

  const handleSubmit = async (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    return false;
  };

  useEffect(() => {
    if (!email) history.push("/auth/login");
  }, [email]);

  useEffect(() => {
    const verificationCode = code.trim();
    const valid = verificationCode.length === 6;

    async function authenticate() {
      if (codeError) setCodeError(null);

      try {
        if (onSubmit) onSubmit(true);
        await authToken(verificationCode, email);
        setIntentCount(0);
      } catch (e) {
        const newCount = intentCount + 1;
        setCode("");
        if (newCount === 3) {
          resendCode("error");
          setIntentCount(0);
        } else {
          setCodeError({
            type: "error",
            message: t("Oops! That’s not quite right. Please double check for typos."),
          });
          setIntentCount(newCount);
        }

        setTimeout(() => {
          document.querySelector<HTMLElement>("input[name=code-0]")?.focus();
        }, 100);

        if (onSubmit) onSubmit(false);
      }
    }

    if (valid) authenticate();
  }, [code]);

  return (
    <form onSubmit={handleSubmit} className="form-container justify-between flex-1">
      <div>
        <Trans parent="h2">Enter login code</Trans>

        <div className="block relative my-8">
          {codeError && <div className={classNames("mb-4", codeError.type === "error" ? "text-red-900" : "text-green-900")}>{codeError.message}</div>}

          <div className="form-col w-full">
            <FormInput
              type="number"
              placeholder={t("Please paste (or type) your 6 digit code:")}
              aria-placeholder="login-email-input"
              name="code"
              value={code}
              description={
                <Trans i18nKey="We sent a temporary sign-in code to your email.">
                  We sent a temporary sign-in code to <span className="font-bold">{{ email }}</span>
                </Trans>
              }
              onChange={(evt) => {
                const { value } = evt.target as HTMLInputElement;
                setCode(value);
              }}
            />
          </div>
        </div>

        <div className="mt-4">
          <Trans parent="span">Didn’t get the code?</Trans>
          <button
            type="button"
            id="resend-verification-code"
            data-testid="resend-verification-code"
            className="hover:underline text-light-blue-900 focus:outline-none focus:ring-0 ml-2"
            onClick={() => resendCode("success")}
            disabled={disabledCodeBtn}
          >
            <Trans>Resend code</Trans>
          </button>
        </div>
      </div>
    </form>
  );
}

export default LoginCode;
