import theme from "~/theme";
import { AutocompleteStatusColor } from "./models";

const { colors } = theme;
const { lightBlue, grey, purple, yellow, green, blue, pink, orange, red, teal } = colors;

export const MenuColors: AutocompleteStatusColor = {
  white: {
    Option: "bg-white text-navy-900",
    SelectContainer: "bg-white",
    SingleValue: "text-navy-900",
    Control: blue[100],
  },
  blue: {
    Option: "bg-blue-600 text-white",
    SelectContainer: "bg-blue-600",
    SingleValue: "text-white",
    Control: lightBlue[100],
  },
  yellow: {
    Option: "bg-yellow-600 text-white b",
    SelectContainer: "bg-yellow-100",
    SingleValue: "text-white",
    Control: yellow[100],
  },
  green: {
    Option: "bg-green-600 text-white",
    SelectContainer: "bg-green-600",
    SingleValue: "text-white",
    Control: green[100],
  },
  purple: {
    Option: "bg-purple-600 text-white",
    SelectContainer: "bg-purple-100",
    SingleValue: "text-white",
    Control: purple[100],
  },
  pink: {
    Option: "bg-pink-600 text-white",
    SelectContainer: "bg-pink-100",
    SingleValue: "text-white",
    Control: pink[100],
  },
  orange: {
    Option: "bg-orange-600 text-white",
    SelectContainer: "bg-orange-100",
    SingleValue: "text-white",
    Control: orange[100],
  },
  red: {
    Option: "bg-red-600 text-white",
    SelectContainer: "bg-red-100",
    SingleValue: "text-white",
    Control: red[100],
  },
  teal: {
    Option: "bg-teal-600 text-white",
    SelectContainer: "bg-teal-100",
    SingleValue: "text-white",
    Control: teal[100],
  },
  grey: {
    Option: "bg-grey-600 text-white",
    SelectContainer: "bg-grey-80",
    SingleValue: "text-white",
    Control: grey[800],
  },
};
