import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { NavLink, useHistory } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import { FormInput } from "~/common/forms/input";
import { FIRST_LOGIN_KEY, useAuth } from "~/core/auth";
import { User } from "~/core/contacts";
import { useMe } from "~/core/account";
import { DEFAULT_PATH } from "~/core/auth/utils";
import { ProjectsListMap, useProjectOptions } from "~/core/projects";
import { NavRoutes } from "~/common/navbar/NavRoutes";
import { PhoneInput } from "~/common/forms/phone-input";
import { Button } from "~/common/forms/button";

const FirstLogin: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { me, updateMe } = useMe();
  const { isLoggedIn, loading: authIsLoading } = useAuth();
  const { show } = useIntercom();
  const [showWelcomeMap, setShowWelcomeMap] = useState<boolean>(false);

  const {
    control,
    trigger,
    handleSubmit,
    formState: { isValid, isDirty },
  } = useForm<User>({
    defaultValues: me as User,
  });

  const goToDashboard = () => history.push(DEFAULT_PATH);

  const { options } = useProjectOptions();

  useEffect(() => {
    const loginCounts = me?.loginsCount ?? 0;
    if (loginCounts > 1 || localStorage.getItem(FIRST_LOGIN_KEY)) {
      goToDashboard();
    }
  }, []);

  useEffect(() => {
    if (!authIsLoading && !isLoggedIn) history.push("/auth/login");
    trigger();
  }, [authIsLoading]);

  const onSubmit = async (data: User) => {
    // If no changes were made we just redirect to the dashboard
    if (isDirty) await updateMe(data);
    localStorage.setItem(FIRST_LOGIN_KEY, "true");

    if (me?.positionType === "internal") setShowWelcomeMap(true);
    else goToDashboard();
  };

  return (
    <>
      {!showWelcomeMap ? (
        <div className="h-screen overflow-hidden flex justify-center items-center">
          <div className="p-7 flex-1 min-w-max">
            <div
              className="m-auto w-10/12 flex flex-col"
              style={{
                maxWidth: "950px",
                height: "calc(100vh - 10rem)",
                maxHeight: "628px",
              }}
            >
              <div className="mb-12">
                <NavLink to="/auth/login">
                  <img src="/vectors/new-logo.svg" alt="Logo" style={{ width: "160px" }} />
                </NavLink>
              </div>

              <form onSubmit={handleSubmit(onSubmit)} className="form-container form-col justify-between flex-1">
                <div>
                  <h2 className="text-blue-900">Confirm your details to get started</h2>
                </div>

                <div className="flex-1 form-container scrollbar-cecil-y">
                  <div className="flex gap-6">
                    <Controller
                      control={control}
                      name="firstName"
                      rules={{ required: true }}
                      render={({ field }) => (
                        <FormInput
                          required
                          id="contact-firstName"
                          label={t("First name")}
                          value={field.value}
                          onChange={(evt: any) => {
                            field.onChange(evt);
                            trigger();
                          }}
                        />
                      )}
                    />

                    <Controller
                      control={control}
                      name="lastName"
                      rules={{ required: true }}
                      render={({ field }) => (
                        <FormInput
                          required
                          id="contact-lastName"
                          label={t("Last name")}
                          value={field.value}
                          onChange={(evt: any) => {
                            field.onChange(evt);
                            trigger();
                          }}
                        />
                      )}
                    />
                  </div>

                  <div>
                    <Controller
                      control={control}
                      name="email"
                      rules={{ required: true }}
                      render={({ field }) => <FormInput id="contact-email" type="email" disabled label={t("Email")} value={field.value} />}
                    />

                    <div className="mt-2">
                      <Trans i18nKey="Contact Cecil to change the email for this account.">
                        <button type="button" className="text-light-blue-900 underline" onClick={() => show()}>
                          Contact Cecil
                        </button>{" "}
                        to change the email for this account
                      </Trans>
                    </div>
                  </div>

                  <Controller
                    control={control}
                    name="phoneNumber"
                    render={({ field }) => <PhoneInput label={t("Phone")} value={field.value} onChange={field.onChange} />}
                  />
                </div>

                <footer className="flex justify-end bg-white sticky bottom-0 py-4">
                  <Button type="submit" variant="btn-blue" size="btn-large" disabled={!isValid}>
                    {t("Finish")}
                  </Button>
                </footer>
              </form>
            </div>
          </div>

          <div
            className="p-7 h-screen flex items-center justify-center relative w-6/12"
            style={{
              backgroundImage: "url('/images/login-wizard.png')",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          />
        </div>
      ) : (
        <div className="flex justify-center">
          <div className="p-8 flex flex-col gap-8" style={{ width: "1308px" }}>
            <h1 className="text-blue-900">
              Welcome to Cecil,
              <br />
              Home base for all your project data
            </h1>

            <ProjectsListMap height={460} zoom={1.5} center={[0, 0]} showProjectWithoutLocations={false} options={options} handleViewAllUnMarked={() => {}} />

            <h2 className="text-blue-900">Where to start...</h2>

            <div className="flex justify-between items-stretch gap-7">
              <div className="rounded border border-grey-200 p-7 flex flex-col gap-6">
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M27.5 15C27.5 21.9036 21.9036 27.5 15 27.5V29.5C23.0081 29.5 29.5 23.0081 29.5 15H27.5ZM15 27.5C8.09644 27.5 2.5 21.9036 2.5 15H0.5C0.5 23.0081 6.99187 29.5 15 29.5V27.5ZM2.5 15C2.5 8.09644 8.09644 2.5 15 2.5V0.5C6.99187 0.5 0.5 6.99187 0.5 15H2.5ZM15 2.5C21.9036 2.5 27.5 8.09644 27.5 15H29.5C29.5 6.99187 23.0081 0.5 15 0.5V2.5ZM18.5 15C18.5 18.6504 18.0053 21.9136 17.2333 24.2297C16.8455 25.393 16.4059 26.2622 15.9662 26.8201C15.5176 27.3893 15.1836 27.5 15 27.5V29.5C16.0591 29.5 16.9088 28.8552 17.537 28.0581C18.1741 27.2497 18.7041 26.1419 19.1307 24.8622C19.9873 22.2922 20.5 18.8055 20.5 15H18.5ZM15 27.5C14.8164 27.5 14.4824 27.3893 14.0338 26.8201C13.5941 26.2622 13.1545 25.393 12.7667 24.2297C11.9947 21.9136 11.5 18.6504 11.5 15H9.5C9.5 18.8055 10.0127 22.2922 10.8693 24.8622C11.2959 26.1419 11.8259 27.2497 12.463 28.0581C13.0912 28.8552 13.9409 29.5 15 29.5V27.5ZM11.5 15C11.5 11.3496 11.9947 8.08638 12.7667 5.77029C13.1545 4.60701 13.5941 3.73776 14.0338 3.17987C14.4824 2.61071 14.8164 2.5 15 2.5V0.5C13.9409 0.5 13.0912 1.14481 12.463 1.94192C11.8259 2.75031 11.2959 3.85809 10.8693 5.13783C10.0127 7.70777 9.5 11.1945 9.5 15H11.5ZM15 2.5C15.1836 2.5 15.5176 2.61071 15.9662 3.17987C16.4059 3.73776 16.8455 4.60701 17.2333 5.77029C18.0053 8.08638 18.5 11.3496 18.5 15H20.5C20.5 11.1945 19.9873 7.70777 19.1307 5.13783C18.7041 3.85809 18.1741 2.75031 17.537 1.94192C16.9088 1.14481 16.0591 0.5 15 0.5V2.5ZM1.5 16H28.5V14H1.5V16Z"
                    fill="#202E4E"
                  />
                </svg>

                <h3 className="text-grey-900 font-semibold">Centralise your data</h3>

                <div className="text-grey-900 text-lg flex-1">
                  Start adding project data including site
                  <br />
                  attributes, inventory and more. Contact us to
                  <br /> assist with uploading in bulk.
                </div>

                <Button variant="btn-secondary" onClick={() => history.push(NavRoutes.projects.path)}>
                  View your projects
                </Button>
              </div>

              <div className="rounded border border-grey-200 p-7 flex flex-col gap-6">
                <svg width="30" height="28" viewBox="0 0 30 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M3 1V0C2.44772 0 2 0.447715 2 1L3 1ZM27 1H28C28 0.447715 27.5523 0 27 0V1ZM8 14.5C8 15.0523 8.44772 15.5 9 15.5C9.55228 15.5 10 15.0523 10 14.5H8ZM10 13C10 12.4477 9.55228 12 9 12C8.44772 12 8 12.4477 8 13H10ZM14 14.5C14 15.0523 14.4477 15.5 15 15.5C15.5523 15.5 16 15.0523 16 14.5H14ZM16 10C16 9.44772 15.5523 9 15 9C14.4477 9 14 9.44772 14 10H16ZM20 14.5C20 15.0523 20.4477 15.5 21 15.5C21.5523 15.5 22 15.0523 22 14.5H20ZM22 7C22 6.44772 21.5523 6 21 6C20.4477 6 20 6.44772 20 7H22ZM8.29289 25.7929C7.90237 26.1834 7.90237 26.8166 8.29289 27.2071C8.68342 27.5976 9.31658 27.5976 9.70711 27.2071L8.29289 25.7929ZM15 20.5L15.7071 19.7929C15.3166 19.4024 14.6834 19.4024 14.2929 19.7929L15 20.5ZM20.2929 27.2071C20.6834 27.5976 21.3166 27.5976 21.7071 27.2071C22.0976 26.8166 22.0976 26.1834 21.7071 25.7929L20.2929 27.2071ZM1.5 0C0.947715 0 0.5 0.447715 0.5 1C0.5 1.55228 0.947715 2 1.5 2V0ZM28.5 2C29.0523 2 29.5 1.55228 29.5 1C29.5 0.447715 29.0523 0 28.5 0V2ZM3 2H27V0H3V2ZM26 1V19H28V1H26ZM25.5 19.5H4.5V21.5H25.5V19.5ZM4 19V1H2V19H4ZM4.5 19.5C4.22386 19.5 4 19.2761 4 19H2C2 20.3807 3.11929 21.5 4.5 21.5V19.5ZM26 19C26 19.2761 25.7761 19.5 25.5 19.5V21.5C26.8807 21.5 28 20.3807 28 19H26ZM10 14.5V13H8V14.5H10ZM16 14.5V10H14V14.5H16ZM22 14.5V7H20V14.5H22ZM9.70711 27.2071L15.7071 21.2071L14.2929 19.7929L8.29289 25.7929L9.70711 27.2071ZM14.2929 21.2071L20.2929 27.2071L21.7071 25.7929L15.7071 19.7929L14.2929 21.2071ZM1.5 2H28.5V0H1.5V2Z"
                    fill="#202E4E"
                  />
                </svg>

                <h3 className="text-grey-900 font-semibold">Track project progress</h3>

                <div className="text-grey-900 text-lg flex-1">
                  Organise projects into pipelines to provide
                  <br />
                  visibility, keep them on track and identify issues.
                </div>

                <Button variant="btn-secondary" onClick={() => history.push(NavRoutes.pipeline.path)}>
                  Visit pipelines
                </Button>
              </div>

              <div className="rounded border border-grey-200 p-7 flex flex-col gap-6">
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M13.5 1.58252H14.5C14.5 1.29817 14.379 1.02727 14.1671 0.837578C13.9553 0.647883 13.6727 0.557332 13.3901 0.588574L13.5 1.58252ZM28.4176 16.5001L29.4116 16.61C29.4428 16.3274 29.3522 16.0448 29.1625 15.833C28.9729 15.6212 28.702 15.5001 28.4176 15.5001V16.5001ZM13.5 16.5001H12.5C12.5 17.0524 12.9477 17.5001 13.5 17.5001V16.5001ZM27.7317 10.5001V11.5001C28.0564 11.5001 28.3608 11.3426 28.5482 11.0775C28.7356 10.8125 28.7827 10.4729 28.6746 10.1669L27.7317 10.5001ZM19.5 10.5001H18.5C18.5 11.0524 18.9477 11.5001 19.5 11.5001V10.5001ZM19.5 2.26857L19.8332 1.32573C19.5272 1.21756 19.1877 1.26467 18.9226 1.4521C18.6576 1.63953 18.5 1.94396 18.5 2.26857H19.5ZM2.5 15.0001C2.5 8.56669 7.361 3.26724 13.6099 2.57646L13.3901 0.588574C6.13914 1.39013 0.5 7.5359 0.5 15.0001H2.5ZM15 27.5001C8.09644 27.5001 2.5 21.9037 2.5 15.0001H0.5C0.5 23.0083 6.99187 29.5001 15 29.5001V27.5001ZM27.4237 16.3902C26.7329 22.6391 21.4334 27.5001 15 27.5001V29.5001C22.4642 29.5001 28.61 23.861 29.4116 16.61L27.4237 16.3902ZM28.4176 15.5001H13.5V17.5001H28.4176V15.5001ZM14.5 16.5001V1.58252H12.5V16.5001H14.5ZM27.7317 9.50013H19.5V11.5001H27.7317V9.50013ZM20.5 10.5001V2.26857H18.5V10.5001H20.5ZM19.1668 3.21141C22.7177 4.46649 25.5338 7.2825 26.7889 10.8334L28.6746 10.1669C27.218 6.04629 23.9539 2.78216 19.8332 1.32573L19.1668 3.21141Z"
                    fill="#202E4E"
                  />
                </svg>

                <h3 className="text-grey-900 font-semibold">Share reports</h3>

                <div className="text-grey-900 text-lg flex-1">
                  Give stakeholders up-to-date access to portfolio
                  <br />
                  insights related to credit inventory and site data.
                </div>

                <Button variant="btn-secondary" onClick={() => history.push(NavRoutes.reports.path)}>
                  Visit reports
                </Button>
              </div>
            </div>

            <div className="text-center flex flex-col items-center gap-5">
              <div className="font-bold text-blue-900 text-xl">We’re here to help</div>
              <div className="text-lg">
                If you ever have any questions or feedback, send us an <a href="mailto:hello@cecil.earth">email</a> or message us via the chat.
              </div>

              <Button onClick={goToDashboard}>Let’s go</Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FirstLogin;
