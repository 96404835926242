import { UseFormTrigger } from "react-hook-form";
import { AutocompleteOption, GetSelectableParamsReturn, Selectable, SelectableId } from "./models";

export const selectablesToOption = (selectables: Selectable[]): AutocompleteOption[] => {
  return (selectables ?? []).map((s) => {
    return { label: s.name, value: s.id, meta: s };
  });
};

export const getSelectableName = (selectable: SelectableId, selectables: Selectable[]): string =>
  selectables?.find(({ id }) => id === selectable.selectableId)?.name ?? "";

export const getSelectableParams = <T, S>(
  currentObj: T | undefined = {} as T,
  formData: Partial<S>,
  key: keyof T | keyof S,
  selectKey: keyof SelectableId = "selectableId"
): GetSelectableParamsReturn => {
  const formDataKeyValues = formData[key as keyof S] as unknown;
  const currentObjKeyValues = (currentObj[key as keyof T] || []) as unknown;

  const formTypes = formDataKeyValues as string[];
  const currentObjTypes = currentObjKeyValues as SelectableId[];

  const prevTypes = currentObjTypes.filter((s) => formTypes?.includes(s[selectKey])) || [];

  const prevTypesIds = prevTypes?.map((s) => s[selectKey]);

  const newTypesIds = formTypes?.filter((s) => !prevTypesIds?.includes(s)) || [];

  const newTypes = newTypesIds?.map((id) => {
    return {
      selectableId: id,
    };
  });

  const requestTypes = formTypes ? [...prevTypes, ...newTypes] : [];

  const newformData = {
    [key]: requestTypes,
  };

  return newformData;
};

export const multipleAutocompleteChange = <T>(field: any, trigger: UseFormTrigger<T>) => {
  return (item: any, meta: any) => {
    switch (meta.action) {
      case "create-option":
        const withNewOption = (field.value ?? []).concat(item.value);
        field.onChange(withNewOption);
        break;
      default:
        const ids = item.map((i: any) => i.value);
        field.onChange(ids ?? null);
        break;
    }
    trigger();
  };
};

export const getSelectableIdList = (list?: SelectableId[]) => list?.map(({ selectableId }) => selectableId) ?? [];

export function filterToOption(filters?: { id: string; value: string }[]): AutocompleteOption[] | undefined {
  return filters?.map((f) => ({ value: f.id, label: f.value }));
}

export function projectTypesToOption(projectTypes?: { id: string; name: string }[]): AutocompleteOption[] | undefined {
  return projectTypes?.map((f) => {
    let { name: label } = f;

    const splitLabel = label.split("/");

    if (splitLabel.length === 2) {
      label = `${splitLabel[0]} ${splitLabel[1]}`;
    }

    return { value: f.id, label };
  });
}
