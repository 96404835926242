import { buildAPIURL } from "~/common/http";
import { ResourceType } from "~/common/models";
import { promiser } from "~/common/utils";
import apiClient from "../../common/api-client";
import { AssignedUser, Contact } from "../contacts";
import { AddContactsArgs } from "../contacts/models";
import { ProjectDirectoryTreeResponseWrapper, ResourceFilesParams } from "../files/models";
import { downloadCsv } from "../reports/utils";
import {
  AddProject,
  FormulaGroup,
  FormulaParameter,
  GetProjectsOptions,
  GroundcoverAssets,
  InviteProjectUsersResponseWrapper,
  InviteRequest,
  Project,
  ProjectFilters,
  ProjectLocation,
  ProjectStatus,
  ProjectType,
  ProjectsMarkersResponseWrapper,
  ProjectsResponse,
  Site,
  Stage,
  StageTemplateGroup,
} from "./models";
import { getProjectsSort, populateProject, updateProjectArea } from "./utils";

const limit = parseInt(process.env.REACT_APP_PROJECTS_LIMIT ?? "20", 10);

/**
 * SITE RELATED SERVICES
 */
export function getProjectSites(project: Project): Promise<Site[]> {
  const url = buildAPIURL(`/v1/projects/${project.id}/sites`);
  return apiClient.get(url).then((resp) => resp.data.sites);
}

export function getSite(project: Project, siteId: string): Promise<Site> {
  const url = buildAPIURL(`/v1/projects/${project.id}/sites/${siteId}`);
  return apiClient.get(url).then((resp) => resp.data);
}

export function updateSite(siteId: string, projectId: string, site: Partial<Site>): Promise<Site> {
  const url = buildAPIURL(`/v1/projects/${projectId}/sites/${siteId}`);

  // make sure we don't update projectId
  delete site.projectId;
  return apiClient.patch(url, site).then((response) => response.data);
}

export function deleteSite(site: Site): Promise<any> {
  const url = buildAPIURL(`/v1/projects/${site.projectId}/sites/${site.id}`);
  return apiClient.delete(url);
}

export function uploadGroundcoverImage(site: Site, file: File): Promise<Site> {
  const url = buildAPIURL(`/v1/projects/${site.projectId}/sites/${site.id}/images/groundcover`);

  const formData = new FormData();
  formData.append("asset", file);

  return apiClient
    .post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((resp) => resp.data);
}

const fetchGroundcoverImages = (site: Site, path: string): Promise<GroundcoverAssets[]> => {
  if (site.groundCoverAssets) {
    const promises = site.groundCoverAssets.map((asset) => {
      // prevent doing a request for assets with a url
      if (asset.url) return Promise.resolve(asset);

      const url = buildAPIURL(`${path}/${asset.assetId}`);
      return apiClient.get(url, { params: { redirect: false } }).then((response) => ({
        ...asset,
        url: response.data.url,
      }));
    });
    return Promise.all(promises);
  }
  return Promise.resolve([]);
};

export function getGroundcoverImages(site: Site): Promise<GroundcoverAssets[]> {
  return fetchGroundcoverImages(site, `/v1/assets`);
}

export function deleteGroundcoverImage(site: Site, asset: GroundcoverAssets): Promise<any> {
  const url = buildAPIURL(`/v1/projects/${site.projectId}/sites/${site.id}/images/groundcover/${asset.id}`);
  return apiClient.delete(url);
}

/**
 * PROJECT RELATED SERVICES
 */
export function getProjects(options?: GetProjectsOptions) {
  delete options?.user;

  const { page = 1, sort, ...rest } = options ?? {};

  const newLimit = options?.limit || limit;
  const offset = page * newLimit - newLimit;
  const projectsSort = getProjectsSort(sort);

  const path = `/v1/projects`;
  const url = buildAPIURL(path, { ...rest, limit: newLimit, offset, sort: projectsSort });

  return promiser<ProjectsResponse>(
    apiClient.get(url).then((response) => {
      const { meta, data } = response;
      meta.perPage = limit;
      return { meta, data };
    })
  );
}

// Get a single project base on its id
// As right now we need to do some logic here until the BE team moves all this
// logic to the API.
export async function getProject(id: string): Promise<Project> {
  const url = buildAPIURL(`/v1/projects/${id}`);
  return apiClient
    .get(url)
    .then((resp) => resp.data)
    .then((project: Project) => ({
      ...project,
      stage: project.stages?.find((s: Stage) => s.id === project.currentStageId),
    }))
    .then((project: Project) => populateProject(project));
}

// Creating a project is a 2 steps process.
// 1. We create the project
export async function createProject(project: AddProject): Promise<Project> {
  const url = buildAPIURL("/v1/projects");

  const newProject = await apiClient
    .post(url, { ...project, status: "active" })
    .then((resp) => resp.data)
    .then((resp) => resp as Project);

  return { ...newProject };
}

export async function duplicateProject(project: { id: string; name: string }): Promise<Project> {
  const { id, name } = project;
  const url = buildAPIURL(`/v1/projects/${id}/duplicate`);
  const newProject = await apiClient.post(url, { name });
  return newProject.data;
}

/**
 * Updating projects involves updating/create other entities such as site and user.
 * This method will do all the required checks to make sure that updating the project
 * will also update all the extra information
 * @TODO missing error handling
 * @param project Project
 * @returns Promise<Project>
 */
export async function updateProject(project: Partial<Project>): Promise<Project> {
  // do not send stage
  const url = buildAPIURL(`/v1/projects/${project.id}`);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { stage, sites, ...toSaved } = updateProjectArea(project as Project); // remove stage from project

  // HACK: we have to remove (set undefined) the assignedOwnerAt for investors
  // because it fails in the BE
  toSaved.assignedUsers = toSaved.assignedUsers?.map((assignedUser) => {
    if (assignedUser.positionType === "external") return { ...assignedUser, assignedOwnerAt: undefined };
    return assignedUser;
  });

  let savedProject = await apiClient
    .patch(url, toSaved)
    .then((resp) => resp.data)
    .then((resp) => resp as Project);

  const [site] = sites ?? [];
  if (site) {
    const newSite = (await updateSite(site.id, savedProject.id, site)) as Site;
    savedProject = { ...savedProject, sites: [newSite] };
  }

  return populateProject(savedProject);
}

export async function createProjectLocation(location: ProjectLocation): Promise<ProjectLocation> {
  const url = buildAPIURL(`/v1/projects/${location.projectId}/locations`);
  return apiClient.post(url, location).then((resp) => resp.data as ProjectLocation);
}

export async function updateLocation(location: ProjectLocation): Promise<ProjectLocation> {
  const url = buildAPIURL(`/v1/projects/${location.projectId}/locations/${location.id}`);
  return apiClient.patch(url, location).then((resp) => resp.data as ProjectLocation);
}

export async function deleteProjectLocation(location: ProjectLocation): Promise<any> {
  const url = buildAPIURL(`/v1/projects/${location.projectId}/locations/${location.id}`);
  return apiClient.delete(url);
}

export async function getProjectFilters(): Promise<ProjectFilters> {
  const url = buildAPIURL(`/v1/projects/filters`);
  return apiClient.get(url).then((resp) => resp.data.filters as ProjectFilters);
}

export function getProjectTypes(): Promise<ProjectType[]> {
  const url = buildAPIURL("/v1/projects/types");
  return apiClient.get(url).then((resp) => resp.data.projectTypes);
}

export const updateProjectContacts = async (project: Project, contact: Contact): Promise<Project> => {
  const assignedUsers = (project.assignedUsers || [])?.filter((u) => u.id !== contact.id).concat(contact);
  const newProject = await updateProject({ id: project.id, assignedUsers });
  return newProject;
};

export const countUserProjects = async (options: GetProjectsOptions): Promise<number> => {
  const { flagged, projectAssignedUserId } = options;

  const favProjects = getProjects({
    limit: 1,
    flagged,
  });

  const assignedProjects = getProjects({
    limit: 1,
    projectAssignedUserId,
  });

  const [[{ meta: favouriteMeta }], [{ meta: assignedMeta }]] = await Promise.all([favProjects, assignedProjects]);

  return favouriteMeta.total + assignedMeta.total;
};

export const exportCSV = async (options?: GetProjectsOptions) => {
  const path = "/v1/projects/export";
  delete options?.user;

  const url = buildAPIURL(path, options);
  const response = await apiClient.get(url);
  downloadCsv(response, "project-export");
};

export async function archive(project: { id: string }): Promise<Project> {
  const { id } = project;
  const url = buildAPIURL(`/v1/projects/${id}/archive`);
  const response = await apiClient.post(url);
  return response.data;
}

export async function unArchive(project: { id: string }): Promise<any> {
  const { id } = project;
  const url = buildAPIURL(`/v1/projects/${id}/unarchive`);
  const response = await apiClient.post(url);
  return response.data;
}

export const invite: InviteRequest = async (params) => {
  const { id, ...body } = params;

  const url = buildAPIURL(`/v1/projects/${id}/invite`);

  const inviteResponse = await apiClient
    .post(url, body)
    .then((resp) => resp.data)
    .then((resp) => resp as InviteProjectUsersResponseWrapper);

  return inviteResponse;
};

export function getProjectsMarkers(filters?: GetProjectsOptions): Promise<ProjectsMarkersResponseWrapper> {
  const url = buildAPIURL(`/v1/projects/portfolio/markers`, filters);
  return apiClient.get(url);
}

export const assignContacts = async (props: { project: Project; contacts: AssignedUser[] }): Promise<Project> => {
  const { project, contacts } = props;
  const [site] = project.sites ?? [];

  const projectAssignedUsers = project.assignedUsers ?? [];

  const newSite = site;
  let newProject: Project;

  if (contacts.length > 0) {
    newProject = await updateProject({
      id: project.id,
      assignedUsers: projectAssignedUsers
        .concat(contacts)
        .map((u) => ({ ...u, assignedOwnerAt: undefined }))
        .filter((usr, idx, self) => idx === self.findIndex((n) => n.id === usr.id)),
    });
  } else {
    newProject = project;
  }

  return { ...newProject, sites: [newSite] };
};

export const getDirectory = (params: ResourceFilesParams & { resourceType?: ResourceType }): Promise<ProjectDirectoryTreeResponseWrapper> => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const url = `/v1/directory_tree`;
  const request = buildAPIURL(url, params);
  return apiClient.get<ProjectDirectoryTreeResponseWrapper>(request);
};

export const getStages = async (projectId: string): Promise<Stage[]> => {
  const url = buildAPIURL(`/v1/projects/${projectId}/stages`);
  const result = await apiClient.get(url);
  return result.data.stages as Stage[];
};

export const getStageTemplateGroups = async (): Promise<StageTemplateGroup[]> => {
  const url = buildAPIURL(`/v1/stage_template_groups`);
  const response = await apiClient.get<any>(url);
  return response.data.stageTemplateGroups as StageTemplateGroup[];
};

export const updateStages = async (projectId: string, stages: Stage[]): Promise<Stage[]> => {
  return Promise.all(
    stages.map(async (stage) => {
      const url = buildAPIURL(`/v1/projects/${projectId}/stages/${stage.id}`);
      const result = await apiClient.patch(url, stage);
      return result.data;
    })
  );
};

export const updateStage = async (projectId: string, stage: Partial<Stage>): Promise<Stage> => {
  const { id, ...rest } = stage;
  const url = buildAPIURL(`/v1/projects/${projectId}/stages/${id}`);
  const result = await apiClient.patch(url, rest);
  return result.data;
};

export const addContactsToProjects = async (projects: Project[], data: AddContactsArgs) => {
  const { contacts, shareMessage } = data;

  const addContactsBulk = projects.map((p) => assignContacts({ project: p, contacts }));

  const shareProjectBulk = shareMessage
    ? projects.map((p) =>
        invite({
          id: p.id,
          userIds: data.contacts.map((u) => u.id),
          message: data.shareMessage,
        })
      )
    : [];

  const [updatedProjects] = await Promise.all([Promise.all(addContactsBulk), shareProjectBulk]);

  return updatedProjects;
};

// Financials data
export const getProjectFormulaGroups = (projectId: string, archived = false): Promise<FormulaGroup[]> => {
  const queryParams = { ...(archived ? { archived } : {}) };
  const url = `/v1/projects/${projectId}/formula_groups`;
  const request = buildAPIURL(url, queryParams);

  // TODO: remove temporary mock response
  return apiClient
    .get(request)
    .then((response) => (response.data.formulaGroups || []) as FormulaGroup[])
    .then((formulagroups) =>
      formulagroups.map((fg) => ({
        ...fg,
        parameters: fg.parameters.sort((a, b) => a.name.localeCompare(b.name)),
      }))
    );
};

export const updateFormulaParameter = (parameter: FormulaParameter): Promise<FormulaParameter> => {
  const { id, ...rest } = parameter;
  const url = `/v1/formula_parameters/${id}`;
  const request = buildAPIURL(url);

  return apiClient.patch(request, rest).then((response) => response.data);
};

export const getProjectStatuses = (projectId: string): Promise<ProjectStatus[]> => {
  const url = `/v1/projects/${projectId}/statuses`;
  const request = buildAPIURL(url);
  return apiClient.get(request).then((response) => response.data.projectStatus || []);
};
