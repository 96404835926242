import { useContext } from "react";
import { ToastContext } from "./components/ToastContext";

export const useToast = () => {
  const { toastArchive, toastClose, toastError, toastInfo, toastLoading, toastSuccess, toastSystem, toastWarning, closeToastLoading } = useContext(ToastContext);

  return {
    toastArchive,
    toastClose,
    toastError,
    toastInfo,
    toastLoading,
    toastSuccess,
    toastSystem,
    toastWarning,
    closeToastLoading,
  };
};
