import { buildAPIURL } from "~/common/http";
import apiClient from "../../common/api-client";
import { Alert, AlertsParams, AlertsResponse, AlertsUpdateResponse, UpdateAlertParams } from "./models";
import { AlertypeParam } from "./utils";

const limit = parseInt(process.env.REACT_APP_PROJECTS_LIMIT ?? "20", 10);

const getQueryParams = (params: AlertsParams): any => {
  const queryParams = {
    limit: params?.limit || limit,
    offset: (params?.page ?? 1) * limit - limit,
    type: AlertypeParam[params.type],
  };
  return queryParams;
};

export const getAlerts = async (params: AlertsParams): Promise<AlertsResponse> => {
  const url = buildAPIURL(`/v1/notifications`, getQueryParams(params));

  const { data, meta } = await apiClient.get(url.toString());
  return { data: data?.notifications || [], meta: { ...meta, perPage: limit } };
};

export const updateAlert = (alert: Partial<Alert>): Promise<Alert> => {
  const { id } = alert;
  const url = buildAPIURL(`/v1/notifications/${id}`);
  return apiClient.patch(url, alert).then((response) => response.data);
};

export const updateAlertIds = (params: UpdateAlertParams): Promise<AlertsUpdateResponse> => {
  const url = buildAPIURL(`/v1/notifications`);

  const { alerts, ...rest } = params;

  return apiClient
    .patch(url, {
      ...rest,
      ids: alerts?.map((a) => a.id),
    })
    .then((response) => response.data);
};
