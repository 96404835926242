import { useContext } from "react";
import { TranskeyContext } from "./components/TranskeyContext";

export const useTransKeyProvider = () => {
  const context = useContext(TranskeyContext);

  if (!context) {
    throw new Error(`useTransKeyProvider must be used within a TransKeyProvider`);
  }

  return context;
};
