import React from "react";
import classNames from "classnames";
import { Button } from "~/common/forms/button";
import { IconButtonProps } from "../models";

export const IconButton: React.FC<IconButtonProps> = (props: IconButtonProps) => {
  const { icon, children, iconPosition = "left", className, ...rest } = props;

  const iconOrder = iconPosition === "left" ? "order-1" : "order-2";
  const labelOrder = iconPosition === "left" ? "order-2" : "order-1";

  return (
    <Button {...rest} className={classNames(className, "icon-button")}>
      <div className={classNames("flex items-center", children && "gap-4")}>
        <div className={iconOrder}>{icon}</div>
        <span className={classNames("whitespace-nowrap", labelOrder)}>{children}</span>
      </div>
    </Button>
  );
};
