import classNames from "classnames";
import React from "react";
import "./Loader.css";

type LoaderType = "main" | "secondary";

interface Props {
  type?: LoaderType;
  className?: string;
}

const Loader: React.FC<Props> = ({ type = "main", className = "w-7 h-7" }) => {
  return (
    <span
      style={{
        borderBottomColor: "transparent",
        borderRadius: "50%",
        animation: "rotation 1s linear infinite",
      }}
      className={classNames(className, type === "main" ? "border-blue-900" : "border-white", "border-4 inline-block box-border")}
    />
  );
};

export default Loader;
