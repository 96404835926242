import classNames from "classnames";
import React, { ReactNode } from "react";

type Props = {
  label: string;
  value?: string;
  render?: () => ReactNode;
  justify?: string;
  className?: string;
};

const PinDetails: React.FC<Props> = ({ label, value, render, className, justify = "start" }) => {
  return (
    <div className={classNames(className, "flex items-start", `justify-${justify}`)}>
      <span className="w-2/5 font-semibold">{label}</span>
      <div className="w-3/5">{render ? render() : <span className="text-grey-900">{value}</span>}</div>
    </div>
  );
};

export default PinDetails;
