import React, { ReactNode } from "react";
import { createPortal } from "react-dom";
import { usePortal } from "../hooks";

const Portal: React.FC<{ id: string; children: ReactNode }> = ({ id, children }) => {
  const target = usePortal(id);
  return createPortal(children, target);
};

export default Portal;
