import lottie from "lottie-web";
import React, { useEffect, useRef } from "react";
import animationData from "./loading.json";

type Props = {
  width?: number;
  height?: number;
};

const LottieLoader: React.FC<Props> = (props: Props) => {
  const { width = 80, height = 80 } = props;
  const container = useRef<HTMLDivElement | null>(null);
  const loaded = useRef<boolean>(false);

  useEffect(() => {
    if (container.current && !loaded.current) {
      loaded.current = true;
      lottie.loadAnimation({
        container: container.current,
        animationData,
        renderer: "svg",
        loop: true,
        autoplay: true,
      });
    }
  }, [container.current]);

  return <div ref={container} style={{ height, width }} />;
};

export default LottieLoader;
