import * as auth0 from "auth0-js";
import { removeOrg } from "~/core/org";

const domain = process.env.REACT_APP_AUTH0_DOMAIN || "";
const clientID = process.env.REACT_APP_AUTH0_CLIENT_ID || "";
const redirectUri = process.env.REACT_APP_AUTH0_REDIRECT_URL || "";
const audience = process.env.REACT_APP_AUTH0_AUDIENCE || "";
let token: any;
let timer: ReturnType<typeof setTimeout>;

const webAuth = new auth0.WebAuth({
  domain,
  clientID,
  redirectUri,
  audience,
  responseType: "token id_token",
  scope: "openid email offline_access",
});

export async function authOtp(email: string): Promise<any> {
  return new Promise((resolve, reject) => {
    webAuth.passwordlessStart({ connection: "email", send: "code", email }, (err, res) => {
      if (err) reject(err);
      resolve(res);
    });
  });
}

export async function authToken(verificationCode: string, email: string): Promise<any> {
  localStorage.setItem("loginEvent", "true");

  return new Promise((resolve, reject) => {
    webAuth.passwordlessLogin({ connection: "email", email, verificationCode }, (err, res) => {
      if (err) {
        sessionStorage.removeItem("loginEvent");
        reject(err);
      } else {
        resolve(res);
      }
    });
  });
}

export async function retrieveToken() {
  if (token) return token;

  return new Promise((resolve, reject) => {
    webAuth.checkSession({}, (err, result) => {
      if (err) reject(err);
      else {
        if (timer) clearTimeout(timer);
        timer = setTimeout(() => {
          token = null;
          retrieveToken();
        }, (result.expiresIn - 60) * 1000); // refresh 1 minutes before expiration

        token = result;
        resolve(result);
      }
    });
  });
}

export function logout(): void {
  removeOrg();
  const returnTo = `${redirectUri}/auth`;
  webAuth.logout({ returnTo, clientID });
}
