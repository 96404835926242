import classNames from "classnames";
import React, { useEffect, useRef, useState, KeyboardEvent } from "react";
import { FormInputProps, IconPosition } from "../models";
import "./Input.css";

const FormInput: React.FC<FormInputProps> = (props: FormInputProps) => {
  let { id, inputref: inputRef } = props;
  if (!id) id = `${props.name}-id` || "";

  const inputProps = { ...props, id };
  const {
    validator,
    label,
    inputClassName,
    rounded = "rounded-none",
    icon,
    required,
    errorMessage,
    submitIcon = false,
    clearIcon = false,
    value,
    readOnly,
    hasError = false,
    validateOn = "change",
    disabled = false,
    description,
    iconPosition = IconPosition.LEFT,
    readOnlyComponent,
    innerIcons,
    inputStyleClass = "form-input",
    onSubmitClick,
    onClearClick,
    clearIconAlwaysVisible,
    spacer,
    ...rest
  } = inputProps;

  const getRealValue = (val: any) => {
    return val === 0 || val ? val : "";
  };

  const getReadonlyValue = (val: any) => {
    return val === 0 || val ? val : "--";
  };

  const iconOrder = iconPosition === IconPosition.LEFT ? "order-1" : "order-2";
  const inputOrder = iconPosition === IconPosition.LEFT ? "order-2" : "order-1 flex-1";

  const [error, setError] = useState<Error | null>(null);
  const [showClearIcon, setShowClearIcon] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState(getRealValue(value as any));

  if (!inputRef && clearIcon) inputRef = useRef<HTMLInputElement | null>(null);

  const fieldClassName = classNames(
    "w-full",
    !readOnly && !innerIcons && !icon && inputStyleClass,
    disabled && !readOnly && "is-disabled",
    inputClassName,
    (error || errorMessage || hasError) && "error"
  );

  const handleKeyUp = (evt: KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    switch (evt.code) {
      case "Escape":
        if (onClearClick) onClearClick(evt);
        break;
      case "Enter":
        if (onSubmitClick) onSubmitClick(evt);
        break;
    }
  };

  useEffect(() => {
    setInputValue(getRealValue(value as any));
    if (clearIconAlwaysVisible) {
      setShowClearIcon(true);
    } else {
      setShowClearIcon(((value as string)?.length ?? 0) > 0);
    }
  }, [value]);

  // disable mouse wheel on inputs type number
  useEffect(() => {
    function mouseWheelEvent(event: MouseEvent) {
      const input = event.target as HTMLInputElement;
      if (input.type === "number") input.blur();
    }
    document.addEventListener("wheel", mouseWheelEvent);
    return () => document.addEventListener("wheel", mouseWheelEvent);
  }, []);

  delete inputProps.textAlign;

  const input =
    rest.type === "textarea" ? (
      <textarea
        id={id}
        data-testid={id}
        readOnly={readOnly}
        disabled={disabled}
        aria-label="form-input"
        value={inputValue}
        className={fieldClassName}
        rows={rest.rows}
        cols={rest.cols}
        placeholder={rest.placeholder}
        onClick={(evt) => evt.stopPropagation()}
        onKeyUp={handleKeyUp}
        onBlur={(evt) => {
          const { value: newValue } = evt.target;
          if (validator && validateOn === "blur") setError(validator(newValue));
          if (inputProps.onBlur) inputProps.onBlur(evt);
        }}
        onChange={(evt) => {
          const { value: newValue } = evt.target;
          if (validator && validateOn === "change") setError(validator(newValue));
          setInputValue(newValue);
          if (inputProps.onChange) inputProps.onChange(evt);
        }}
      />
    ) : (
      <input
        {...rest}
        id={id}
        data-testid={id}
        ref={inputRef}
        readOnly={readOnly}
        disabled={disabled}
        aria-label="form-input"
        className={fieldClassName}
        value={inputValue}
        onClick={(evt) => evt.stopPropagation()}
        onKeyUp={handleKeyUp}
        onBlur={(evt) => {
          const { value: newValue } = evt.target;
          if (validator && validateOn === "blur") setError(validator(newValue));
          if (inputProps.onBlur) inputProps.onBlur(evt);
        }}
        onChange={(evt) => {
          const { value: newValue } = evt.target;
          if (validator && validateOn === "change") setError(validator(newValue));
          setInputValue(newValue);
          if (inputProps.onChange) inputProps.onChange(evt);
        }}
      />
    );

  const labelNode = label && (
    <label aria-label="form-input-label" htmlFor={inputProps.id} className={classNames("form-field-label flex items-center", required && "required")}>
      {label}
    </label>
  );

  const submitButton = submitIcon && (
    <button className="order-3" onClick={onSubmitClick}>
      <svg xmlns="http://www.w3.org/2000/svg" width={22} height={22} fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
      </svg>
    </button>
  );

  const clearButton = clearIcon && (
    <button
      className="order-3"
      onClick={(evt) => {
        if (onClearClick) {
          onClearClick(evt);
          return;
        }

        const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, "value")?.set;

        if (nativeInputValueSetter && inputRef?.current) {
          nativeInputValueSetter.call(inputRef?.current, "");
          const ev2 = new Event("input", { bubbles: true });
          inputRef?.current?.dispatchEvent(ev2);
        }
      }}
    >
      <svg xmlns="http://www.w3.org/2000/svg" width={22} height={22} fill="none" viewBox="0 0 24 24" stroke="#202E4E">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
      </svg>
    </button>
  );

  return (
    <div className={classNames("field-grid", innerIcons && inputStyleClass)}>
      {labelNode && labelNode}

      <div className={classNames("field-area", rounded, (error || errorMessage || hasError) && "error")}>
        {spacer}

        {readOnly ? (
          <div>{readOnlyComponent || getReadonlyValue(inputValue)}</div>
        ) : (
          <>
            {icon ? (
              <div className={classNames("flex items-center gap-4", inputStyleClass)}>
                <div className={iconOrder}>{icon}</div>
                <div className={classNames(inputOrder, "flex-1")}>{input}</div>
                {showClearIcon && clearButton}
              </div>
            ) : (
              <div className="flex items-center">
                {input} {submitIcon && submitButton} {showClearIcon && clearButton}
              </div>
            )}
          </>
        )}
      </div>

      {errorMessage && <div className="font-sans text-base font-semibold text-red-900">{errorMessage}</div>}
      {description && <span className="text-base text-grey-800 mt-2">{description}</span>}
    </div>
  );
};

export default FormInput;
