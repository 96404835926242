import React, { useEffect, useReducer, useRef } from "react";
import { AlertsContextType, AlertsState } from "../models";
import { reducer } from "../reducer";
import { getAlerts } from "../services";
import { NOTIFICATION_INTERVAL } from "../utils";
import { AlertsContext } from "./AlertContext";

const initialState: AlertsState = {
  unReadCounter: 0,
  totalCounter: 0,
};

export const AlertsProvider: React.FC<{ active: boolean }> = ({ active, children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const intervalRef = useRef<any>();

  const fetchALertCounter = async () => {
    try {
      const { meta } = await getAlerts({ type: "all", limit: 1, page: 1 });
      dispatch({ type: "SET_COUNTERS", payload: meta });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);

      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      cancelCounterInterval();
    }
  };

  const cancelCounterInterval = () => clearInterval(intervalRef.current);

  const startCounterInterval = () => {
    intervalRef.current = setInterval(fetchALertCounter, NOTIFICATION_INTERVAL);
  };

  useEffect(() => {
    if (active) {
      fetchALertCounter();
      startCounterInterval();
    }
    return () => {
      if (active) cancelCounterInterval();
    };
  }, []);

  const value: AlertsContextType = {
    state,
    dispatch,
    cancelCounterInterval,
    startCounterInterval,
  };

  return <AlertsContext.Provider value={value}>{children}</AlertsContext.Provider>;
};
