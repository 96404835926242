import apiClient from "~/common/api-client";
import { buildAPIURL } from "~/common/http";
import { User } from "../contacts/models";

export const getMe = async (): Promise<User> => {
  const response = await apiClient.get(buildAPIURL("/v1/me"));
  return response.data as User;
};

export const updateMe = async (me: { id: string } & Partial<User>): Promise<User> => {
  const response = await apiClient.patch(buildAPIURL("/v1/me"), me);
  return response.data as User;
};
