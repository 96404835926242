import { useContext, useEffect, useReducer, useRef } from "react";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { useParams } from "~/common/hooks";
import { AlertsContext } from "./components/AlertContext";
import { Alert, AlertsParams, CounterMeta, UpdateAlertParams } from "./models";
import { getAlerts, updateAlertIds } from "./services";
import { NOTIFICATION_INTERVAL } from "./utils";

interface State {
  alerts: Alert[];
  meta: CounterMeta;
  loading: boolean;
}

const initialState: State = {
  alerts: [],
  meta: {
    total: 0,
    totalUnread: 0,
  },
  loading: false,
};

type PartialReducer = (s: State, newState: Partial<State>) => State;

const partialReducer: PartialReducer = (s, newState) => ({
  ...s,
  ...newState,
});

export const useAlertsProvider = () => {
  const context = useContext(AlertsContext);

  if (!context) {
    throw new Error(`useAlertsProvider must be used within a AlertsProvider`);
  }

  return context;
};

export const useNewAlerts = () => {
  const intervalRef = useRef<any>();
  const location = useLocation();

  const {
    state: { unReadCounter, totalCounter },
    dispatch,
    cancelCounterInterval,
    startCounterInterval,
  } = useAlertsProvider();

  const [state, setState] = useReducer(partialReducer, initialState);

  const { params, setParams } = useParams<AlertsParams>({
    defaultParams: {
      type: "all",
      page: 1,
    },
    updateUrl: true,
  });

  const changePage = (selectedItem: { selected: number }) => {
    const { selected } = selectedItem;
    setParams({ ...params, page: selected });
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const update = async (updateParams: UpdateAlertParams) => {
    const { notifications } = await updateAlertIds(updateParams);
    const updatedIds = notifications?.map((a) => a.id);

    setState({
      alerts: state.alerts.map((a) => {
        if (updatedIds.includes(a.id)) {
          const found = notifications.find((b) => b.id === a.id);
          return found || a;
        }
        return a;
      }),
    });

    if (updatedIds.length === 1)
      if (updateParams.markRead) {
        dispatch({ type: "DECREMENT_UNREAD_COUNTER" });
      } else {
        dispatch({ type: "INCREMENT_UNREAD_COUNTER" });
      }
    else
      dispatch({
        type: "SET_UNREAD_COUNTER",
        payload: 0,
      });
  };

  const fetchAlerts = async () => {
    if (!params) return;

    // setState({ loading: true });
    const response = await getAlerts(params);

    setState({
      alerts: response.data || [],
      loading: false,
      meta: response.meta,
    });

    if (params.type === "all")
      dispatch({
        type: "SET_UNREAD_COUNTER",
        payload: response.meta.totalUnread,
      });
  };

  useEffect(() => {
    if (params?.type === "all") cancelCounterInterval();

    return () => {
      if (params?.type === "all") startCounterInterval();
      clearInterval(intervalRef.current);
    };
  }, [params?.type]);

  useEffect(() => {
    fetchAlerts();
    if (intervalRef.current) clearInterval(intervalRef.current);
    intervalRef.current = setInterval(fetchAlerts, NOTIFICATION_INTERVAL);
  }, [params]);

  useEffect(() => {
    if (location.search && params) {
      const parsed = queryString.parse(location.search, {
        parseNumbers: true,
      }) as any;
      if (params.type !== parsed.type || params?.page !== parsed.page) {
        setParams(parsed);
      }
    }
  }, [location.search]);

  const totalPages = state.meta ? Math.ceil(state.meta.total / 20) : 1;
  const { loading, alerts } = state;

  return {
    loading,
    alerts,
    unReadCounter,
    totalCounter,
    params,
    update,
    setParams,
    changePage,
    totalPages,
  };
};
