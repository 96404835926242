import { List } from "~/app.namespace";
import { BoardColumn, BoardData } from "~/common/containers/board/models";
import { sortKey } from "~/common/containers/table/utils";
import { countries } from "~/common/models";
import { AutocompleteOption, Selectable, SelectableId, getSelectableIdList } from "~/common/selectables";
import { getMeasurement } from "~/common/utils";
import { AssignedUser, PositionType, User } from "../contacts/models";
import { GetProjectsOptions, Project, ProjectType, Site, SiteTemplateField, Stage } from "./models";

export const sortContacts = (contacts: AssignedUser[] = []) => {
  const sortOrder: PositionType[] = ["internal", "external"];

  const sortedContacts = [...contacts].sort((a, b) => {
    if (a.assignedOwnerAt) return -1;
    if (b.assignedOwnerAt) return 1;

    return a.positionType === b.positionType
      ? a.firstName.localeCompare(b.firstName)
      : sortOrder.indexOf(a.positionType || "internal") - sortOrder.indexOf(b.positionType || "internal");
  });

  return sortedContacts;
};

export function getProjectType(project: Project, projectTypes: ProjectType[]): ProjectType | undefined {
  const { projectTypeId } = project;
  const projectType = projectTypes.find(({ id }) => id === projectTypeId);
  return projectType;
}

export function getProjectTypeName(project: Project, projectTypes: ProjectType[], defaultValue = "--"): string {
  const type = getProjectType(project, projectTypes);
  return !type ? defaultValue : type.name;
}

export function getProjectMethodlogyName(project: Project, projectTypes: ProjectType[], returnValue = "--"): string {
  const { projectTypeMethodologyId } = project;
  const projectType = getProjectType(project, projectTypes);
  if (projectType && projectType.methodologies) {
    const projectMethodology = projectType.methodologies.find(({ id }) => id === projectTypeMethodologyId);

    return projectMethodology?.name ?? returnValue;
  }

  return returnValue;
}

export function getProjectStageName(project: Project, defaultValue = "--"): string {
  const { stages, currentStageId } = project;
  const stage = stages?.find(({ id }) => id === currentStageId);
  return stage?.name ?? defaultValue;
}

export function getProjectSite(project: Project): Site {
  const [site] = project.sites ?? [{} as Site];
  return site;
}

export function getProjectPrimaryContact(project: Project): AssignedUser | null {
  const [site] = project.sites ?? [];
  const [assignedUser] = site?.assignedUsers ?? [];
  return assignedUser || null;
}

export function getProjectContacts(project: Project): AssignedUser[] {
  const users: AssignedUser[] = [];
  if (project.assignedUsers) {
    users.push(...project.assignedUsers);
  }

  return sortContacts(users);
}

export function getProjectPrimaryContactName(project: Project, returnValue?: string): string {
  const contact = getProjectPrimaryContact(project);
  const defaultValue = returnValue ?? "";
  return contact ? `${contact.firstName} ${contact.lastName}` : defaultValue;
}

export function getProjectPrimaryContactEmail(project: Project): string {
  const contact = getProjectPrimaryContact(project);
  return contact?.email ?? "";
}

export function getProjectPrimaryContactPhoneNumber(project: Project): string {
  const contact = getProjectPrimaryContact(project);
  return contact?.phoneNumber ?? "";
}

export function getProjectTotalArea(project: Project, defaultValue = "--"): string {
  if (project.sites) {
    const { projectAreaHectares } = project.sites[0];
    return projectAreaHectares?.toString() || defaultValue;
  }
  return defaultValue;
}

export function getProjectSelectableTags(project: Project, selectables: Selectable[]): string[] | null {
  const projectTags = project.projectTags?.map(({ selectableId }) => selectableId);
  const matches = selectables.filter(({ id }) => projectTags?.includes(id));
  return (matches?.length ?? 0) > 0 ? matches.map(({ name }) => name) : null;
}

export function getProjectSelectableStandarName(project: Project, selectables: Selectable[], defaultValue = "--"): string {
  return selectables?.find((s) => s.id === project.projectStandardSelectableId)?.name ?? defaultValue;
}

export function getProjectSelectableRegistryName(project: Project, selectables: Selectable[], defaultValue = "--"): string {
  return selectables?.find((s) => s.id === project.projectRegistrySelectableId)?.name ?? defaultValue;
}

export function getProjectOwner(project: Project): AssignedUser | undefined {
  const [owner] = project.assignedUsers?.filter((u) => (u as User).positionType === "internal") ?? [];
  return owner;
}

export function getProjectOwnerName(project: Project, returnValue?: string): string {
  const assignedUser = getProjectOwner(project);
  const defaultValue = returnValue ?? "";
  return assignedUser ? `${assignedUser.firstName} ${assignedUser.lastName}` : defaultValue;
}

export const getProjectAssignedUsers = (project: Project, type?: PositionType): AssignedUser[] => {
  if (!type) return project.assignedUsers ?? [];
  return project.assignedUsers?.filter((u) => u.positionType === type) ?? [];
};

const convertProject = (project: Project, fn: (n: number) => number) => {
  if (project.sites && project.sites.length > 0) {
    const [site] = project.sites;

    const existingForestAreaHectares = site?.existingForestAreaHectares && fn(site.existingForestAreaHectares);
    const projectAreaHectares = site?.projectAreaHectares && fn(site.projectAreaHectares);
    const riparianAreaHectares = site?.riparianAreaHectares && fn(site.riparianAreaHectares);
    const stockingDensityStemsPerHectare = site?.stockingDensityStemsPerHectare && Math.round(fn(site.stockingDensityStemsPerHectare)); // this value is an int in the BE
    const wetlandAreaHectares = site?.wetlandAreaHectares && fn(site.wetlandAreaHectares);
    const riparianManagementRestorationAreaHectares = site?.riparianManagementRestorationAreaHectares && fn(site.riparianManagementRestorationAreaHectares);
    const remnantVegetationManagementRestorationAreaHectares =
      site?.remnantVegetationManagementRestorationAreaHectares && fn(site.remnantVegetationManagementRestorationAreaHectares);
    const maxEstimatedCO2eTonnesPerHectare = site?.maxEstimatedCO2eTonnesPerHectare && fn(site.maxEstimatedCO2eTonnesPerHectare);
    const minEstimatedCO2eTonnesPerHectare = site?.minEstimatedCO2eTonnesPerHectare && fn(site.minEstimatedCO2eTonnesPerHectare);
    const interimCreditingAssessmentTCO2HectaresPerYear = site?.interimCreditingAssessmentTCO2HectaresPerYear && fn(site.interimCreditingAssessmentTCO2HectaresPerYear);
    const averageAreaOfPrimaryWoodyVegetationMaintainedHectares =
      site?.averageAreaOfPrimaryWoodyVegetationMaintainedHectares && fn(site?.averageAreaOfPrimaryWoodyVegetationMaintainedHectares);
    const averageAreaOfSecondaryWoodyVegetationMaintainedHectares =
      site?.averageAreaOfSecondaryWoodyVegetationMaintainedHectares && fn(site?.averageAreaOfSecondaryWoodyVegetationMaintainedHectares);
    const changeInSecondaryWoodyVegetationHectares = site?.changeInSecondaryWoodyVegetationHectares && fn(site.changeInSecondaryWoodyVegetationHectares);
    const certifiedCreditAreaHectares = site?.certifiedCreditAreaHectares && fn(site.certifiedCreditAreaHectares);
    const eligibleForestAreaHectares = site?.eligibleForestAreaHectares && fn(site.eligibleForestAreaHectares);
    const forestAreaHectares = site?.forestAreaHectares && fn(site.forestAreaHectares);
    const modifiedPeatlandConditionAreaHectares = site?.modifiedPeatlandConditionAreaHectares && fn(site.modifiedPeatlandConditionAreaHectares);
    const claimableCO2Tonnes = site?.claimableCO2Tonnes && fn(site.claimableCO2Tonnes);
    const twentyFiveYearYieldACCUPerHectare = site?.twentyFiveYearYieldACCUPerHectare && fn(site.twentyFiveYearYieldACCUPerHectare);
    const areaPreservedHectares = site?.areaPreservedHectares && fn(site.areaPreservedHectares);
    const cultivatedTreeZoneHectares = site?.cultivatedTreeZoneHectares && fn(site.cultivatedTreeZoneHectares);
    const vulnerableZonesHectares = site?.vulnerableZonesHectares && fn(site.vulnerableZonesHectares);
    const surfaceAgrivoltaicsHectares = site?.surfaceAgrivoltaicsHectares && fn(site.surfaceAgrivoltaicsHectares);
    const surfacePhotovoltaicHectares = site?.surfacePhotovoltaicHectares && fn(site.surfacePhotovoltaicHectares);
    const treeVolumeM3HA = site?.treeVolumeM3HA && fn(site.treeVolumeM3HA);
    const averageAnnualIncreaseM3HAPerYear = site?.averageAnnualIncreaseM3HAPerYear && fn(site.averageAnnualIncreaseM3HAPerYear);
    const areaPlantedBySpeciesExoticHectares = site?.areaPlantedBySpeciesExoticHectares && fn(site.areaPlantedBySpeciesExoticHectares);
    const areaPlantedBySpeciesIndigenousHectares = site?.areaPlantedBySpeciesIndigenousHectares && fn(site.areaPlantedBySpeciesIndigenousHectares);
    const areaToBePlantedExoticHectares = site?.areaToBePlantedExoticHectares && fn(site.areaToBePlantedExoticHectares);
    const areaToBePlantedIndigenousHectares = site?.areaToBePlantedIndigenousHectares && fn(site.areaToBePlantedIndigenousHectares);

    const newSite = {
      ...site,
      ...(existingForestAreaHectares && { existingForestAreaHectares }),
      ...(projectAreaHectares && { projectAreaHectares }),
      ...(riparianAreaHectares && { riparianAreaHectares }),
      ...(remnantVegetationManagementRestorationAreaHectares && { remnantVegetationManagementRestorationAreaHectares }),
      ...(riparianManagementRestorationAreaHectares && { riparianManagementRestorationAreaHectares }),
      ...(stockingDensityStemsPerHectare && { stockingDensityStemsPerHectare }),
      ...(wetlandAreaHectares && { wetlandAreaHectares }),
      ...(maxEstimatedCO2eTonnesPerHectare && { maxEstimatedCO2eTonnesPerHectare }),
      ...(minEstimatedCO2eTonnesPerHectare && { minEstimatedCO2eTonnesPerHectare }),
      ...(interimCreditingAssessmentTCO2HectaresPerYear && { interimCreditingAssessmentTCO2HectaresPerYear }),
      ...(averageAreaOfPrimaryWoodyVegetationMaintainedHectares && { averageAreaOfPrimaryWoodyVegetationMaintainedHectares }),
      ...(averageAreaOfSecondaryWoodyVegetationMaintainedHectares && { averageAreaOfSecondaryWoodyVegetationMaintainedHectares }),
      ...(changeInSecondaryWoodyVegetationHectares && { changeInSecondaryWoodyVegetationHectares }),
      ...(twentyFiveYearYieldACCUPerHectare && { twentyFiveYearYieldACCUPerHectare }),
      ...(areaPreservedHectares && { areaPreservedHectares }),
      ...(certifiedCreditAreaHectares && { certifiedCreditAreaHectares }),
      ...(eligibleForestAreaHectares && { eligibleForestAreaHectares }),
      ...(forestAreaHectares && { forestAreaHectares }),
      ...(claimableCO2Tonnes && { claimableCO2Tonnes }),
      ...(modifiedPeatlandConditionAreaHectares && { modifiedPeatlandConditionAreaHectares }),
      ...(cultivatedTreeZoneHectares && { cultivatedTreeZoneHectares }),
      ...(vulnerableZonesHectares && { vulnerableZonesHectares }),
      ...(surfaceAgrivoltaicsHectares && { surfaceAgrivoltaicsHectares }),
      ...(surfacePhotovoltaicHectares && { surfacePhotovoltaicHectares }),
      ...(treeVolumeM3HA && { treeVolumeM3HA }),
      ...(averageAnnualIncreaseM3HAPerYear && { averageAnnualIncreaseM3HAPerYear }),
      ...(areaPlantedBySpeciesExoticHectares && { areaPlantedBySpeciesExoticHectares }),
      ...(areaPlantedBySpeciesIndigenousHectares && { areaPlantedBySpeciesIndigenousHectares }),
      ...(areaToBePlantedExoticHectares && { areaToBePlantedExoticHectares }),
      ...(areaToBePlantedIndigenousHectares && { areaToBePlantedIndigenousHectares }),
    };

    project = { ...project, sites: [newSite] };
  }

  const convertedProject = {
    ...project,
    potentialPropertyAreaHectares: project.potentialPropertyAreaHectares && fn(project.potentialPropertyAreaHectares),
  };

  return convertedProject;
};

export const hectaresToAcres = (hectares: number) => hectares / 0.40468564;
export const acresToHectares = (acres: number) => acres * 0.40468564;

export const convertToAcres = (currentArea: number): number => {
  const measurement = getMeasurement();
  let area = currentArea;

  if (measurement === "acres") area = hectaresToAcres(area);
  return area;
};

export const convertToHectares = (currentArea: number): number => {
  const measurement = getMeasurement();
  let area = currentArea;

  if (measurement === "acres") area = acresToHectares(area);
  return area;
};

export const getProjectArea = (project: Project): Project => convertProject(project, convertToAcres);

export const updateProjectArea = (project: Project): Project => convertProject(project, convertToHectares);

export const populateProject = (project: Project): Project => {
  const stage = project.stages?.find((s) => s.id === project.currentStageId);
  const assignedUsers = sortContacts(project.assignedUsers ?? []);

  return {
    ...getProjectArea(project),
    stage,
    assignedUsers,
  };
};

export const defaultFilterOptions: GetProjectsOptions = {
  sort: "name",
  page: 1,

  closedOnly: undefined,
  limit: undefined,
  offset: undefined,
  id: undefined,
  status: undefined,
  projectAssignedUserId: undefined,
  siteAssignedUserId: undefined,
  projectOwnerId: undefined,
  search: undefined,
  stageTemplateId: undefined,
  flagged: undefined,
  tagId: undefined,
  sourceSelectableId: undefined,
  projectTypeId: undefined,
  region: undefined,
  archived: undefined,
  emptyLocationOnly: undefined,
  name: undefined,
  myProjects: undefined,
  user: undefined,
  registryId: undefined,
};

export const getDefaultFilterOptions = (id?: string): GetProjectsOptions => {
  let searchStr = window.location.search;
  if (!searchStr) {
    searchStr = localStorage.getItem(`projectFilters-${id}`) || "";
  }

  if (searchStr) {
    const params = new URLSearchParams(searchStr);
    const opts = {} as GetProjectsOptions;
    params.forEach((value, key) => {
      Object.assign(opts, { [key]: value });
    });
    return opts;
  }

  return defaultFilterOptions;
};

export const getProjectsSort = (sort?: string) => {
  let newSort = sort;

  if (sort && sort.replace("-", "") === "contacts") {
    const dir: List.SortDirection = sort.includes("-") ? "desc" : "asc";
    newSort = sortKey("projectAssignedUserName", dir);
  }

  return newSort;
};

export const countryCodesToOptions = (): AutocompleteOption[] => {
  return Object.entries(countries).map(([code, country]) => ({
    label: `${code} - ${country}`,
    value: code,
  }));
};

export const extractFieldValue = <T>(entity: T, field: SiteTemplateField): Partial<T> => {
  const fieldName = (field.name.includes("sites") ? field.name.replace("sites.0.", "") : field.name) as keyof T;
  const value: any = field.unit === "multi-select" ? getSelectableIdList(entity[fieldName] as SelectableId[]) : entity[fieldName];
  return { [fieldName]: value } as Partial<T>;
};

export const buildProjectAttributesFormData = (project: Project, hasField: (field: string, projectTypeId?: string) => boolean, fields: SiteTemplateField[]) => {
  const orgTemplateFields = fields.filter((field) => hasField(field.id, project.projectTypeId));
  const [site] = project.sites!;

  const formData = orgTemplateFields.reduce(
    (prev, curr) => {
      if (curr.name.includes("sites")) {
        const extractedData = extractFieldValue<Site>(site, curr);
        const [currentSite] = prev.sites!;
        const newSite = { ...currentSite, ...extractedData };
        return { ...prev, sites: [newSite] };
      }

      const extractedData = extractFieldValue<Project>(project, curr);
      return { ...prev, ...extractedData };
    },
    { sites: [{ id: site.id }], id: project.id } as Partial<Project>
  );

  return formData;
};

export const getBoardItems = <T>(data: T[], key: string): Record<string, T> => {
  if (data.length === 0) return {} as Record<string, T>;

  return data.reduce((acc, item) => {
    const i = item as any;
    const k = i[key];

    return {
      ...acc,
      [k]: item,
    };
  }, {} as Record<string, T>);
};

export const getBoardColums = (projects: Project[], stages: Stage[]) => {
  if (stages.length === 0) return {} as Record<string, BoardColumn>;

  return stages.reduce((acc, stage) => {
    const { id, name } = stage;

    const itemIds = projects
      .filter((p) => {
        const currentStage = p.stages?.find(({ stageTemplateId }) => stageTemplateId === id);
        return currentStage && currentStage.id === p.currentStageId;
      })
      .sort((a, b) => a.sortOrder - b.sortOrder)
      .map((p) => p.id);

    return {
      ...acc,
      [id]: {
        id,
        title: name,
        itemIds,
        stageTemplateId: projects.find((p) => p.id === itemIds[0])?.currentStageId,
      },
    };
  }, {} as Record<string, BoardData["columns"]["c-1"]>);
};
