/* eslint-disable prefer-destructuring */
import { StatusColor } from "~/common/forms/color-menu/models";
import { MenuColors } from "~/common/forms/color-menu/utils";
import theme from "~/theme";
import { Variant } from "../models";

interface CustomStylesProps {
  hasError?: boolean;
  menuWidth?: number;
  variant?: Variant;
}

const { colors, spacing, fontFamily, fontSize, borderRadius } = theme;
const { red, grey, blue } = colors;

const [baseFontSize, baseLineHeight] = fontSize.base;

const fontBase = {
  fontSize: baseFontSize,
  lineHeight: baseLineHeight,
  fontFamily: fontFamily.sans,
};

const getControlBorder = (state: any, hasError?: boolean, variant?: Variant): string => {
  const { isFocused, isDisabled } = state;

  if (hasError) return `1px solid ${red[500]}`;
  if (isDisabled) return `1px solid ${grey[500]}`;
  if (variant === Variant.SECONDARY) return `1px solid ${blue[100]}`;

  const [d] = state.getValue();
  const type = d?.meta?.colour as StatusColor;

  if (type && MenuColors[type]?.Control) {
    return `1px solid ${MenuColors[type].Control}`;
  }

  if (isFocused) return `1px solid ${grey[500]}`;
  return `1px solid ${grey[400]}`;
};

const inputHoverColor = (state: any, hasError?: boolean, variant?: Variant): string => {
  if (variant === Variant.SECONDARY) return blue[500];
  return getControlBorder(state, hasError, variant);
};

const getControlHoverBorder = (state: any, hasError?: boolean, variant?: Variant): string => {
  return getControlBorder(state, hasError, variant);
};

const getBgColor = (state: any, hasError?: boolean, variant?: Variant) => {
  if (state.isDisabled) return grey[100];
  if (variant === Variant.SECONDARY) return blue[100];

  return false;
};

const getHoverBackgroundColor = (state: any, hasError?: boolean, variant?: Variant) => {
  if (state.isDisabled) return grey[200];
  if (variant === Variant.SECONDARY) return blue[200];
  return false;
};

export const customStyles = (props: CustomStylesProps) => {
  const { menuWidth, hasError, variant } = props;

  return {
    container: () => ({
      pointerEvents: "auto",
      position: "relative",
      boxSizing: "border-box",
    }),
    control: (provided: any, state: any) => ({
      ...fontBase,
      padding: "8px 16px",
      color: variant === Variant.SECONDARY && blue[500],
      borderRadius: borderRadius.DEFAULT,
      border: getControlBorder(state, hasError, variant),
      backgroundColor: getBgColor(state, hasError, variant),
      cursor: state.isDisabled ? "not-allowed" : "pointer",
      "&:hover": {
        border: getControlHoverBorder(state, hasError, variant),
        backgroundColor: getHoverBackgroundColor(state, hasError, variant),
      },
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      borderWidth: "1px",
      justifyContent: "space-between",
      minHeight: "40px",
      position: "relative",
      boxSizing: "border-box",
      outline: "0 !important",
    }),
    singleValue: (provided: any, state: any) => ({
      ...provided,
      ...fontBase,
      fontWeight: variant === Variant.SECONDARY ? 600 : 400,
      color: variant === Variant.SECONDARY ? blue[500] : grey[800],
      "&:hover": {
        color: inputHoverColor(state, hasError, variant),
      },
    }),
    noOptionsMessage: (provided: any) => ({
      ...provided,
      textAlign: "left",
    }),
    menu: (provided: any) => ({
      ...provided,
      zIndex: 50,
      color: variant === Variant.SECONDARY ? blue[500] : grey[100],
    }),
    menuPortal: (provided: any) => ({
      ...provided,
      zIndex: 100,
      width: menuWidth ?? provided.width,
    }),
    multiValueLabel: (provided: any) => ({
      ...provided,
      fontSize: fontSize.sm,
      padding: `0 ${spacing[3]}`,
      color: grey[800],
      boxSizing: "border-box",
    }),
    multiValue: () => ({
      fontFamily: fontFamily.sans,
      backgroundColor: grey[100],
      margin: 0,
      display: "flex",
      borderRadius: borderRadius.DEFAULT,
      minWidth: 0,
      alignItems: "center",
      padding: `${spacing[1]} ${spacing[3]}`,
      border: `1px solid ${colors.grey[400]}`,
      boxSizing: "border-box",
    }),
    valueContainer: (provided: any, state: any) => ({
      ...fontBase,
      display: "flex",
      flex: "1 1 0%",
      flexWrap: "wrap",
      position: "relative",
      overflow: "hidden",
      alignItems: "center",
      margin: 0,
      padding: 0,
      boxSizing: "border-box",
      gap: spacing[4],
      "&:hover": {
        color: inputHoverColor(state, hasError, variant),
      },
    }),
    placeholder: (provided: any) => ({
      ...provided,
      ...fontBase,
      padding: 0,
      margin: 0,
      color: grey[400],
    }),
    option: (provided: any, state: any) => ({
      padding: spacing[4],
      cursor: "pointer",
      backgroundColor: state.isSelected && blue[100],
      color: grey[800],
      "&:hover": {
        backgroundColor: state.isSelected ? blue[100] : grey[100],
      },
    }),
    clearIndicator: (provided: any) => ({
      ...provided,
      padding: 0,
      margin: 0,
      color: grey[800],
      display: "flex",
      alignItems: "center",
      "&:hover": {
        color: red[500],
      },
    }),
    multiValueRemove: (provided: any) => ({
      ...provided,
      "&:hover": {
        backgroundColor: "transparent",
        color: red[500],
      },
    }),
    dropdownIndicator: (provided: any) => ({
      ...provided,
      padding: 0,
      margin: 0,
    }),
    indicatorsContainer: () => ({
      display: "flex",
      boxSizing: "border-box",
    }),
    indicatorSeparator: (provided: any) => ({
      ...provided,
      padding: 0,
      margin: 0,
    }),
    input: (provided: any) => ({
      ...provided,
      color: grey[800],
      margin: 0,
      padding: 0,
    }),
  };
};
