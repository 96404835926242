import { Transition } from "@headlessui/react";
import classNames from "classnames";
import { Feature, FeatureCollection } from "geojson";
import mapboxgl, { GeoJSONSource, LngLat, LngLatLike } from "mapbox-gl";
import { useToast } from "~/common/toasts";
import { useTranslation } from "react-i18next";
import toGeoJSON from "@mapbox/togeojson";
import geojsonExtent from "@mapbox/geojson-extent";
import React, { useEffect, useRef, useState } from "react";
import { ProjectMarker } from "~/core/reports/models";
import { useMe } from "~/core/account";
import { usePinProjects } from "~/core/reports/hooks";
import { CecilMapControl } from "../models";
import "./Map.css";
import MapInfoPanel from "./MapInfoPanel";
import { stringToColour } from "../utils";

type Props = {
  markers?: ProjectMarker[];
  center?: number[];
  height?: number;
  isStatic?: boolean;
  disabledMarkerClick?: boolean;
  skeleton?: boolean;
  zoom?: number;
  mapStyle?: string;
  onRestore?: (map: mapboxgl.Map) => void;
  preserveDefaultZoom?: boolean;
  infoPanelClassName?: string;
  mapFile?: Document | null;
};

const skeletonClasses = "h-map bg-grey rounded";

const Map: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();
  const {
    markers,
    skeleton,
    center,
    isStatic = false,
    height = 600,
    zoom = 1.5,
    disabledMarkerClick = false,
    mapStyle = "mapbox://styles/mapbox/streets-v11",
    onRestore,
    preserveDefaultZoom = false,
    infoPanelClassName,
    mapFile,
  } = props;

  const initRef = useRef<boolean>(false);
  const [map, setMap] = useState<mapboxgl.Map | null>(null);
  const [bounds, setBounds] = useState<mapboxgl.LngLatBounds | null>(null);
  const { toastError } = useToast();
  const markersColour = mapStyle === "mapbox://styles/mapbox/streets-v11" ? "blue" : "white";

  const clusterMaxZoom = 14;
  const clusterRadius = 50;
  const mapPadding = 50;
  const mapDuration = 650;
  const mapZoom = center && !zoom ? 6 : zoom;

  let mapCenter: LngLat;
  try {
    if (center) mapCenter = new LngLat(center[0], center[1]);
    else mapCenter = new LngLat(151.2099, -33.865143);
  } catch (e) {
    mapCenter = new LngLat(151.2099, -33.865143);
  }

  const { me } = useMe();
  const [currentProjectIds, setProjectIds] = useState<string[]>([]);
  const [mapControl] = useState(new CecilMapControl({ padding: mapPadding }));
  const mapContainer = useRef<HTMLDivElement | null>(null);

  const { pinProjects } = usePinProjects(currentProjectIds, me);

  // On height change resize map
  useEffect(() => {
    if (map) map.resize();
  }, [height]);

  // Add map controls and update the restore bounds
  useEffect(() => {
    if (map && (markers?.length ?? 0) > 0) {
      const markersBounds = new mapboxgl.LngLatBounds();
      markers?.forEach(({ lng, lat }) => markersBounds.extend([lng, lat]));
      if ((markers?.length ?? 0) > 0 && !center) {
        const currentZoom = map.getZoom();
        const newZoom = currentZoom > 6 ? currentZoom : 6;
        map.fitBounds(markersBounds, {
          maxZoom: preserveDefaultZoom ? mapZoom : newZoom,
          padding: mapPadding,
          duration: mapDuration,
        });
      } else {
        map.easeTo({ zoom: mapZoom, center: mapCenter, duration: mapDuration });
      }

      mapControl.onRestore(() => {
        if (onRestore) {
          onRestore(map);
          return;
        }

        setProjectIds([]);
        if (markers) {
          map.fitBounds(markersBounds, {
            padding: mapPadding,
            duration: mapDuration,
          });
        } else if (center) {
          map.easeTo({
            zoom: mapZoom,
            center: mapCenter,
            duration: mapDuration,
          });
        }
      });

      if (!map.hasControl(mapControl) && !isStatic) {
        map.addControl(mapControl, "bottom-left");
      }
    }
  }, [map, JSON.stringify(markers)]);

  // Add map source and layers
  useEffect(() => {
    if (!map) return;

    let newMap = map;
    const layer = newMap.getLayer("custom-layer");
    if (layer) newMap = newMap.removeLayer("custom-layer");
    const source = map.getSource("custom-source");
    if (source) newMap = newMap.removeSource("custom-source");

    if (mapFile) {
      try {
        const geoJSON = toGeoJSON.kml(mapFile);
        const geoJSONWithColors = {
          ...geoJSON,
          features: geoJSON.features.map((feature: any) => ({
            ...feature,
            properties: {
              ...feature.properties,
              color: stringToColour(feature.properties?.name ?? ""),
            },
          })),
        };

        newMap.addSource("custom-source", {
          type: "geojson",
          data: geoJSONWithColors,
        });

        newMap.addLayer({
          id: "custom-layer",
          type: "fill",
          source: "custom-source", // reference the data source
          layout: {},
          paint: {
            "fill-color": ["to-color", ["get", "color"], "#0080ff"], // blue color fill
            "fill-opacity": 0.5,
          },
        });

        newMap = newMap.fitBounds(geojsonExtent(geoJSONWithColors), { animate: false });
        setMap(newMap);
      } catch (e) {
        toastError({ message: t("unable to load map file. Please try again or contact support if you need assistance.") });
      }
    }

    if ((markers?.length ?? 0) > 0) {
      // Parse data
      const features =
        markers?.map((m) => ({
          type: "Feature",
          geometry: { type: "Point", coordinates: [m.lng, m.lat] },
          properties: { id: m.projectId },
        })) ?? [];

      const mapData = {
        type: "FeatureCollection",
        features,
      } as FeatureCollection;

      // If we don't have the map source we add the source and the layers
      if (!map.getSource("projects")) {
        map.addSource("projects", {
          type: "geojson",
          data: mapData,
          cluster: true,
          clusterMaxZoom, // Max zoom to cluster points on
          clusterRadius, // Radius of each cluster when clustering points (defaults to 50)
        });

        map.addLayer({
          id: "clusters",
          type: "circle",
          source: "projects",
          filter: ["has", "point_count"],
          paint: {
            "circle-color": markersColour === "blue" ? "#200E32" : "#FFFFFF",
            "circle-radius": 20,
            "circle-stroke-color": markersColour === "blue" ? "#FFFFFF" : "#200E32",
            "circle-stroke-width": 1,
          },
        });

        map.addLayer({
          id: "cluster-count",
          type: "symbol",
          source: "projects",
          filter: ["has", "point_count"],
          layout: {
            "text-field": "{point_count_abbreviated}",
            "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
            "text-size": 12,
          },
          paint: {
            "text-color": markersColour === "blue" ? "white" : "#200E32",
          },
        });

        map.addLayer({
          id: "unclustered-point",
          type: "symbol",
          source: "projects",
          filter: ["!", ["has", "point_count"]],
          layout: {
            "icon-image": "marker",
          },
        });

        if (!disabledMarkerClick) {
          // inspect a cluster on click
          map.on("click", "clusters", (e) => {
            const layerFeatures = map.queryRenderedFeatures(e.point, {
              layers: ["clusters"],
            });

            const [feature] = layerFeatures;
            // eslint-disable-next-line camelcase
            const { cluster_id } = feature.properties as any;
            const sourceProjects = map.getSource("projects") as GeoJSONSource;
            sourceProjects.getClusterExpansionZoom(cluster_id, (err, clusterZoom) => {
              if (err) return;
              if (feature.geometry.type === "Point") {
                const newCenter = feature.geometry.coordinates as LngLatLike;
                const newZoom = Math.min(clusterZoom, clusterMaxZoom);

                const currentZoom = map.getZoom();
                if (currentZoom === newZoom) {
                  sourceProjects.getClusterChildren(cluster_id, (_, fs) => {
                    if (fs.length > 1) {
                      const ids = fs.map((f) => {
                        const { id } = f.properties as any;
                        return id;
                      });

                      setProjectIds(ids);
                    }
                  });
                }

                map.easeTo({
                  center: newCenter,
                  zoom: newZoom,
                  duration: mapDuration,
                });
              }
            });
          });

          // When a click event occurs on a project
          map.on("click", "unclustered-point", (e) => {
            setBounds(map.getBounds());

            const [feature] = e.features ?? [];
            if (feature && feature.geometry.type === "Point") {
              const coordinates = feature.geometry.coordinates as LngLatLike;
              const { id } = feature.properties as any;
              setProjectIds([id]);

              const newZoom = Math.max(map.getZoom(), 6);
              map.easeTo({
                center: coordinates,
                zoom: newZoom,
                duration: mapDuration,
                padding: { top: 0, bottom: 0, left: 0, right: 200 },
              });
            }
          });

          map.on("mouseenter", "clusters", () => {
            map.getCanvas().style.cursor = isStatic ? "default" : "pointer";
          });
          map.on("mouseleave", "clusters", () => {
            map.getCanvas().style.cursor = "";
          });
          map.on("mouseenter", "unclustered-point", () => {
            map.getCanvas().style.cursor = isStatic ? "default" : "pointer";
          });
          map.on("mouseleave", "unclustered-point", () => {
            map.getCanvas().style.cursor = "";
          });
        }
      } else {
        setProjectIds([]);
        const sourceProjects = map.getSource("projects") as GeoJSONSource;
        sourceProjects.setData(mapData);
      }
    }
  }, [map, mapFile, JSON.stringify(markers)]);

  useEffect(() => {
    if (map) map.setCenter(mapCenter);
  }, [JSON.stringify(mapCenter)]);

  // Creates the map
  useEffect(() => {
    if (mapContainer.current && !initRef.current) {
      mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN ?? "";

      const options: mapboxgl.MapboxOptions = {
        container: mapContainer.current,
        style: mapStyle,
        zoom: mapZoom,
        maxZoom: clusterMaxZoom,
        center: mapCenter,
      };

      const newMap = new mapboxgl.Map(options);
      newMap.dragRotate?.disable();
      newMap.touchZoomRotate?.disableRotation();
      initRef.current = true;

      if (isStatic) {
        // Disable drag and zoom handlers.
        newMap.dragPan.disable();
        newMap.touchZoomRotate.disable();
        newMap.doubleClickZoom.disable();
        newMap.scrollZoom.disable();
        newMap.keyboard.disable();
      }

      newMap.on("load", (mapEvent) => {
        const marker = new Image(28, 34);
        marker.onload = () => {
          newMap.addImage("marker", marker);
          setMap(mapEvent.target);

          const onClick = (e: any) => {
            const lis = e.features
              .filter((feature: Feature) => feature.properties?.name)
              .map((feature: Feature) => feature.properties?.name)
              .map((name: string) => `<li class="font-bold">${name}</li>`);

            if (lis.length > 0) {
              const list = `<ul class="ml-4 p-4 list-disc">${lis.join("")}</ul>`;
              new mapboxgl.Popup().setLngLat(e.lngLat).setHTML(list).addTo(newMap);
            }
          };
          newMap.on("click", "custom-layer", onClick);
        };
        marker.src = `/icons/${markersColour === "blue" ? "MapMarkerBlue" : "MapMarkerWhite"}.png`;
      });
    }
  }, [mapContainer.current]);

  const infoPanelClassNames = classNames("overflow-hidden absolute z-30 bg-white shadow-lg rounded-md w-max top-7 right-7 p-7", infoPanelClassName);

  return (
    <>
      <div className="relative overflow-hidden">
        <div ref={mapContainer} className={classNames("rounded flex-1 w-full", skeleton && skeletonClasses)} style={{ height: `${height}px` }} />

        <Transition
          as="div"
          show={!!(currentProjectIds.length > 0 && pinProjects)}
          className={infoPanelClassNames}
          enter="transform transition-all ease-in-out duration-300"
          enterFrom="translate-x-full opactiy-0 scale-75"
          enterTo="translate-x-0 opactiy-100 scale-100"
          leave="transform transition-all ease-out-in duration-300"
          leaveFrom="translate-x-0 opactiy-100 scale-100"
          leaveTo="translate-x-full opacity-50 scale-75"
        >
          <div data-testid="info-panel" className="relative scrollbar-cecil-y" style={{ maxHeight: `${height - 120}px`, width: "395px" }}>
            <div className="absolute w-full flex justify-end">
              <button
                className="hover:text-link"
                onClick={() => {
                  setProjectIds([]);
                  if (bounds)
                    map?.fitBounds(bounds, {
                      duration: mapDuration,
                      padding: 0,
                    });
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>

            <div className={classNames("divide-grey-100 divide-y gap-6 flex flex-col", pinProjects.length > 1 && "-mt-3")} style={{ maxHeight: `${height - 120}px` }}>
              {pinProjects?.map((project) => (
                <MapInfoPanel key={project.id} project={project} isMultiple={pinProjects.length > 1} />
              ))}
            </div>
          </div>
        </Transition>
      </div>
    </>
  );
};

export default Map;
