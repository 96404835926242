import { AlertsAction, AlertsState } from "./models";

export const reducer = (state: AlertsState, action: AlertsAction): AlertsState => {
  switch (action.type) {
    case "SET_COUNTERS": {
      return {
        ...state,
        totalCounter: action.payload.total,
        unReadCounter: action.payload.totalUnread,
      };
    }
    case "SET_UNREAD_COUNTER": {
      return { ...state, unReadCounter: action.payload };
    }
    case "DECREMENT_UNREAD_COUNTER": {
      return { ...state, unReadCounter: Math.max(state.unReadCounter - 1, 0) };
    }
    case "INCREMENT_UNREAD_COUNTER": {
      return { ...state, unReadCounter: state.unReadCounter + 1 };
    }
    default: {
      return state;
    }
  }
};
