import { createContext } from "react";
import { ToastContextType } from "../models";

export const ToastContext = createContext<ToastContextType>({
  toast: undefined,
  toastInfo: () => {},
  toastSuccess: () => {},
  toastWarning: () => {},
  toastError: () => {},
  toastSystem: () => {},
  toastArchive: () => {},
  toastLoading: () => {},
  toastClose: () => {},
  closeToastLoading: () => {},
});
