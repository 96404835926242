import { AlertCounters, AlertInclude, AlertsPageQuery, AlertsType, AlertsTypeQuery } from "./models";

export const AlertypeParam: Record<AlertsPageQuery, AlertsTypeQuery[]> = {
  all: ["activity", "mention", "reminder"],
  activity: ["activity"],
  reminder: ["reminder"],
  mention: ["mention"],
};

const activityTypeTranslation: Partial<Record<AlertsType, string>> = {
  activity_project_owner_assigned: "has marked you as a project owner",
  activity_project_contact_assigned: "has added you to a project",
  activity_prospect_contact_assigned: "has added you to a prospect",
  activity_prospect_owner_assigned: "has marked you as a prospect owner",
};

export const getAlertData = (
  includes: AlertInclude[] = [],
  activityType: AlertsType
): {
  notified: string; // user who recieved the notification
  notifier: string; // resource who sends the notification (user, project, task)
  linkTo: string; // link and tab to the notification,
  projectLink: string;
  projectName: string;
  projectClosedAt: string;
  projectArchivedAt: string;
  translationKey: string;
  translationNS: string;
} => {
  const { attributes: notifiedAttr } = includes?.find((t) => t.type === "user") || {};
  const { attributes: notifierAttr, type: notifierType, id: notifierId } = includes?.find((t) => t.type !== "user") || {};
  const notified = `${notifiedAttr?.firstName} ${notifiedAttr?.lastName}`;

  let projectLink;
  let projectName;
  let projectArchivedAt;
  let projectClosedAt;
  let notifier;
  let linkTo;
  let translationKey;
  let translationNS;

  projectName = notifierAttr?.name;
  projectLink = `/projects/${notifierId}`;
  translationNS = "projects";

  if (notifierType === "note") {
    notifier = `${notifierAttr?.user?.firstName} ${notifierAttr?.user?.lastName} notes`;
    linkTo = `/contacts/${notifierAttr?.user?.id}/notes`;
    projectArchivedAt = notifierAttr?.projectArchivedAt || notifierAttr?.prospectArchivedAt;
    projectClosedAt = notifierAttr?.projectClosedAt || notifierAttr?.prospectClosedAt;
    translationNS = notifierAttr?.prospectArchivedAt || notifierAttr?.prospectClosedAt ? "prospects" : translationNS;

    if (activityType === "mention_note") {
      translationKey = "mentioned you in a contact note:";
    }
  }

  if (notifierType === "unit_group") {
    notifier = ` an issuance`;
    translationKey = "mentioned you in";
    linkTo = `/projects/${notifierAttr?.projectId}/inventory/${notifierId}`;
    projectArchivedAt = notifierAttr?.projectArchivedAt || notifierAttr?.prospectArchivedAt;
    projectClosedAt = notifierAttr?.projectClosedAt || notifierAttr?.prospectClosedAt;
    translationNS = notifierAttr?.prospectArchivedAt || notifierAttr?.prospectClosedAt ? "prospects" : translationNS;
  }

  if (notifierType === "task") {
    const projectId = notifierAttr?.projectId;
    const prospectId = notifierAttr?.prospectId;
    const taskId = notifierId;
    notifier = `${notifierAttr?.taskName}`;
    projectArchivedAt = notifierAttr?.projectArchivedAt || notifierAttr?.prospectArchivedAt;
    projectClosedAt = notifierAttr?.projectClosedAt || notifierAttr?.prospectClosedAt;
    translationNS = notifierAttr?.prospectArchivedAt || notifierAttr?.prospectClosedAt ? "prospects" : translationNS;

    if (projectId && taskId) {
      linkTo = `/projects/${projectId}/tasks/${taskId}`;
    }
    if (prospectId && taskId) {
      linkTo = `/prospects/${prospectId}/tasks/${taskId}`;
    }

    projectName = notifierAttr?.projectName || notifiedAttr?.prospectName;
    projectLink = `/${projectId ? "projects" : "prospects"}/${projectId || prospectId}`;

    translationKey = "mentioned you in a task note:";
    if (activityType === "activity_task_user_assigned") {
      translationKey = "has assigned you to the task:";
    }
  }

  if (notifierType === "project") {
    projectName = notifierAttr?.name;
    projectLink = `/projects/${notifierId}`;
    projectArchivedAt = notifierAttr?.archivedAt ?? "";
    projectClosedAt = notifierAttr?.closedAt ?? "";

    if (activityType === "mention_note") {
      notifier = `${notifierAttr?.name} notes`;
      linkTo = `/projects/${notifierId}/notes`;
      translationKey = "mentioned you in a project note:";

      projectName = "";
      projectLink = "";
    }

    if (activityType === "activity_project_contact_assigned" || activityType === "activity_project_owner_assigned") {
      notifier = `${notifierAttr?.name}`;
      linkTo = `/projects/${notifierId}/overview`;
      translationKey = activityTypeTranslation[activityType];
    }
  }

  if (notifierType === "prospect") {
    projectName = notifierAttr?.name;
    projectLink = `/prospects/${notifierId}`;
    projectArchivedAt = notifierAttr?.archivedAt ?? "";
    projectClosedAt = notifierAttr?.closedAt ?? "";
    translationNS = "prospects";

    if (activityType === "mention_note") {
      notifier = `${notifierAttr?.name} notes`;
      linkTo = `/prospects/${notifierId}/notes`;
      translationKey = "mentioned you in a prospect note:";

      projectName = "";
      projectLink = "";
    }

    if (activityType === "activity_prospect_contact_assigned" || activityType === "activity_prospect_owner_assigned") {
      notifier = `${notifierAttr?.name}`;
      linkTo = `/prospects/${notifierId}/overview`;
      translationKey = activityTypeTranslation[activityType];
    }
  }

  return {
    notified: notified ?? "",
    notifier: notifier ?? "",
    linkTo: linkTo ?? "#",
    projectLink: projectLink ?? "#",
    projectName: projectName ?? "",
    projectArchivedAt: projectArchivedAt ?? "",
    projectClosedAt: projectClosedAt ?? "",
    translationKey: translationKey ?? "",
    translationNS,
  };
};

export const AlertCountersInitialValue: AlertCounters = {
  all: {
    totalUnread: 0,
    total: 0,
  },
  activity: 0,
  mention: 0,
  reminder: 0,
};

export const NOTIFICATION_INTERVAL = 20000;
