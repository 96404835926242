import React, { FunctionComponent, useRef } from "react";
import classNames from "classnames";
import { ButtonProps } from "../models";
import "./Button.css";

export const Button: FunctionComponent<ButtonProps> = (props: ButtonProps) => {
  const {
    id,
    btnName,
    title,
    disabled,
    onClick,
    children,
    className,
    style,
    buttonRef: ref,
    skeleton,
    variant = "btn-blue",
    size = "btn-medium",
    type = "button",
  } = props;

  if (!title && !children) {
    throw new Error("Either 'title' and/or 'children' needs to be specified for Button");
  }

  const buttonRef = ref || useRef<HTMLButtonElement | null>(null);
  const buttonVariant = skeleton ? "btn-skeleton" : variant;
  const btnClassName = classNames("btn", buttonVariant, size, className);

  const btnSylingProps = {
    className: btnClassName,
    ...(style && { style }),
  };

  return (
    <button id={id} type={type} title={title} name={btnName} ref={buttonRef} data-testid={id} onClick={onClick} disabled={disabled} {...btnSylingProps}>
      {children}
    </button>
  );
};
