import classNames from "classnames";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { formatDate } from "~/common/utils";
import PinDetails from "~/core/reports/components/PinDetails";
import { IconButton } from "~/common/forms/icon-button";
import { getProjectMethodlogyName, Project, ProjectTypeLabel, useProjectTypes } from "~/core/projects";
import Loader from "~/common/animations/Loader";
import { ContactsColumn } from "~/core/contacts";
import AddressDisplay from "./AddressDisplay";

type Props = {
  project: Project;
  isMultiple?: boolean;
};

const MapInfoPanel: React.FC<Props> = ({ project, isMultiple = false }) => {
  const { t } = useTranslation();
  const { projectTypes } = useProjectTypes();

  return (
    <div key={project.id} className={classNames(isMultiple && "pt-6")}>
      {project.id ? (
        <div className="h-full flex flex-col justify-between mt-2 gap-6">
          <div className="flex flex-col gap-7">
            <div className="flex flex-col gap-6">
              <h3 title={project.name} className="flex-1 truncate text-blue-900">
                {project.name}
              </h3>

              <AddressDisplay location={project?.sites[0]} />
            </div>

            <div className="flex flex-col gap-4">
              <PinDetails label={t("Project type")} render={() => <ProjectTypeLabel project={project} />} />
              <PinDetails label={t("Project stage")} value={project.stage?.name || "-"} />
              <PinDetails label={t("Methodology")} value={getProjectMethodlogyName(project, projectTypes, "-")} />
              <PinDetails label={t("Start date")} value={formatDate(project.startedAt, "--")} />
            </div>
            <div className="w-full flex flex-col gap-7">
              <div className="flex items-center gap-6">
                <div className="font-semibold">{t("Contacts")}</div>
                <ContactsColumn project={project} />
              </div>
              <IconButton
                iconPosition="right"
                size="btn-small"
                variant="btn-secondary"
                onClick={() => {
                  window.open(`/projects/${project.id}/overview`, "_blank");
                }}
                icon={
                  <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M8.62377 0.709397C8.23325 0.318873 7.60008 0.318873 7.20956 0.709397C6.81904 1.09992 6.81904 1.73309 7.20956 2.12361L8.62377 0.709397ZM12.5 5.99984L13.2071 6.70694C13.3946 6.51941 13.5 6.26505 13.5 5.99984C13.5 5.73462 13.3946 5.48027 13.2071 5.29273L12.5 5.99984ZM7.20956 9.87606C6.81904 10.2666 6.81904 10.8998 7.20956 11.2903C7.60008 11.6808 8.23325 11.6808 8.62377 11.2903L7.20956 9.87606ZM1.5 4.99984C0.947715 4.99984 0.5 5.44755 0.5 5.99984C0.5 6.55212 0.947715 6.99984 1.5 6.99984L1.5 4.99984ZM7.20956 2.12361L11.7929 6.70694L13.2071 5.29273L8.62377 0.709397L7.20956 2.12361ZM11.7929 5.29273L7.20956 9.87606L8.62377 11.2903L13.2071 6.70694L11.7929 5.29273ZM12.5 4.99984L1.5 4.99984L1.5 6.99984L12.5 6.99984V4.99984Z"
                      fill="currentColor"
                    />
                  </svg>
                }
              >
                <Trans ns="projects">View project</Trans>
              </IconButton>
            </div>
          </div>
        </div>
      ) : (
        <div className="w-full h-full flex flex-col items-center justify-center py-13">
          <Loader className="w-11 h-11" />
          <span className="text-light text-body block mt-8">
            <Trans ns="projects">Retrieving project data...</Trans>
          </span>
        </div>
      )}
    </div>
  );
};

export default MapInfoPanel;
