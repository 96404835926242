import React, { ReactNode, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import { ErrorPage } from "~/common/error";
import LottieLoader from "~/common/animations/LottieLoader";
import { getMe } from "~/common/services";
import { getMeasurement } from "~/common/utils";
import { DEFAULT_PATH } from "~/core/auth/utils";
import { displayName, User } from "~/core/contacts";
import { getUserRoles } from "~/core/org/services";
import { UserRole } from "~/core/org/models";
import { getStoredOrg, Org, storeActiveOrg } from "~/core/org";
import { getProjectTypes, ProjectType } from "~/core/projects";
import { MeContext } from "../models";

declare global {
  interface Window {
    analytics: any;
  }
}

type Props = {
  children: ReactNode;
};

const MeProvider: React.FC<Props> = ({ children }) => {
  const { boot, update, trackEvent } = useIntercom();
  const history = useHistory();
  const [me, setMe] = useState<User | null>(null);
  const [activeOrg, setActiveOrg] = useState<Org | null>(null);
  const [projectTypes, setProjectTypes] = useState<ProjectType[]>([]);
  const [userRoles, setUserRoles] = useState<UserRole[]>([]);
  const firstRun = useRef<boolean>(true);
  const lastPath = useRef<string>("");
  const { show } = useIntercom();

  const setOrgLanguage = (org: Org) => {
    const currentLang = localStorage.getItem("i18nextLng");
    if (!currentLang) localStorage.setItem("i18nextLng", org.languageCode ?? "en-AU");
  };

  useEffect(() => {
    (async () => {
      const user = await getMe();
      if (!user) {
        history.push("/auth/login");
        return;
      }

      setMe(user);

      let storedOrgId = getStoredOrg();
      if (!storedOrgId) {
        storedOrgId = user.orgs[0].id;
      }

      const findOrg = user.orgs.find((o: Org) => o.id === storedOrgId) ?? user.orgs[0];
      if (findOrg && findOrg?.id) {
        storeActiveOrg(findOrg);
        setActiveOrg(findOrg);
        setOrgLanguage(findOrg);
      }

      setUserRoles(await getUserRoles());

      // This shouldn't be here but I'll add it for now to avoid multiple calls
      const types = await getProjectTypes();
      setProjectTypes(types);

      localStorage.setItem("measurement", getMeasurement());
    })();
  }, []);

  useEffect(() => {
    history.listen((location) => {
      if (lastPath.current !== location.pathname && window.analytics) {
        lastPath.current = location.pathname;
        window.analytics.page(location.pathname);
      }
    });
  }, [history]);

  // we initialize intercom & fullstory with user data
  useEffect(() => {
    if (me) {
      if (firstRun.current) {
        boot({
          userHash: me.intercomUserHash,
          name: displayName(me),
          email: me.email,
          userId: me.id,
          phone: me.phoneNumber,
          customAttributes: {
            Role: me.positionType,
          },
          companies: me.orgs?.map((o) => ({ companyId: o.id, name: o.name })) ?? [],
        });

        firstRun.current = false;
      }

      if (localStorage.getItem("loginEvent")) {
        trackEvent("login-completed");
        localStorage.removeItem("loginEvent");
      }

      if (window.analytics) {
        window.analytics.identify(me.id, {
          name: displayName(me),
          email: me.email,
          company: activeOrg?.id,
        });
      }

      if (activeOrg?.id && me.positionType !== "external") {
        update({
          userId: me?.id,
          company: {
            companyId: activeOrg.id,
            size: activeOrg.totalUsers,
            name: activeOrg.name,
            customAttributes: {
              totalProjects: activeOrg.totalProjects,
            },
          },
        });
      }
    }
  }, [me?.id, activeOrg?.id]);

  const setOrg = (newOrg: Org, forceReload = false) => {
    storeActiveOrg(newOrg);
    setActiveOrg(newOrg);
    setOrgLanguage(newOrg);
    if (forceReload) window.location.replace(DEFAULT_PATH);
  };

  return !me ? (
    <div className="h-screen bg-white absolute top-0 left-0 w-full flex justify-center items-center z-50">
      <LottieLoader />
    </div>
  ) : (
    <>
      {(me.orgs || []).length === 0 ? (
        <ErrorPage hideBackButton>
          <div className="text-center text-white">
            <h1 className="mb-6">Sorry! Your user is not assigned to any organisation.</h1>
            <p className="text-xl">
              Please contact{" "}
              <button className="p-0 bg-transparent text-white hover:underline" onClick={show}>
                support
              </button>{" "}
              if you need assistance.
            </p>
          </div>
        </ErrorPage>
      ) : (
        <MeContext.Provider value={{ me, setMe, activeOrg, setOrg, projectTypes, userRoles }}>{children}</MeContext.Provider>
      )}
    </>
  );
};

export default MeProvider;
