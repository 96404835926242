import classNames from "classnames";
import React, { ReactNode, useRef, useState } from "react";
import { Tooltip } from "~/common/containers/tooltip";
import { LegacyPositionType } from "../models";

interface Props {
  name?: string;
  tooltipContent?: ReactNode | string;
  className?: string;
  style?: React.CSSProperties;
  avatarType?: keyof AvatarColors;
  active?: boolean;
  deactivatedContact?: boolean;
  children?: ReactNode;
}

type DefaultAvatarColor = "blue-900" | "blue-600" | "blue-200" | "teal-100" | "grey-200";

type AvatarColors = Partial<Record<DefaultAvatarColor | LegacyPositionType, string>>;

const avatarBgColors: AvatarColors = {
  "blue-900": "text-white bg-blue-900",
  "blue-600": "text-white bg-blue-600",
  "blue-200": "text-blue-500 bg-blue-200",
  "teal-100": "text-white bg-teal-100",
  "grey-200": "text-grey-600 bg-grey-100 line-through",

  // positionType
  internal: "text-yellow-700 bg-orange-100",
  project_developer: "text-yellow-700 bg-orange-100",

  external: "text-green-700 bg-green-100",
  landholder: "text-green-700 bg-green-100",
  investor: "text-green-700 bg-green-100",
  operator: "text-green-700 bg-green-100",
};

const avatarBorderColors: AvatarColors = {
  "blue-900": "border-2 border-blue-900",
  "blue-600": "border-2 border-blue-600",
  "blue-200": "border-2 border-blue-200",
  "teal-100": "border-2 border-teal-100",
  "grey-200": "border-2 border-grey-400",

  // positionType
  internal: "border-2 border-yellow-700",
  project_developer: "border-2 border-yellow-700",

  external: "border-2 border-green-700",
  landholder: "border-2 border-green-700",
  investor: "border-2 border-green-700",
  operator: "border-2 border-green-700",
};

const ContactAvatar: React.FC<Props> = (props: Props) => {
  const [isShown, setIsShown] = useState(false);
  const tooltipRef = useRef<HTMLDivElement | null>(null);

  const { name, className = "", style = { width: "36px", height: "36px" }, avatarType, active = false, deactivatedContact = false, tooltipContent, children } = props;

  const initials =
    !children &&
    name
      ?.split(" ")
      ?.slice(0, 2)
      ?.map((word) => word[0]?.toUpperCase())
      ?.join("");

  const colorClassNames = avatarType ? avatarBgColors[deactivatedContact ? "grey-200" : avatarType] : avatarBgColors[deactivatedContact ? "grey-200" : "blue-900"];

  const colorBorder = avatarType && active && avatarBorderColors[deactivatedContact ? "grey-200" : avatarType];

  const handleOnMouseEnter = () => {
    setIsShown(true);
  };

  const handleOnMouseLeave = () => {
    setIsShown(false);
  };

  return (
    <>
      <div ref={tooltipRef} className={classNames("flex", className)} style={style} onMouseEnter={handleOnMouseEnter} onMouseLeave={handleOnMouseLeave}>
        <div className="h-full w-full rounded-full border-2 border-white">
          <div className={classNames("h-full w-full rounded-full flex justify-center items-center font-semibold fs-mask text-base", colorClassNames, colorBorder)}>
            {children || (
              <>
                {initials || (
                  <div className="m-6">
                    <svg xmlns="http://www.w3.org/2000/svg" className="w-full h-full" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                    </svg>
                  </div>
                )}
              </>
            )}
          </div>
        </div>

        {tooltipContent && (
          <Tooltip visible={isShown} objRef={tooltipRef}>
            {tooltipContent}
          </Tooltip>
        )}
      </div>
    </>
  );
};

export default ContactAvatar;
