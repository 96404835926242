import classNames from "classnames";
import React, { MouseEventHandler, MutableRefObject, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import Loader from "~/common/animations/Loader";
import { Button, ButtonClicked, ButtonSize } from "../../button";

type Props = {
  className?: string;
  isLoading?: boolean;
  loadingText?: string;
  onCancel?: MouseEventHandler<ButtonClicked>;
  onSubmit?: MouseEventHandler<ButtonClicked>;
  size?: ButtonSize;
  justifyButtons?: string;
  btnsClassName?: string;
  submitBtnOrder?: number;
  submitBtnText?: string;
  submitDisabled?: boolean;
  cancelDisabled?: boolean;
  submitBtnRef?: MutableRefObject<HTMLButtonElement | null>;
  submitBtnType?: "button" | "submit";
  cancelBtnText?: string;
  hideCancelButton?: boolean;
  cta?: ReactNode;
};

const FormFooter: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const {
    className = "justify-start",
    isLoading = false,
    loadingText = "",
    onCancel,
    onSubmit,
    justifyButtons = "justify-start",
    submitBtnOrder = 1,
    submitBtnRef,
    submitBtnText = t("Save"),
    submitBtnType = "submit",
    submitDisabled = false,
    cancelDisabled = false,
    cancelBtnText = t("Cancel"),
    btnsClassName = "",
    size,
    hideCancelButton = false,
    cta,
  } = props;

  const cancelClass = classNames(btnsClassName, submitBtnOrder === 1 ? "order-2" : "order-1");

  return (
    <footer className={classNames("flex items-center w-full", className || "justify-between")}>
      <div className={classNames("flex items-center gap-4", justifyButtons, !cta && "flex-1")}>
        {!hideCancelButton && (
          <Button type="button" className={classNames(cancelClass)} variant="btn-transparent" size={size} disabled={cancelDisabled} onClick={onCancel}>
            {cancelBtnText}
          </Button>
        )}

        <Button
          className={classNames(btnsClassName, `order-${submitBtnOrder}`)}
          type={submitBtnType}
          disabled={submitDisabled}
          buttonRef={submitBtnRef}
          size={size}
          onClick={onSubmit}
        >
          {isLoading ? (
            <div className="flex items-center gap-4">
              <Loader className="w-7 h-7" type="secondary" /> {loadingText}
            </div>
          ) : (
            submitBtnText
          )}
        </Button>
      </div>

      {cta && cta}
    </footer>
  );
};

export default FormFooter;
