import { useContext } from "react";
import { User } from "~/core/contacts";
import { useToast } from "~/common/toasts";
import { Feedback } from "~/common/models";
import { updateMe } from "./services";
import { MeContext } from "./models";

type UseMeResponse = {
  me: User | null;
  updateMe: (usr: Partial<User>, feedback?: Feedback) => Promise<void>;
};

export const useMe = (): UseMeResponse => {
  const { me, setMe } = useContext(MeContext);
  const { toastSuccess, toastError } = useToast();

  const patchMe = async (userData: Partial<User>, feedback?: Feedback) => {
    try {
      const savedUser = await updateMe(userData as User);
      setMe(savedUser);
      if (feedback?.fulfilled) toastSuccess({ message: feedback.fulfilled });
    } catch (e) {
      if (feedback?.rejected) toastError({ message: feedback.rejected });
    }
  };

  return { me, updateMe: patchMe };
};
