import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation, withTranslation } from "react-i18next";
import { FormFooter } from "~/common/forms/form-footer";
import { FormInput } from "~/common/forms/input";
import { PhoneInput } from "~/common/forms/phone-input";
import { Autocomplete } from "~/common/selectables";
import { getMeasurement, getTimezoneOptions, isValidEmail } from "~/common/utils";
import { User } from "~/core/contacts";
import { useMe } from "../hooks";

const timezoneOptions = getTimezoneOptions();

type Props = {
  readOnly?: boolean;
  onSubmit?: () => void;
  onClose?: () => void;
};

type FormData = {
  id: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
  measurement?: string;
  timeZone?: string;
};

const extractFormData = (measurement: string, me?: User): FormData => {
  return {
    id: me?.id ?? "",
    firstName: me?.firstName,
    lastName: me?.lastName,
    email: me?.email,
    phoneNumber: me?.phoneNumber,
    timeZone: me?.timeZone || "",
    measurement,
  };
};

const AccountForm: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();
  const { onClose, readOnly } = props;
  const { me, updateMe } = useMe();
  const [validPhoneNumber, setValidPhoneNumber] = useState(true);
  const [loading, setLoading] = useState<boolean>(false);
  const meTimezone = me?.timeZone ? timezoneOptions.find((o) => o.value === me?.timeZone)?.label : null;
  const measurement = localStorage.getItem("measurement") || "hectares";
  const {
    handleSubmit,
    register,
    control,
    trigger,
    reset,
    formState: { isDirty, isValid },
  } = useForm({
    defaultValues: extractFormData(measurement, me as User),
  });

  const closeForm = () => {
    reset(extractFormData(getMeasurement(), me as User));
    if (onClose) onClose();
  };

  const submit = async (formData: FormData) => {
    const { measurement: newMeasurement, ...rest } = formData;
    localStorage.setItem("measurement", newMeasurement!);

    setLoading(true);
    await updateMe({ ...rest, timeZone: rest.timeZone || null } as Partial<User>, {
      fulfilled: t("account updated"),
      rejected: t("unable to update the account. Please try again or contact support if you need assistance."),
    });

    setLoading(false);
    closeForm();
  };

  useEffect(() => {
    if (me?.id) reset(extractFormData(measurement, me));
  }, [me?.id, me?.phoneNumber, me?.timeZone, me?.email, me?.firstName, me?.lastName]);

  return (
    <form onSubmit={handleSubmit(submit)} className={classNames("form-container w-1/3", !readOnly ? "form-col" : "form-row-md")}>
      <input type="hidden" {...register("id")} />

      <Controller
        control={control}
        name="firstName"
        render={({ field }) => (
          <FormInput
            readOnly={readOnly}
            label={t("First name")}
            autoComplete="off"
            name={field.name}
            value={field.value}
            onChange={field.onChange}
            onBlur={field.onBlur}
          />
        )}
      />

      <Controller
        control={control}
        name="lastName"
        render={({ field }) => (
          <FormInput
            readOnly={readOnly}
            label={t("Last name")}
            autoComplete="off"
            name={field.name}
            value={field.value}
            onChange={field.onChange}
            onBlur={field.onBlur}
          />
        )}
      />

      <Controller
        control={control}
        name="email"
        rules={{ required: true, validate: isValidEmail }}
        render={({ field }) => (
          <FormInput
            readOnly={readOnly}
            required
            id="email"
            label={t("Email")}
            autoComplete="off"
            name={field.name}
            value={field.value}
            onBlur={field.onBlur}
            onChange={(evt) => {
              const { value } = evt.target as HTMLInputElement;
              field.onChange(value);
              trigger();
            }}
          />
        )}
      />

      <Controller
        name="phoneNumber"
        control={control}
        rules={{ validate: () => validPhoneNumber }}
        render={({ field }) => (
          <PhoneInput
            readOnly={readOnly}
            label={t("Phone number")}
            id="new-phonenumber"
            hasError={!validPhoneNumber}
            value={field.value}
            onChange={field.onChange}
            onBlur={field.onBlur}
            validateOn="change"
            onValidate={(isValidPhoneNumber) => {
              setValidPhoneNumber(isValidPhoneNumber);
              trigger();
            }}
          />
        )}
      />

      <Controller
        name="measurement"
        control={control}
        render={({ field }) => (
          <Autocomplete
            isClearable={false}
            readOnly={readOnly}
            readOnlyComponent={<span>{t(field.value!.charAt(0).toUpperCase() + field.value!.slice(1))}</span>}
            label={t("System of measurement")}
            value={field.value}
            onChange={(evt: any) => {
              field.onChange(evt.value);
              trigger();
            }}
            options={[
              { value: "hectares", label: t("Hectares") },
              { value: "acres", label: t("Acres") },
            ]}
          />
        )}
      />

      <Controller
        name="timeZone"
        control={control}
        render={({ field }) => (
          <Autocomplete
            isClearable
            readOnly={readOnly}
            readOnlyComponent={<span>{meTimezone}</span>}
            description={t("Set your timezone to optimise the timing of alerts")}
            label={t("Timezone")}
            placeholder={t("Select a timezone")}
            value={field.value}
            onChange={(evt: any) => {
              const newValue = evt?.value || "";
              field.onChange(newValue);
              trigger();
            }}
            options={timezoneOptions}
          />
        )}
      />
      {!readOnly && (
        <FormFooter
          className="justify-start"
          isLoading={loading}
          loadingText={t("Saving edits...")}
          onCancel={closeForm}
          submitDisabled={!isDirty || !isValid}
          size="btn-small"
        />
      )}
    </form>
  );
};

export default withTranslation()(AccountForm);
