import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { components } from "react-select";
import { useDebounce } from "~/common/hooks";
import { addressOptions, geocodeAddresses } from "~/common/map";
import { Autocomplete, AutocompleteOption, AutocompleteProps } from "~/common/selectables";

const Control: React.FC<any> = ({ children, ...props }) => (
  <components.Control {...props}>
    <div className="flex gap-6 w-full">
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M17.5429 18.9571C17.9334 19.3476 18.5666 19.3476 18.9571 18.9571C19.3476 18.5666 19.3476 17.9334 18.9571 17.5429L17.5429 18.9571ZM13.4571 12.0429C13.0666 11.6524 12.4334 11.6524 12.0429 12.0429C11.6524 12.4334 11.6524 13.0666 12.0429 13.4571L13.4571 12.0429ZM13.5833 8.16667C13.5833 11.1582 11.1582 13.5833 8.16667 13.5833V15.5833C12.2628 15.5833 15.5833 12.2628 15.5833 8.16667H13.5833ZM8.16667 13.5833C5.17512 13.5833 2.75 11.1582 2.75 8.16667H0.75C0.75 12.2628 4.07055 15.5833 8.16667 15.5833V13.5833ZM2.75 8.16667C2.75 5.17512 5.17512 2.75 8.16667 2.75V0.75C4.07055 0.75 0.75 4.07055 0.75 8.16667H2.75ZM8.16667 2.75C11.1582 2.75 13.5833 5.17512 13.5833 8.16667H15.5833C15.5833 4.07055 12.2628 0.75 8.16667 0.75V2.75ZM18.9571 17.5429L13.4571 12.0429L12.0429 13.4571L17.5429 18.9571L18.9571 17.5429Z"
          fill="#41444C"
        />
      </svg>

      {children}
    </div>
  </components.Control>
);

const getInitialOption = (address: string): AutocompleteOption => {
  return {
    label: address,
    value: address,
  } as AutocompleteOption;
};

const AddressAutocomplete: React.FC<AutocompleteProps> = (props) => {
  const { t } = useTranslation();
  const { value, ...rest } = props;
  const [inputValue, setInputValue] = useState(value);
  const [options, setOptions] = useState<AutocompleteOption<any>[]>([]);

  const loadOptions = async (address: string) => {
    return geocodeAddresses(address).then((features) => addressOptions([{ place_name: address }, ...features]));
  };

  const handleInputChange = (val: string) => setInputValue(val);

  useDebounce(
    inputValue,
    async () => {
      const autoOptions: AutocompleteOption[] = inputValue ? await loadOptions(inputValue as string) : [];
      setOptions(autoOptions);
    },
    300
  );

  const selectedOption = value && getInitialOption(value as string);

  return (
    <Autocomplete
      {...rest}
      asyncSelect
      value={value}
      options={options}
      placeholder={t("Search an address...")}
      selectedOption={selectedOption}
      onInputChange={handleInputChange}
      noOptionsMessage={() => null}
      loadOptions={loadOptions}
      customComponents={{
        DropdownIndicator: () => <></>,
        Control,
      }}
    />
  );
};

export default AddressAutocomplete;
