import React from "react";
import { Trans } from "react-i18next";

type Props = {
  name: string;
  defaultValue?: boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
};

const ExclusionFilter: React.FC<Props> = ({ name, onChange, defaultValue = false }) => {
  return (
    <div className="flex items-center gap-6">
      <label className="flex gap-4 items-center font-normal">
        <input name={name} onChange={onChange} type="radio" value={undefined} defaultChecked={!defaultValue} />
        <Trans parent="span">Is</Trans>
      </label>

      <label className="flex gap-4 items-center font-normal">
        <input name={name} onChange={onChange} type="radio" value="true" defaultChecked={defaultValue} />
        <Trans parent="span">Is not</Trans>
      </label>
    </div>
  );
};

export default ExclusionFilter;
