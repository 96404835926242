import moment from "moment";
import { BarChartData, ChartsData, CustomReport, GlobalReport, OverviewPeriod, ReportsChartOverviewDataUnitGroup } from "./models";

export const getFirstDay = (date: string) => {
  return moment(date).startOf("month").format("YYYY-MM-DD");
};

export const periodEntries: Record<OverviewPeriod, number> = {
  actual: 0,
  today: 0,
  year: 13,
  quarter: 12,
  week: 7,
  month: 30,
};

const periodSplit: Partial<Record<OverviewPeriod, moment.unitOfTime.DurationConstructor>> = {
  year: "month",
  quarter: "week",
  week: "days",
  month: "days",
};

export const addMissingOverviewData = (data: ChartsData[], period: OverviewPeriod): ChartsData[] => {
  const newData: ChartsData[] = [];
  const missingEntries = periodEntries[period] - data.length;

  const startDate = data[0]?.date;

  if (data.length > 0 && startDate && missingEntries > 0) {
    const firstDate = moment(startDate);

    for (let i = 0; i <= missingEntries - 1; i += 1) {
      const pervData = newData[newData.length - 1];
      const prevDate = i === 0 ? firstDate : pervData.date;

      const date = moment(prevDate).subtract(1, periodSplit[period]).format("YYYY-MM-DD");

      const keys = Object.keys(data[0]);

      const rest = keys.reduce((prev, current) => {
        return {
          ...prev,
          [current]: 0,
        };
      }, {} as ChartsData);

      newData.push({ ...rest, date } as ChartsData);
    }
  }

  const sortedDates = [...newData, ...data]
    .sort((a, b) =>
      // eslint-disable-next-line no-nested-ternary
      a.date < b.date ? 1 : a.date > b.date ? -1 : 0
    )
    .reverse();

  return sortedDates;
};

const getDateUnits = (data: ReportsChartOverviewDataUnitGroup[]) => {
  return data.reduce(
    (prev, curr) => ({
      ...prev,
      [curr.date]: curr.totalUnits?.toString() ?? null,
    }),
    {} as { [key: string]: string }
  );
};

export const getforecastedIssuedData = (
  issuedData: ReportsChartOverviewDataUnitGroup[],
  forecastedData: ReportsChartOverviewDataUnitGroup[],
  name?: string
): { [key: string]: string }[] => {
  if (!forecastedData || !issuedData) return [];

  const dates = [...new Set(forecastedData.concat(issuedData).map(({ date }) => date))];

  const forecastedUnits = getDateUnits(forecastedData);
  const issuedUnits = getDateUnits(issuedData);

  return dates
    .map((d) => ({
      ...(name ? { name } : { date: d }),
      ...(forecastedUnits[d] && { a: forecastedUnits[d] }),
      ...(issuedUnits[d] && { b: issuedUnits[d] }),
    }))
    .sort((a: any, b: any) =>
      // eslint-disable-next-line no-nested-ternary
      a?.date < b?.date ? 1 : a?.date > b?.date ? -1 : 0
    )
    .reverse();
};

export const max = <D extends unknown>(arr: D[], fn: (d: D) => number) => Math.max(...arr.map(fn));

export const getBarKeys = (data: BarChartData[]) => Object.keys(data[0]).filter((d) => d !== "date" && d !== "meta");

export const downloadCsv = (response: any, fileName: string) => {
  const file = new Blob([response], { type: "application/octetstream" });
  const objectURL = window.URL.createObjectURL(file);

  const link = document.createElement("a");
  link.download = `${fileName}-${moment().format("YYYY-MM-DD")}.csv`;
  link.href = objectURL;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

// ExportType & translation key
export const reportNameMap: Record<GlobalReport | CustomReport, string> = {
  allProjectData: "Project data",
  allProspectData: "Prospect data",
  allInventoryData: "All inventory data",
  allTasksData: "All tasks data",
  allEventsData: "All events data",
  allSelectablesData: "All selectables data",
  all_inventory_data_custom: "All inventory data (custom)",
  grassroot_weekly_ops_report: "Weekly operations report",
  agriprove_tasks_t1_t5_michael_weekly: "Tasks T1-T5",
  agriprove_full_task_list: "Full task list",
  agriprove_lead_management: "Lead management",
  agriprove_sla_registrations_pending: "SLA registrations pending",
  agriprove_amber_investor_report: "Amber investor report",
  agriprove_amber_ii_investor_report: "Amber II investor report",
  loveto_metaset_and_site_data: "Metaset and site data",
  forest_carbon_progress_tracker: "Progress tracker",
  upscale_carbon_progress_tracker: "Progress tracker",
  ekos_progress_tracker: "Progress tracker",
  cca_progress_tracker: "Progress tracker",
  agriprove_progress_tracker: "Progress tracker",
  scottish_woodlands_progress_tracker: "Progress tracker",
  cca_custom_tasks: "Custom tasks",
  cca_custom_events: "Custom events",
  cca_financial_data: "Financial data",
  treevive_data_tracker: "Data tracker",
  treevive_project_progress_tracker: "Project progress tracker",
  treevive_archived_project_progress_tracker: "Archived project progress tracker",
  treevive_prospect_progress_tracker: "Prospect progress tracker",
  treevive_archived_prospect_progress_tracker: "Archived prospect progress tracker",
  ridge_group_data_tracker: "Data tracker",
  treestory_data_tracker: "Data tracker",
  hasten_ventures_data_tracker: "Data tracker",
  atenil_data_tracker: "Data tracker",
  regeneration_data_tracker: "Data tracker",
  kateri_data_tracker: "Data tracker",
  loveto_data_tracker: "Data tracker",
  impact_ag_data_tracker: "Data tracker",
  one_tree_planted_data_tracker: "Data tracker",
  permagen_data_tracker: "Data tracker",
  scottish_woodlands_start_validation: "Validation date",
  scottish_woodlands_start_verification: "Verification date",
  forest_carbon_project_export: "Custom project data",
  all_events_data_custom: "All events data (custom)",
  all_tasks_data_custom: "All tasks data (custom)",
};

export const reportFileNameMap: Record<GlobalReport | CustomReport, string> = {
  allProjectData: "project-export",
  allProspectData: "prospect-export",
  allInventoryData: "all-inventory-export",
  allTasksData: "all-tasks-export",
  allEventsData: "all-events-export",
  allSelectablesData: "all-selectables-data",
  all_inventory_data_custom: "all-inventory-export-custom",
  grassroot_weekly_ops_report: "weekly-operations-report",
  agriprove_tasks_t1_t5_michael_weekly: "agriprove_tasks_t1_t5_michael_weekly",
  agriprove_full_task_list: "tasks-list",
  agriprove_lead_management: "lead-management",
  agriprove_sla_registrations_pending: "sla-registrations-pending",
  agriprove_amber_investor_report: "amber-investor-report",
  agriprove_amber_ii_investor_report: "amber-ii-investor-report",
  loveto_metaset_and_site_data: "metaset-and-site-data",
  forest_carbon_progress_tracker: "carbon-progress-tracker",
  upscale_carbon_progress_tracker: "carbon-progress-tracker",
  ekos_progress_tracker: "ekos-progress-tracker",
  cca_progress_tracker: "cca-progress-tracker",
  cca_custom_tasks: "cca-custom-tasks",
  cca_custom_events: "cca-custom-events",
  cca_financial_data: "cca-financial-data",
  agriprove_progress_tracker: "agriprove-progress-tracker",
  scottish_woodlands_progress_tracker: "woodlands-progress-tracker",
  treevive_data_tracker: "treevive-data-tracker",
  treevive_project_progress_tracker: "treevive-project-progress-tracker",
  treevive_archived_project_progress_tracker: "treevive-archived-project-progress-tracker",
  treevive_prospect_progress_tracker: "treevive-prospect-progress-tracker",
  treevive_archived_prospect_progress_tracker: "treevive-archived-prospect-progress-tracker",
  ridge_group_data_tracker: "ridge-group-data-tracker",
  treestory_data_tracker: "treestory-data-tracker",
  hasten_ventures_data_tracker: "hasten-ventures-data-tracker",
  atenil_data_tracker: "atenil-data-tracker",
  regeneration_data_tracker: "regeneration-data-tracker",
  kateri_data_tracker: "kateri-data-tracker",
  loveto_data_tracker: "loveto-data-tracker",
  impact_ag_data_tracker: "impact-ag-data-tracker",
  one_tree_planted_data_tracker: "one-tree-planted-data-tracker",
  permagen_data_tracker: "permagen-data-tracker",
  scottish_woodlands_start_validation: "validation-date",
  scottish_woodlands_start_verification: "verification-date",
  forest_carbon_project_export: "forest-carbon-project-export",
  all_events_data_custom: "all-events-data-custom",
  all_tasks_data_custom: "all-tasks-data-custom",
};
