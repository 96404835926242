import { createContext } from "react";
import { User } from "../contacts";
import { Org } from "../org";
import { UserRole } from "../org/models";
import { ProjectType } from "../projects";

export type MeContextType = {
  me: User | null;
  setMe: (me: User) => void;
  activeOrg: Org | null;
  setOrg: (org: Org, forceReload?: boolean) => void;
  projectTypes: ProjectType[];
  userRoles: UserRole[];
};

export const MeContext = createContext<MeContextType>({
  me: null,
  setMe: () => {},
  activeOrg: null,
  setOrg: () => {},
  projectTypes: [],
  userRoles: [],
});
