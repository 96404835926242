export const objectKeys = <T>(obj: T): Array<keyof T> => Object.keys(obj as any) as Array<keyof T>;
export const truncateText = (word: string, limit = 25) => (word.length > limit ? `${word.slice(0, limit)}...` : word);
export const isEmptyArr = (arr?: any[]) => !arr?.length;
export const projectTypeColour = (projectTypeId: string): string => {
  switch (projectTypeId) {
    case "29fd9fe2-24e0-41f3-b570-dd23ebd1e7bb":
    case "b42e4b82-3e08-4304-8bf9-ca33f9bb7a93":
      return "#E5D3FF";
    case "24a46d72-8013-4139-a5fc-afc65053101e":
    case "8ea2ab3c-c707-41ee-9f0f-5835fcccda60":
      return "#B8E9FF";
    case "bc165822-4aa9-4cf9-92da-f75fdd8f4656":
    case "1489679f-7ced-47eb-ac24-828fadada545":
      return "#87EE93";
    case "be39c9cb-682c-4e93-8e08-691a972b0c9f":
    case "a0df340f-c982-4ae3-8d72-3c6f2ca298ad":
      return "#984CFF";
    case "61843254-2b47-49ad-b697-56b6cb533b84":
      return "#78D9FF";
    default:
      return "#EFEEEB";
  }
};
