import React, { useEffect, useRef, useState } from "react";
import { Map, MapSummary } from "~/common/map";
import { useProjectsMarkers } from "../hooks";
import { GetProjectsOptions } from "../models";

type Props = {
  options: GetProjectsOptions;
  showProjectWithoutLocations?: boolean;
  height?: number;
  zoom?: number;
  center?: number[];
  handleViewAllUnMarked: () => void;
};

const ProjectsListMap: React.FC<Props> = ({ options, showProjectWithoutLocations = true, height, center, zoom, handleViewAllUnMarked }) => {
  const mapContainerRef = useRef<HTMLDivElement | null>(null);
  const [mapHeight, setMapHeight] = useState<number>();
  const [totalHectares, setTotalHectares] = useState(0);

  const {
    response: { data, meta },
    filter,
  } = useProjectsMarkers(options);

  useEffect(() => {
    const el = mapContainerRef.current;
    if (el && !height) {
      const bounds = el.getBoundingClientRect();
      const wHeight = window.screen.availHeight;
      const remaining = wHeight - bounds.y - 24; // 24 would be the common padding
      setMapHeight(remaining);
    }

    if (height) setMapHeight(height);
  }, [mapContainerRef.current]);

  useEffect(() => filter(options), [options]);

  // calculate map totals
  useEffect(() => {
    setTotalHectares(data?.reduce((prev, curr) => prev + (curr.projectAreaHectares ?? 0), 0) ?? 0);
  }, [data]);

  return (
    <div className="relative overflow-hidden">
      {data?.length > 0 && (
        <div className="flex flex-col space-y-6 absolute top-7 transform right-7 z-10">
          {totalHectares && (
            <MapSummary
              showProjectWithoutLocations={showProjectWithoutLocations}
              onViewAllUnMarked={handleViewAllUnMarked}
              numberOfProjects={data.length}
              projectArea={totalHectares}
              totalProjectsWithoutLocation={meta.totalProjectsWithoutLocation}
            />
          )}
        </div>
      )}

      <div ref={mapContainerRef}>{mapHeight && <Map height={mapHeight} markers={data} zoom={zoom} center={center} />}</div>
    </div>
  );
};

export default ProjectsListMap;
