import * as H from "history";
import moment from "moment";
import queryString from "query-string";
import { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import { TabItem } from "~/common/containers/tabs";
import { useMe } from "~/core/account";
import { INVENTORY_KEY } from "~/core/inventory/utils";
import { objectKeys } from "~/helpers";
import { UseParams } from "./models";

type UseHandleBlockedNavigationType = (
  canNavigate: () => boolean,
  callback: (nextLocation: H.Location<unknown>) => void
) => string | ((location: H.Location<unknown>, action: H.Action) => string | boolean);

export const useHandleBlockedNavigation: UseHandleBlockedNavigationType = (canNavigate, callback) => {
  return (location: H.Location<unknown>) => {
    if (canNavigate()) {
      return true;
    }

    callback(location);
    return false;
  };
};

export const useClickOutside = (ref: any, callback: (t?: MouseEvent | null) => void) => {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref?.current && !ref?.current?.contains(event.target)) {
        callback(event);
      }
    }

    // Bind the event listener
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [ref?.current]);
};

export const useDocumentTitle = (title?: string) => {
  const { me } = useMe();
  const { update } = useIntercom();

  useEffect(() => {
    if (title) document.title = `${title} | Cecil Workspace`;
    if (me?.id) update({ lastRequestAt: moment().utc().unix().toString() });
  }, [title]);
};

export const useDebounce = (trigger: any, callback: Function, delay: number) => {
  useEffect(() => {
    const timeout = setTimeout(() => callback(trigger), delay);
    return () => clearTimeout(timeout);
  }, [trigger]);
};

export const useTabs = <T extends unknown>(defaultTab: T, tabs?: TabItem[]) => {
  const [tab, setTab] = useState<T>(defaultTab);
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    if (tabs) {
      const pathName = location.pathname;
      const exists = tabs?.find(({ path }) => path === pathName);
      const tabPath = exists ? pathName.split("/").pop() ?? defaultTab : defaultTab;

      setTab(tabPath as T);
    }
  }, [tabs, location.pathname]);

  return tab;
};

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

export const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
};

export const useRootOverflow = () => {
  useEffect(() => {
    const root = document.getElementById("root");
    root?.classList.add("fixed", "w-full", "h-full", "overflow-hidden");
    return () => {
      root?.classList.remove("fixed", "w-full", "h-full", "overflow-hidden");
    };
  });
};

export const useTablePage = () => {
  useEffect(() => {
    const pageContent = document.querySelector(".page-max-content");
    pageContent?.classList.add("table-content");
    return () => {
      pageContent?.classList.remove("table-content");
    };
  }, []);
};

export const useReload = () => {
  const [forceReload, setReload] = useState(0);

  const reload = () => setReload(Date.now());

  return {
    reload,
    forceReload,
  };
};

const PARAMS_KEY = "params";

export const useParams: UseParams = (props) => {
  const location = useLocation();
  const history = useHistory();

  const { updateUrl = false, syncLocalStorage = false, paramsCountKeys = [], excludeFromURL = [], localStorageKey } = props;
  const { current: defaultParams } = useRef(props.defaultParams);
  const [params, setParamState] = useState<any>();
  const [paramsCounter, setParamsCounter] = useState(0);

  const restoreDefaultParams = () => setParamState(defaultParams);

  const syncUrl = (newParams: Record<string, any>) => {
    const paramsToSync = objectKeys(newParams).reduce((acc, key) => {
      if (!excludeFromURL.includes(key)) {
        acc[key] = newParams[key];
      }
      return acc;
    }, {} as typeof params);

    const searchString = queryString.stringify(paramsToSync, {
      arrayFormat: "comma",
    });

    history.replace({
      pathname: location.pathname,
      search: searchString,
    });

    if (syncLocalStorage) localStorage.setItem(PARAMS_KEY, searchString);
  };

  const readFromStorage = (): Record<string, any> | null => {
    let parsed = null;

    if (!localStorageKey) return parsed;

    const storedValues = localStorage.getItem(PARAMS_KEY);

    if (storedValues) parsed = JSON.parse(storedValues);

    return parsed;
  };

  const syncTolocalstorage = (newParams: Partial<typeof params>) => {
    if (!localStorageKey) return;

    const prevStoredParams = readFromStorage();

    const searchString = queryString.stringify(newParams, {
      arrayFormat: "comma",
    });

    const newStorageParams = {
      params: {
        ...(prevStoredParams ? prevStoredParams.params : {}),
        [localStorageKey]: searchString,
      },
    };

    localStorage.setItem(PARAMS_KEY, JSON.stringify(newStorageParams));
  };

  const setParams = (parsed: Partial<typeof params>) => {
    setParamState((prev: any) => {
      const newParams = {
        ...prev,
        ...parsed,
      };

      if (newParams.sort === "submitted_at" && newParams.recordType === "forecasted") {
        newParams.sort = "forecasted_at";
      }
      if (newParams.sort === "forecasted_at" && newParams.recordType === "issued") {
        newParams.sort = "submitted_at";
      }

      objectKeys(newParams).forEach((key) => {
        if (newParams[key] === "") newParams[key] = undefined;
      });

      return newParams;
    });
  };

  const parseParams = (urlString: string) => {
    return queryString.parse(urlString, {
      parseBooleans: true,
      arrayFormat: "comma",
      parseNumbers: true,
    });
  };

  const countKeys = () => {
    if (params) {
      const totalCounter = paramsCountKeys.reduce((prev, curr) => {
        const value = (params as any)[curr];
        if (value && value?.toString().trim()) return prev + 1;
        return prev;
      }, 0);

      setParamsCounter(totalCounter);
    }
  };

  const getPrevStoredParams = (): string | boolean => {
    const storedParams = readFromStorage();
    if (!storedParams || !localStorageKey) return false;

    return storedParams.params[localStorageKey];
  };

  const setParamsFromUrl = () => {
    const parsedUrlParams = parseParams(location.search);

    if (Object.keys(parsedUrlParams).length === 0) restoreDefaultParams();
    else
      setParams({
        ...defaultParams,
        ...parsedUrlParams,
      });
  };

  const setInitialParams = () => {
    const prevStoredParams = getPrevStoredParams();

    if (prevStoredParams && typeof prevStoredParams === "string") {
      const parsedStorageParams = parseParams(prevStoredParams);
      parsedStorageParams.sort = parsedStorageParams.recordType === "forecasted" ? "forecasted_at" : "submitted_at";
      setParams(parsedStorageParams);
    } else setParamsFromUrl();
  };

  useEffect(() => {
    setParamsFromUrl();
  }, [location.search]);

  useEffect(() => {
    // Read params from local storage or URL or defaultParams and set them
    setInitialParams();
  }, []);

  useEffect(() => {
    if (params) {
      if (updateUrl) syncUrl(params);
      if (localStorageKey) syncTolocalstorage(params);
      countKeys();
    }
  }, [params]);

  return {
    params,
    paramsCounter,
    setParams,
    restoreDefaultParams,
  };
};

export const useResetInventoryParams = () => {
  const location = useLocation();

  useEffect(() => {
    const prevStoredValues = localStorage.getItem(PARAMS_KEY) ?? "";
    const parsedStoredValues = prevStoredValues && JSON.parse(prevStoredValues);

    if (!location.pathname.includes(INVENTORY_KEY)) {
      const resetInventoryStorage = {
        params: {
          ...(parsedStoredValues?.params ?? {}),
          inventory: "",
        },
      };
      localStorage.setItem(PARAMS_KEY, JSON.stringify(resetInventoryStorage));
    }
  }, [location.pathname]);
};

export const useSearchName = (initialValue: string | undefined, cb: (obj: { search?: string }) => void) => {
  const [searchName, setSearchName] = useState<string | undefined>(initialValue);

  useDebounce(
    searchName,
    () => {
      cb({
        search: searchName || undefined,
      });
    },
    300
  );

  return { setSearchName };
};

export const useTableSelection = <T extends { id: string }>(data: T[]) => {
  const [selectedItems, setSelectedItems] = useState<T[]>([]);

  const onSelectItem = (row: T) => {
    setSelectedItems((prev) => {
      const isSelected = prev.find(({ id }) => id === row.id);
      if (isSelected) return prev.filter(({ id }) => id !== row.id);
      return prev.concat(row);
    });
  };

  const onSelectAll = (check: boolean) => {
    setSelectedItems(check ? data : ([] as T[]));
  };

  const selectedAll = selectedItems.length > 0 && selectedItems.length === data.length;

  return {
    selectedItems,
    onSelectItem,
    onSelectAll,
    selectedAll,
    setSelectedItems,
  };
};
