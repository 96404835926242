import { createContext } from "react";

interface State {
  editModalOpen: boolean;
}

interface ContextState {
  projectLocation: State;
  setProjectLocation: (val: State) => void;
  closeModal: () => void;
  openModal: () => void;
}

const defaultState: ContextState = {
  projectLocation: {
    editModalOpen: false,
  },
  setProjectLocation: () => {},
  closeModal: () => {},
  openModal: () => {},
};

const ProjectLocationContext = createContext<ContextState>(defaultState);

export default ProjectLocationContext;
