import { buildAPIURL } from "~/common/http";
import apiClient from "../../common/api-client";
import { Org, UserRole } from "./models";

export const ORG_ID_STORAGE_KEY = "earth.cecil.org.id";

export const storeActiveOrg = (org: Pick<Org, "id">) => {
  localStorage.setItem(ORG_ID_STORAGE_KEY, org.id);
};

export const getStoredOrg = () => {
  return localStorage.getItem(ORG_ID_STORAGE_KEY);
};

export const parseOrg = (org: Org): Org => {
  if (org.siteFieldsSettings) {
    const jsonSettings = JSON.parse(org.siteFieldsSettings);
    org.siteFieldsSettings = Object.keys(jsonSettings).length > 0 ? jsonSettings : null;
  }
  if (org.assessmentFieldsSettings) {
    const jsonAssessments = JSON.parse(org.assessmentFieldsSettings);
    org.assessmentFieldsSettings = Object.keys(jsonAssessments).length > 0 ? jsonAssessments : null;
  }
  if (org.overviewFieldsSettings) {
    const jsonOverview = JSON.parse(org.overviewFieldsSettings);
    org.overviewFieldsSettings = Object.keys(jsonOverview).length > 0 ? jsonOverview : null;
  }
  if (org.navigation) {
    const jsonNavigation = JSON.parse(org.navigation.toString());
    org.navigation = Object.keys(jsonNavigation).length > 0 ? jsonNavigation : null;
  }

  return org;
};

export const removeOrg = () => {
  return localStorage.removeItem(ORG_ID_STORAGE_KEY);
};

export const patchOrg = async (org: Org): Promise<Org> => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { id, navigation, ...rest } = org;

  rest.assessmentFieldsSettings = rest.assessmentFieldsSettings ? JSON.stringify(rest.assessmentFieldsSettings) : undefined;
  rest.overviewFieldsSettings = rest.overviewFieldsSettings ? JSON.stringify(rest.overviewFieldsSettings) : undefined;
  rest.siteFieldsSettings = rest.siteFieldsSettings ? JSON.stringify(rest.siteFieldsSettings) : undefined;

  const url = buildAPIURL(`/v1/orgs/${id}`);
  const response = await apiClient.patch(url, rest);
  return parseOrg(response.data);
};

export const getUserRoles = async (): Promise<UserRole[]> => {
  const url = buildAPIURL(`/v1/user_roles`);
  const { data } = await apiClient.get(url);
  return (data.userRoles || []) as UserRole[];
};
