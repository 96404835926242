import React from "react";
import { useTranslation } from "react-i18next";
import { FormSection } from "~/common/containers";
import { Page } from "~/common/containers/page";
import AccountForm from "../components/AccountForm";

const Account: React.FC<any> = (props) => {
  const { t } = useTranslation();
  return (
    <Page title={t("Account")}>
      <FormSection {...props} buttonText={t("Edit account")}>
        {({ edit, setEditMode }) => (
          <AccountForm
            readOnly={!edit}
            onSubmit={() => {
              setEditMode(false);
            }}
            onClose={() => setEditMode(false)}
          />
        )}
      </FormSection>
    </Page>
  );
};

export default Account;
