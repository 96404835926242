import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { useTransKeyProvider } from "~/common/translation";
import { ProjectLocation } from "~/core/projects";

interface Props {
  location?: ProjectLocation;
}

const AddressDisplay: React.FC<Props> = ({ location }) => {
  const { t } = useTranslation();
  const { i18nKey = "projects" } = useTransKeyProvider();
  const { address, latitude, longitude } = location || ({} as ProjectLocation);

  const addressText = (): string => {
    if (!address && latitude && longitude) return t("No address set", { context: i18nKey });
    if (!address && !latitude && !longitude) return t("No location set", { context: i18nKey });
    return address!;
  };

  return (
    <div className="flex items-center gap-5">
      <div className="text-orange-500">
        <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
        </svg>
      </div>

      <div className={classNames("text-lg", !address && "font-semibold text-grey-800")}>{addressText()}</div>
    </div>
  );
};

export default AddressDisplay;
