import { buildAPIURL } from "~/common/http";
import { ResourceQueryParams, ResourceType } from "~/common/models";
import { promiser } from "~/common/utils";
import apiClient from "../../common/api-client";
import { User } from "../contacts";
import { Asset, CreateFolderBody, DirectoryItem, Folder, FoldersQueryParams, Link, LinkBody, PatchLinkParams, ProjectDocument, ShareFileParams } from "./models";

export const addFileType = <T extends ProjectDocument | Link>(file: T): T => {
  const fileType = file.hasOwnProperty("url") ? "link" : "document";
  return { ...file, fileType } as T;
};

/**
 * FOLDERS SERVICES
 */
export const getFolders = (params?: FoldersQueryParams): Promise<Folder[]> => {
  const url = buildAPIURL("/v1/folders", params);
  return apiClient.get(url.toString()).then((response) => response.data.folders);
};

export const getFolder = (folderId: string): Promise<Folder> => {
  const url = buildAPIURL(`/v1/folders/${folderId}`);
  return apiClient.get(url.toString()).then((response) => response.data);
};

export const createFolder = (bodyParams: CreateFolderBody): Promise<Folder> => {
  const url = buildAPIURL("/v1/folders");
  return apiClient.post(url.toString(), bodyParams).then((response) => response.data);
};

export const updateFolder = (params: { folderId: string; name?: string; parentFolderId?: string }) => {
  const { folderId, ...restParams } = params;

  const url = buildAPIURL(`/v1/folders/${folderId}`);
  return apiClient.patch(url.toString(), restParams).then((response) => response.data);
};

export const deleteFolder = (folderId: string): Promise<void> => {
  const url = buildAPIURL(`/v1/folders/${folderId}`);
  return apiClient.delete(url.toString());
};

/**
 * LINKS SERVICES
 */
export const getResourceLinks = async (params: ResourceQueryParams, me?: User): Promise<Link[]> => {
  const path = "/links";
  const withParams = me?.positionType === "internal" ? params : {};
  const url = buildAPIURL(`/v1${path}`, withParams);
  const response = await apiClient.get(url);
  return response.data.links.map((l: Link) => addFileType<Link>(l));
};

export const createResourceLink = async (body: LinkBody): Promise<Link> => {
  const url = buildAPIURL(`/v1/links`);
  const response = await apiClient.post(url, body);
  return addFileType<Link>(response.data);
};

export const updateResourceLink = async (params: PatchLinkParams): Promise<Link> => {
  const url = buildAPIURL(`/v1/links/${params.linkId}`);
  const response = await apiClient.patch(url, params.body);
  return addFileType<Link>(response.data);
};

export const deleteLink = async (linkId: string): Promise<Link> => {
  const url = buildAPIURL(`/v1/links/${linkId}`);
  return apiClient.delete(url);
};

export const shareLink = async (params: ShareFileParams) => {
  const url = buildAPIURL(`/v1/links/share`);
  return promiser<ProjectDocument[]>(apiClient.put(url, params).then((res: any) => res.data.links.map((link: Link) => addFileType<Link>(link))));
};

/**
 * FILES SERVICES
 */
export const getResourceDocuments = async (params: ResourceQueryParams) => {
  const path = "/documents";
  const url = buildAPIURL(`/v1${path}`, params);
  const response = await apiClient.get(url);
  return response.data.documents.map((doc: ProjectDocument) => addFileType<ProjectDocument>(doc));
};

export const getDocument = async (doc: ProjectDocument): Promise<ProjectDocument> => {
  const url = buildAPIURL(`/v1/documents/${doc.id}`);
  const response = await apiClient.get(url);
  return addFileType<ProjectDocument>(response.data);
};

export const bulkDownload = async (doc: ProjectDocument[]): Promise<Asset[]> => {
  const docUrls = doc.map((d) => {
    return buildAPIURL(`/v1/assets/${d.asset.id}`, {
      redirect: "false",
      download: true,
      filename: d.name,
    });
  });

  const downloaded = await Promise.all(
    docUrls.map(async (url) => {
      return apiClient.get(url).then((res) => res.data);
    })
  );

  downloaded.forEach((asset, i) => {
    setTimeout(() => {
      const anchor = document.createElement("a");
      const linkId = `document-link-${i}`;
      anchor?.setAttribute("id", linkId);
      document.body.appendChild(anchor);
      const el = document.getElementById(linkId);
      el?.setAttribute("href", asset.url);
      el?.setAttribute("download", asset.url);
      el?.click();
      if (el?.parentNode) el.parentNode.removeChild(el);
    }, i * 400);
  });

  return downloaded;
};

export const getDocumentAsset = async (doc: ProjectDocument): Promise<string> => {
  const path = `/v1/assets/${doc.asset.id}`;
  const url = buildAPIURL(path, {
    redirect: "false",
    filename: doc.name,
  });

  const response = await apiClient.get(url);
  const file = await apiClient.get(response.data.url);
  return file;
};

export const downloadDocument = async (doc: ProjectDocument | DirectoryItem): Promise<Asset> => {
  const path = `/v1/assets/${doc.asset.id}`;
  const url = buildAPIURL(path, {
    redirect: "false",
    download: true,
    filename: doc.name,
  });

  const response = await apiClient.get(url);
  const asset = response.data;
  const anchor = document.createElement("a");
  anchor.href = asset.url;
  anchor.download = asset.url;
  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
  return response.data;
};

export const createResourceDocument = async (
  resourceId: string,
  resourceType: ResourceType,
  doc: Pick<ProjectDocument, "name" | "parentFolderId" | "type">,
  file: File
): Promise<ProjectDocument> => {
  const formData = new FormData();
  formData.append("asset", file);
  formData.append("name", doc.name);
  formData.append("resourceType", resourceType);
  formData.append("resourceId", resourceId);
  formData.append("parentFolderId", doc.parentFolderId!);
  formData.append("type", doc.type || "general");

  const url = buildAPIURL("/v1/documents");
  const response = await apiClient.post(url, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
  return addFileType<ProjectDocument>(response.data);
};

export const deleteDocument = (docId: string): Promise<ProjectDocument> => {
  const url = buildAPIURL(`/v1/documents/${docId}`);
  return apiClient.delete(url);
};

export const updateResourceDocument = async (doc: Partial<ProjectDocument>) => {
  const url = buildAPIURL(`/v1/documents/${doc.id}`);
  const response = await apiClient.patch(url, doc);
  return addFileType<ProjectDocument>(response.data);
};

export const shareDocument = async (params: ShareFileParams) => {
  const url = buildAPIURL(`/v1/documents/share`);
  return promiser<ProjectDocument[]>(apiClient.put(url, params).then((res: any) => res.data.documents.map((doc: ProjectDocument) => addFileType<ProjectDocument>(doc))));
};
