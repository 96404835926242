import classNames from "classnames";
import intlTelInput from "intl-tel-input";
import React, { ChangeEventHandler, FocusEventHandler, useEffect, useRef } from "react";

type Props = {
  id?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onValidate?: (isValid: boolean) => void;
  readOnly?: boolean;
  label?: string;
  hasError?: boolean;
  value?: string;
  name?: string;
  className?: string;
  required?: boolean;
  errorMessage?: string;
  validateOn?: "change" | "blur";
};

const PhoneInput: React.FC<Props> = (props: Props) => {
  const { id, value, name, className, validateOn = "change", hasError = false, label, required, errorMessage, readOnly, onChange, onBlur, onValidate } = props;

  const inputRef = useRef<HTMLInputElement | null>(null);

  const getIti = (): intlTelInput.Plugin | null => {
    return inputRef.current ? window.intlTelInputGlobals.getInstance(inputRef.current) : null;
  };

  useEffect(() => {
    const phoneInput = inputRef.current;
    if (phoneInput && !getIti()) {
      const tel = intlTelInput(phoneInput, {
        initialCountry: "au",
        preferredCountries: ["au", "nz", "de"],
        utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.12/js/utils.min.js",
      });

      tel.promise.then(() => getIti()?.setNumber(value || ""));
    }
  }, [inputRef.current, readOnly]);

  useEffect(() => {
    getIti()?.setNumber(value || "");
  }, [value, readOnly]);

  return (
    <div className="field-grid">
      {label && (
        <label aria-label="form-input-label" htmlFor={id} className={classNames("flex items-center label-area", required && "required")}>
          {label}
        </label>
      )}
      {readOnly ? (
        <span>{value}</span>
      ) : (
        <div className={classNames("form-input field-area", hasError && "error")}>
          <input
            id={id}
            name={name}
            type="text"
            className={classNames("w-full", className)}
            ref={inputRef}
            onBlur={(evt) => {
              const iti = getIti();
              const hasNumber = (iti?.getNumber() ?? "").length > 0;
              if (onValidate && validateOn === "blur") onValidate((!hasNumber || iti?.isValidNumber()) ?? true);
              if (onBlur) onBlur(evt);
            }}
            onChange={(evt) => {
              const iti = getIti();
              const phoneNumber = iti?.getNumber() ?? "";
              const newEvent = { ...evt };
              newEvent.target.value = phoneNumber;
              if (onValidate && validateOn === "change") onValidate(iti?.isValidNumber() ?? false);

              // if phoneNumber is valid set the valid state even if was configured to
              // be validated on blur
              const isValid = iti?.isValidNumber();
              if (onValidate) {
                if (isValid || phoneNumber === "") onValidate(true);
                else if (validateOn === "change") onValidate(false);
              }

              if (onChange) onChange(newEvent);
            }}
          />
        </div>
      )}

      {errorMessage && <div className="text-red-900 text-caption2">{errorMessage}</div>}
    </div>
  );
};

export default PhoneInput;
