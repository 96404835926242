import React, { useCallback } from "react";
import { formatNumber } from "~/common/utils";
import "./MapTotal.css";

interface Props {
  title: string;
  total: number;
  suffix?: string;
  color?: string;
}

const MapTotal: React.FC<Props> = ({ title, suffix, total, color = "#E3E3E3" }) => {
  const titleBoxRef = useCallback(
    (node) => {
      if (node) {
        node.style.setProperty("--title-color", color);
      }
    },
    [color]
  );

  const totalStr = formatNumber(Math.trunc(total));

  return (
    <div className="total-wrapper font-sans">
      <div className=" flex items-center">
        <div className="flex-1 relative pl-4 font-bold text-title">
          <div data-testid="title" ref={titleBoxRef} className="total-title flex items-center">
            {title}
          </div>
        </div>
        <div className="text-grey-900 break-words">
          {totalStr} {suffix}
        </div>
      </div>
    </div>
  );
};

export default MapTotal;
