import { useContext } from "react";
import { MeContext } from "../account";
import { assessmentTemplateFieldsList, siteTemplateFieldsList } from "../projects";
import { Assessment } from "../prospects/models";
import { Org, OrgResult, UserRole } from "./models";
import { patchOrg } from "./services";

export const useOrg = (): OrgResult => {
  const { activeOrg: org, setOrg, me, userRoles } = useContext(MeContext);

  const getAssessmentTemplate = (templateId: string) => {
    const templates = org?.assessmentFieldsSettings ?? [];
    const template = templates.find(({ id }: any) => id === templateId);
    return template;
  };

  const hasOverviewField = (field: string, projectTypeId?: string): boolean => {
    const orgFields = !org?.overviewFieldsSettings ? [] : org?.overviewFieldsSettings[projectTypeId ?? "default"] || org?.overviewFieldsSettings.default || [];
    return Object.keys(orgFields).length > 0 ? Object.keys(orgFields).includes(field) : false;
  };

  const hasSiteField = (siteField: string, projectTypeId?: string): boolean => {
    const orgFields = !org?.siteFieldsSettings ? [] : org?.siteFieldsSettings[projectTypeId ?? "default"] || org?.siteFieldsSettings.default || [];
    return org?.siteFieldsSettings && Object.keys(orgFields).length > 0 ? Object.keys(orgFields).includes(siteField) : true; // Default true because we show all fields if there is no config
  };

  const hasAssessmentField = (fieldName: string, assessment?: Assessment): boolean => {
    if (!assessment || !assessment.assessmentFieldsSettings) return false;
    return Object.keys(assessment.assessmentFieldsSettings).includes(fieldName);
  };

  const hasAssessmentSection = (siteSection: string, assessment?: Assessment): boolean => {
    if (!org?.siteFieldsSettings) return true;
    const fields = assessmentTemplateFieldsList.filter((field) => Object.keys(assessment?.assessmentFieldsSettings ?? {}).includes(field.id));
    return fields.filter((field) => field.section.includes(siteSection)).length > 0;
  };

  const hasSiteSection = (siteSection: string, projectTypeId?: string): boolean => {
    if (!org?.siteFieldsSettings) return true;

    const orgFields = org.siteFieldsSettings[projectTypeId ?? "default"] || org.siteFieldsSettings.default || [];
    const fields = siteTemplateFieldsList.filter((field) => Object.keys(orgFields).includes(field.id));
    return fields.filter((field) => field.section.includes(siteSection)).length > 0;
  };

  const updateOrg = async (orgData: Partial<Org>) => {
    const newOrg = await patchOrg(orgData as Org);
    setOrg(newOrg);

    // we need to restart the app in order to reload the new language files
    if (org?.languageCode !== newOrg.languageCode) {
      localStorage.setItem("i18nextLng", newOrg.languageCode);
      window.location.reload();
    }
  };

  const switchOrg = async (id: string) => {
    const newOrg = me?.orgs.find((o: Org) => o.id === id) as Org;
    setOrg(newOrg, true);
  };

  const findUserRoleName = (id: string): string => {
    return userRoles.find((role) => role.id === id)?.name ?? "";
  };

  const getProjectRoles = (): UserRole[] => {
    return userRoles.filter((role) => role.type === "project_assigned_user");
  };

  const getProspectRoles = (): UserRole[] => {
    return userRoles.filter((role) => role.type === "prospect_assigned_user");
  };

  return {
    org,
    updateOrg,
    switchOrg,
    hasOverviewField,
    hasSiteField,
    hasSiteSection,
    hasAssessmentField,
    hasAssessmentSection,
    getAssessmentTemplate,
    findUserRoleName,
    getProjectRoles,
    getProspectRoles,
  };
};
