import classNames from "classnames";
import React, { ReactNode } from "react";
import "./Page.css";
import PageTitle from "./PageTitle";

type Props = {
  title: ReactNode;
  children: ReactNode;
  className?: string;
};

const Page: React.FC<Props> = ({ title, children, className }) => {
  let pageTitle = title;
  if (typeof title === "string") {
    pageTitle = <PageTitle documentTitle={title} />;
  }

  return (
    <div className="page-container">
      {pageTitle}
      <div className={classNames(className || "page-container page-content page-max-content")}>{children}</div>
    </div>
  );
};

export default Page;
