import React, { ReactNode } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "~/common/forms/button";

type Props = {
  children: ReactNode;
  hideBackButton?: boolean;
};

const ErrorPage: React.FC<Props> = ({ children, hideBackButton = false }) => {
  const history = useHistory();

  return (
    <div className="h-screen flex flex-col items-center justify-center bg-blue-900">
      <img src="/vectors/error-top-left.svg" alt="top left" className="fixed top-0 left-0 w-1/2 desktop:w-auto -mt-14" />
      <img src="/vectors/error-bottom-right.svg" alt="top left" className="fixed bottom-0 right-0 w-1/2 desktop:w-auto -mb-14 tablet:mb-0" />

      <div className="mx-24">{children}</div>

      {!hideBackButton && (
        <Button variant="btn-transparent" size="btn-medium" onClick={() => history.push("/projects")}>
          Return to projects
        </Button>
      )}
    </div>
  );
};

export default ErrorPage;
