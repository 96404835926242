import intlTelInput from "intl-tel-input";
import { HTMLProps, KeyboardEvent, MouseEvent, MutableRefObject, ReactNode } from "react";

export interface InputValidator {
  (value: any): Error | null;
}

export enum IconPosition {
  LEFT = "left",
  RIGHT = "right",
}

export interface FormInputProps extends HTMLProps<HTMLInputElement | HTMLTextAreaElement> {
  validator?: InputValidator;
  validateOn?: "change" | "blur";
  label?: string;
  textAlign?: "left" | "right" | "center" | "justify";
  rounded?: "rounded" | "rounded-b" | "rounded-t" | "rounded-l" | "rounded-r" | "rounded-none";
  inputClassName?: string;
  icon?: ReactNode;
  iconPosition?: IconPosition;
  inlineActions?: boolean;
  labelClassName?: string;
  telref?: MutableRefObject<intlTelInput.Plugin | null>;
  inputref?: MutableRefObject<HTMLInputElement | null>;
  phoneValue?: string;
  errorMessage?: string;
  testId?: string;
  submitIcon?: boolean;
  clearIcon?: boolean;
  hasError?: boolean;
  description?: ReactNode;
  readOnlyComponent?: ReactNode;
  innerIcons?: boolean;
  clearIconAlwaysVisible?: boolean;
  inputStyleClass?: string;
  onSubmitClick?: (evt: MouseEvent<HTMLElement> | KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  onClearClick?: (evt: MouseEvent<HTMLElement> | KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  children?: ReactNode;
  spacer?: ReactNode;
}
