import React from "react";

type Props = {
  name: string;
  width: number;
  height: number;
  className?: string;
};

const Icon: React.FC<Props> = (props: Props) => {
  const { name, width, height, className } = props;
  return (
    <svg className={className} width={width} height={height}>
      <use xlinkHref={`/icons/icons.svg#${name}`} />
    </svg>
  );
};

export default Icon;
