import React, { ReactNode } from "react";
import { ActionMeta, MenuPlacement, MenuPosition, OptionTypeBase, ValueType } from "react-select";

export const orgLevelSelectables = [
  "project_co_benefit",
  "site_group",
  "project_source",
  "prospect_source",
  "project_tag",
  "project_activity",
  "project_advisor_name",
  "project_category",
  "project_host_name",
  "project_service",
  "project_validator",
  "project_land_management_activity",
  "project_land_management_strategy",
  "project_grazing_management_activity",
  "project_status",
  "project_contract_status",
  "user_tag",
  "proponent_name",
  "designation_near_site",
  "designation_on_site",
  "site_clients",
  "site_land_capabilities_class",
  "site_land_title",
  "site_bio_region",
  "site_bio_subregion",
  "site_catchment",
  "site_threatened_ecosystems",
  "site_threatened_species",
  "site_county",
  "site_grant_model_option",
  "site_native_woodland_nvc_type",
  "site_breeding_type",
  "site_cultural_practice",
  "site_digital_tool_used",
  "site_farm_management_system",
  "site_irrigation_energy_source",
  "site_irrigation_water_source",
  "site_farm_biogas_production",
  "site_tillage_management",
  "site_dung_management_system",
  "site_management_of_hedges",
  "site_management_of_forest_edge",
  "site_management_of_single_tree",
  "site_management_of_tree_lines",
  "site_primary_enterprise_type",
  "site_primary_habitats",
  "site_management_activities",
  "site_land_use",
  "site_developers",
  "site_deal_types",
  "site_local_government_area",
  "field_sites",
  "land_types",
  "environmental_designations_on_site",
  "historical_designations_on_site",
  "landscape_designations_on_site",
  "investor_landholder",
  "project_partners",

  "resource_flag_label",
  "prospect_resource_flag_label",
  "unit_group_resource_flag_label",
] as const;

export const globalLevelSelectables = [
  "project_standard",
  "project_registry",
  "project_un_sdgs",
  "project_host_name",

  "site_soil_type",
  "site_payment_option",
  "site_planting_type",
  "site_species_type",
  "site_proposed_methodology",
  "site_soil_drainage",
  "project_co_benefit_rating",
  "job_title",
  "business_unit",

  "assessment_payment_option",
  "assessment_soil_type",
  "assessment_planting_type",
  "assessment_species_type",
] as const;

export const selectableTypes = [...orgLevelSelectables, ...globalLevelSelectables] as const;

export type SelectableType = typeof selectableTypes[number];

export type OrgLevelSelectable = typeof orgLevelSelectables[number];
export type GlobalSelectable = typeof globalLevelSelectables[number];

export type OrgLevelSelectableResult = Record<OrgLevelSelectable, Selectable[]>;
export type GlobalLevelSelectableResult = Record<GlobalSelectable, Selectable[]>;

export type PeyloadSelectablesResult = GlobalLevelSelectableResult | OrgLevelSelectableResult;

export type Selectable = {
  type: SelectableType;
  name: string;
  id: string;
};

export type CreateSelectableParams = Pick<Selectable, "type" | "name">;
export type DeleteSelectableParams = Pick<Selectable, "type" | "id">;

export type SelectableId = {
  id: string;
  selectableId: string;
  name: string;
};

export interface GetSelectableParamsReturn {
  [key: string]: {
    id?: string;
    selectableId?: string;
  }[];
}

export type ReadOnlyType = "col" | "row";

export type GetSelectablesOptions = {
  id?: string[];
  includeId?: string | string[];
  name?: string;
  sort?: string;
  type: SelectableType;
};

export enum Variant {
  DEFAULT,
  WHITE,
  SQUARE,
  ROUNDED_LEFT,
  ROUNDED_RIGHT,
  CURRENCY,
  SECONDARY,
}

export interface AutocompleteOption<T = string> {
  value: T;
  label: string;
  meta?: any;
}

export type AutocompleteProps = {
  id?: string;
  inputId?: string;
  isMulti?: boolean;
  creatable?: boolean;
  name?: string;
  required?: boolean;
  description?: string;
  label?: string;
  labelClassName?: string;
  menuIsOpen?: boolean;
  isLoading?: boolean;
  isDisabled?: boolean;
  isClearable?: boolean;
  hasError?: boolean;
  errorMessage?: string;
  options?: AutocompleteOption[];
  value?: string | string[] | AutocompleteOption | AutocompleteOption[];
  defaultValue?: AutocompleteOption | AutocompleteOption[];
  className?: string;
  classNamePrefix?: string;
  placeholder?: string;
  variant?: Variant;
  menuWidth?: number;
  menuPortalTarget?: HTMLElement | null;
  menuPosition?: MenuPosition;
  menuPlacement?: MenuPlacement;
  menuShouldScrollIntoView?: boolean;
  closeMenuOnScroll?: boolean;
  closeMenuOnSelect?: boolean;
  customComponents?: any | undefined;
  blurInputOnSelect?: boolean;
  asyncSelect?: boolean;
  isSearchable?: boolean;
  selectedOption?: any;
  readOnly?: boolean;
  readOnlyComponent?: ReactNode;
  readOnlyType?: ReadOnlyType;
  exclusionFilterName?: string;
  exclusionFilterValue?: boolean;
  asModal?: boolean;
  isFieldGrid?: boolean;
  onMenuOpen?: () => void;
  onMenuClose?: () => void;
  onInputChange?: (evt: any) => void;
  noOptionsMessage?: () => any;
  loadOptions?: (val: string) => Promise<AutocompleteOption[]>;
  filterOption?: (option: any, rawInput: string) => boolean;
  onFocus?: () => void;
  onBlur?: () => void;
  onExclusionChange?: React.ChangeEventHandler<HTMLInputElement>;
  onChange?: (
    newValue: ValueType<OptionTypeBase, boolean>,
    actionMeta: ActionMeta<OptionTypeBase>
  ) => void | Promise<void> | ((value: ValueType<OptionTypeBase, boolean>, action: ActionMeta<OptionTypeBase>) => void) | undefined;
};
