import classNames from "classnames";
import React, { MutableRefObject, ReactNode, useEffect, useRef, useState } from "react";
import { Portal } from "~/common/containers/portal";
import { TooltipPosition } from "../models";

type Props = {
  className?: string;
  children: ReactNode;
  objRef?: MutableRefObject<HTMLElement | null>;
  position?: TooltipPosition;
  style?: React.CSSProperties;
  visible?: boolean;
};

const Tooltip: React.FC<Props> = ({ children, className, style, visible = true, objRef = null, position = "TOP" }) => {
  const tooltipRef = useRef<HTMLDivElement | null>(null);
  const [scrollY, setScrollY] = useState(0);

  const handleScrollEvent = () => {
    setScrollY(window.pageYOffset);
  };

  useEffect(() => {
    document.addEventListener("scroll", handleScrollEvent);
    return () => {
      document.removeEventListener("scroll", handleScrollEvent);
    };
  }, []);

  useEffect(() => {
    if (objRef?.current && tooltipRef.current && visible) {
      const objBounds = objRef.current.getBoundingClientRect();
      const tooltipBounds = tooltipRef.current.getBoundingClientRect();

      let objX = 0;
      switch (position) {
        case "BOTTOM":
        case "TOP":
          objX = objBounds.x + objBounds.width / 2 - tooltipBounds.width / 2;
          break;
        case "LEFT":
          objX = objBounds.x - tooltipBounds.width - 8;
          break;
        case "BOTTOM_LEFT":
          objX = objBounds.x - 8;
          break;
        default:
          // Position.RIGHT
          objX = objBounds.x + objBounds.width + 8;
          break;
      }

      let objY = 0;
      switch (position) {
        case "RIGHT":
        case "LEFT":
          objY = objBounds.y + objBounds.height / 2 - tooltipBounds.height / 2;
          break;
        case "TOP":
          objY = objBounds.y - tooltipBounds.height - 8;
          break;
        default:
          // Position.BOTTOM/Position.BOTTOM_LEFT
          objY = objBounds.y + objBounds.height + 8;
          break;
      }

      tooltipRef.current.style.top = `${objY + scrollY}px`;
      tooltipRef.current.style.left = `${objX}px`;
    }
  }, [objRef?.current, tooltipRef.current, position, visible, scrollY]);

  return (
    <Portal id="tooltip-portal">
      <div ref={tooltipRef} className={classNames("flex items-center w-max absolute z-70", className)} style={style}>
        {visible && <div className="text-sm w-max p-4 bg-grey-900 border-0 rounded text-white z-10 relative">{children}</div>}
      </div>
    </Portal>
  );
};

export default Tooltip;
