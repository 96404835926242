import React, { useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";
import { useMe } from "~/core/account";
import { Navigation } from "~/core/org/models";
import "./Navbar.css";
import { useOrg } from "~/core/org";
import { useAlertsProvider } from "~/core/alerts/hooks";
import NavBarItem from "./NavbarItem/NavbarItem";
import NavBarUserProfile from "./NavBarUserProfile/NavBarUserProfile";
import { NavRoute } from "../models";
import { NavRoutes } from "../NavRoutes";

const NavBar: React.FC = () => {
  const { me } = useMe();
  const { org } = useOrg();
  const ref = useRef(null);
  const {
    state: { unReadCounter },
  } = useAlertsProvider();

  const { t } = useTranslation();

  let orgNavigation = org?.navigation || [];
  if (me?.projectAccess !== "full") {
    orgNavigation = orgNavigation?.filter((nav) => nav.name !== "Prospects");
  }

  let dividerCount = 0;
  const linkList: Array<Navigation> = [];
  orgNavigation.forEach((nav) => {
    if (nav.type === "divider") {
      dividerCount += 1;
      return;
    }

    linkList[dividerCount] = (linkList[dividerCount] || []).concat(nav);
  });

  return (
    <nav data-testid="main-navbar" ref={ref} className="main-navbar py-6 flex felx-col justify-between">
      <img alt="Logo" src="/vectors/new-logo.svg" width={80} className="pl-6" />
      <div className="mt-5 px-6 text-grey-600 font-semibold capitalize">{org?.name}</div>

      {linkList.map((linkGroup) => {
        const key = uuidv4();
        return (
          <div key={key} className="flex flex-col nav-group py-5">
            {linkGroup.map((link) => {
              const counter = link.name === "Alerts" ? unReadCounter : 0;
              const { icon } = NavRoutes[link.icon as NavRoute];
              if (!link.path) return null;
              return <NavBarItem key={link.path!} icon={icon} label={t(link.name)} path={link.path!} counter={counter} type={link.type} />;
            })}
          </div>
        );
      })}

      <div className="flex-1" />

      <NavBarUserProfile />
    </nav>
  );
};

export default NavBar;
