import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { useToast } from "~/common/toasts";
import { authOtp } from "~/core/auth";
import { isValidEmail } from "~/common/utils";
import { FormInput } from "~/common/forms/input";
import { Button } from "~/common/forms/button";

interface Props {
  onSubmit: Function;
}

type FormData = {
  email: string;
};

const LoginEmail: React.FC<Props> = ({ onSubmit }) => {
  const {
    control,
    trigger,
    handleSubmit,
    formState: { isDirty, isValid },
    setError,
  } = useForm<FormData>({
    mode: "onChange",
  });

  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);
  const { toastError } = useToast();

  const onFormSubmit = async (formData: FormData) => {
    const { email } = formData;
    setLoading(true);
    try {
      await authOtp(email);
      onSubmit(email);
    } catch (e) {
      toastError({ message: t("Invalid email address") });
      setError("email", {
        type: "manual",
        message: t("Invalid email address"),
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <form className="flex flex-col gap-7 form-col justify-between flex-1" onSubmit={handleSubmit(onFormSubmit)} noValidate>
      <div className="flex items-center gap-4">
        <h2 className="text-blue-500">Workspace</h2>
        <h2 className="text-blue-900">login</h2>
      </div>

      <div className="my-4 w-full relative">
        <Controller
          name="email"
          control={control}
          rules={{ required: true, validate: isValidEmail }}
          render={({ field }) => (
            <FormInput
              type="email"
              placeholder={t("name@workemail.com")}
              aria-placeholder="login-email-input"
              name={field.name}
              value={field.value}
              description={t("Enter your email to receive a temporary login code")}
              onChange={(evt) => {
                const { value } = evt.target as HTMLInputElement;
                field.onChange(value);
                trigger();
              }}
            />
          )}
        />
      </div>

      <footer className="flex sticky bottom-0 py-4">
        <Button type="submit" variant="btn-blue" size="btn-large" disabled={!isDirty || !isValid || isLoading}>
          <Trans>Continue</Trans>
        </Button>
      </footer>
    </form>
  );
};

export default LoginEmail;
