import { MenuItem } from "~/common/forms/menu";
import { IMeta } from "~/common/http";
import { Feedback } from "~/common/models";
import { SelectableId, SelectableType } from "~/common/selectables";
import { AssignedUser, User } from "../contacts/models";
import { Asset } from "../files";
import { Assessment, ResourceLocationProps } from "../prospects/models";

export interface GetProjectsOptions {
  limit?: number;
  offset?: number;
  sort?: string;
  id?: string;
  page?: number;
  status?: string;
  projectAssignedUserId?: string;
  siteAssignedUserId?: string;
  projectOwnerId?: string;
  search?: string;
  stageTemplateId?: string;
  flagLabelSelectableId?: string;
  flagged?: boolean;
  tagId?: string;
  sourceSelectableId?: string;
  validatorSelectableId?: string;
  projectTypeId?: string;
  region?: string;
  archived?: boolean;
  emptyLocationOnly?: boolean;
  closedOnly?: boolean;
  projectStatusTemplateId?: string;
  bioRegionSelectableId?: string;
  methodologyId?: string;

  // Not in swagger
  name?: string;
  myProjects?: boolean;
  user?: User;

  // in swagger, not used in app
  registryId?: string;

  // exclusion filters
  isNotProjectTypeIds?: boolean;
  isNotStageTemplateIds?: boolean;
  isNotStatuses?: boolean;
  isNotRegistryId?: boolean;
  isNotSiteAssignedUserIds?: boolean;
  isNotSourceSelectableIds?: boolean;
  isNotTagSelectableIds?: boolean;
  isNotProjectRegions?: boolean;
  isNotProjectOwnerIds?: boolean;
  isNotValidatorSelectableIds?: boolean;
  isNotProjectStatusTemplateIds?: boolean;
  isNotBioRegionSelectableId?: boolean;
  isNotMethodologyIds?: boolean;
}

export type GroundcoverAssets = {
  id: string;
  siteId: string;
  assetId: string;
  url?: string;
};

export type BaseSite = {
  id: string;
  name: string;
  projectAreaHectares: number;
  averageRainfallMM: number;
  percentageOfConiferSpecies?: number;
  percentageOfBroadleafSpecies?: number;
  propertyId?: string;
  stockingDensityStemsPerHectare?: number;
  totalTreesAvailable?: number;
  gridReference?: number;
  nearestTown?: string;
  existingForestAreaHectares?: number;
  windSpeedMetersPerSecond?: number;
  temperatureCelsius?: number;
  percentageOfIndigenous?: number;
  percentageOfHardwood?: number;
  percentageOfSoftwood?: number;
  riparianAreaHectares?: number;
  wetlandAreaHectares?: number;
  netHectaresAvailable?: number;
  totalBroadleaf?: number;
  totalConifer?: number;
  soilCarbonSequestrationTonnesCO2eYear?: number;
  treeCarbonSequestrationTonnesCO2eYear?: number;
  riparianManagementRestorationAreaHectares?: number;
  remnantVegetationManagementRestorationAreaHectares?: number;
  activelyErodingBlanketBogFlatBareHectares?: number;
  activelyErodingBlanketBogHaggGullyHectares?: number;
  drainedBlanketBogArtificialHectares?: number;
  drainedBlanketBogHaggGullyHectares?: number;
  maxEstimatedCO2eTonnesPerHectare?: number;
  minEstimatedCO2eTonnesPerHectare?: number;
  landManagementScore?: number;
  interimCreditingAssessmentTCO2HectaresPerYear?: number;
  ecologicalConditionImprovementAndConservationScore?: number;
  securingAssetsNecessaryForLifeScore?: number;
  socialImpactScore?: number;
  contextOfRegenerativeImpactScore?: number;
  potentialToImproveImpactScore?: number;
  totalScoreContributingToValuation?: number;
  changeInGroundcoverPercentage?: number;
  groundcoverComparisonToLocalMedian?: number;
  groundcoverComparisonToSubregionalMedian?: number;
  averageAreaOfPrimaryWoodyVegetationMaintainedHectares?: number;
  averageAreaOfSecondaryWoodyVegetationMaintainedHectares?: number;
  changeInSecondaryWoodyVegetationHectares?: number;
  certifiedCreditAreaHectares?: number;
  eligibleForestAreaHectares?: number;
  forestAreaHectares?: number;
  modifiedPeatlandConditionAreaHectares?: number;
  purchaseContractDurationYears?: number;
  circularBiodiversityPresentScore?: number;
  circularCommunityEconomicBenefitScore?: number;
  circularContributionToRenewableEnergyScore?: number;
  circularCulturalHeritageScore?: number;
  circularExpectedBiodiversityGainScore?: number;
  circularPublicAccessAndRecreationScore?: number;
  circularResearchAndEducationScore?: number;
  circularSocietalEconomicBenefitScore?: number;
  circularVisualAmenityScore?: number;
  circularWaterManagementScore?: number;
  baselineQuoteAmount?: number;
  baselineQuoteCurrency?: string;
  paymentOptionSelectableId?: string;
  ageOfOperatingHeads?: number;
  farmWorkersTotal?: number;
  monthsOfFloorCoverage?: number;
  moRate?: number;
  siren?: string;
  organicMaterialRate?: number;
  altitude?: number;
  soilCoverageMonths?: number;
  collars?: number;
  animalMassKG?: number;
  accessibleAcreagePrivate?: number;
  accessibleAcreageState?: number;
  accessibleAcreageBlmUsfs?: number;
  accessibleAcreageUnknownOther?: number;
  daysInGrowingSeason?: number;
  daysCattleSupportByBufferArea?: number;
  depthToRockCM?: number;
  depthToSampleCM?: number;
  rotationsPerSeason?: number;
  claimableCO2Tonnes?: number;
  twentyFiveYearYieldACCUPerHectare?: number;
  animalsInPastureDays?: number;
  plantedPercentage?: number;
  areaPreservedHectares?: number;
  preservedPercentage?: number;
  eucalyptusSalignaPercentage?: number;
  eucalyptusUrograndisPercentage?: number;
  proposedStartDate?: string;
  averageIssuanceVolumePerYear?: number;
  closingDateOfTheAccountingBalance?: string;
  dieselConsumptionLitersPerYear?: number;
  essenceConsumptionLitersPerYear?: number;
  electricityConsumptionKilowattPerYear?: number;
  totalWaterConsumptionLitersPerYear?: number;
  totalWaterConsumptionForIrrigationLitersPerYear?: number;
  productQuantityRefrigeratedKilogramsPerYear?: number;
  quantityOfSojaBoughtTonnesYear?: number;
  averagePresenceOfCoverCrops?: number;
  percentageOfSeedIndependence?: number;
  percentageOfSurfaceIrrigated?: number;
  cultivatedTreeZoneHectares?: number;
  vulnerableZonesHectares?: number;
  surfaceAgrivoltaicsHectares?: number;
  surfacePhotovoltaicHectares?: number;
  totalFarmRevenuePerYearAmount?: number;
  totalFarmRevenuePerYearCurrency?: string;
  totalRevenueFromDirectSalesPerYearAmount?: number;
  totalRevenueFromDirectSalesPerYearCurrency?: string;
  expensesForSyntheticFertiliserPerYearAmount?: number;
  expensesForSyntheticFertiliserPerYearCurrency?: string;
  percentageOfAlder?: number;
  percentageOfAsh?: number;
  percentageOfAspen?: number;
  percentageOfBeech?: number;
  percentageOfBirch?: number;
  percentageOfCherry?: number;
  percentageOfDouglasFir?: number;
  percentageOfEucalyptus?: number;
  percentageOfHawthorn?: number;
  percentageOfHazel?: number;
  percentageOfHolly?: number;
  percentageOfJuniper?: number;
  percentageOfLarch?: number;
  percentageOfMaple?: number;
  percentageOfNorwaySpruce?: number;
  percentageOfOak?: number;
  percentageOfOtherBroadleaf?: number;
  percentageOfOtherConifer?: number;
  percentageOfRowan?: number;
  percentageOfScotsPine?: number;
  percentageOfSitkaSpruce?: number;
  percentageOfSycamore?: number;
  percentageOfWesternRedCedar?: number;
  percentageOfWillow?: number;
  validationDate?: string;
  nextVerificationDate?: string;
  totalAmountOfSyntheticNitrogenAppliedToSoilsKg?: number;
  totalAmountOfOrganicNitrogenAppliedToSoilsKg?: number;
  quantityOfFeedBoughtTonnesPerYear?: number;
  treeVolumeM3HA?: number;
  averageAnnualIncreaseM3HAPerYear?: number;
  treeDiameterCM?: number;
  treeHeightMeters?: number;
  otherEnterpriseType?: string;
  lotNumbers?: string;
  numberOfCarbonEstimationAreas?: number;
  numberOfCarbonEstimationAreasAfterFieldStratification?: number;
  numberOfCarbonEstimationAreasDesktopAssessmentFeasibility?: number;
  numberOfCows?: number;
  numberOfFires?: number;
  numberOfPaddocks?: number;
  numberOfPlantTypesForCoverCrops?: number;
  numberOfPlantVarietiesForAlfalfa?: number;
  numberOfPlantVarietiesForBarley?: number;
  numberOfPlantVarietiesForBeansAndPulses?: number;
  numberOfPlantVarietiesForClover?: number;
  numberOfPlantVarietiesForDrybean?: number;
  numberOfPlantVarietiesForMaize?: number;
  numberOfPlantVarietiesForMillet?: number;
  numberOfPlantVarietiesForOats?: number;
  numberOfPlantVarietiesForOtherForagesIncludingPerennialGrassesAndCloverPastures?: number;
  numberOfPlantVarietiesForOtherNFixingForageCrops?: number;
  numberOfPlantVarietiesForOtherNonNFixingGrainCrops?: number;
  numberOfPlantVarietiesForOtherRootCrops?: number;
  numberOfPlantVarietiesForPotatoes?: number;
  numberOfPlantVarietiesForRapeseed?: number;
  numberOfPlantVarietiesForRice?: number;
  numberOfPlantVarietiesForRye?: number;
  numberOfPlantVarietiesForSorghum?: number;
  numberOfPlantVarietiesForSoybean?: number;
  numberOfPlantVarietiesForSpringWheat?: number;
  numberOfPlantVarietiesForSunflower?: number;
  numberOfPlantVarietiesForWheat?: number;
  numberOfPlantVarietiesForWinterWheat?: number;
  numberOfSamplePoints?: number;
  numberOfStratas?: number;
  estimatedPricePerTonneAmount?: number;
  estimatedPricePerTonneCurrency?: string;
  yieldTCO2HA?: number;
  accusPerAnnum?: number;
  landCostPricePerHaAmount?: number;
  landCostPricePerHaCurrency?: string;
  investmentCapitalAmount?: number;
  investmentCapitalCurrency?: string;
  investmentCapitalPerACCUAmount?: number;
  investmentCapitalPerACCUCurrency?: string;
  irrHurdle?: number;
  investorLandholderSelectableId?: string;
  investorLandholder?: SelectableId;
  registeredClaimablePIUs?: number;
  registeredYear?: number;
  projectStartYear?: number;
  areaPlantedBySpeciesExoticHectares?: number;
  areaPlantedBySpeciesIndigenousHectares?: number;
  areaToBePlantedExoticHectares?: number;
  areaToBePlantedIndigenousHectares?: number;
  totalStemsPlantedBySpeciesExotic?: number;
  totalStemsPlantedBySpeciesIndigenous?: number;
  totalStemsToBePlantedBySpeciesExotic?: number;
  totalStemsToBePlantedBySpeciesIndigenous?: number;
  distanceToMajorUrbanCentreKMs?: number;
  implementationDate?: string;
  accountHolder?: string;
  registryAccount?: string;
  proposedProjectType?: string;
  businessUnitSelectableId?: string;
  propertySizeHectares?: number;
  salesPriceAmount?: number;
  salesPriceCurrency?: string;
  landValueAmount?: number;
  landValueCurrency?: string;
  medianRainfallMMPerYear?: number;
  expectedNumberOfJobsCreatedIndividuals?: number;
  expectedNumberOfJobsFilledByWomenIndividuals?: number;
  numberOfThreatenedSpeciesProtectedThroughProject?: number;
  totalAgroforestryAreaHA?: number;
  totalAreaListedAsHighConservationAreaHA?: number;
  totalConservationAreaHA?: number;
  totalNumberOfLocalStakeholdersSupportedThroughBenefitAgreementsIndividuals?: number;
  totalProductionForestAreaHA?: number;
  bankAccountNumber?: string;
  bankAccountSwift?: string;
  bankName?: string;
  bankAddress?: string;
  bankAccountHolderName?: string;
  bankAccountHolderAddress?: string;
  bankRemarksNotes?: string;
};

export type Site = BaseSite & {
  projectId: string;
  groundcoverAssetID?: string | null;
  assignedUsers?: AssignedUser[];
  groundCoverAssets?: GroundcoverAssets[];
  sitePlantingTypes?: SelectableId[];
  siteSpeciesTypes?: SelectableId[];
  designationsNearSite?: SelectableId[];
  designationsOnSite?: SelectableId[];
  environmentalDesignationsOnSite?: SelectableId[];
  historicalDesignationsOnSite?: SelectableId[];
  landscapeDesignationsOnSite?: SelectableId[];
  siteLandCapabilitiesClass?: SelectableId[];
  siteLandTitles?: SelectableId[];
  siteSoilTypes?: SelectableId[];
  siteBioRegions?: SelectableId[];
  siteBioSubregions?: SelectableId[];
  siteCatchments?: SelectableId[];
  siteGroups?: SelectableId[];
  siteThreatenedEcosystems?: SelectableId[];
  siteThreatenedSpecies?: SelectableId[];
  siteCounties?: SelectableId[];
  siteGrantModelOptions?: SelectableId[];
  siteNativeWoodlandNVCTypes?: SelectableId[];
  paymentOption?: SelectableId;
  siteFieldSites?: SelectableId[];
  siteLandTypes?: SelectableId[];
  siteBreedingTypes?: SelectableId[];
  siteCulturalPractises?: SelectableId[];
  siteDigitalToolsUsed?: SelectableId[];
  siteProposedMethodologies?: SelectableId[];
  siteFarmManagementSystems?: SelectableId[];
  siteIrrigationEnergySources?: SelectableId[];
  siteIrrigationWaterSources?: SelectableId[];
  siteOnFarmBiogasProductions?: SelectableId[];
  siteSoilDrainages?: SelectableId[];
  siteTillageManagement?: SelectableId[];
  siteDungManagementSystems?: SelectableId[];
  siteManagementOfHedges?: SelectableId[];
  siteManagementOfForestEdges?: SelectableId[];
  siteManagementOfSingleTrees?: SelectableId[];
  siteManagementOfTreeLines?: SelectableId[];
  sitePrimaryEnterpriseTypes?: SelectableId[];
  sitePrimaryHabitats?: SelectableId[];
};

export type SiteTemplateField = {
  creatable?: boolean;
  disabled?: boolean;
  id: string;
  i18nKey: string;
  name: string;
  rules?: any;
  section: string | string[];
  selectableType?: SelectableType;
  sortOrder: number;
  unit: "number" | "text" | "text-area" | "currency" | "date" | "multi-select" | "select" | "global-select";
  description?: string;
  placeholder?: string;
};

const baseTemplateFieldsList: SiteTemplateField[] = [
  {
    i18nKey: "Site area (ha)",
    id: "potentialPropertyAreaHectares",
    name: "potentialPropertyAreaHectares",
    rules: {
      min: 0,
    },
    section: ["area", "overview"],
    sortOrder: 1,
    unit: "number",
  },
  {
    i18nKey: "Project area (ha)",
    id: "projectAreaHectares",
    name: "sites.0.projectAreaHectares",
    rules: {
      min: 0,
    },
    section: ["area", "overview"],
    sortOrder: 2,
    unit: "number",
  },
  {
    i18nKey: "Carbon estimation areas",
    id: "numberOfCarbonEstimationAreas",
    name: "sites.0.numberOfCarbonEstimationAreas",
    rules: {
      min: 0,
    },
    section: ["area", "overview"],
    sortOrder: 3,
    unit: "number",
  },
  {
    i18nKey: "Potential CEA",
    id: "numberOfCarbonEstimationAreasAfterFieldStratification",
    name: "sites.0.numberOfCarbonEstimationAreasAfterFieldStratification",
    rules: {
      min: 0,
    },
    section: ["area", "overview"],
    sortOrder: 3.1,
    unit: "number",
  },
  {
    i18nKey: "RAPID CEA",
    id: "numberOfCarbonEstimationAreasDesktopAssessmentFeasibility",
    name: "sites.0.numberOfCarbonEstimationAreasDesktopAssessmentFeasibility",
    rules: {
      min: 0,
    },
    section: ["area", "overview"],
    sortOrder: 3.2,
    unit: "number",
  },
  {
    i18nKey: "Strata",
    id: "numberOfStratas",
    name: "sites.0.numberOfStratas",
    rules: {
      min: 0,
    },
    section: ["area", "overview"],
    sortOrder: 4,
    unit: "number",
  },
  {
    i18nKey: "Sample points",
    id: "numberOfSamplePoints",
    name: "sites.0.numberOfSamplePoints",
    rules: {
      min: 0,
    },
    section: ["area", "overview"],
    sortOrder: 5,
    unit: "number",
  },
  {
    i18nKey: "25 year yield (ACCU/ha)",
    id: "twentyFiveYearYieldACCUPerHectare",
    name: "sites.0.twentyFiveYearYieldACCUPerHectare",
    rules: {
      min: 0,
    },
    section: ["area", "overview"],
    sortOrder: 6,
    unit: "number",
  },
  {
    i18nKey: "Rainfall (mm/year)",
    id: "averageRainfallMM",
    name: "sites.0.averageRainfallMM",
    rules: {
      min: 0,
    },
    section: ["attributes", "overview"],
    sortOrder: 7,
    unit: "number",
  },
  {
    i18nKey: "Total trees",
    id: "totalTreeNumber",
    name: "totalTreeNumber",
    rules: {
      min: 0,
    },
    section: ["attributes", "overview"],
    sortOrder: 8,
    unit: "number",
  },
  {
    i18nKey: "Rotation length (years)",
    id: "rotationLengthYears",
    name: "rotationLengthYears",
    rules: {
      min: 0,
    },
    section: ["attributes", "overview"],
    sortOrder: 9,
    unit: "number",
  },
  {
    i18nKey: "Conifer (%)",
    id: "percentageOfConiferSpecies",
    name: "sites.0.percentageOfConiferSpecies",
    rules: {
      min: 0,
      max: 100,
    },
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 10,
  },
  {
    i18nKey: "Broadleaf (%)",
    id: "percentageOfBroadleafSpecies",
    name: "sites.0.percentageOfBroadleafSpecies",
    rules: {
      min: 0,
      max: 100,
    },
    section: ["attributes", "overview"],
    sortOrder: 11,
    unit: "number",
  },
  {
    i18nKey: "Stocking density (trees/ha)",
    id: "stockingDensityStemsPerHectare",
    name: "sites.0.stockingDensityStemsPerHectare",
    rules: {
      min: 0,
    },
    section: ["attributes", "overview"],
    sortOrder: 12,
    unit: "number",
  },
  {
    i18nKey: "Trees available",
    id: "totalTreesAvailable",
    name: "sites.0.totalTreesAvailable",
    section: ["attributes", "overview"],
    sortOrder: 13,
    unit: "number",
  },
  {
    i18nKey: "Grid reference",
    id: "gridReference",
    name: "sites.0.gridReference",
    section: ["attributes", "overview"],
    sortOrder: 14,
    unit: "text",
  },
  {
    i18nKey: "Nearest town",
    id: "nearestTown",
    name: "sites.0.nearestTown",
    section: ["attributes", "overview"],
    sortOrder: 15,
    unit: "text",
  },
  {
    i18nKey: "Existing forest area (ha)",
    id: "existingForestAreaHectares",
    name: "sites.0.existingForestAreaHectares",
    rules: {
      min: 0,
    },
    section: ["attributes", "overview"],
    sortOrder: 16,
    unit: "number",
  },
  {
    i18nKey: "Purchase contract CO2 (tonnes)",
    id: "contractedCO2Tonnes",
    name: "contractedCO2Tonnes",
    rules: {
      min: 0,
    },
    section: ["attributes", "overview"],
    sortOrder: 17,
    unit: "number",
  },
  {
    i18nKey: "Project CO2 (tonnes)",
    id: "projectCO2ForecastTonnes",
    name: "projectCO2ForecastTonnes",
    rules: {
      min: 0,
    },
    section: ["attributes", "overview"],
    sortOrder: 18,
    unit: "number",
  },
  {
    i18nKey: "Available CO2 (tonnes)",
    id: "availableCO2Tonnes",
    name: "availableCO2Tonnes",
    rules: {
      min: 0,
    },
    section: ["attributes", "overview"],
    sortOrder: 19,
    unit: "number",
  },
  {
    i18nKey: "Permanence (years)",
    id: "permanencePeriodYears",
    name: "permanencePeriodYears",
    rules: {
      min: 0,
      max: 200,
    },
    section: ["attributes", "overview"],
    sortOrder: 20,
    unit: "number",
  },
  {
    i18nKey: "Wind speed (m/s)",
    id: "windSpeedMetersPerSecond",
    name: "sites.0.windSpeedMetersPerSecond",
    rules: {
      min: 0,
    },
    section: ["attributes", "overview"],
    sortOrder: 21,
    unit: "number",
  },
  {
    i18nKey: "Temperature (ºC)",
    id: "temperatureCelsius",
    name: "sites.0.temperatureCelsius",
    rules: {
      max: 99,
    },
    section: ["attributes", "overview"],
    sortOrder: 22,
    unit: "number",
  },
  {
    i18nKey: "Indigenous (%)",
    id: "percentageOfIndigenous",
    name: "sites.0.percentageOfIndigenous",
    rules: {
      min: 0,
      max: 100,
    },
    section: ["attributes", "overview"],
    sortOrder: 23,
    unit: "number",
  },
  {
    i18nKey: "Hardwood (%)",
    id: "percentageOfHardwood",
    name: "sites.0.percentageOfHardwood",
    rules: {
      min: 0,
      max: 100,
    },
    section: ["attributes", "overview"],
    sortOrder: 24,
    unit: "number",
  },
  {
    i18nKey: "Softwood (%)",
    id: "percentageOfSoftwood",
    name: "sites.0.percentageOfSoftwood",
    rules: {
      min: 0,
      max: 100,
    },
    section: ["attributes", "overview"],
    sortOrder: 25,
    unit: "number",
  },
  {
    i18nKey: "Riparian area (ha)",
    id: "riparianAreaHectares",
    name: "sites.0.riparianAreaHectares",
    rules: {
      min: 0,
    },
    section: ["attributes", "overview"],
    sortOrder: 26,
    unit: "number",
  },
  {
    i18nKey: "Wetland area (ha)",
    id: "wetlandAreaHectares",
    name: "sites.0.wetlandAreaHectares",
    rules: {
      min: 0,
    },
    section: ["attributes", "overview"],
    sortOrder: 27,
    unit: "number",
  },
  {
    i18nKey: "Trees/CO2 tonne",
    id: "totalTreesPerCO2Tonne",
    name: "totalTreesPerCO2Tonne",
    rules: {
      min: 0,
    },
    section: ["attributes", "overview"],
    sortOrder: 28,
    unit: "number",
  },
  {
    i18nKey: "Validation only quote",
    id: "validationOnlyQuote",
    name: "validationOnlyQuote",
    section: ["attributes", "overview"],
    sortOrder: 29,
    unit: "currency",
  },
  {
    i18nKey: "CO2 sold (tonnes)",
    id: "soldCO2Tonnes",
    name: "soldCO2Tonnes",
    rules: {
      min: 0,
    },
    section: ["attributes", "overview"],
    sortOrder: 30,
    unit: "number",
  },
  {
    i18nKey: "CO2 on offer (tonnes)",
    id: "onOfferCO2Tonnes",
    name: "onOfferCO2Tonnes",
    rules: {
      min: 0,
    },
    section: ["attributes", "overview"],
    sortOrder: 31,
    unit: "number",
  },
  {
    i18nKey: "Net hectares available",
    id: "netHectaresAvailable",
    name: "sites.0.netHectaresAvailable",
    rules: {
      min: 0,
    },
    section: ["attributes", "overview"],
    sortOrder: 32,
    unit: "number",
  },
  {
    i18nKey: "Total broadleaf trees",
    id: "totalBroadleaf",
    name: "sites.0.totalBroadleaf",
    rules: {
      min: 0,
    },
    section: ["attributes", "overview"],
    sortOrder: 33,
    unit: "number",
  },
  {
    i18nKey: "Total conifer trees",
    id: "totalConifer",
    name: "sites.0.totalConifer",
    rules: {
      min: 0,
    },
    section: ["attributes", "overview"],
    sortOrder: 34,
    unit: "number",
  },
  {
    i18nKey: "Site area soil carbon sequestration (tCO2e/year)",
    id: "siteSoilCarbonSequestrationTonnesCO2eYear",
    name: "sites.0.soilCarbonSequestrationTonnesCO2eYear",
    rules: {
      min: 0,
    },
    section: ["attributes", "overview"],
    sortOrder: 35,
    unit: "number",
  },
  {
    i18nKey: "Project area soil carbon sequestration (tCO2e/year)",
    id: "projectSoilCarbonSequestrationTonnesCO2eYear",
    name: "soilCarbonSequestrationTonnesCO2eYear",
    rules: {
      min: 0,
    },
    section: ["attributes", "overview"],
    sortOrder: 36,
    unit: "number",
  },
  {
    i18nKey: "Site area tree carbon sequestration (tCO2e/year)",
    id: "siteTreeCarbonSequestrationTonnesCO2eYear",
    name: "sites.0.treeCarbonSequestrationTonnesCO2eYear",
    rules: {
      min: 0,
    },
    section: ["attributes", "overview"],
    sortOrder: 37,
    unit: "number",
  },
  {
    i18nKey: "Project area tree carbon sequestration (tCO2e/year)",
    id: "projectTreeCarbonSequestrationTonnesCO2eYear",
    name: "treeCarbonSequestrationTonnesCO2eYear",
    rules: {
      min: 0,
    },
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 38,
  },
  {
    i18nKey: "Riparian management & restoration area (ha)",
    id: "riparianManagementRestorationAreaHectares",
    name: "sites.0.riparianManagementRestorationAreaHectares",
    rules: {
      min: 0,
    },
    section: ["attributes", "overview"],
    sortOrder: 39,
    unit: "number",
  },
  {
    i18nKey: "Remnant vegetation management & restoration area (ha)",
    id: "remnantVegetationManagementRestorationAreaHectares",
    name: "sites.0.remnantVegetationManagementRestorationAreaHectares",
    rules: {
      min: 0,
    },
    section: ["attributes", "overview"],
    sortOrder: 40,
    unit: "number",
  },
  {
    i18nKey: "Actively eroding blanket bog (flat/bare)",
    id: "activelyErodingBlanketBogFlatBareHectares",
    name: "sites.0.activelyErodingBlanketBogFlatBareHectares",
    rules: {
      min: 0,
    },
    section: ["attributes", "overview"],
    sortOrder: 41,
    unit: "number",
  },
  {
    i18nKey: "Actively eroding blanket bog (hagg/gully)",
    id: "activelyErodingBlanketBogHaggGullyHectares",
    name: "sites.0.activelyErodingBlanketBogHaggGullyHectares",
    rules: {
      min: 0,
    },
    section: ["attributes", "overview"],
    sortOrder: 42,
    unit: "number",
  },
  {
    i18nKey: "Drained blanket bog (artificial)",
    id: "drainedBlanketBogArtificialHectares",
    name: "sites.0.drainedBlanketBogArtificialHectares",
    rules: {
      min: 0,
    },
    section: ["attributes", "overview"],
    sortOrder: 43,
    unit: "number",
  },
  {
    i18nKey: "Drained blanket bog (hagg/gully)",
    id: "drainedBlanketBogHaggGullyHectares",
    name: "sites.0.drainedBlanketBogHaggGullyHectares",
    rules: {
      min: 0,
    },
    section: ["attributes", "overview"],
    sortOrder: 44,
    unit: "number",
  },
  {
    i18nKey: "Total predicted emission reductions over project lifetime (tCO2e)",
    id: "totalPredictedEmissionReductionsOverProjectLifetimeTCO2e",
    name: "totalPredictedEmissionReductionsOverProjectLifetimeTCO2e",
    rules: {
      min: 0,
    },
    section: ["attributes", "overview"],
    sortOrder: 45,
    unit: "number",
  },
  {
    i18nKey: "Predicted claimable emission reductions over project lifetime (tCO2e)",
    id: "predictedClaimableEmissionReductionsOverProjectLifetimeTCO2e",
    name: "predictedClaimableEmissionReductionsOverProjectLifetimeTCO2e",
    rules: {
      min: 0,
    },
    unit: "number",
    section: ["attributes", "overview"],
    sortOrder: 46,
  },
  {
    i18nKey: "CO2 Buffer (tonnes)",
    id: "predictedContributionToBufferOverProjectLifetimeTCO2e",
    name: "predictedContributionToBufferOverProjectLifetimeTCO2e",
    rules: {
      min: 0,
    },
    section: ["attributes", "overview"],
    sortOrder: 47,
    unit: "number",
  },
  {
    i18nKey: "Total issuance CO2 (tonnes)",
    id: "totalCarbonIssuanceOverProjectLifetimeTCO2e",
    name: "totalCarbonIssuanceOverProjectLifetimeTCO2e",
    rules: {
      min: 0,
    },
    section: ["attributes", "overview"],
    sortOrder: 48,
    unit: "number",
  },
  {
    i18nKey: "CO2 Net project (tonnes)",
    id: "totalIssuedCarbonOverProjectLifetimeTCO2e",
    name: "totalIssuedCarbonOverProjectLifetimeTCO2e",
    rules: {
      min: 0,
    },
    section: ["attributes", "overview"],
    sortOrder: 49,
    unit: "number",
  },
  {
    i18nKey: "CO2 Bought (tonnes)",
    id: "boughtCO2Tonnes",
    name: "boughtCO2Tonnes",
    rules: {
      min: 0,
    },
    section: ["attributes", "overview"],
    sortOrder: 50,
    unit: "number",
  },
  {
    i18nKey: "Estimated CO2e tonnes/ha (high)",
    id: "maxEstimatedCO2eTonnesPerHectare",
    name: "sites.0.maxEstimatedCO2eTonnesPerHectare",
    rules: {
      min: 0,
    },
    section: ["attributes", "overview"],
    sortOrder: 51,
    unit: "number",
  },
  {
    i18nKey: "Estimated CO2e tonnes/ha (low)",
    id: "minEstimatedCO2eTonnesPerHectare",
    name: "sites.0.minEstimatedCO2eTonnesPerHectare",
    rules: {
      min: 0,
    },
    section: ["attributes", "overview"],
    sortOrder: 52,
    unit: "number",
  },
  {
    i18nKey: "Land management score",
    id: "landManagementScore",
    name: "sites.0.landManagementScore",
    rules: {
      min: 0,
    },
    section: ["attributes", "overview"],
    sortOrder: 53,
    unit: "number",
  },
  {
    i18nKey: "Additionality %",
    id: "additionalCO2SequesteredPercentage",
    name: "additionalCO2SequesteredPercentage",
    rules: {
      min: 0,
      max: 100,
    },
    section: ["attributes", "overview"],
    sortOrder: 54,
    unit: "number",
  },
  {
    i18nKey: "Date of submission Post RV",
    id: "postProjectVerificationDocumentsSubmissionAt",
    name: "postProjectVerificationDocumentsSubmissionAt",
    section: ["attributes", "overview"],
    sortOrder: 55,
    unit: "date",
  },
  {
    i18nKey: "Date of submission Pre RV",
    id: "preProjectVerificationDocumentsSubmissionAt",
    name: "preProjectVerificationDocumentsSubmissionAt",
    section: ["attributes", "overview"],
    sortOrder: 56,
    unit: "date",
  },
  {
    i18nKey: "Interim Crediting Assessment (tC02/ha/yr)",
    id: "interimCreditingAssessmentTCO2HectaresPerYear",
    name: "sites.0.interimCreditingAssessmentTCO2HectaresPerYear",
    rules: {
      min: 0,
    },
    section: ["attributes", "overview"],
    sortOrder: 57,
    unit: "number",
  },
  {
    i18nKey: "Meta Set 1 - Ecological Condition Improvement and Conservation",
    id: "ecologicalConditionImprovementAndConservation",
    name: "sites.0.ecologicalConditionImprovementAndConservationScore",
    section: ["attributes", "overview"],
    sortOrder: 58,
    unit: "number",
  },
  {
    i18nKey: "Meta Set 2 - Securing Assets Necessary for Life",
    id: "securingAssetsNecessaryForLife",
    name: "sites.0.securingAssetsNecessaryForLifeScore",
    section: ["attributes", "overview"],
    sortOrder: 59,
    unit: "number",
  },
  {
    i18nKey: "Meta Set 3 - Social Impact",
    id: "socialImpact",
    name: "sites.0.socialImpactScore",
    section: ["attributes", "overview"],
    sortOrder: 60,
    unit: "number",
  },
  {
    i18nKey: "Meta Set 4 - Context of Regenerative Impact",
    id: "contextOfRegenerativeImpact",
    name: "sites.0.contextOfRegenerativeImpactScore",
    section: ["attributes", "overview"],
    sortOrder: 61,
    unit: "number",
  },
  {
    i18nKey: "Meta Set 5 - Potential to Improve Impact",
    id: "potentialToImproveImpact",
    name: "sites.0.potentialToImproveImpactScore",
    section: ["attributes", "overview"],
    sortOrder: 62,
    unit: "number",
  },
  {
    i18nKey: "Total score contributing to valuation",
    id: "totalScoreContributingToValuation",
    name: "sites.0.totalScoreContributingToValuation",
    section: ["attributes", "overview"],
    sortOrder: 63,
    unit: "number",
  },
  {
    i18nKey: "Change in groundcover (%)",
    id: "changeInGroundcoverPercentage",
    name: "sites.0.changeInGroundcoverPercentage",
    section: ["attributes", "overview"],
    sortOrder: 64,
    unit: "number",
  },
  {
    i18nKey: "Groundcover comparison to local median",
    id: "groundcoverComparisonToLocalMedian",
    name: "sites.0.groundcoverComparisonToLocalMedian",
    section: ["attributes", "overview"],
    sortOrder: 65,
    unit: "number",
  },
  {
    i18nKey: "Groundcover comparison to subregional median",
    id: "groundcoverComparisonToSubregionalMedian",
    name: "sites.0.groundcoverComparisonToSubregionalMedian",
    section: ["attributes", "overview"],
    sortOrder: 66,
    unit: "number",
  },
  {
    i18nKey: "Average area of primary woody vegetation maintained (ha)",
    id: "averageAreaOfPrimaryWoodyVegetationMaintainedHectares",
    name: "sites.0.averageAreaOfPrimaryWoodyVegetationMaintainedHectares",
    rules: {
      min: 0,
    },
    section: ["attributes", "overview"],
    sortOrder: 67,
    unit: "number",
  },
  {
    i18nKey: "Average area of secondary woody vegetation maintained (ha)",
    id: "averageAreaOfSecondaryWoodyVegetationMaintainedHectares",
    name: "sites.0.averageAreaOfSecondaryWoodyVegetationMaintainedHectares",
    rules: {
      min: 0,
    },
    section: ["attributes", "overview"],
    sortOrder: 68,
    unit: "number",
  },
  {
    i18nKey: "Change in secondary woody vegetation (ha)",
    id: "changeInSecondaryWoodyVegetationHectares",
    name: "sites.0.changeInSecondaryWoodyVegetationHectares",
    section: ["attributes", "overview"],
    sortOrder: 69,
    unit: "number",
  },
  {
    i18nKey: "Certified credit area (ha)",
    id: "certifiedCreditAreaHectares",
    name: "sites.0.certifiedCreditAreaHectares",
    rules: {
      min: 0,
    },
    section: ["attributes", "overview"],
    sortOrder: 70,
    unit: "number",
  },
  {
    i18nKey: "Eligible forest area (ha)",
    id: "eligibleForestAreaHectares",
    name: "sites.0.eligibleForestAreaHectares",
    rules: {
      min: 0,
    },
    section: ["attributes", "overview"],
    sortOrder: 71,
    unit: "number",
  },
  {
    i18nKey: "Total forest area (ha)",
    id: "forestAreaHectares",
    name: "sites.0.forestAreaHectares",
    rules: {
      min: 0,
    },
    section: ["attributes", "overview"],
    sortOrder: 72,
    unit: "number",
  },
  {
    i18nKey: "Modified Peatland condition (ha)",
    id: "modifiedPeatlandConditionAreaHectares",
    name: "sites.0.modifiedPeatlandConditionAreaHectares",
    rules: {
      min: 0,
    },
    section: ["attributes", "overview"],
    sortOrder: 73,
    unit: "number",
  },
  {
    i18nKey: "Purchase contract duration (years)",
    id: "purchaseContractDurationYears",
    name: "sites.0.purchaseContractDurationYears",
    rules: {
      min: 0,
    },
    section: ["attributes", "overview"],
    sortOrder: 74,
    unit: "number",
  },
  {
    i18nKey: "Circular biodiversity present score",
    id: "circularBiodiversityPresentScore",
    name: "sites.0.circularBiodiversityPresentScore",
    rules: {
      min: 0,
    },
    section: ["attributes", "overview"],
    sortOrder: 75,
    unit: "number",
  },
  {
    i18nKey: "Circular community economic benefit score",
    id: "circularCommunityEconomicBenefitScore",
    name: "sites.0.circularCommunityEconomicBenefitScore",
    rules: {
      min: 0,
    },
    section: ["attributes", "overview"],
    sortOrder: 76,
    unit: "number",
  },
  {
    i18nKey: "Circular contribution to renewable energy score",
    id: "circularContributionToRenewableEnergyScore",
    name: "sites.0.circularContributionToRenewableEnergyScore",
    rules: {
      min: 0,
    },
    section: ["attributes", "overview"],
    sortOrder: 77,
    unit: "number",
  },
  {
    i18nKey: "Circular cultural heritage score",
    id: "circularCulturalHeritageScore",
    name: "sites.0.circularCulturalHeritageScore",
    rules: {
      min: 0,
    },
    section: ["attributes", "overview"],
    sortOrder: 78,
    unit: "number",
  },
  {
    i18nKey: "Circular expected biodiversity gain score",
    id: "circularExpectedBiodiversityGainScore",
    name: "sites.0.circularExpectedBiodiversityGainScore",
    rules: {
      min: 0,
    },
    section: ["attributes", "overview"],
    sortOrder: 79,
    unit: "number",
  },
  {
    i18nKey: "Circular public access and recreation score",
    id: "circularPublicAccessAndRecreationScore",
    name: "sites.0.circularPublicAccessAndRecreationScore",
    rules: {
      min: 0,
    },
    section: ["attributes", "overview"],
    sortOrder: 80,
    unit: "number",
  },
  {
    i18nKey: "Circular research and education score",
    id: "circularResearchAndEducationScore",
    name: "sites.0.circularResearchAndEducationScore",
    rules: {
      min: 0,
    },
    section: ["attributes", "overview"],
    sortOrder: 81,
    unit: "number",
  },
  {
    i18nKey: "Circular societal economic benefit score",
    id: "circularSocietalEconomicBenefitScore",
    name: "sites.0.circularSocietalEconomicBenefitScore",
    rules: {
      min: 0,
    },
    section: ["attributes", "overview"],
    sortOrder: 82,
    unit: "number",
  },
  {
    i18nKey: "Circular visual amenity score",
    id: "circularVisualAmenityScore",
    name: "sites.0.circularVisualAmenityScore",
    rules: {
      min: 0,
    },
    section: ["attributes", "overview"],
    sortOrder: 83,
    unit: "number",
  },
  {
    i18nKey: "Circular water management score",
    id: "circularWaterManagementScore",
    name: "sites.0.circularWaterManagementScore",
    rules: {
      min: 0,
    },
    section: ["attributes", "overview"],
    sortOrder: 84,
    unit: "number",
  },
  {
    i18nKey: "Baseline quote",
    id: "baselineQuote",
    name: "baselineQuote",
    section: ["attributes", "overview"],
    sortOrder: 85,
    unit: "currency",
  },
  {
    i18nKey: "Age of operating heads",
    id: "ageOfOperatingHeads",
    name: "sites.0.ageOfOperatingHeads",
    rules: {
      min: 0,
    },
    section: ["attributes", "overview"],
    sortOrder: 86,
    unit: "number",
  },
  {
    i18nKey: "Number of farm workers",
    id: "farmWorkersTotal",
    name: "sites.0.farmWorkersTotal",
    rules: {
      min: 0,
    },
    section: ["attributes", "overview"],
    sortOrder: 87,
    unit: "number",
  },
  {
    i18nKey: "Floor coverage (months)",
    id: "monthsOfFloorCoverage",
    name: "sites.0.monthsOfFloorCoverage",
    rules: {
      min: 0,
    },
    section: ["attributes", "overview"],
    sortOrder: 88,
    unit: "number",
  },
  {
    i18nKey: "M.O rate",
    id: "moRate",
    name: "sites.0.moRate",
    rules: {
      min: 1,
      max: 100,
    },
    section: ["attributes", "overview"],
    sortOrder: 89,
    unit: "number",
  },
  {
    i18nKey: "SIREN",
    id: "siren",
    name: "sites.0.siren",
    section: ["attributes", "overview"],
    sortOrder: 90,
    unit: "text",
  },
  {
    i18nKey: "Organic material rate",
    id: "organicMaterialRate",
    name: "sites.0.organicMaterialRate",
    rules: {
      min: 0,
    },
    section: ["attributes", "overview"],
    sortOrder: 91,
    unit: "number",
  },
  {
    i18nKey: "Altitude",
    id: "altitude",
    name: "sites.0.altitude",
    rules: {
      min: 0,
    },
    section: ["attributes", "overview"],
    sortOrder: 92,
    unit: "number",
  },
  {
    i18nKey: "Soil coverage (months)",
    id: "soilCoverageMonths",
    name: "sites.0.soilCoverageMonths",
    rules: {
      min: 0,
    },
    section: ["attributes", "overview"],
    sortOrder: 93,
    unit: "number",
  },
  {
    i18nKey: "Collars",
    id: "collars",
    name: "sites.0.collars",
    rules: {
      min: 0,
    },
    section: ["attributes", "overview"],
    sortOrder: 94,
    unit: "number",
  },
  {
    i18nKey: "Animal mass (kg)",
    id: "animalMassKG",
    name: "sites.0.animalMassKG",
    rules: {
      min: 0,
    },
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 95,
  },
  {
    i18nKey: "Accessible acreage (private)",
    id: "accessibleAcreagePrivate",
    name: "sites.0.accessibleAcreagePrivate",
    rules: {
      min: 0,
    },
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 96,
  },
  {
    i18nKey: "Accessible acreage (state)",
    id: "accessibleAcreageState",
    name: "sites.0.accessibleAcreageState",
    rules: {
      min: 0,
    },
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 97,
  },
  {
    i18nKey: "Accessible acreage (BLM/USFS)",
    id: "accessibleAcreageBlmUsfs",
    name: "sites.0.accessibleAcreageBlmUsfs",
    rules: {
      min: 0,
    },
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 98,
  },
  {
    i18nKey: "Accessible acreage (unknown/other)",
    id: "accessibleAcreageUnknownOther",
    name: "sites.0.accessibleAcreageUnknownOther",
    rules: {
      min: 0,
    },
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 99,
  },
  {
    i18nKey: "Days in growing season",
    id: "daysInGrowingSeason",
    name: "sites.0.daysInGrowingSeason",
    rules: {
      min: 0,
    },
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 100,
  },
  {
    i18nKey: "Days cattle support by buffer area",
    id: "daysCattleSupportByBufferArea",
    name: "sites.0.daysCattleSupportByBufferArea",
    rules: {
      min: 0,
    },
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 101,
  },
  {
    i18nKey: "Depth to rock (cm)",
    id: "depthToRockCM",
    name: "sites.0.depthToRockCM",
    rules: {
      min: 0,
    },
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 102,
  },
  {
    i18nKey: "Depth of sample (cm)",
    id: "depthToSampleCM",
    name: "sites.0.depthToSampleCM",
    rules: {
      min: 0,
    },
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 103,
  },
  {
    i18nKey: "Number of cows",
    id: "numberOfCows",
    name: "sites.0.numberOfCows",
    rules: {
      min: 0,
    },
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 104,
  },
  {
    i18nKey: "Number of paddocks",
    id: "numberOfPaddocks",
    name: "sites.0.numberOfPaddocks",
    rules: {
      min: 0,
    },
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 105,
  },
  {
    i18nKey: "Number of fires",
    id: "numberOfFires",
    name: "sites.0.numberOfFires",
    rules: {
      min: 0,
    },
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 106,
  },
  {
    i18nKey: "Rotations per season",
    id: "rotationsPerSeason",
    name: "sites.0.rotationsPerSeason",
    rules: {
      min: 0,
    },
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 107,
  },
  {
    i18nKey: "Claimable CO2 (tonnes/ha)",
    id: "claimableCO2Tonnes",
    name: "sites.0.claimableCO2Tonnes",
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 108,
  },
  {
    i18nKey: "Animals in pasture (days)",
    id: "animalsInPastureDays",
    name: "sites.0.animalsInPastureDays",
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 109,
  },
  {
    i18nKey: "Planted (%)",
    id: "plantedPercentage",
    name: "sites.0.plantedPercentage",
    rules: {
      min: 0,
      max: 100,
    },
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 110,
  },
  {
    i18nKey: "Area preserved (ha)",
    id: "areaPreservedHectares",
    name: "sites.0.areaPreservedHectares",
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 111,
  },
  {
    i18nKey: "Preserved (%)",
    id: "preservedPercentage",
    name: "sites.0.preservedPercentage",
    rules: {
      min: 0,
      max: 100,
    },
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 112,
  },
  {
    i18nKey: "Eucalyptus saligna (%)",
    id: "eucalyptusSalignaPercentage",
    name: "sites.0.eucalyptusSalignaPercentage",
    rules: {
      min: 0,
      max: 100,
    },
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 113,
  },
  {
    i18nKey: "Eucalyptus urograndis (%)",
    id: "eucalyptusUrograndisPercentage",
    name: "sites.0.eucalyptusUrograndisPercentage",
    rules: {
      min: 0,
      max: 100,
    },
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 114,
  },
  {
    i18nKey: "Proposed start date",
    id: "proposedStartDate",
    name: "sites.0.proposedStartDate",
    section: ["attributes", "overview"],
    unit: "date",
    sortOrder: 115,
  },
  {
    i18nKey: "Closing date of the accounting balance",
    id: "closingDateOfTheAccountingBalance",
    name: "sites.0.closingDateOfTheAccountingBalance",
    section: ["attributes", "overview"],
    unit: "date",
    sortOrder: 116,
  },
  {
    i18nKey: "Diesel consumption (L/year)",
    id: "dieselConsumptionLitersPerYear",
    name: "sites.0.dieselConsumptionLitersPerYear",
    rules: {
      min: 0,
    },
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 117,
  },
  {
    i18nKey: "Essence consumption (L/year)",
    id: "essenceConsumptionLitersPerYear",
    name: "sites.0.essenceConsumptionLitersPerYear",
    rules: {
      min: 0,
    },
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 118,
  },
  {
    i18nKey: "Electricity consumption (kW/year)",
    id: "electricityConsumptionKilowattPerYear",
    name: "sites.0.electricityConsumptionKilowattPerYear",
    rules: {
      min: 0,
    },
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 119,
  },
  {
    i18nKey: "Total water consumption (L/year)",
    id: "totalWaterConsumptionLitersPerYear",
    name: "sites.0.totalWaterConsumptionLitersPerYear",
    rules: {
      min: 0,
    },
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 120,
  },
  {
    i18nKey: "Total water consumption for irrigation (L/year)",
    id: "totalWaterConsumptionForIrrigationLitersPerYear",
    name: "sites.0.totalWaterConsumptionForIrrigationLitersPerYear",
    rules: {
      min: 0,
    },
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 121,
  },
  {
    i18nKey: "Product quantity refrigerated (Kg/year)",
    id: "productQuantityRefrigeratedKilogramsPerYear",
    name: "sites.0.productQuantityRefrigeratedKilogramsPerYear",
    rules: {
      min: 0,
    },
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 122,
  },
  {
    i18nKey: "Quantity of soja bought (tonnes/year)",
    id: "quantityOfSojaBoughtTonnesYear",
    name: "sites.0.quantityOfSojaBoughtTonnesYear",
    rules: {
      min: 0,
    },
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 123,
  },
  {
    i18nKey: "Number of plant types for cover crops",
    id: "numberOfPlantTypesForCoverCrops",
    name: "sites.0.numberOfPlantTypesForCoverCrops",
    rules: {
      min: 0,
    },
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 124,
  },
  {
    i18nKey: "Average presence of cover crops (month/year)",
    id: "averagePresenceOfCoverCrops",
    name: "sites.0.averagePresenceOfCoverCrops",
    rules: {
      min: 0,
    },
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 125,
  },
  {
    i18nKey: "Seed independence (%)",
    id: "percentageOfSeedIndependence",
    name: "sites.0.percentageOfSeedIndependence",
    rules: {
      min: 0,
      max: 100,
    },
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 126,
  },
  {
    i18nKey: "Surface irrigated (%)",
    id: "percentageOfSurfaceIrrigated",
    name: "sites.0.percentageOfSurfaceIrrigated",
    rules: {
      min: 0,
      max: 100,
    },
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 127,
  },
  {
    i18nKey: "Cultivated tree zone (ha)",
    id: "cultivatedTreeZoneHectares",
    name: "sites.0.cultivatedTreeZoneHectares",
    rules: {
      min: 0,
    },
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 128,
  },
  {
    i18nKey: "Vulnerable zones (ha)",
    id: "vulnerableZonesHectares",
    name: "sites.0.vulnerableZonesHectares",
    rules: {
      min: 0,
    },
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 129,
  },
  {
    i18nKey: "Surface agrivoltaics (ha)",
    id: "surfaceAgrivoltaicsHectares",
    name: "sites.0.surfaceAgrivoltaicsHectares",
    rules: {
      min: 0,
    },
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 130,
  },
  {
    i18nKey: "Surface photovoltaic (ha)",
    id: "surfacePhotovoltaicHectares",
    name: "sites.0.surfacePhotovoltaicHectares",
    rules: {
      min: 0,
    },
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 131,
  },
  {
    i18nKey: "Total farm revenue (per year)",
    id: "totalFarmRevenuePerYear",
    name: "sites.0.totalFarmRevenuePerYear",
    section: ["attributes", "overview"],
    unit: "currency",
    sortOrder: 132,
  },
  {
    i18nKey: "Total revenue from direct sales (per year)",
    id: "totalRevenueFromDirectSalesPerYear",
    name: "sites.0.totalRevenueFromDirectSalesPerYear",
    section: ["attributes", "overview"],
    unit: "currency",
    sortOrder: 133,
  },
  {
    i18nKey: "Expenses for synthetic fertiliser (per year)",
    id: "expensesForSyntheticFertiliserPerYear",
    name: "sites.0.expensesForSyntheticFertiliserPerYear",
    section: ["attributes", "overview"],
    unit: "currency",
    sortOrder: 134,
  },
  {
    i18nKey: "Alder (%)",
    id: "percentageOfAlder",
    name: "sites.0.percentageOfAlder",
    rules: {
      min: 0,
      max: 100,
    },
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 135,
  },
  {
    i18nKey: "Ash (%)",
    id: "percentageOfAsh",
    name: "sites.0.percentageOfAsh",
    rules: {
      min: 0,
      max: 100,
    },
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 136,
  },
  {
    i18nKey: "Aspen (%)",
    id: "percentageOfAspen",
    name: "sites.0.percentageOfAspen",
    rules: {
      min: 0,
      max: 100,
    },
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 137,
  },
  {
    i18nKey: "Beech (%)",
    id: "percentageOfBeech",
    name: "sites.0.percentageOfBeech",
    rules: {
      min: 0,
      max: 100,
    },
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 138,
  },
  {
    i18nKey: "Birch (%)",
    id: "percentageOfBirch",
    name: "sites.0.percentageOfBirch",
    rules: {
      min: 0,
      max: 100,
    },
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 139,
  },
  {
    i18nKey: "Cherry (%)",
    id: "percentageOfCherry",
    name: "sites.0.percentageOfCherry",
    rules: {
      min: 0,
      max: 100,
    },
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 140,
  },
  {
    i18nKey: "Douglas fir (%)",
    id: "percentageOfDouglasFir",
    name: "sites.0.percentageOfDouglasFir",
    rules: {
      min: 0,
      max: 100,
    },
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 141,
  },
  {
    i18nKey: "Eucalyptus (%)",
    id: "percentageOfEucalyptus",
    name: "sites.0.percentageOfEucalyptus",
    rules: {
      min: 0,
      max: 100,
    },
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 142,
  },
  {
    i18nKey: "Hawthorn (%)",
    id: "percentageOfHawthorn",
    name: "sites.0.percentageOfHawthorn",
    rules: {
      min: 0,
      max: 100,
    },
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 143,
  },
  {
    i18nKey: "Hazel (%)",
    id: "percentageOfHazel",
    name: "sites.0.percentageOfHazel",
    rules: {
      min: 0,
      max: 100,
    },
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 144,
  },
  {
    i18nKey: "Holly (%)",
    id: "percentageOfHolly",
    name: "sites.0.percentageOfHolly",
    rules: {
      min: 0,
      max: 100,
    },
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 145,
  },
  {
    i18nKey: "Juniper (%)",
    id: "percentageOfJuniper",
    name: "sites.0.percentageOfJuniper",
    rules: {
      min: 0,
      max: 100,
    },
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 146,
  },
  {
    i18nKey: "Larch (%)",
    id: "percentageOfLarch",
    name: "sites.0.percentageOfLarch",
    rules: {
      min: 0,
      max: 100,
    },
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 147,
  },
  {
    i18nKey: "Maple (%)",
    id: "percentageOfMaple",
    name: "sites.0.percentageOfMaple",
    rules: {
      min: 0,
      max: 100,
    },
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 148,
  },
  {
    i18nKey: "Norway spruce (%)",
    id: "percentageOfNorwaySpruce",
    name: "sites.0.percentageOfNorwaySpruce",
    rules: {
      min: 0,
      max: 100,
    },
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 149,
  },
  {
    i18nKey: "Oak (%)",
    id: "percentageOfOak",
    name: "sites.0.percentageOfOak",
    rules: {
      min: 0,
      max: 100,
    },
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 150,
  },
  {
    i18nKey: "Other broadleaf (%)",
    id: "percentageOfOtherBroadleaf",
    name: "sites.0.percentageOfOtherBroadleaf",
    rules: {
      min: 0,
      max: 100,
    },
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 151,
  },
  {
    i18nKey: "Other conifer (%)",
    id: "percentageOfOtherConifer",
    name: "sites.0.percentageOfOtherConifer",
    rules: {
      min: 0,
      max: 100,
    },
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 152,
  },
  {
    i18nKey: "Rowan (%)",
    id: "percentageOfRowan",
    name: "sites.0.percentageOfRowan",
    rules: {
      min: 0,
      max: 100,
    },
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 153,
  },
  {
    i18nKey: "Scots pine (%)",
    id: "percentageOfScotsPine",
    name: "sites.0.percentageOfScotsPine",
    rules: {
      min: 0,
      max: 100,
    },
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 154,
  },
  {
    i18nKey: "Sitka spruce (%)",
    id: "percentageOfSitkaSpruce",
    name: "sites.0.percentageOfSitkaSpruce",
    rules: {
      min: 0,
      max: 100,
    },
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 155,
  },
  {
    i18nKey: "Sycamore (%)",
    id: "percentageOfSycamore",
    name: "sites.0.percentageOfSycamore",
    rules: {
      min: 0,
      max: 100,
    },
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 156,
  },
  {
    i18nKey: "Western red cedar (%)",
    id: "percentageOfWesternRedCedar",
    name: "sites.0.percentageOfWesternRedCedar",
    rules: {
      min: 0,
      max: 100,
    },
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 157,
  },
  {
    i18nKey: "Willow (%)",
    id: "percentageOfWillow",
    name: "sites.0.percentageOfWillow",
    rules: {
      min: 0,
      max: 100,
    },
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 158,
  },
  {
    i18nKey: "Validation date",
    id: "validationDate",
    name: "sites.0.validationDate",
    section: ["attributes", "overview"],
    unit: "date",
    sortOrder: 159,
  },
  {
    i18nKey: "Next verification date",
    id: "nextVerificationDate",
    name: "sites.0.nextVerificationDate",
    section: ["attributes", "overview"],
    unit: "date",
    sortOrder: 160,
  },
  {
    i18nKey: "Total amount of synthetic nitrogen applied to soils (kg)",
    id: "totalAmountOfSyntheticNitrogenAppliedToSoilsKg",
    name: "sites.0.totalAmountOfSyntheticNitrogenAppliedToSoilsKg",
    rules: {
      min: 0,
    },
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 161,
  },
  {
    i18nKey: "Total amount of organic nitrogen applied to soils (kg)",
    id: "totalAmountOfOrganicNitrogenAppliedToSoilsKg",
    name: "sites.0.totalAmountOfOrganicNitrogenAppliedToSoilsKg",
    rules: {
      min: 0,
    },
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 162,
  },
  {
    i18nKey: "Quantity of feed bought (t/year)",
    id: "quantityOfFeedBoughtTonnesPerYear",
    name: "sites.0.quantityOfFeedBoughtTonnesPerYear",
    rules: {
      min: 0,
    },
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 163,
  },
  {
    i18nKey: "Tree volume (m3/ha)",
    id: "treeVolumeM3HA",
    name: "sites.0.treeVolumeM3HA",
    rules: {
      min: 0,
    },
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 164,
  },
  {
    i18nKey: "Average annual tree volume increase (m3/ha/year)",
    id: "averageAnnualIncreaseM3HAPerYear",
    name: "sites.0.averageAnnualIncreaseM3HAPerYear",
    rules: {
      min: 0,
    },
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 165,
  },
  {
    i18nKey: "Tree diameter (cm)",
    id: "treeDiameterCM",
    name: "sites.0.treeDiameterCM",
    rules: {
      min: 0,
    },
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 166,
  },
  {
    i18nKey: "Tree height (m)",
    id: "treeHeightMeters",
    name: "sites.0.treeHeightMeters",
    rules: {
      min: 0,
    },
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 167,
  },
  {
    i18nKey: "Other enterprise type",
    id: "otherEnterpriseType",
    name: "sites.0.otherEnterpriseType",
    section: ["attributes", "overview"],
    unit: "text",
    sortOrder: 168,
  },
  {
    i18nKey: "Lot numbers",
    id: "lotNumbers",
    name: "sites.0.lotNumbers",
    section: ["attributes", "overview"],
    unit: "text",
    sortOrder: 168.01,
  },
  {
    i18nKey: "Number of plant varieties for alfalfa",
    id: "numberOfPlantVarietiesForAlfalfa",
    name: "sites.0.numberOfPlantVarietiesForAlfalfa",
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 168.02,
  },
  {
    i18nKey: "Number of plant varieties for barley",
    id: "numberOfPlantVarietiesForBarley",
    name: "sites.0.numberOfPlantVarietiesForBarley",
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 168.03,
  },
  {
    i18nKey: "Number of plant varieties for beans and pulses",
    id: "numberOfPlantVarietiesForBeansAndPulses",
    name: "sites.0.numberOfPlantVarietiesForBeansAndPulses",
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 168.04,
  },
  {
    i18nKey: "Number of plant varieties for clover",
    id: "numberOfPlantVarietiesForClover",
    name: "sites.0.numberOfPlantVarietiesForClover",
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 168.041,
  },
  {
    i18nKey: "Number of plant varieties for drybean",
    id: "numberOfPlantVarietiesForDrybean",
    name: "sites.0.numberOfPlantVarietiesForDrybean",
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 168.05,
  },
  {
    i18nKey: "Number of plant varieties for maize",
    id: "numberOfPlantVarietiesForMaize",
    name: "sites.0.numberOfPlantVarietiesForMaize",
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 168.06,
  },
  {
    i18nKey: "Number of plant varieties for millet",
    id: "numberOfPlantVarietiesForMillet",
    name: "sites.0.numberOfPlantVarietiesForMillet",
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 168.07,
  },
  {
    i18nKey: "Number of plant varieties for oats",
    id: "numberOfPlantVarietiesForOats",
    name: "sites.0.numberOfPlantVarietiesForOats",
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 168.08,
  },
  {
    i18nKey: "Number of plant varieties for other forages including perennial grasses and clover pastures",
    id: "numberOfPlantVarietiesForOtherForagesIncludingPerennialGrassesAndCloverPastures",
    name: "sites.0.numberOfPlantVarietiesForOtherForagesIncludingPerennialGrassesAndCloverPastures",
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 168.09,
  },
  {
    i18nKey: "Number of plant varieties for other N-fixing forage crops",
    id: "numberOfPlantVarietiesForOtherNFixingForageCrops",
    name: "sites.0.numberOfPlantVarietiesForOtherNFixingForageCrops",
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 168.11,
  },
  {
    i18nKey: "Number of plant varieties for other non N-fixing grain crops",
    id: "numberOfPlantVarietiesForOtherNonNFixingGrainCrops",
    name: "sites.0.numberOfPlantVarietiesForOtherNonNFixingGrainCrops",
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 168.12,
  },
  {
    i18nKey: "Number of plant varieties for other root crops",
    id: "numberOfPlantVarietiesForOtherRootCrops",
    name: "sites.0.numberOfPlantVarietiesForOtherRootCrops",
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 168.13,
  },
  {
    i18nKey: "Number of plant varieties for potatoes",
    id: "numberOfPlantVarietiesForPotatoes",
    name: "sites.0.numberOfPlantVarietiesForPotatoes",
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 168.14,
  },
  {
    i18nKey: "Number of plant varieties for rapeseed",
    id: "numberOfPlantVarietiesForRapeseed",
    name: "sites.0.numberOfPlantVarietiesForRapeseed",
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 168.15,
  },
  {
    i18nKey: "Number of plant varieties for rice",
    id: "numberOfPlantVarietiesForRice",
    name: "sites.0.numberOfPlantVarietiesForRice",
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 168.16,
  },
  {
    i18nKey: "Number of plant varieties for rye",
    id: "numberOfPlantVarietiesForRye",
    name: "sites.0.numberOfPlantVarietiesForRye",
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 168.17,
  },
  {
    i18nKey: "Number of plant varieties for sorghum",
    id: "numberOfPlantVarietiesForSorghum",
    name: "sites.0.numberOfPlantVarietiesForSorghum",
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 168.18,
  },
  {
    i18nKey: "Number of plant varieties for soybean",
    id: "numberOfPlantVarietiesForSoybean",
    name: "sites.0.numberOfPlantVarietiesForSoybean",
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 168.19,
  },
  {
    i18nKey: "Number of plant varieties for spring wheat",
    id: "numberOfPlantVarietiesForSpringWheat",
    name: "sites.0.numberOfPlantVarietiesForSpringWheat",
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 168.21,
  },
  {
    i18nKey: "Number of plant varieties for sunflower",
    id: "numberOfPlantVarietiesForSunflower",
    name: "sites.0.numberOfPlantVarietiesForSunflower",
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 168.22,
  },
  {
    i18nKey: "Number of plant varieties for wheat",
    id: "numberOfPlantVarietiesForWheat",
    name: "sites.0.numberOfPlantVarietiesForWheat",
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 168.23,
  },
  {
    i18nKey: "Number of plant varieties for winter wheat",
    id: "numberOfPlantVarietiesForWinterWheat",
    name: "sites.0.numberOfPlantVarietiesForWinterWheat",
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 168.24,
  },
  {
    i18nKey: "Estimated price/tonne",
    id: "estimatedPricePerTonne",
    name: "sites.0.estimatedPricePerTonne",
    section: ["attributes", "overview"],
    unit: "currency",
    sortOrder: 168.25,
  },
  {
    i18nKey: "Yield (tCO2/ha)",
    id: "yieldTCO2HA",
    name: "sites.0.yieldTCO2HA",
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 168.26,
  },
  {
    i18nKey: "ACCUs per annum",
    id: "accusPerAnnum",
    name: "sites.0.accusPerAnnum",
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 168.27,
  },
  {
    i18nKey: "Land cost (per ha)",
    id: "landCostPricePerHa",
    name: "sites.0.landCostPricePerHa",
    section: ["attributes", "overview"],
    unit: "currency",
    sortOrder: 168.28,
  },
  {
    i18nKey: "Investment capital",
    id: "investmentCapital",
    name: "sites.0.investmentCapital",
    section: ["attributes", "overview"],
    unit: "currency",
    sortOrder: 168.29,
  },
  {
    i18nKey: "Investment capital per ACCU",
    id: "investmentCapitalPerACCU",
    name: "sites.0.investmentCapitalPerACCU",
    section: ["attributes", "overview"],
    unit: "currency",
    sortOrder: 168.29,
  },
  {
    i18nKey: "IRR hurdle",
    id: "irrHurdle",
    name: "sites.0.irrHurdle",
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 168.31,
  },
  {
    i18nKey: "Investor/Landowner",
    id: "investorLandholder",
    name: "sites.0.investorLandholderSelectableId",
    section: ["attributes", "overview"],
    selectableType: "investor_landholder",
    unit: "select",
    sortOrder: 168.32,
  },
  {
    i18nKey: "Registered claimable PIUs",
    id: "registeredClaimablePIUs",
    name: "sites.0.registeredClaimablePIUs",
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 168.33,
  },
  {
    i18nKey: "Registered year",
    id: "registeredYear",
    name: "sites.0.registeredYear",
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 168.34,
  },
  {
    i18nKey: "Project start year (the year planting finishes)",
    id: "projectStartYear",
    name: "sites.0.projectStartYear",
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 168.35,
  },
  {
    i18nKey: "Area planted (Exotic)",
    id: "areaPlantedBySpeciesExoticHectares",
    name: "sites.0.areaPlantedBySpeciesExoticHectares",
    section: ["area", "overview"],
    unit: "number",
    sortOrder: 168.36,
  },
  {
    i18nKey: "Area planted (Indigenous)",
    id: "areaPlantedBySpeciesIndigenousHectares",
    name: "sites.0.areaPlantedBySpeciesIndigenousHectares",
    section: ["area", "overview"],
    unit: "number",
    sortOrder: 168.37,
  },
  {
    i18nKey: "Area to be planted (Exotic)",
    id: "areaToBePlantedExoticHectares",
    name: "sites.0.areaToBePlantedExoticHectares",
    section: ["area", "overview"],
    unit: "number",
    sortOrder: 168.38,
  },
  {
    i18nKey: "Area to be planted (Indigenous)",
    id: "areaToBePlantedIndigenousHectares",
    name: "sites.0.areaToBePlantedIndigenousHectares",
    section: ["area", "overview"],
    unit: "number",
    sortOrder: 168.39,
  },
  {
    i18nKey: "Number of stems planted (Exotic)",
    id: "totalStemsPlantedBySpeciesExotic",
    name: "sites.0.totalStemsPlantedBySpeciesExotic",
    section: ["area", "overview"],
    unit: "number",
    sortOrder: 168.41,
  },
  {
    i18nKey: "Number of stems planted (Indigenous)",
    id: "totalStemsPlantedBySpeciesIndigenous",
    name: "sites.0.totalStemsPlantedBySpeciesIndigenous",
    section: ["area", "overview"],
    unit: "number",
    sortOrder: 168.4,
  },
  {
    i18nKey: "Number of stems to be planted (Exotic)",
    id: "totalStemsToBePlantedBySpeciesExotic",
    name: "sites.0.totalStemsToBePlantedBySpeciesExotic",
    section: ["area", "overview"],
    unit: "number",
    sortOrder: 168.42,
  },
  {
    i18nKey: "Number of stems to be planted (Indigenous)",
    id: "totalStemsToBePlantedBySpeciesIndigenous",
    name: "sites.0.totalStemsToBePlantedBySpeciesIndigenous",
    section: ["area", "overview"],
    unit: "number",
    sortOrder: 168.43,
  },
  {
    i18nKey: "Estimated annual emission reductions (tCO2e)",
    id: "estimatedAnnualEmissionReductionstCO2e",
    name: "sites.0.estimatedAnnualEmissionReductionstCO2e",
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 168.44,
  },
  {
    i18nKey: "Distance to major urban centre (kms)",
    id: "distanceToMajorUrbanCentreKMs",
    name: "sites.0.distanceToMajorUrbanCentreKMs",
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 168.45,
  },
  {
    i18nKey: "Total volume (20 years)",
    id: "totalVolumeTwentyYears",
    name: "sites.0.totalVolumeTwentyYears",
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 168.46,
  },
  {
    i18nKey: "Volume offered per year",
    id: "volumeOfferedPerYear",
    name: "sites.0.volumeOfferedPerYear",
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 168.47,
  },
  {
    i18nKey: "Price per year",
    id: "pricePerYear",
    name: "sites.0.pricePerYear",
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 168.48,
  },
  {
    i18nKey: "Country",
    id: "countryCode",
    name: "sites.0.countryCode",
    section: ["attributes", "overview"],
    unit: "text",
    sortOrder: 168.49,
  },
  {
    i18nKey: "Free form",
    id: "freeForm",
    name: "sites.0.freeForm",
    section: ["attributes", "overview"],
    unit: "text-area",
    sortOrder: 168.51,
  },
  {
    i18nKey: "Implementation date",
    id: "implementationDate",
    name: "sites.0.implementationDate",
    section: ["attributes", "overview"],
    unit: "date",
    sortOrder: 168.52,
  },
  {
    i18nKey: "Account holder",
    id: "accountHolder",
    name: "sites.0.accountHolder",
    section: ["attributes", "overview"],
    unit: "text",
    sortOrder: 168.53,
  },
  {
    i18nKey: "Registry account",
    id: "registryAccount",
    name: "sites.0.registryAccount",
    section: ["attributes", "overview"],
    unit: "text",
    sortOrder: 168.54,
  },
  {
    i18nKey: "Proposed project type",
    id: "proposedProjectType",
    name: "sites.0.proposedProjectType",
    section: ["attributes", "overview"],
    unit: "text",
    sortOrder: 168.55,
  },
  {
    i18nKey: "Area Registered (ha)",
    id: "areaRegisteredHectares",
    name: "sites.0.areaRegisteredHectares",
    section: ["area", "overview"],
    unit: "number",
    sortOrder: -3,
  },
  {
    i18nKey: "Area Submitted (ha)",
    id: "areaSubmittedHectares",
    name: "sites.0.areaSubmittedHectares",
    section: ["area", "overview"],
    unit: "number",
    sortOrder: -2,
  },
  {
    i18nKey: "Potential Area (ha)",
    id: "potentialAreaHectares",
    name: "sites.0.potentialAreaHectares",
    section: ["area", "overview"],
    unit: "number",
    sortOrder: -3,
  },
  {
    i18nKey: "Business unit",
    id: "businessUnit",
    name: "sites.0.businessUnitSelectableId",
    section: ["attributes", "overview"],
    unit: "select",
    selectableType: "business_unit",
    sortOrder: 168.59,
  },
  {
    i18nKey: "Land Tenure",
    id: "landTenure",
    name: "sites.0.landTenure",
    section: ["attributes", "overview"],
    unit: "text",
    sortOrder: 168.61,
  },
  {
    i18nKey: "Native title holder",
    id: "nativeTitleHolder",
    name: "sites.0.nativeTitleHolder",
    section: ["attributes", "overview"],
    unit: "text",
    sortOrder: 168.63,
  },
  {
    i18nKey: "Risk score",
    id: "riskScore",
    name: "sites.0.riskScore",
    section: "attributes",
    unit: "number",
    sortOrder: 168.64,
  },
  {
    i18nKey: "NDC score",
    id: "ndcScore",
    name: "sites.0.ndcScore",
    section: "attributes",
    unit: "number",
    sortOrder: 168.65,
  },
  {
    i18nKey: "REDD+ readiness",
    id: "reddReadiness",
    name: "sites.0.reddReadiness",
    section: "attributes",
    unit: "number",
    sortOrder: 168.66,
  },
  {
    i18nKey: "Restore analysis",
    id: "restoreAnalysis",
    name: "sites.0.restoreAnalysis",
    section: "attributes",
    unit: "text",
    sortOrder: 168.67,
  },
  {
    i18nKey: "IUCN Red List species/Hotspot/KBA",
    id: "iucnRedListSpeciesHotspotKBA",
    name: "sites.0.iucnRedListSpeciesHotspotKBA",
    section: "attributes",
    unit: "text",
    sortOrder: 168.68,
  },
  {
    i18nKey: "Community engagement and participation",
    id: "communityEngagementAndParticipation",
    name: "sites.0.communityEngagementAndParticipation",
    section: "attributes",
    unit: "text",
    sortOrder: 168.69,
  },
  {
    i18nKey: "FPIC status",
    id: "fpicStatus",
    name: "sites.0.fpicStatus",
    section: "attributes",
    unit: "text",
    sortOrder: 168.71,
  },
  {
    i18nKey: "Is there any benefit share mechanism in place",
    id: "benefitShareMechanism",
    name: "sites.0.benefitShareMechanism",
    section: "attributes",
    unit: "text",
    sortOrder: 168.72,
  },
  {
    i18nKey: "Ownership",
    id: "ownership",
    name: "sites.0.ownership",
    section: "attributes",
    unit: "text",
    sortOrder: 168.73,
  },
  {
    i18nKey: "Stakeholders",
    id: "stakeholders",
    name: "sites.0.stakeholders",
    section: "attributes",
    unit: "text",
    sortOrder: 168.74,
  },
  {
    i18nKey: "Local implementation partners",
    id: "localImplementationPartners",
    name: "sites.0.localImplementationPartners",
    section: "attributes",
    unit: "text",
    sortOrder: 168.75,
  },
  {
    i18nKey: "Property size (ha)",
    id: "propertySizeHectares",
    name: "sites.0.propertySizeHectares",
    section: "area",
    unit: "number",
    sortOrder: 168.76,
  },
  {
    creatable: false,
    i18nKey: "Advisor name",
    id: "projectAdvisorName",
    name: "projectAdvisorNameSelectableId",
    section: ["attributes", "overview"],
    selectableType: "project_advisor_name",
    unit: "select",
    sortOrder: 209,
  },
  {
    creatable: false,
    i18nKey: "Host name",
    id: "projectHostName",
    name: "projectHostNameSelectableId",
    section: ["attributes", "overview"],
    selectableType: "project_host_name",
    unit: "select",
    sortOrder: 210,
  },
  {
    i18nKey: "Application number",
    id: "applicationNumber",
    name: "sites.0.applicationNumber",
    section: ["attributes", "overview"],
    unit: "text",
    sortOrder: 211,
  },
  {
    i18nKey: "Holding account number",
    id: "holdingAccountNumber",
    name: "sites.0.holdingAccountNumber",
    section: ["attributes", "overview"],
    unit: "text",
    sortOrder: 212,
  },
  {
    i18nKey: "Average max biomass",
    id: "averageMaxBiomass",
    name: "sites.0.averageMaxBiomass",
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 213,
  },
  {
    i18nKey: "Sales price",
    id: "salesPrice",
    name: "sites.0.salesPrice",
    section: ["financials", "overview"],
    unit: "currency",
    sortOrder: 214,
  },
  {
    i18nKey: "Land value",
    id: "landValue",
    name: "sites.0.landValue",
    section: ["financials", "overview"],
    unit: "currency",
    sortOrder: 215,
  },
  {
    i18nKey: "Median Rainfall (mm/year)",
    id: "medianRainfallMMPerYear",
    name: "sites.0.medianRainfallMMPerYear",
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 215,
  },
  {
    i18nKey: "Expected number of jobs created (individuals)",
    id: "expectedNumberOfJobsCreatedIndividuals",
    name: "sites.0.expectedNumberOfJobsCreatedIndividuals",
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 216,
  },
  {
    i18nKey: "Expected number of jobs filled by women (individuals)",
    id: "expectedNumberOfJobsFilledByWomenIndividuals",
    name: "sites.0.expectedNumberOfJobsFilledByWomenIndividuals",
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 217,
  },
  {
    i18nKey: "Number of threatened species protected through project ",
    id: "numberOfThreatenedSpeciesProtectedThroughProject",
    name: "sites.0.numberOfThreatenedSpeciesProtectedThroughProject",
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 218,
  },
  {
    i18nKey: "Total agroforestry area (ha)",
    id: "totalAgroforestryAreaHA",
    name: "sites.0.totalAgroforestryAreaHA",
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 219,
  },
  {
    i18nKey: "Total area listed as (high) conservation area (ha)",
    id: "totalAreaListedAsHighConservationAreaHA",
    name: "sites.0.totalAreaListedAsHighConservationAreaHA",
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 220,
  },
  {
    i18nKey: "Total conservation area (ha)",
    id: "totalConservationAreaHA",
    name: "sites.0.totalConservationAreaHA",
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 221,
  },
  {
    i18nKey: "Total number of local stakeholders supported through benefit agreements (individuals)",
    id: "totalNumberOfLocalStakeholdersSupportedThroughBenefitAgreementsIndividuals",
    name: "sites.0.totalNumberOfLocalStakeholdersSupportedThroughBenefitAgreementsIndividuals",
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 222,
  },
  {
    i18nKey: "Total production forest area (ha)",
    id: "totalProductionForestAreaHA",
    name: "sites.0.totalProductionForestAreaHA",
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 223,
  },
];

export const siteTemplateFieldsList: SiteTemplateField[] = [...baseTemplateFieldsList].concat([
  {
    i18nKey: "Methodology",
    id: "projectTypeMethodologyId",
    name: "projectTypeMethodologyId",
    section: "overview",
    unit: "select",
    sortOrder: 0.01,
  },
  {
    i18nKey: "Start date",
    id: "startedAt",
    name: "startedAt",
    section: "overview",
    unit: "date",
    sortOrder: 0.02,
  },
  {
    i18nKey: "Duration (years)",
    id: "projectDurationYears",
    name: "projectDurationYears",
    section: "overview",
    unit: "number",
    sortOrder: 0.03,
  },
  {
    i18nKey: "Property ID",
    id: "propertyId",
    name: "propertyId",
    section: "overview",
    unit: "text",
    sortOrder: 0.04,
  },

  {
    i18nKey: "Registry ID",
    id: "registryId",
    name: "registryId",
    section: "overview",
    unit: "text",
    sortOrder: 0.05,
  },
  {
    i18nKey: "Registry account number",
    id: "registryAccountNumber",
    name: "registryAccountNumber",
    section: "overview",
    unit: "text",
    sortOrder: 0.06,
  },
  {
    i18nKey: "Registry account (ANREU)",
    id: "registryAccountANREU",
    name: "registryAccountANREU",
    section: "overview",
    unit: "text",
    sortOrder: 0.061,
  },
  {
    i18nKey: "Sources",
    id: "projectSources",
    name: "projectSources",
    section: "overview",
    unit: "multi-select",
    selectableType: "project_source",
    sortOrder: 0.07,
  },
  {
    i18nKey: "Tags",
    id: "projectTags",
    name: "projectTags",
    section: "overview",
    unit: "multi-select",
    selectableType: "project_tag",
    sortOrder: 0.08,
  },
  {
    i18nKey: "Co-benefits",
    id: "projectCoBenefits",
    name: "projectCoBenefits",
    section: "overview",
    unit: "multi-select",
    selectableType: "project_co_benefit",
    sortOrder: 0.09,
  },
  {
    i18nKey: "Bioregion",
    id: "bioRegionSelectableId",
    name: "sites.0.siteBioRegions",
    section: ["area", "overview"],
    selectableType: "site_bio_region",
    unit: "multi-select",
    sortOrder: 169,
    placeholder: "Select bioregion...",
    description: "Enter a bioregion name or select from the list",
  },
  {
    i18nKey: "Subregion",
    id: "bioSubRegionSelectableId",
    name: "sites.0.siteBioSubregions",
    section: ["area", "overview"],
    selectableType: "site_bio_subregion",
    unit: "multi-select",
    sortOrder: 170,
  },
  {
    i18nKey: "Catchment",
    id: "catchmentSelectableId",
    name: "sites.0.siteCatchments",
    section: ["area", "overview"],
    selectableType: "site_catchment",
    unit: "multi-select",
    sortOrder: 171,
  },
  {
    i18nKey: "Average issuance volume/year",
    id: "averageIssuanceVolumePerYear",
    name: "sites.0.averageIssuanceVolumePerYear",
    rules: {
      min: 0,
    },
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 172,
  },
  {
    creatable: false,
    i18nKey: "Soil types",
    id: "siteSoilTypes",
    name: "sites.0.siteSoilTypes",
    section: ["attributes", "overview"],
    selectableType: "site_soil_type",
    unit: "multi-select",
    sortOrder: 173,
  },
  {
    creatable: false,
    i18nKey: "Planting type",
    id: "sitePlantingTypes",
    name: "sites.0.sitePlantingTypes",
    section: ["attributes", "overview"],
    selectableType: "site_planting_type",
    unit: "multi-select",
    sortOrder: 174,
  },
  {
    creatable: false,
    i18nKey: "Species type",
    id: "siteSpeciesTypes",
    name: "sites.0.siteSpeciesTypes",
    section: ["attributes", "overview"],
    selectableType: "site_species_type",
    unit: "multi-select",
    sortOrder: 175,
  },
  {
    i18nKey: "Designations near site",
    id: "designationsNearSite",
    name: "sites.0.designationsNearSite",
    section: ["attributes", "overview"],
    selectableType: "designation_near_site",
    unit: "multi-select",
    sortOrder: 176,
  },
  {
    i18nKey: "Designations on site",
    id: "designationsOnSite",
    name: "sites.0.designationsOnSite",
    section: ["attributes", "overview"],
    selectableType: "designation_on_site",
    unit: "multi-select",
    sortOrder: 176.1,
  },
  {
    i18nKey: "Environmental designations on site",
    id: "environmentalDesignationsOnSite",
    name: "sites.0.environmentalDesignationsOnSite",
    section: ["attributes", "overview"],
    selectableType: "environmental_designations_on_site",
    unit: "multi-select",
    sortOrder: 176.2,
  },
  {
    i18nKey: "Historical designations on site",
    id: "historicalDesignationsOnSite",
    name: "sites.0.historicalDesignationsOnSite",
    section: ["attributes", "overview"],
    selectableType: "historical_designations_on_site",
    unit: "multi-select",
    sortOrder: 176.3,
  },
  {
    i18nKey: "Landscape designations on site",
    id: "landscapeDesignationsOnSite",
    name: "sites.0.landscapeDesignationsOnSite",
    section: ["attributes", "overview"],
    selectableType: "landscape_designations_on_site",
    unit: "multi-select",
    sortOrder: 177,
  },
  {
    i18nKey: "Land titles",
    id: "siteLandTitles",
    name: "sites.0.siteLandTitles",
    section: ["attributes", "overview"],
    selectableType: "site_land_title",
    unit: "multi-select",
    sortOrder: 178,
  },
  {
    i18nKey: "Threatened ecosystems",
    id: "siteThreatenedEcosystems",
    name: "sites.0.siteThreatenedEcosystems",
    section: ["attributes", "overview"],
    selectableType: "site_threatened_ecosystems",
    unit: "multi-select",
    sortOrder: 179,
  },
  {
    i18nKey: "Threatened species",
    id: "siteThreatenedSpecies",
    name: "sites.0.siteThreatenedSpecies",
    section: ["attributes", "overview"],
    selectableType: "site_threatened_species",
    unit: "multi-select",
    sortOrder: 180,
  },
  {
    i18nKey: "Land management (past/present)",
    id: "projectLandManagementActivities",
    name: "projectLandManagementActivities",
    section: ["attributes", "overview"],
    selectableType: "project_land_management_activity",
    unit: "multi-select",
    sortOrder: 181,
  },
  {
    i18nKey: "Grazing management (past/present)",
    id: "projectGrazingManagementActivities",
    name: "projectGrazingManagementActivities",
    section: ["attributes", "overview"],
    selectableType: "project_grazing_management_activity",
    unit: "multi-select",
    sortOrder: 182,
  },
  {
    i18nKey: "Land management strategy",
    id: "projectLandManagementStrategies",
    name: "projectLandManagementStrategies",
    section: ["attributes", "overview"],
    selectableType: "project_land_management_strategy",
    unit: "multi-select",
    sortOrder: 183,
  },
  {
    i18nKey: "Project category",
    id: "projectCategory",
    name: "projectCategorySelectableId",
    section: ["attributes", "overview"],
    selectableType: "project_category",
    unit: "select",
    sortOrder: 184,
  },
  {
    i18nKey: "County",
    id: "siteCounties",
    name: "sites.0.siteCounties",
    section: ["attributes", "overview"],
    selectableType: "site_county",
    unit: "multi-select",
    sortOrder: 185,
  },
  {
    i18nKey: "Land capability class",
    id: "siteLandCapabilitiesClass",
    name: "sites.0.siteLandCapabilitiesClass",
    section: ["attributes", "overview"],
    selectableType: "site_land_capabilities_class",
    unit: "multi-select",
    sortOrder: 186,
  },
  {
    i18nKey: "Contract status",
    id: "projectContractStatus",
    name: "projectContractStatusSelectableId",
    section: ["attributes", "overview"],
    selectableType: "project_contract_status",
    unit: "select",
    sortOrder: 187,
  },
  {
    i18nKey: "Implementation status",
    id: "projectStatus",
    name: "projectStatusSelectableId",
    section: ["attributes", "overview"],
    selectableType: "project_status",
    unit: "select",
    sortOrder: 188,
  },
  {
    i18nKey: "Grant model options",
    id: "siteGrantModelOptions",
    name: "sites.0.siteGrantModelOptions",
    section: ["attributes", "overview"],
    selectableType: "site_grant_model_option",
    unit: "multi-select",
    sortOrder: 189,
  },
  {
    i18nKey: "Native woodland NVC types",
    id: "siteNativeWoodlandNVCTypes",
    name: "sites.0.siteNativeWoodlandNVCTypes",
    section: ["attributes", "overview"],
    selectableType: "site_native_woodland_nvc_type",
    unit: "multi-select",
    sortOrder: 190,
  },
  {
    i18nKey: "Digital tools used",
    id: "siteDigitalToolsUsed",
    name: "sites.0.siteDigitalToolsUsed",
    section: ["attributes", "overview"],
    selectableType: "site_digital_tool_used",
    unit: "multi-select",
    sortOrder: 191,
  },
  {
    i18nKey: "Payment option",
    id: "paymentOption",
    name: "sites.0.paymentOptionSelectableId",
    section: ["attributes", "overview"],
    selectableType: "site_payment_option",
    unit: "select",
    sortOrder: 192,
  },
  {
    i18nKey: "Breeding type",
    id: "siteBreedingTypes",
    name: "sites.0.siteBreedingTypes",
    section: ["attributes", "overview"],
    selectableType: "site_breeding_type",
    unit: "multi-select",
    sortOrder: 193,
  },
  {
    i18nKey: "Cultural practises",
    id: "siteCulturalPractises",
    name: "sites.0.siteCulturalPractises",
    section: ["attributes", "overview"],
    selectableType: "site_cultural_practice",
    unit: "multi-select",
    sortOrder: 194,
  },
  {
    i18nKey: "Proposed methodology",
    id: "siteProposedMethodologies",
    name: "sites.0.siteProposedMethodologies",
    section: ["attributes", "overview"],
    selectableType: "site_proposed_methodology",
    unit: "multi-select",
    sortOrder: 195,
  },
  {
    i18nKey: "Farm management system",
    id: "siteFarmManagementSystems",
    name: "sites.0.siteFarmManagementSystems",
    section: ["attributes", "overview"],
    selectableType: "site_farm_management_system",
    unit: "multi-select",
    sortOrder: 196,
  },
  {
    i18nKey: "Irrigation energy source",
    id: "siteIrrigationEnergySources",
    name: "sites.0.siteIrrigationEnergySources",
    section: ["attributes", "overview"],
    selectableType: "site_irrigation_energy_source",
    unit: "multi-select",
    sortOrder: 197,
  },
  {
    i18nKey: "Irrigation water source",
    id: "siteIrrigationWaterSources",
    name: "sites.0.siteIrrigationWaterSources",
    section: ["attributes", "overview"],
    selectableType: "site_irrigation_water_source",
    unit: "multi-select",
    sortOrder: 198,
  },
  {
    i18nKey: "On-farm biogas production",
    id: "siteOnFarmBiogasProductions",
    name: "sites.0.siteOnFarmBiogasProductions",
    section: ["attributes", "overview"],
    selectableType: "site_farm_biogas_production",
    unit: "multi-select",
    sortOrder: 199,
  },
  {
    i18nKey: "Soil drainage",
    id: "siteSoilDrainages",
    name: "sites.0.siteSoilDrainages",
    section: ["attributes", "overview"],
    selectableType: "site_soil_drainage",
    unit: "multi-select",
    sortOrder: 200,
  },
  {
    i18nKey: "Tillage management",
    id: "siteTillageManagement",
    name: "sites.0.siteTillageManagement",
    section: ["attributes", "overview"],
    selectableType: "site_tillage_management",
    unit: "multi-select",
    sortOrder: 201,
  },
  {
    i18nKey: "Dung management system",
    id: "siteDungManagementSystems",
    name: "sites.0.siteDungManagementSystems",
    section: ["attributes", "overview"],
    selectableType: "site_dung_management_system",
    unit: "multi-select",
    sortOrder: 202,
  },
  {
    i18nKey: "Management of hedges",
    id: "siteManagementOfHedges",
    name: "sites.0.siteManagementOfHedges",
    section: ["attributes", "overview"],
    selectableType: "site_management_of_hedges",
    unit: "multi-select",
    sortOrder: 203,
  },
  {
    i18nKey: "Management of forest edges",
    id: "siteManagementOfForestEdges",
    name: "sites.0.siteManagementOfForestEdges",
    section: ["attributes", "overview"],
    selectableType: "site_management_of_forest_edge",
    unit: "multi-select",
    sortOrder: 204,
  },
  {
    i18nKey: "Management of single trees",
    id: "siteManagementOfSingleTrees",
    name: "sites.0.siteManagementOfSingleTrees",
    section: ["attributes", "overview"],
    selectableType: "site_management_of_single_tree",
    unit: "multi-select",
    sortOrder: 205,
  },
  {
    i18nKey: "Management of tree lines",
    id: "siteManagementOfTreeLines",
    name: "sites.0.siteManagementOfTreeLines",
    section: ["attributes", "overview"],
    selectableType: "site_management_of_tree_lines",
    unit: "multi-select",
    sortOrder: 206,
  },
  {
    i18nKey: "Primary enterprise type",
    id: "sitePrimaryEnterpriseTypes",
    name: "sites.0.sitePrimaryEnterpriseTypes",
    section: ["attributes", "overview"],
    selectableType: "site_primary_enterprise_type",
    unit: "multi-select",
    sortOrder: 168,
  },
  {
    i18nKey: "Primary habitat",
    id: "sitePrimaryHabitats",
    name: "sites.0.sitePrimaryHabitats",
    section: ["attributes", "overview"],
    selectableType: "site_primary_habitats",
    unit: "multi-select",
    sortOrder: 207,
  },
  {
    i18nKey: "Client",
    id: "siteClients",
    name: "sites.0.siteClients",
    section: ["attributes", "overview"],
    selectableType: "site_clients",
    unit: "multi-select",
    sortOrder: 208,
  },
  {
    i18nKey: "Management activities",
    id: "siteManagementActivities",
    name: "sites.0.siteManagementActivities",
    section: ["attributes", "overview"],
    selectableType: "site_management_activities",
    unit: "multi-select",
    sortOrder: 211,
  },
  {
    i18nKey: "Land use",
    id: "siteLandUse",
    name: "sites.0.siteLandUse",
    section: ["attributes", "overview"],
    selectableType: "site_land_use",
    unit: "multi-select",
    sortOrder: 211,
  },
  {
    i18nKey: "Developer",
    id: "siteDeveloper",
    name: "sites.0.siteDeveloper",
    section: "attributes",
    selectableType: "site_developers",
    unit: "multi-select",
    sortOrder: 212,
  },
  {
    i18nKey: "Deal type",
    id: "siteDealType",
    name: "sites.0.siteDealType",
    section: "attributes",
    selectableType: "site_deal_types",
    unit: "multi-select",
    sortOrder: 213,
  },
  {
    i18nKey: "Project partners",
    id: "projectPartners",
    name: "projectPartners",
    section: ["attributes", "overview"],
    selectableType: "project_partners",
    unit: "multi-select",
    sortOrder: 214,
  },
  {
    i18nKey: "Proponent",
    id: "proponentNames",
    name: "proponentNames",
    section: ["overview"],
    selectableType: "proponent_name",
    unit: "multi-select",
    sortOrder: 215,
  },
  {
    i18nKey: "Field sites",
    id: "fieldSites",
    name: "sites.0.siteFieldSites",
    section: ["attributes", "overview"],
    selectableType: "field_sites",
    unit: "multi-select",
    sortOrder: 216,
  },
  {
    i18nKey: "Land types",
    id: "landTypes",
    name: "sites.0.siteLandTypes",
    section: ["attributes", "overview"],
    selectableType: "land_types",
    unit: "multi-select",
    sortOrder: 217,
  },
  {
    i18nKey: "Local Government Area (LGA)",
    id: "siteLocalGovernmentArea",
    name: "sites.0.siteLocalGovernmentAreas",
    section: ["attributes", "overview"],
    unit: "multi-select",
    selectableType: "site_local_government_area",
    sortOrder: 218,
  },
]);

export const assessmentTemplateFieldsList: SiteTemplateField[] = [...baseTemplateFieldsList].concat([
  {
    i18nKey: "Duration (years)",
    id: "projectDurationYears",
    name: "projectDurationYears",
    section: ["attributes", "overview"],
    unit: "number",
    sortOrder: 169,
  },
  {
    i18nKey: "Standard",
    id: "projectStandardSelectableId",
    name: "projectStandardSelectableId",
    section: ["attributes", "overview"],
    selectableType: "project_standard",
    unit: "select",
    sortOrder: 169.1,
  },
  {
    i18nKey: "Bioregion",
    id: "assessmentBioRegions",
    name: "assessmentBioRegions",
    section: ["area", "overview"],
    selectableType: "site_bio_region",
    unit: "multi-select",
    sortOrder: 169.2,
  },
  {
    i18nKey: "Subregion",
    id: "assessmentBioSubregions",
    name: "assessmentBioSubregions",
    section: ["area", "overview"],
    selectableType: "site_bio_subregion",
    unit: "multi-select",
    sortOrder: 170,
  },
  {
    i18nKey: "Catchment",
    id: "assessmentCatchments",
    name: "assessmentCatchments",
    section: ["area", "overview"],
    selectableType: "site_catchment",
    unit: "multi-select",
    sortOrder: 171,
  },
  {
    i18nKey: "Average issuance volume/year",
    id: "averageIssuanceVolumePerYear",
    name: "sites.0.averageIssuanceVolumePerYear",
    rules: {
      min: 0,
    },
    section: "predictedOutcomes",
    unit: "number",
    sortOrder: 172,
  },
  {
    creatable: false,
    i18nKey: "Soil types",
    id: "assessmentSoilTypes",
    name: "assessmentSoilTypes",
    section: ["attributes", "overview"],
    selectableType: "site_soil_type",
    unit: "multi-select",
    sortOrder: 173,
  },
  {
    creatable: false,
    i18nKey: "Planting type",
    id: "assessmentPlantingTypes",
    name: "assessmentPlantingTypes",
    section: ["attributes", "overview"],
    selectableType: "site_planting_type",
    unit: "multi-select",
    sortOrder: 174,
  },
  {
    creatable: false,
    i18nKey: "Species type",
    id: "assessmentSpeciesTypes",
    name: "assessmentSpeciesTypes",
    section: ["attributes", "overview"],
    selectableType: "site_species_type",
    unit: "multi-select",
    sortOrder: 175,
  },
  {
    i18nKey: "Designations near site",
    id: "designationsNearAssessment",
    name: "designationsNearAssessment",
    section: ["attributes", "overview"],
    selectableType: "designation_near_site",
    unit: "multi-select",
    sortOrder: 176,
  },
  {
    i18nKey: "Designations on site",
    id: "designationsOnAssessment",
    name: "designationsOnAssessment",
    section: ["attributes", "overview"],
    selectableType: "designation_on_site",
    unit: "multi-select",
    sortOrder: 177,
  },
  {
    i18nKey: "Environmental designations on assessment",
    id: "environmentalDesignationsOnAssessment",
    name: "environmentalDesignationsOnAssessment",
    section: ["attributes", "overview"],
    selectableType: "environmental_designations_on_site",
    unit: "multi-select",
    sortOrder: 177,
  },
  {
    i18nKey: "Historical designations on assessment",
    id: "historicalDesignationsOnAssessment",
    name: "historicalDesignationsOnAssessment",
    section: ["attributes", "overview"],
    selectableType: "historical_designations_on_site",
    unit: "multi-select",
    sortOrder: 177,
  },
  {
    i18nKey: "Landscape designations on assessment",
    id: "landscapeDesignationsOnAssessment",
    name: "landscapeDesignationsOnAssessment",
    section: ["attributes", "overview"],
    selectableType: "landscape_designations_on_site",
    unit: "multi-select",
    sortOrder: 177,
  },
  {
    i18nKey: "Land titles",
    id: "assessmentLandTitles",
    name: "assessmentLandTitles",
    section: ["attributes", "overview"],
    selectableType: "site_land_title",
    unit: "multi-select",
    sortOrder: 178,
  },
  {
    i18nKey: "Threatened ecosystems",
    id: "assessmentThreatenedEcosystems",
    name: "assessmentThreatenedEcosystems",
    section: ["attributes", "overview"],
    selectableType: "site_threatened_ecosystems",
    unit: "multi-select",
    sortOrder: 179,
  },
  {
    i18nKey: "Threatened species",
    id: "assessmentThreatenedSpecies",
    name: "assessmentThreatenedSpecies",
    section: ["attributes", "overview"],
    selectableType: "site_threatened_species",
    unit: "multi-select",
    sortOrder: 180,
  },
  {
    i18nKey: "County",
    id: "assessmentCounties",
    name: "assessmentCounties",
    section: ["attributes", "overview"],
    selectableType: "site_county",
    unit: "multi-select",
    sortOrder: 181,
  },
  {
    i18nKey: "Land capability class",
    id: "assessmentLandCapabilitiesClass",
    name: "assessmentLandCapabilitiesClass",
    section: ["attributes", "overview"],
    selectableType: "site_land_capabilities_class",
    unit: "multi-select",
    sortOrder: 182,
  },
  {
    i18nKey: "Grant model options",
    id: "assessmentGrantModelOptions",
    name: "assessmentGrantModelOptions",
    section: ["attributes", "overview"],
    selectableType: "site_grant_model_option",
    unit: "multi-select",
    sortOrder: 183,
  },
  {
    i18nKey: "Native woodland NVC types",
    id: "assessmentNativeWoodlandNVCTypes",
    name: "assessmentNativeWoodlandNVCTypes",
    section: ["attributes", "overview"],
    selectableType: "site_native_woodland_nvc_type",
    unit: "multi-select",
    sortOrder: 184,
  },
  {
    i18nKey: "Digital tools used",
    id: "assessmentDigitalToolsUsed",
    name: "assessmentDigitalToolsUsed",
    section: ["attributes", "overview"],
    selectableType: "site_digital_tool_used",
    unit: "multi-select",
    sortOrder: 185,
  },
  {
    i18nKey: "Breeding type",
    id: "assessmentBreedingTypes",
    name: "assessmentBreedingTypes",
    section: ["attributes", "overview"],
    selectableType: "site_breeding_type",
    unit: "multi-select",
    sortOrder: 186,
  },
  {
    i18nKey: "Cultural practises",
    id: "assessmentCulturalPractises",
    name: "assessmentCulturalPractises",
    section: ["attributes", "overview"],
    selectableType: "site_cultural_practice",
    unit: "multi-select",
    sortOrder: 187,
  },
  {
    i18nKey: "Proposed methodology",
    id: "assessmentProposedMethodologies",
    name: "assessmentProposedMethodologies",
    section: ["attributes", "overview"],
    selectableType: "site_proposed_methodology",
    unit: "multi-select",
    sortOrder: 188,
  },
  {
    i18nKey: "Farm management system",
    id: "assessmentFarmManagementSystems",
    name: "assessmentFarmManagementSystems",
    section: ["attributes", "overview"],
    selectableType: "site_farm_management_system",
    unit: "multi-select",
    sortOrder: 189,
  },
  {
    i18nKey: "Irrigation energy source",
    id: "assessmentIrrigationEnergySources",
    name: "assessmentIrrigationEnergySources",
    section: ["attributes", "overview"],
    selectableType: "site_irrigation_energy_source",
    unit: "multi-select",
    sortOrder: 190,
  },
  {
    i18nKey: "Irrigation water source",
    id: "assessmentIrrigationWaterSources",
    name: "assessmentIrrigationWaterSources",
    section: ["attributes", "overview"],
    selectableType: "site_irrigation_water_source",
    unit: "multi-select",
    sortOrder: 191,
  },
  {
    i18nKey: "On-farm biogas production",
    id: "assessmentOnFarmBiogasProductions",
    name: "assessmentOnFarmBiogasProductions",
    section: ["attributes", "overview"],
    selectableType: "site_farm_biogas_production",
    unit: "multi-select",
    sortOrder: 192,
  },
  {
    i18nKey: "Soil drainage",
    id: "assessmentSoilDrainages",
    name: "assessmentSoilDrainages",
    section: ["attributes", "overview"],
    selectableType: "site_soil_drainage",
    unit: "multi-select",
    sortOrder: 193,
  },
  {
    i18nKey: "Tillage management",
    id: "assessmentTillageManagement",
    name: "assessmentTillageManagement",
    section: ["attributes", "overview"],
    selectableType: "site_tillage_management",
    unit: "multi-select",
    sortOrder: 194,
  },
  {
    i18nKey: "Dung management system",
    id: "assessmentDungManagementSystems",
    name: "assessmentDungManagementSystems",
    section: ["attributes", "overview"],
    selectableType: "site_dung_management_system",
    unit: "multi-select",
    sortOrder: 195,
  },
  {
    i18nKey: "Management of hedges",
    id: "assessmentManagementOfHedges",
    name: "assessmentManagementOfHedges",
    section: ["attributes", "overview"],
    selectableType: "site_management_of_hedges",
    unit: "multi-select",
    sortOrder: 196,
  },
  {
    i18nKey: "Management of forest edges",
    id: "assessmentManagementOfForestEdges",
    name: "assessmentManagementOfForestEdges",
    section: ["attributes", "overview"],
    selectableType: "site_management_of_forest_edge",
    unit: "multi-select",
    sortOrder: 197,
  },
  {
    i18nKey: "Management of single trees",
    id: "assessmentManagementOfSingleTrees",
    name: "assessmentManagementOfSingleTrees",
    section: ["attributes", "overview"],
    selectableType: "site_management_of_single_tree",
    unit: "multi-select",
    sortOrder: 198,
  },
  {
    i18nKey: "Management of tree lines",
    id: "assessmentManagementOfTreeLines",
    name: "assessmentManagementOfTreeLines",
    section: ["attributes", "overview"],
    selectableType: "site_management_of_tree_lines",
    unit: "multi-select",
    sortOrder: 199,
  },
  {
    i18nKey: "Primary enterprise type",
    id: "assessmentPrimaryEnterpriseTypes",
    name: "assessmentPrimaryEnterpriseTypes",
    section: ["attributes", "overview"],
    selectableType: "site_primary_enterprise_type",
    unit: "multi-select",
    sortOrder: 168,
  },
  {
    i18nKey: "Primary habitat",
    id: "assessmentPrimaryHabitats",
    name: "assessmentPrimaryHabitats",
    section: ["attributes", "overview"],
    selectableType: "site_primary_habitats",
    unit: "multi-select",
    sortOrder: 200,
  },
  {
    i18nKey: "Proponent",
    id: "proponentNames",
    name: "proponentNames",
    section: ["attributes"],
    selectableType: "proponent_name",
    unit: "multi-select",
    sortOrder: 201,
  },
  {
    i18nKey: "Client",
    id: "assessmentClients",
    name: "assessmentClients",
    section: ["attributes", "overview"],
    selectableType: "site_clients",
    unit: "multi-select",
    sortOrder: 202,
  },
  {
    i18nKey: "Management activities",
    id: "assessmentManagementActivities",
    name: "assessmentManagementActivities",
    section: ["attributes", "overview"],
    selectableType: "site_management_activities",
    unit: "multi-select",
    sortOrder: 203,
  },
  {
    i18nKey: "Land use",
    id: "assessmentLandUse",
    name: "assessmentLandUse",
    section: ["attributes", "overview"],
    selectableType: "site_land_use",
    unit: "multi-select",
    sortOrder: 204,
  },
  {
    i18nKey: "Developer",
    id: "assessmentDeveloper",
    name: "assessmentDeveloper",
    section: ["attributes"],
    selectableType: "site_developers",
    unit: "multi-select",
    sortOrder: 205,
  },
  {
    i18nKey: "Deal type",
    id: "assessmentDealType",
    name: "assessmentDealType",
    section: ["attributes", "overview"],
    selectableType: "site_deal_types",
    unit: "multi-select",
    sortOrder: 206,
  },
  {
    i18nKey: "Project partners",
    id: "assessmentPartners",
    name: "assessmentPartners",
    section: ["attributes", "overview"],
    selectableType: "project_partners",
    unit: "multi-select",
    sortOrder: 207,
  },
  {
    i18nKey: "Field sites",
    id: "assessmentFieldSites",
    name: "assessmentFieldSites",
    section: ["attributes", "overview"],
    selectableType: "field_sites",
    unit: "multi-select",
    sortOrder: 208,
  },
  {
    i18nKey: "Land types",
    id: "assessmentLandTypes",
    name: "assessmentLandTypes",
    section: ["attributes", "overview"],
    selectableType: "land_types",
    unit: "multi-select",
    sortOrder: 209,
  },
  {
    i18nKey: "Local Government Area (LGA)",
    id: "assessmentLocalGovernmentArea",
    name: "assessmentLocalGovernmentArea",
    section: ["attributes", "overview"],
    unit: "multi-select",
    selectableType: "site_local_government_area",
    sortOrder: 210,
  },
  // Very special field for project type
  {
    i18nKey: "Project type",
    id: "projectTypeId",
    name: "projectTypeId",
    section: ["attributes"],
    unit: "global-select",
    sortOrder: 211,
  },
]);

export const projectStageOptions: MenuItem[] = [
  { value: "setup", name: "Setup" },
  { value: "maintain", name: "Maintain" },
];

export type ProjectFlag = {
  createdAt: string;
  id: string;
  labelSelectableId: string;
  resourceId: string;
  type: string;
};

export type ResourceFlag = {
  createdAt: string;
  id: string;
  labelSelectableId: string;
  resourceId: string;
  type: string;
};

export interface BaseStage {
  completedAt?: string;
  description?: string;
  expectedCompleteAt?: string;
  expectedStartAt?: string;
  id: string;
  name: string;
  previousStageId?: string;
  stageTemplateId: string;
  startedAt?: string;
  sortOrder: number;
  completionNumberOfDays?: number;
}

export interface Stage extends BaseStage {
  projectId: string;
}

export type ProjectStatus = {
  id: string;
  name: string;
  sortOrder: number;
  color?: string;
};

export type ProjectLocation = {
  id: string;
  address?: string;
  countryCode?: string | null;
  latitude?: number;
  longitude?: number;
  postalCode?: string;
  region?: string | null;
  projectId?: string;
};

export type Project = {
  activity: string;
  // @TODO Delete assigned owner related fields
  locations?: ProjectLocation[];
  assignedUsers?: AssignedUser[];
  projectFlag?: ProjectFlag;
  id: string;
  lastCheckIn: string;
  name: string;
  orgId?: string;
  potentialPropertyAreaHectares: number;
  proponentName: string;
  sites: Site[];
  currentStageId: string;
  currentStatusId: string;
  stage?: Stage;
  stages?: Stage[];
  startedAt: string;
  verificationBodyId: string;
  ciboLabsMapUrl?: string;
  projectTypeId: string;
  projectType?: ProjectType;
  totalPotentialCredits?: number;
  registryId?: string;
  registryAccountNumber?: string;
  registryAccountANREU?: string;
  description?: string | null;
  pricePerCreditAmount?: number;
  pricePerCreditCurrency?: string;
  totalTreeNumber?: number;
  rotationLengthYears?: number;
  grantId?: string;
  projectDurationYears?: number;
  permanencePeriodYears?: number;
  propertyId?: string;
  projectBufferTonnes?: number;
  issuedCredits?: number;
  availableCO2Tonnes?: number;
  contractedCO2Tonnes?: number;
  totalTreesPerCO2Tonne?: number;
  validationOnlyQuoteAmount?: number;
  validationOnlyQuoteCurrency?: string;
  soldCO2Tonnes?: number;
  onOfferCO2Tonnes?: number;
  soilCarbonSequestrationTonnesCO2eYear?: number;
  treeCarbonSequestrationTonnesCO2eYear?: number;
  projectCO2ForecastTonnes?: number;
  totalPredictedEmissionReductionsOverProjectLifetimeTCO2e?: number;
  predictedClaimableEmissionReductionsOverProjectLifetimeTCO2e?: number;
  predictedContributionToBufferOverProjectLifetimeTCO2e?: number;
  additionalCO2SequesteredPercentage?: number;
  postProjectVerificationDocumentsSubmissionAt?: string;
  preProjectVerificationDocumentsSubmissionAt?: string;
  totalCarbonIssuanceOverProjectLifetimeTCO2e?: number;
  totalIssuedCarbonOverProjectLifetimeTCO2e: number;
  boughtCO2Tonnes: number | null;
  closedAt: string | null;
  contractIdCAC?: string;
  contractStartAt?: string;
  contractEndAt?: string;
  sortOrder: number;
  previousProjectId?: string | null;

  // selectables
  projectCategorySelectableId?: string;
  projectCategory: SelectableId;
  projectContractStatusSelectableId?: string;
  projectContractStatus: SelectableId;
  projectStatusSelectableId?: string;
  projectStatus: SelectableId;
  projectCoBenefits?: SelectableId[];
  projectRegistrySelectableId: string;
  projectSourceSelectableId: string;
  projectStandardSelectableId: string;
  projectSources?: SelectableId[];
  projectTags?: SelectableId[];
  projectTypeMethodologyId: string;
  projectUNSDGs?: SelectableId[];
  projectActivities?: SelectableId[];
  projectLandManagementActivities?: SelectableId[];
  projectGrazingManagementActivities?: SelectableId[];
  projectLandManagementStrategies?: SelectableId[];
  proponentNames?: SelectableId[];
  projectServices?: SelectableId[];
  projectValidators?: SelectableId[];

  // Dates
  applicationSentAt?: string;
  baselineEndAt?: string;
  baselineStartAt?: string;
  endedAt?: string;
  expectedEndAt?: string;
  expectedStartAt?: string;
  groupEndAt?: string;
  groupStartAt?: string;
  implementationEndAt?: string;
  implementationStartAt?: string;
  landholderConfirmedParticipationAt?: string;
  modellingStartAt?: string;
  modellingEndAt?: string;
  permanencePeriodStartAt?: string;
  permanencePeriodEndAt?: string;
  archivedAt: string | null;

  // TODO not sure if this are going to be the final fields
  // but I'll include them now and update later if they don't
  // match with BE implementation
  eligibleActivity?: string;

  modifiedAt?: string;
  modifiedByUserDetails?: AssignedUser;
  serviceAgreementDocuments: ServiceAgreementDocument[];
};

export type ProjectFilter = {
  id: string;
  value: string;
};

export type ProjectFilters = {
  projectAssignedUserName: ProjectFilter[];
  siteAssignedUserName: ProjectFilter[];
  stageTemplateId: ProjectFilter[];
  projectStatusName: ProjectFilter[];
  stageTemplateGroupName: ProjectFilter[];
  region: ProjectFilter[];
  prospectRegion: ProjectFilter[];
  prospectStageTemplateId: ProjectFilter[];
  siteBioRegions: ProjectFilter[];
  methodology: ProjectFilter[];
};

export const projectMethodologiesOptions: MenuItem[] = [
  { value: "avoided clearing", name: "Avoided clearing" },
  { value: "avoided deforestation", name: "Avoided deforestation" },
  {
    value: "afforestation reforestation",
    name: "Afforestation / reforestation",
  },
  { value: "farm forestry", name: "Farm forestry" },
  { value: "human induced regeneration", name: "Human induced regeneration" },
  { value: "humus protection program", name: "Humus protection program" },
  { value: "native forest", name: "Native forest" },
  { value: "peatland code", name: "Peatland code" },
  { value: "plantation forestry", name: "Plantation forestry" },
  { value: "reforestation by plantings", name: "Reforestation by plantings" },
  {
    value: "soil carbon(grazing systems)",
    name: "Soil carbon: grazing systems",
  },
  { value: "soil carbon(measurement)", name: "Soil carbon: measurement" },
  { value: "soil carbon(modelling)", name: "Soil carbon: modelling" },
  { value: "woodland carbon code", name: "Woodland carbon code" },
];

type Type = {
  id: string;
  name: string;
  description?: string;
};

export type ProjectType = Type & {
  methodologies: Type[];
  sortOrder: number;
};

export type ProjectsResponse = {
  meta: Pick<IMeta, "total" | "perPage">;
  data: {
    projects: Project[];
  };
};

export type AddProject = {
  name: string;
  projectTags: string[];
  projectSources: string[];
  projectOwner?: AssignedUser; // this is special is not going to be pushed to the API
  assignedUsers?: AssignedUser[];
  sites: Site[];
  projectTypeId?: string;
} & ResourceLocationProps;

export type InviteProjectUsersResponse = {
  userIds: string[];
};

export type InviteProjectUsersResponseWrapper = {
  data: InviteProjectUsersResponse;
};

export type InviteParams = {
  id: string;
  userIds: string[];
  message?: string;
};

export type InviteRequest = (params: InviteParams) => Promise<InviteProjectUsersResponseWrapper>;

export type ProjectMarker = {
  projectId: string;
  siteId: string;
  lat: number;
  lng: number;
  projectAreaHectares: number;
  potentialPropertyAreaHectares?: number;
};

export type ProjectsMarkersResponseWrapper = {
  data: ProjectsMarkersResponse;
  meta: { totalProjectsWithoutLocation: number };
};

export type ProjectsMarkersResponse = {
  projectsMarkers: ProjectMarker[];
};

export type AssignContactArgs = (args: { project: Project; selectedContacts: AssignedUser[]; message?: string }) => void;

export type FilterTypes =
  | "search"
  | "all_projects"
  | "my_projects"
  | "recently_updated"
  | "flagged"
  | "project_type"
  | "stage"
  | "region"
  | "source"
  | "project_assigned_user"
  | "site_assigned_user"
  | "tag"
  | "project_owner"
  | "closedOnly"
  | "all_prospects"
  | "my_prospects"
  | "validator"
  | "project_status"
  | "methodology";

export type AttrProjectProps = Pick<
  Project,
  | "id"
  | "totalTreeNumber"
  | "rotationLengthYears"
  | "availableCO2Tonnes"
  | "contractedCO2Tonnes"
  | "totalTreesPerCO2Tonne"
  | "validationOnlyQuoteAmount"
  | "validationOnlyQuoteCurrency"
  | "projectCategorySelectableId"
  | "projectCO2ForecastTonnes"
  | "permanencePeriodYears"
  | "soldCO2Tonnes"
  | "onOfferCO2Tonnes"
  | "soilCarbonSequestrationTonnesCO2eYear"
  | "treeCarbonSequestrationTonnesCO2eYear"
  | "totalPredictedEmissionReductionsOverProjectLifetimeTCO2e"
  | "predictedClaimableEmissionReductionsOverProjectLifetimeTCO2e"
  | "predictedContributionToBufferOverProjectLifetimeTCO2e"
  | "additionalCO2SequesteredPercentage"
  | "postProjectVerificationDocumentsSubmissionAt"
  | "preProjectVerificationDocumentsSubmissionAt"
  | "projectContractStatusSelectableId"
  | "projectStatusSelectableId"
  | "totalCarbonIssuanceOverProjectLifetimeTCO2e"
  | "totalIssuedCarbonOverProjectLifetimeTCO2e"
  | "boughtCO2Tonnes"
>;

export type PredictedOutcomesProps = Pick<Assessment, "id">;

export type AttrSiteProps = Partial<
  Pick<
    Site,
    | "id"
    | "projectId"
    | "percentageOfConiferSpecies"
    | "numberOfCarbonEstimationAreas"
    | "numberOfStratas"
    | "numberOfSamplePoints"
    | "percentageOfBroadleafSpecies"
    | "stockingDensityStemsPerHectare"
    | "totalTreesAvailable"
    | "averageRainfallMM"
    | "gridReference"
    | "nearestTown"
    | "existingForestAreaHectares"
    | "windSpeedMetersPerSecond"
    | "temperatureCelsius"
    | "percentageOfIndigenous"
    | "percentageOfHardwood"
    | "percentageOfSoftwood"
    | "riparianAreaHectares"
    | "wetlandAreaHectares"
    | "netHectaresAvailable"
    | "totalBroadleaf"
    | "totalConifer"
    | "soilCarbonSequestrationTonnesCO2eYear"
    | "treeCarbonSequestrationTonnesCO2eYear"
    | "riparianManagementRestorationAreaHectares"
    | "remnantVegetationManagementRestorationAreaHectares"
    | "activelyErodingBlanketBogFlatBareHectares"
    | "activelyErodingBlanketBogHaggGullyHectares"
    | "drainedBlanketBogArtificialHectares"
    | "drainedBlanketBogHaggGullyHectares"
    | "maxEstimatedCO2eTonnesPerHectare"
    | "minEstimatedCO2eTonnesPerHectare"
    | "landManagementScore"
    | "interimCreditingAssessmentTCO2HectaresPerYear"
    | "ecologicalConditionImprovementAndConservationScore"
    | "securingAssetsNecessaryForLifeScore"
    | "socialImpactScore"
    | "contextOfRegenerativeImpactScore"
    | "potentialToImproveImpactScore"
    | "totalScoreContributingToValuation"
    | "changeInGroundcoverPercentage"
    | "groundcoverComparisonToLocalMedian"
    | "groundcoverComparisonToSubregionalMedian"
    | "averageAreaOfPrimaryWoodyVegetationMaintainedHectares"
    | "averageAreaOfSecondaryWoodyVegetationMaintainedHectares"
    | "changeInSecondaryWoodyVegetationHectares"
    | "certifiedCreditAreaHectares"
    | "eligibleForestAreaHectares"
    | "forestAreaHectares"
    | "modifiedPeatlandConditionAreaHectares"
    | "purchaseContractDurationYears"
    | "circularBiodiversityPresentScore"
    | "circularCommunityEconomicBenefitScore"
    | "circularContributionToRenewableEnergyScore"
    | "circularCulturalHeritageScore"
    | "circularExpectedBiodiversityGainScore"
    | "circularPublicAccessAndRecreationScore"
    | "circularResearchAndEducationScore"
    | "circularSocietalEconomicBenefitScore"
    | "circularVisualAmenityScore"
    | "circularWaterManagementScore"
    | "baselineQuoteAmount"
    | "baselineQuoteCurrency"
    | "paymentOptionSelectableId"
    | "ageOfOperatingHeads"
    | "farmWorkersTotal"
    | "monthsOfFloorCoverage"
    | "moRate"
    | "siren"
    | "altitude"
    | "organicMaterialRate"
    | "soilCoverageMonths"
    | "collars"
    | "animalMassKG"
    | "accessibleAcreagePrivate"
    | "accessibleAcreageState"
    | "accessibleAcreageBlmUsfs"
    | "accessibleAcreageUnknownOther"
    | "daysInGrowingSeason"
    | "daysCattleSupportByBufferArea"
    | "depthToRockCM"
    | "depthToSampleCM"
    | "numberOfCows"
    | "numberOfPaddocks"
    | "numberOfFires"
    | "rotationsPerSeason"
    | "claimableCO2Tonnes"
    | "animalsInPastureDays"
    | "plantedPercentage"
    | "areaPreservedHectares"
    | "preservedPercentage"
    | "eucalyptusSalignaPercentage"
    | "eucalyptusUrograndisPercentage"
    | "closingDateOfTheAccountingBalance"
    | "dieselConsumptionLitersPerYear"
    | "essenceConsumptionLitersPerYear"
    | "electricityConsumptionKilowattPerYear"
    | "totalWaterConsumptionLitersPerYear"
    | "totalWaterConsumptionForIrrigationLitersPerYear"
    | "productQuantityRefrigeratedKilogramsPerYear"
    | "quantityOfSojaBoughtTonnesYear"
    | "numberOfPlantTypesForCoverCrops"
    | "averagePresenceOfCoverCrops"
    | "percentageOfSeedIndependence"
    | "percentageOfSurfaceIrrigated"
    | "cultivatedTreeZoneHectares"
    | "vulnerableZonesHectares"
    | "surfaceAgrivoltaicsHectares"
    | "surfacePhotovoltaicHectares"
    | "totalFarmRevenuePerYearAmount"
    | "totalFarmRevenuePerYearCurrency"
    | "totalRevenueFromDirectSalesPerYearAmount"
    | "totalRevenueFromDirectSalesPerYearCurrency"
    | "expensesForSyntheticFertiliserPerYearAmount"
    | "expensesForSyntheticFertiliserPerYearCurrency"
    | "percentageOfAlder"
    | "percentageOfAsh"
    | "percentageOfAspen"
    | "percentageOfBeech"
    | "percentageOfBirch"
    | "percentageOfCherry"
    | "percentageOfDouglasFir"
    | "percentageOfEucalyptus"
    | "percentageOfHawthorn"
    | "percentageOfHazel"
    | "percentageOfHolly"
    | "percentageOfJuniper"
    | "percentageOfLarch"
    | "percentageOfMaple"
    | "percentageOfNorwaySpruce"
    | "percentageOfOak"
    | "percentageOfOtherBroadleaf"
    | "percentageOfOtherConifer"
    | "percentageOfRowan"
    | "percentageOfScotsPine"
    | "percentageOfSitkaSpruce"
    | "percentageOfSycamore"
    | "percentageOfWesternRedCedar"
    | "percentageOfWillow"
    | "validationDate"
    | "nextVerificationDate"
    | "totalAmountOfSyntheticNitrogenAppliedToSoilsKg"
    | "totalAmountOfOrganicNitrogenAppliedToSoilsKg"
    | "quantityOfFeedBoughtTonnesPerYear"
    | "treeVolumeM3HA"
    | "averageAnnualIncreaseM3HAPerYear"
    | "treeDiameterCM"
    | "treeHeightMeters"
    | "otherEnterpriseType"
    | "lotNumbers"
    | "proposedProjectType"
  >
>;

export interface ServiceAgreementDocument {
  id: string;
  documentId: string;
}

export type SettingsTemplate = {
  [field in keyof Assessment]: true;
};

export type ProjectContextType = {
  id: string;
  project: Project;
  loading: boolean;
  processing: boolean;
  invite: (params: InviteParams) => void;
  replace: (project: Project) => void;
  archive: (feedback?: Feedback) => void;
  unArchive: (feedback?: Feedback) => void;
  duplicate: (name: string) => Promise<Project>;
  setFlagLabel: (label: string | null) => Promise<void>;
  removeFlag: (id: string) => void;
  update: (data: { id: string } & Partial<Project>, feedback?: Feedback) => Promise<void>;
  updateProjectSite: (id: string, projectId: string, data: Partial<Site>) => Promise<void>;
  updateLocation: (data: ProjectLocation) => Promise<void>;
  createLocation: (data: ProjectLocation) => Promise<void>;
  deleteLocation: (data: ProjectLocation) => Promise<void>;
};

export enum FormulaGroupUnits {
  CurrencyAUD = "currency:aud",
  CurrencyBGP = "currency:bgp",
  CurrencyUSD = "currency:usd",
  CurrencyEUR = "currency:eur",
  Numeric = "numeric",
}

export enum FormulaParameterType {
  IssuanceApproved = "issuance:approved",
  IssuanceSubmitted = "issuance:submitted",
  IssuanceRetired = "issuance:retired",
  Numeric = "numeric",
  Currency = "currency",
}

export type Formula = {
  id: string;
  formulaTemplateGroupId: string;
  name: string;
  formula: string;
  result: number;
};

export type FormulaParameter = {
  id: string;
  formulaTemplateGroupId: string;
  name: string;
  formulaName: string;
  type: FormulaParameterType;
  value: number;
};

export type FormulaGroup = {
  id: string;
  name: string;
  units: FormulaGroupUnits;
  isDefault: boolean;
  formulas: Formula[];
  parameters: FormulaParameter[];
};

export type MemoryDocument = {
  id?: string;
  name: string;
  file?: File;
  asset?: Asset;
};

export type StageTemplateGroup = {
  id: string;
  projectTypeId: string;
  name: string;
  StageTemplates: Stage[];
};

export type ProjectListViewOptions = "list" | "board" | "map";
