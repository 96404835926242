import moment from "moment";
import React, { Suspense } from "react";
import { BrowserRouter as Router, NavLink, Redirect, Route, Switch } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import "./App.css";
import "./i18n";
import "./index.css";
import { Button } from "~/common/forms/button";
import ErrorPage from "./common/error/pages/Error";
import ToastProvider from "./common/toasts/components/ToastProvider";
import { FIRST_LOGIN_KEY, useAuth, RedirectProvider } from "./core/auth";
import Login from "./core/auth/pages/Login/Container";
import { FirstLogin, MeProvider } from "./core/account";
import Main from "./Main";
import { getLang } from "./common/utils";
import { DEFAULT_PATH, REDIRECT_TO_KEY } from "./core/auth/utils";

const App: React.FC = () => {
  const { isLoggedIn, logOut } = useAuth();
  const { trackEvent, hardShutdown } = useIntercom();
  const lang = getLang();
  moment.locale(lang.toLowerCase());

  return (
    <>
      <Suspense fallback={<div />}>
        <Router>
          <ToastProvider>
            <RedirectProvider>
              <Switch>
                <Route exact path="/">
                  <Redirect to={isLoggedIn ? sessionStorage.getItem(REDIRECT_TO_KEY) || DEFAULT_PATH : "/auth"} />
                </Route>

                <Route
                  exact
                  path={["/danone", "/totalenergies", "/agriprove", "/ridgegroup", "/regeneration"]}
                  render={(props) => <Redirect to={`${props.location.pathname}/auth/login`} />}
                />

                <Route path="/auth">
                  <Login />
                </Route>

                <Route path="/:brand/auth">
                  <Login />
                </Route>

                <Route
                  path="/logout"
                  render={() => {
                    trackEvent("logout-completed");
                    hardShutdown();

                    localStorage.removeItem(FIRST_LOGIN_KEY);
                    logOut();
                    return <div />;
                  }}
                />

                <Route path="/404">
                  <ErrorPage>
                    <h1 className="text-white mb-6 text-center">
                      Sorry! The page you’re looking for
                      <br />
                      does not exist.
                    </h1>
                  </ErrorPage>
                </Route>
                <Route path="/401">
                  <ErrorPage>
                    <h1 className="text-white mb-6 text-center">
                      Sorry! You are not
                      <br /> authorised to view this page.
                    </h1>
                  </ErrorPage>
                </Route>
                <Route path="/500">
                  <ErrorPage hideBackButton>
                    <div className="text-white text-center">
                      <h1>Oops! Something’s gone wrong.</h1>
                      <h3 className="my-6">
                        Please let our team know and we will get this fixed as
                        <br />
                        soon as possible.
                      </h3>

                      <Button
                        id="btn-support"
                        variant="btn-transparent"
                        size="btn-large"
                        onClick={() => {
                          window.location.href = "mailto:support@cecil.earth";
                        }}
                      >
                        Contact support
                      </Button>

                      <div className="mt-6">
                        <NavLink to="/projects" className="text-white">
                          Return to projects
                        </NavLink>
                      </div>
                    </div>
                  </ErrorPage>
                </Route>

                {isLoggedIn ? (
                  <Route>
                    <MeProvider>
                      <Switch>
                        <Route exact path="/account/confirmation">
                          <FirstLogin />
                        </Route>

                        <Route>
                          <Main />
                        </Route>
                      </Switch>
                    </MeProvider>
                  </Route>
                ) : (
                  <Redirect to="/auth/login" />
                )}
              </Switch>
            </RedirectProvider>
          </ToastProvider>
        </Router>
      </Suspense>
    </>
  );
};

export default App;
