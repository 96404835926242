import classNames from "classnames";
import { Transition } from "@headlessui/react";
import React, { ReactNode, useContext, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import Loader from "~/common/animations/Loader";
import { ToastType } from "../models";
import { ToastContext } from "./ToastContext";

interface ToastMessageProps {
  title: string;
  className: string;
  iconName?: string;
  icon?: ReactNode;
}

const ToastMessageMapper: Record<ToastType, ToastMessageProps> = {
  loading: {
    title: "",
    icon: <Loader />,
    className: "bg-light-blue-200",
  },
  info: {
    title: "Information",
    icon: (
      <span className="text-light-blue-900">
        <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
      </span>
    ),
    className: "bg-light-blue-200",
  },
  success: {
    title: "Success",
    icon: (
      <span className="text-green-900">
        <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
      </span>
    ),
    className: "bg-green-200",
  },
  warning: {
    title: "Warning",
    icon: (
      <span className="text-yellow-900">
        <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
      </span>
    ),
    className: "bg-yellow-200",
  },
  error: {
    title: "Error",
    icon: (
      <span className="text-red-900">
        <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
      </span>
    ),
    className: "bg-red-200",
  },
  system: {
    title: "System",
    className: "text-white bg-grey-900",
  },
  archived: {
    title: "Archived",
    icon: (
      <span className="text-light-blue-900">
        <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
          />
        </svg>
      </span>
    ),
    className: "bg-light-blue-200",
  },
  old: {
    title: "Old",
    className: "bg-grey-100",
  },
};

const UNCLOSED_TOASTS: ToastType[] = ["loading", "error"];

const Toast: React.FC = () => {
  const { t } = useTranslation();
  const { toast, toastClose } = useContext(ToastContext);
  const toastType = toast?.type as ToastType;
  const { title, icon, className } = ToastMessageMapper[toastType] || {};
  const toastTitle = toast?.title || title;
  const render = toast?.render;

  useEffect(() => {
    const handleToastClose = () => {
      if (toast && !UNCLOSED_TOASTS.includes(toast.type)) {
        setTimeout(() => {
          toastClose();
        }, toast?.duration ?? 5000);
      }
    };

    if (toast) handleToastClose();
  }, [toast]);

  return (
    <Transition
      show={!!toast}
      as="div"
      style={{ zIndex: 200 }}
      className="fixed bottom-7 right-12"
      enter="transition-opacity ease-out duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition ease-in duration-75"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div style={{ minWidth: "360px" }} className={classNames(className, "rounded flex items-center px-7 py-6 text-grey-900")}>
        {toast?.cta ? (
          toast.cta
        ) : (
          <div className="flex-1 flex items-center">
            {icon && <div className="mr-6">{icon}</div>}

            {toastTitle && !render && (
              <span className="font-semibold">
                <Trans parent="span">{toastTitle}</Trans>
                {toast?.message && <span>&nbsp; - &nbsp;</span>}
              </span>
            )}

            {render || <span className="whitespace-nowrap">{t(toast?.message ?? "")}&nbsp;</span>}

            {toast?.action && <span className="font-semibold">{toast?.action}&nbsp;</span>}

            {toast?.linkTo && (
              <span className="flex items-center">
                <NavLink to={toast.linkTo} onClick={toastClose} className="text-light-blue-900 hover:underline">
                  {toast.linkMessage || "View details"}
                </NavLink>
              </span>
            )}
          </div>
        )}

        <button data-testid="notification-close-btn" className="ml-6" onClick={toastClose}>
          <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>
    </Transition>
  );
};

export default Toast;
