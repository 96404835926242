import { Transition } from "@headlessui/react";
import React, { MouseEvent, useEffect, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import { Autocomplete } from "~/common/selectables";
import { TruncateText } from "~/common/text";
import { useMe } from "~/core/account";
import { useAuth } from "~/core/auth";
import { ContactAvatar, displayName, User } from "~/core/contacts";
import { Org, useOrg } from "~/core/org";

const NavBarUserProfile: React.FC = () => {
  const { t } = useTranslation();
  const { logOut } = useAuth();
  const { me } = useMe();
  const { org, switchOrg } = useOrg();
  const componentRef = useRef<HTMLUListElement | null>(null);
  const [expanded, setExpanded] = useState<boolean>(false);
  const { trackEvent, hardShutdown } = useIntercom();
  const [userName, setName] = useState("");
  const [mouseEntered, setMouseEntered] = useState(false);

  const onLogout = (evt: MouseEvent<HTMLButtonElement>) => {
    evt.stopPropagation();
    evt.preventDefault();

    logOut();
    trackEvent("logout-completed");
    hardShutdown();
  };

  const resetNavProfile = () => {
    if (mouseEntered) {
      setExpanded(false);
      setMouseEntered(false);
    }
  };

  const expand = (evt: MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault();
    setExpanded(!expanded);
  };

  const switchActiveOrg = async (orgId: string) => {
    resetNavProfile();
    switchOrg(orgId);
  };

  useEffect(() => {
    setName(displayName(me as User));
  }, [me?.id]);

  if (!org) return <div />;
  const activeOrg = org as Org;
  const allOrgs = me?.orgs ?? [];

  return (
    <>
      <ul ref={componentRef} data-testid="navbar-user-profile relative">
        <li className="relative overflow-visible">
          <button onClick={expand} className="flex items-center gap-4 px-6 w-full bg-transparent text-grey-900">
            <div style={{ width: "40px" }}>
              <ContactAvatar style={{ width: "40px", height: "40px" }} name={userName} />
            </div>
            <div className="flex text-black" style={{ maxWidth: "100px" }}>
              <TruncateText
                showTooltip={false}
                text={displayName({
                  firstName: me?.firstName ?? "",
                  lastName: me?.lastName ?? "",
                })}
              />
            </div>
          </button>
        </li>
      </ul>

      <Transition
        show={expanded}
        as="div"
        enter="transition duration-300 ease-in-out"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition duration-300 ease-in-out"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        data-testid="animated-profile"
        className="bg-white fixed bottom-9 left-8 rounded z-10 w-max shadow-lg p-6"
        onMouseEnter={() => expanded && setMouseEntered(true)}
        onMouseLeave={resetNavProfile}
      >
        <span className="text-grey-800 fs-mask mb-4 block">{me?.email}</span>

        <div style={{ minWidth: 200 }}>
          {(allOrgs.length ?? 0) > 1 ? (
            <Autocomplete
              asModal
              menuPosition="fixed"
              menuPlacement="top"
              isFieldGrid={false}
              creatable={false}
              isClearable={false}
              options={me?.orgs.map(({ id, name }) => ({ label: name, value: id }))}
              value={activeOrg.id}
              className="w-full"
              onChange={(newValue: any) => switchActiveOrg(newValue.value)}
            />
          ) : (
            <ul data-testid="orgs-list" className="flex flex-col gap-4 overflow-y-auto" style={{ maxHeight: "75vh" }}>
              <li key={`org-${activeOrg.id}`}>
                <button className="flex items-center text-blue-900 hover:text-light-blue-900 w-full overflow-hidden">
                  <span data-testid="current-org" className="flex-1 text-left truncate font-semibold mr-4">
                    {activeOrg.name}
                  </span>
                </button>
              </li>
            </ul>
          )}
        </div>

        <ul className="mt-7 flex flex-col gap-4">
          <li>
            <NavLink onClick={resetNavProfile} className="flex items-center text-blue-900 hover:text-light-blue-900 hover:no-underline" to="/workspace">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                />
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
              </svg>
              <span className="font-semibold ml-4">{t("Settings")}</span>
            </NavLink>
          </li>

          <li>
            <NavLink onClick={resetNavProfile} className="flex items-center text-grey-900 hover:text-light-blue-900 hover:no-underline" to="/account">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
              </svg>
              <span className="font-semibold ml-4">{t("Account")}</span>
            </NavLink>
          </li>
        </ul>

        <ul className="mt-7">
          <li>
            <button className="flex items-center text-grey-900 hover:text-light-blue-900" onClick={onLogout}>
              <Trans parent="span" className="ml-2 truncate text-caption2 font-semibold">
                Logout
              </Trans>
            </button>
          </li>
        </ul>
      </Transition>
    </>
  );
};

export default NavBarUserProfile;
