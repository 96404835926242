import classNames from "classnames";
import mapboxgl, { PaddingOptions } from "mapbox-gl";

export type LatLng = {
  lat: number;
  lng: number;
};

/* eslint-disable no-underscore-dangle */
export class CecilMapControl {
  _map?: mapboxgl.Map;

  _container?: HTMLDivElement;

  _restoreTo?: mapboxgl.LngLatBounds;

  _padding?: number | PaddingOptions;

  _onRestore?: Function;

  constructor(options?: { restoreTo?: mapboxgl.LngLatBounds; padding?: number | PaddingOptions; onRestore?: Function }) {
    this._restoreTo = options?.restoreTo;
    this._padding = options?.padding;
    this._onRestore = options?.onRestore;
  }

  onAdd(map: mapboxgl.Map) {
    this._map = map;
    this._container = document.createElement("div");
    this._container.className = "flex flex-col m-6";

    /* ZOOM IN BUTTON */
    const zoomInButton = this.buildButton(
      `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 7V17" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M17 12H7" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>`,
      "rounded-t mb-0"
    );
    zoomInButton.style.marginBottom = "0";
    zoomInButton.addEventListener("click", () => {
      this._map?.easeTo({
        zoom: this._map.getZoom() + 1,
        padding: this._padding,
      });
    });

    /* ZOOM OUT BUTTON */
    const zoomOutButton = this.buildButton(
      `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17 12H7" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>`,
      "rounded-b"
    );
    zoomOutButton.addEventListener("click", () => {
      this._map?.easeTo({
        zoom: this._map.getZoom() - 1,
        padding: this._padding,
      });
    });

    /* RESTORE BUTTON */
    const restoreBounds = this.buildButton(
      `<svg width="24" height = "24" viewBox = "0 0 24 24" fill = "none" xmlns = "http://www.w3.org/2000/svg" >
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM12 3.44774C16.7233 3.44774 20.5523 7.27672 20.5523 12C20.5523 16.7233 16.7233 20.5523 12 20.5523C7.27672 20.5523 3.44774 16.7233 3.44774 12C3.44774 7.27672 7.27672 3.44774 12 3.44774ZM15.0084 8.08461L10.0964 9.6218C9.87 9.69266 9.69266 9.87 9.6218 10.0964L8.08461 15.0084C7.91011 15.566 8.43404 16.0899 8.99164 15.9154L13.9036 14.3782C14.13 14.3073 14.3073 14.13 14.3782 13.9036L15.9154 8.99164C16.0899 8.43404 15.566 7.91011 15.0084 8.08461ZM14.1204 9.87856L13.109 13.109L9.87856 14.1204L10.8901 10.8901L14.1204 9.87856Z" fill="currentColor" />
      </svg>`,
      "rounded mt-4"
    );

    restoreBounds.addEventListener("click", () => {
      if (this._onRestore) this._onRestore();
      if (this._restoreTo) {
        this._map?.fitBounds(this._restoreTo, {
          duration: 300,
          padding: this._padding,
        });
      }
    });

    this._container.appendChild(zoomInButton);
    this._container.appendChild(zoomOutButton);
    this._container.appendChild(restoreBounds);

    return this._container;
  }

  onRestore(callback: () => void): void {
    this._onRestore = callback;
  }

  onRemove() {
    this._container?.parentNode?.removeChild(this._container);
    this._map = undefined;
  }

  // eslint-disable-next-line class-methods-use-this
  buildButton(icon: string, className: string): HTMLButtonElement {
    const button = document.createElement("button");
    const classList = classNames(
      className,
      "w-max",
      "mapboxgl-ctrl",
      "portofolio-ctrl",
      "text-btnsmall",
      "p-1",
      "bg-white",
      "text-navy-900",
      "btn",
      "font-inter",
      "font-bold",
      "transition-colors",
      "ring-0",
      "border-0",
      "outline-none",
      "hover:bg-blue-100",
      "hover:text-white",
      "shadow"
    );

    button.type = "button";
    button.className = classList;
    button.innerHTML = icon;
    return button;
  }
}
