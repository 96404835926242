import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Button } from "~/common/forms/button";
import { Section } from "~/common/containers";
import { FormSectionProps } from "../models";

const FormArea: React.FC<FormSectionProps> = ({ children, buttonText, editable = true, scrollIntoView }) => {
  const { t } = useTranslation();
  const [edit, setEditMode] = useState(false);
  const areaRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    const { current } = areaRef;

    if (scrollIntoView && current?.scrollIntoView)
      current?.scrollIntoView({
        behavior: "smooth",
      });
  }, [edit]);

  return (
    <div className="page-container" ref={areaRef}>
      {children({
        edit,
        setEditMode,
      })}

      {editable ? (
        <Button size="btn-small" variant="btn-secondary" onClick={() => setEditMode(true)} className={classNames(edit && "invisible")}>
          {buttonText || t("Edit")}
        </Button>
      ) : null}
    </div>
  );
};

const FormSection: React.FC<FormSectionProps> = (props) => {
  const { title, sectionIsOpen = false, children, ...formAreaProps } = props;

  const Form = <FormArea {...formAreaProps}>{children}</FormArea>;

  return (
    <>
      {title ? (
        <Section isOpen={sectionIsOpen} title={title}>
          {Form}
        </Section>
      ) : (
        Form
      )}
    </>
  );
};

export default FormSection;
