import mapboxgl from "mapbox-gl";
import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { OptionTypeBase } from "react-select";
import { AddressAutocomplete, Map, parseGeoData } from "~/common/map";
import { Autocomplete, Variant } from "~/common/selectables";
import RegionAutocomplete from "~/common/map/components/RegionAutocomplete";
import { Button } from "~/common/forms/button";
import { FormInput } from "~/common/forms/input";
import { countryCodesToOptions } from "../utils";

const countryCodeOptions = countryCodesToOptions();

type FormInitialValues = {
  latitude?: number | null;
  longitude?: number | null;
  address?: string | null;
  region?: string | null;
  countryCode?: string | null;
};

type FormData = {
  latitude?: number;
  longitude?: number;
  address: string;
  region: any;
  countryCode: any;
};

const extractFormData = (initialValues?: FormInitialValues): FormData => {
  return {
    latitude: initialValues?.latitude || undefined,
    longitude: initialValues?.longitude || undefined,
    address: initialValues?.address || "",
    region: initialValues?.region || "",
    countryCode: initialValues?.countryCode || "",
  };
};

type Props = {
  onContinue: (data: any) => void;
  onCancel: () => void;
  initialValues?: FormInitialValues;
};

const validLat = (l?: number) => !!(l && l >= -90 && l <= 90);
const validLng = (l?: number) => !!(l && l >= -180 && l <= 180);

const ProjectWizardLocation: React.FC<Props> = ({ onContinue, onCancel, initialValues }) => {
  const { t } = useTranslation();
  const [regionCountryCode, setRegionCountryCode] = useState<string>("");

  const {
    control,
    formState: { isValid, errors },
    handleSubmit,
    setValue,
    trigger,
    getValues,
    watch,
    reset,
  } = useForm<FormData>({
    defaultValues: extractFormData(initialValues),
    mode: "onChange",
  });

  useEffect(() => {
    if (initialValues?.latitude && initialValues.latitude) reset(extractFormData(initialValues), { keepDefaultValues: true });
  }, [reset]);

  const lat = watch("latitude");
  const lng = watch("longitude");

  const validateCoordinates = (): boolean => {
    const [latitude, longitude] = getValues(["latitude", "longitude"]);
    if (!latitude && !longitude) return true;
    return validLng(longitude) && validLat(latitude);
  };

  const handleAddressChange = (field: any, evt: OptionTypeBase | null) => {
    const { value } = evt ?? {};
    const { center } = value ?? {};

    // eslint-disable-next-line @typescript-eslint/no-shadow
    const [lng, lat] = center ?? [];
    const { region, countryCode } = parseGeoData(value);

    setValue("region", region ?? null);
    setValue("countryCode", countryCode ?? null);
    setValue("latitude", lat);
    setValue("longitude", lng);

    field.onChange(value?.place_name ?? null);
    trigger();
  };

  const handleLngLatChange = (field: any, evt: OptionTypeBase | null) => {
    const { value } = evt?.target as HTMLInputElement;
    field.onChange(value ? Number(value) : null);
    trigger();
  };

  const onSubmit = (data: any) => {
    onContinue(data);
  };

  return (
    <div className="m-auto w-10/12" style={{ maxWidth: "950px" }}>
      <form onSubmit={handleSubmit(onSubmit)} className="form-container form-col justify-between" style={{ height: "calc(100vh - 10rem)", maxHeight: "628px" }}>
        <div>
          <Trans ns="projects" parent="h1" className="text-blue-900">
            Set the project location
          </Trans>
          <Trans ns="projects" parent="div">
            Mark the project on the map to find it easily and get geographical insights.
          </Trans>
        </div>

        <div className="flex-1 form-container scrollbar-cecil-y">
          <Controller
            control={control}
            name="address"
            render={({ field }) => (
              <AddressAutocomplete
                label={t("Address")}
                inputId="address"
                value={field.value}
                placeholder={t("Search an address...")}
                description={t("Search for an address or region. You can update this later.")}
                isClearable
                variant={Variant.WHITE}
                menuPortalTarget={document.body}
                closeMenuOnSelect
                onChange={(evt: any) => handleAddressChange(field, evt)}
              />
            )}
          />

          <div className="flex items-start gap-7 w-full">
            {!lat || !lng ? (
              <div className="bg-blue-200 flex-1 flex flex-col items-center justify-center p-7 text-center rounded" style={{ height: "316px" }}>
                <span className="text-orange-500">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 mb-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                    />
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                  </svg>
                </span>

                <div>
                  <Trans i18nKey="Search for a location to set the coordinates or enter them manually.">
                    Search for a location to set
                    <br /> the coordinates or enter
                    <br /> them manually.
                  </Trans>
                </div>
              </div>
            ) : (
              <div className="flex-1">
                <Map
                  disabledMarkerClick
                  preserveDefaultZoom
                  zoom={9}
                  markers={[
                    {
                      projectId: "",
                      siteId: "",
                      projectAreaHectares: 0,
                      lat: !validLat(lat) ? 0 : lat,
                      lng: !validLng(lng) ? 0 : lng,
                    },
                  ]}
                  center={[!validLng(lng) ? 0 : lng, !validLat(lat) ? 0 : lat]}
                  height={316}
                  onRestore={(map) => {
                    map.easeTo({
                      center: new mapboxgl.LngLat(lng, lat),
                      zoom: 9,
                    });
                  }}
                />
              </div>
            )}

            <div className="flex-1 form-container">
              <Controller
                control={control}
                name="longitude"
                rules={{
                  min: -180,
                  max: 180,
                  validate: validateCoordinates,
                }}
                render={({ field }) => (
                  <FormInput
                    max="180"
                    min="-180"
                    step="any"
                    type="number"
                    hasError={!!errors.longitude}
                    label={t("Longitude")}
                    value={field.value}
                    name={field.name}
                    onChange={(evt) => handleLngLatChange(field, evt)}
                  />
                )}
              />

              <Controller
                control={control}
                name="latitude"
                rules={{
                  min: -90,
                  max: 90,
                  validate: validateCoordinates,
                }}
                render={({ field }) => (
                  <FormInput
                    type="number"
                    max="90"
                    min="-90"
                    step="any"
                    label={t("Latitude")}
                    name={field.name}
                    value={field.value}
                    onChange={(evt) => handleLngLatChange(field, evt)}
                    hasError={!!errors.latitude}
                  />
                )}
              />

              <Controller
                control={control}
                name="countryCode"
                render={({ field }) => (
                  <Autocomplete
                    label={t("Country")}
                    id="countryCode"
                    name={field.name}
                    value={field.value}
                    closeMenuOnSelect
                    menuPosition="fixed"
                    inputId="countryCode"
                    menuPlacement="bottom"
                    options={countryCodeOptions}
                    menuPortalTarget={document.body}
                    placeholder={t("Select a country...")}
                    onChange={(e: any) => {
                      const newValue = e?.value || null;
                      if (newValue && newValue.startsWith("GB-")) {
                        setRegionCountryCode("GB");
                      } else {
                        setRegionCountryCode(newValue);
                      }

                      if (getValues("region") || newValue === null) {
                        setValue("region", null);
                      }

                      field.onChange(newValue);
                      trigger();
                    }}
                  />
                )}
              />

              <Controller
                control={control}
                name="region"
                render={({ field }) => {
                  return (
                    <RegionAutocomplete
                      isClearable
                      countryCode={regionCountryCode}
                      value={field.value}
                      label={t("State/province")}
                      inputId="region"
                      closeMenuOnSelect
                      variant={Variant.WHITE}
                      menuPortalTarget={document.body}
                      onChange={(evt: any) => {
                        field.onChange(evt?.value?.text || null);
                        trigger();
                      }}
                    />
                  );
                }}
              />
            </div>
          </div>
        </div>

        <footer className="flex justify-between bg-white sticky bottom-0 py-4">
          <Button variant="btn-transparent" size="btn-large" onClick={onCancel}>
            {t("Back")}
          </Button>

          <Button type="submit" variant="btn-blue" size="btn-large" disabled={!isValid}>
            {t("Finish")}
          </Button>
        </footer>
      </form>
    </div>
  );
};

export default ProjectWizardLocation;
