import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

const DEFAULT_LNG = "en";
const storedLng = localStorage.getItem("i18nextLng");
if (!storedLng) localStorage.setItem("i18nextLng", DEFAULT_LNG);

// do not break current en-US
if (storedLng === "en-US") {
  localStorage.setItem("i18nextLng", "en");
  localStorage.setItem("measurement", "acres");
}
if (storedLng === "en-AU") {
  localStorage.setItem("i18nextLng", "en");
}
if (storedLng === "es-ES") {
  localStorage.setItem("i18nextLng", "es");
}
if (storedLng === "fr-FR") {
  localStorage.setItem("i18nextLng", "fr");
}

i18n
  .use(backend)
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    debug: true,
    fallbackLng: DEFAULT_LNG,
    ns: ["activities", "alerts", "common", "contacts", "dashboard", "events", "files", "inventory", "ncl", "notes", "projects", "prospects", "tasks"],
    defaultNS: "common",
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
