/* eslint-disable no-nested-ternary */
import moment from "moment";
import { AutocompleteOption } from "~/common/selectables";
import { Credit, CreditIssuance, CreditRecordType, CreditStatus, IssuanceFormType, IssuancePeriod, ScheduleIssuance, UnitGroupsOptions } from "./models";

export type CreditWithSelectable = Credit & { labelSelectableId: string | null };

export const forecasted: Array<keyof CreditWithSelectable> = [
  "unitType",
  "forecastedAt",
  "status",
  "monitoringPeriodStartedAt",
  "totalUnits",
  "labelSelectableId",
  "applicationId",
];

export const issued: Array<keyof CreditWithSelectable> = [
  "unitType",
  "totalUnits",
  "status",
  "monitoringPeriodStartedAt",
  "vintageYear",
  "submittedBy",
  "submittedAt",
  "approvedAt",
  "retiredAt",
  "applicationId",
  "serialId",
  "labelSelectableId",
];

export const schedule: Array<keyof ScheduleIssuance> = [
  "unitType",
  "numberOfRepeats",
  "repeatPeriod",
  "unitsPerIssuance",
  "numberOfRecurrences",
  "firstforecastedIssuance",
];

export const CreditStatusFields: Record<IssuanceFormType, Array<keyof CreditIssuance>> = {
  forecasted,
  issued,
  buffer: issued,
  leakage: issued,
  schedule,
};

export const showCreditField = (fields: Array<keyof Credit>, field: keyof Credit) => fields.includes(field);

export const recordLabelClassNames: Partial<Record<CreditRecordType, string>> = {
  forecasted: "bg-purple-25",
  issued: "bg-teal-25",
  buffer: "bg-orange-25",
  leakage: "bg-pink-25",
};

export const RecordType: Partial<Record<CreditRecordType, string>> = {
  forecasted: "Forecasted",
  issued: "Issued",
  buffer: "Buffer",
  leakage: "Leakage",
};

export const modalTitleMap: Record<CreditRecordType, { create: string; edit: string }> = {
  forecasted: {
    create: "Add forecasted issuance",
    edit: "Edit forecasted issuance",
  },
  issued: {
    create: "Add issuance",
    edit: "Edit issuance",
  },
  buffer: {
    create: "Add issuance",
    edit: "Edit issuance",
  },
  leakage: {
    create: "Add issuance",
    edit: "Edit issuance",
  },
};

export const periodOptions: AutocompleteOption<IssuancePeriod>[] = [
  {
    label: "Years",
    value: "year",
  },
  {
    label: "Months",
    value: "month",
  },
];

export const unitTypeOptions: AutocompleteOption[] = [
  { label: "ACCU", value: "accu" },
  { label: "KACCU", value: "kaccu" },
  { label: "VCU", value: "vcu" },
  { label: "VER", value: "ver" },
  { label: "PIU", value: "piu" },
  { label: "PIU RESERVE", value: "piu_reserve" },
  { label: "WCU", value: "wcu" },
  { label: "WCU RESERVE", value: "wcu_reserve" },
  { label: "NZU", value: "nzu" },
  { label: "PCU", value: "pcu" },
  { label: "REGEN", value: "regen" },
  { label: "BCARBON", value: "bcarbon" },
];

export const toMomentPeriod: Record<IssuancePeriod, moment.unitOfTime.DurationConstructor> = {
  month: "months",
  year: "years",
};

export const DEFAULT_INVENTORY_FILTERS: UnitGroupsOptions = {
  sort: "submitted_at",
  limit: parseInt(process.env.REACT_APP_PROJECTS_LIMIT ?? "20", 10),
  page: 1,
  offset: 0,
  recordType: undefined,
  creditType: undefined,
  flagged: false,
};

export const creditStatusOptions: AutocompleteOption<CreditStatus>[] = [
  { label: "Submitted", value: "submitted" },
  { label: "Approved", value: "approved" },
  { label: "Retired", value: "retired" },
];

export const INVENTORY_KEY = "inventory";
