import { createContext } from "react";
import { Project, ProjectContextType } from "../models";

export const ProjectContext = createContext<ProjectContextType>({
  id: "",
  loading: true,
  processing: false,
  project: {} as Project,
  invite: () => {},
  duplicate: () => Promise.resolve({} as Project),
  update: async () => {},
  archive: () => {},
  unArchive: () => {},
  replace: () => {},
  setFlagLabel: async () => {},
  removeFlag: async () => {},
  updateProjectSite: async () => {},
  updateLocation: async () => {},
  createLocation: async () => {},
  deleteLocation: async () => {},
});
