import geocodeService from "@mapbox/mapbox-sdk/services/geocoding";
import { LngLatLike } from "mapbox-gl";
import { AutocompleteOption } from "../selectables";

const mapboxToken = process.env.REACT_APP_MAPBOX_TOKEN ?? "";

export const stringToColour = (str: string): string => {
  let hash: number = 0;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < str.length; i++) {
    // eslint-disable-next-line no-bitwise
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  let colour = "#";

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < 3; i++) {
    // eslint-disable-next-line no-bitwise
    const value = (hash >> (i * 8)) & 0xff;
    colour += `00${value.toString(16)}`.substr(-2);
  }
  return colour;
};

export const parseGeoData = (geoData: any): { [key: string]: string | undefined } => {
  let locality;
  let region;
  let countryCode;
  let postcode;

  if (geoData?.context) {
    countryCode = geoData.context.find(({ id }: any) => id.includes("country"))?.short_code?.toUpperCase();
    region = geoData.context.find(({ id }: any) => id.includes("region"));
    postcode = geoData.context.find(({ id }: any) => id.includes("postcode"))?.text ?? "";
    locality = geoData.context.find(({ id }: any) => id.includes("locality"))?.text ?? "";

    if (countryCode === "GB") {
      countryCode = region?.short_code?.toUpperCase() ?? "";
      region = geoData.context.find(({ id }: any) => id.includes("district"));
    }
  }

  return {
    address: geoData?.text,
    locality,
    region: region?.text ?? "",
    postcode,
    countryCode,
  };
};

export const addressOptions = (features: any[], key = "place_name"): AutocompleteOption[] =>
  features.reduce(
    (prev, curr) => [
      ...prev,
      {
        value: curr,
        label: curr[key],
      },
    ],
    [] as AutocompleteOption[]
  );

export const geoCodeRegions = (text: string, countryCode: string) => {
  return geocodeService({ accessToken: mapboxToken })
    .forwardGeocode({
      query: `${text}`.trim(),
      limit: 4,
      autocomplete: true,
      mode: "mapbox.places",
      countries: [countryCode],
      types: ["region", "district", "place", "locality"],
    })
    .send()
    .then((response: any) => {
      return response?.body?.features ?? [];
    });
};

export const geocodeAddresses = (text: string) => {
  return geocodeService({ accessToken: mapboxToken })
    .forwardGeocode({
      query: `${text}`.trim(),
      autocomplete: true,
      limit: 4,
      types: ["address"],
      mode: "mapbox.places",
    })
    .send()
    .then((response: any) => response?.body?.features ?? []);
};

export function geoCode(address = "", region = ""): Promise<number[]> {
  return geocodeService({ accessToken: mapboxToken })
    .forwardGeocode({
      query: `${address || ""} ${region || ""}`.trim(),
      autocomplete: false,
      limit: 1,
      mode: "mapbox.places",
    })
    .send()
    .then((response: any) => {
      if (response && response.body && response.body.features && response.body.features.length) {
        const { center } = response.body.features[0];
        return center;
      }

      // Default to Sydney
      return [151.2099, -33.865143];
    });
}

export function reverseGeoCode(
  point: LngLatLike
): Promise<{
  region: string;
  countryCode: string;
  address: string;
  value: any;
} | null> {
  return geocodeService({ accessToken: mapboxToken })
    .reverseGeocode({
      query: point,
      mode: "mapbox.places-permanent",
    })
    .send()
    .then((response: any) => {
      if (response && response.body && response.body.features && response.body.features.length) {
        const { features } = response.body;
        const country = features.find((f: any) => f.place_type.includes("country"));
        const region = features.find((f: any) => f.place_type.includes("region"));

        const address = response.body.features[0].place_name;

        return {
          address,
          value: response.body.features[0],
          region: region?.text ?? "",
          countryCode: country?.properties?.short_code?.toUpperCase() ?? "",
        };
      }

      // Default to Sydney
      return null;
    });
}
