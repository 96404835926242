import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { DEFAULT_PATH, REDIRECT_TO_KEY } from "../utils";
import { RedirectProviderContext } from "./RedirectProviderContext";

const RedirectProvider: React.FC = ({ children }) => {
  const location = useLocation();
  const [redirectTo, setRedirectTo] = useState(sessionStorage.getItem(REDIRECT_TO_KEY) || DEFAULT_PATH);

  useEffect(() => {
    const redirect = location.pathname === "/" ? DEFAULT_PATH : location.pathname;
    sessionStorage.setItem(REDIRECT_TO_KEY, redirect);
    setRedirectTo(redirect);
  }, []);

  return <RedirectProviderContext.Provider value={{ redirectTo }}>{children}</RedirectProviderContext.Provider>;
};

export default RedirectProvider;
