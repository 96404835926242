import React, { useEffect, useState } from "react";
import LottieLoader from "~/common/animations/LottieLoader";
import { FIRST_LOGIN_KEY } from "../models";
import { logout, retrieveToken } from "../services";
import { AuthContext } from "./AuthContext";

const AuthProvider: React.FC = ({ children }) => {
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [accessToken, setAccesToken] = useState("");
  const [loading, setLoading] = useState(true);

  const logOut = () => {
    localStorage.removeItem(FIRST_LOGIN_KEY);
    logout();
  };

  useEffect(() => {
    (async () => {
      try {
        const session = await retrieveToken();
        setLoggedIn(true);
        setAccesToken(session.accessToken);
      } catch (e) {
        localStorage.removeItem(FIRST_LOGIN_KEY);
        setLoggedIn(false);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  if (loading)
    return (
      <div className="h-screen bg-white absolute top-0 left-0 w-full flex justify-center items-center z-50">
        <LottieLoader />
      </div>
    );

  return <AuthContext.Provider value={{ isLoggedIn, accessToken, loading, logOut }}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
