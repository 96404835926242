export type CounterBadgeVariant = "red" | "deactive";

export const CounterBadgeVariantClassNames: Record<CounterBadgeVariant, string> = {
  red: "badge-red",
  deactive: "badge-deactive",
};

export type CounterBadgeProps = {
  variant: CounterBadgeVariant;
  counter?: number;
  active?: boolean;
  border?: boolean;
  width?: number;
  height?: number;
};
