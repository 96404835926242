import { User } from "~/core/contacts";
import { ProjectFlag } from "~/core/projects/models";
import apiClient from "./api-client";
import { buildAPIURL } from "./http";
import { Favourite, ResourceType } from "./models";

export const getMe = async (): Promise<User | null> => {
  try {
    const response = await apiClient.get(buildAPIURL("/v1/me"));
    const user = response.data as User;
    const orgs = user.orgs.map((o) => ({
      ...o,
      navigation: o.navigation ? JSON.parse(o.navigation.toString()) || [] : null,
      assessmentFieldsSettings: o.assessmentFieldsSettings ? JSON.parse(o.assessmentFieldsSettings) || [] : null,
      siteFieldsSettings: o.siteFieldsSettings ? JSON.parse(o.siteFieldsSettings) || [] : null,
      customConfig: o.customConfig ? JSON.parse(o.customConfig) || {} : {},
      overviewFieldsSettings: o.overviewFieldsSettings
        ? JSON.parse(o.overviewFieldsSettings) || []
        : {
            default: {
              projectTypeMethodologyId: true,
              startedAt: true,
              projectDurationYears: true,
              propertyId: true,
              registryId: true,
              registryAccountNumber: true,
              projectSources: true,
              projectTags: true,
            },
          },
    }));

    return { ...user, orgs };
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }

  return null;
};

export const updateMe = async (me: { id: string } & Partial<User>): Promise<User> => {
  const response = await apiClient.patch(buildAPIURL("/v1/me"), me);
  return response.data as User;
};

/**
 * Favourite services
 */
export const createFlag = (resourceType: ResourceType, resourceId: string, labelSelectableId: string | null): Promise<ProjectFlag> => {
  const url = buildAPIURL("v1/flags");
  return apiClient.post(url, { type: resourceType, resourceId, labelSelectableId }).then((response) => response.data);
};

export const updateFlag = (id: string, labelSelectableId: string | null): Promise<ProjectFlag> => {
  const url = buildAPIURL(`v1/flags/${id}`);
  return apiClient.patch(url, { labelSelectableId }).then((response) => response.data);
};

export const removeFlag = (flagId: string): Promise<Favourite> => {
  const url = buildAPIURL(`v1/flags/${flagId}`);
  return apiClient.delete(url);
};
