import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { List } from "~/app.namespace";
import { TableColumn } from "~/common/containers/table";
import { NotFoundError } from "~/common/http/utils";
import { Feedback } from "~/common/models";
import { useToast } from "~/common/toasts";
import { MeContext, useMe } from "../account";
import { User } from "../contacts";
import { ProjectDocument, getResourceDocuments } from "../files";
import { useOrg } from "../org";
import { ProjectContext } from "./components/ProjectContext";
import {
  AssignContactArgs,
  FormulaGroup,
  GetProjectsOptions,
  GroundcoverAssets,
  Project,
  ProjectFilters,
  ProjectLocation,
  ProjectMarker,
  Site,
  Stage,
  StageTemplateGroup,
} from "./models";
import {
  archive as archiveProject,
  deleteGroundcoverImage,
  getGroundcoverImages,
  getProject,
  getProjectFilters,
  getProjectFormulaGroups,
  getProjects,
  getProjectsMarkers,
  getStageTemplateGroups,
  getStages,
  invite,
  unArchive,
  uploadGroundcoverImage,
} from "./services";
import { getDefaultFilterOptions } from "./utils";

export const useProject = () => {
  const {
    archive,
    duplicate,
    id,
    loading,
    processing,
    project,
    replace,
    setFlagLabel,
    removeFlag,
    // eslint-disable-next-line @typescript-eslint/no-shadow
    unArchive,
    update,
    updateProjectSite,
    updateLocation,
    createLocation,
    deleteLocation,
  } = useContext(ProjectContext);

  return {
    archive,
    duplicate,
    id,
    loading,
    processing,
    project,
    replace,
    setFlagLabel,
    removeFlag,
    unArchive,
    update,
    updateProjectSite,
    updateLocation,
    createLocation,
    deleteLocation,
  };
};

export const useGroundcoverImages = (project: Project) => {
  const [site] = project.sites;
  const { replace } = useProject();
  const [images, setGroundcoverImages] = useState<GroundcoverAssets[]>([]);
  const [loading, setLoading] = useState(true);
  const [uploading, setUploading] = useState(false);
  const { toastLoading, closeToastLoading, toastSuccess, toastError } = useToast();
  const { t } = useTranslation();

  const upload = async (files: File[]) => {
    setUploading(true);
    const filteredFiles = files.filter((f) => f?.type.includes("image/"));
    const count = filteredFiles.length;

    toastLoading({
      message: t("Your images are uploading.", { count }),
    });
    const newSiteWithImages = filteredFiles.reduce((prev, file) => {
      return prev.then(() => {
        return uploadGroundcoverImage(site, file);
      });
    }, Promise.resolve({}) as Promise<Site>);

    newSiteWithImages
      .then((newSite) => {
        replace({
          ...project,
          sites: [newSite],
        });
        toastSuccess({
          message: t("Images uploaded successfully.", { count }),
        });
      })
      .catch((err: any) => {
        // eslint-disable-next-line @typescript-eslint/no-shadow
        const { response } = err;
        const { title } = response.data.errors[0];
        switch (response.status) {
          case 500:
            toastError({ message: t("Unable to upload file. Please try again or contact support if you need assistance.", { ns: "files" }) });
            break;
          default:
            toastError({ message: t(title, { ns: "files" }) });
            break;
        }
        return Promise.resolve();
      })
      .finally(() => {
        setUploading(false);
        closeToastLoading();
      });
  };

  const remove = async (asset: GroundcoverAssets) => {
    await deleteGroundcoverImage(site, asset);

    replace({
      ...project,
      sites: [
        {
          ...site,
          groundCoverAssets: site.groundCoverAssets?.filter((g) => g.id !== asset.id),
        },
      ],
    });
  };

  useEffect(() => {
    (async () => {
      try {
        const assets = await getGroundcoverImages(site);
        setGroundcoverImages(assets);
      } finally {
        setLoading(false);
      }
    })();
  }, [project.id, site]);

  return { images, loading, uploading, upload, remove };
};

export const useProjectsFilters = () => {
  const [loading, setLoading] = useState(true);
  const [filters, setProjectFilters] = useState<ProjectFilters>({} as ProjectFilters);

  useEffect(() => {
    (async () => {
      try {
        const response = await getProjectFilters();
        setProjectFilters(response);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return { filters, loading };
};

export const useProjectTypes = () => {
  const { projectTypes } = useContext(MeContext);
  return { projectTypes };
};

type Props = {
  defaultOptions?: GetProjectsOptions;
};

export const useProjectOptions = (props?: Props) => {
  const history = useHistory();
  const location = useLocation();
  const { org } = useOrg();
  const { defaultOptions = {} } = props ?? {};
  const [searchName, setSearchName] = useState(defaultOptions.search || "");
  const [options, setOptions] = useState<GetProjectsOptions>(defaultOptions);
  const [filterAmount, setFiltersAmount] = useState(0);

  const filter = (opts: GetProjectsOptions) => {
    setOptions(opts);
    setSearchName(opts.search || "");

    const searchQuery = Object.keys(opts)
      .filter((key) => !!opts[key as keyof GetProjectsOptions])
      .reduce((acc, curr) => {
        acc.append(curr, opts[curr as keyof GetProjectsOptions]!.toString());
        return acc;
      }, new URLSearchParams());

    localStorage.setItem(`projectFilters-${org?.id}`, searchQuery.toString());
    history.replace(`${location.pathname}?${searchQuery.toString()}`, { search: searchQuery.toString() });
  };

  // Update amount of filters
  useEffect(() => {
    const validFilterKeys: Array<keyof GetProjectsOptions> = [
      "siteAssignedUserId",
      "stageTemplateId",
      "tagId",
      "region",
      "sourceSelectableId",
      "validatorSelectableId",
      "projectTypeId",
      "projectStatusTemplateId",
    ];

    const totalFilters = validFilterKeys.reduce((prev, curr) => {
      const value = options[curr];
      if (value && value?.toString().trim()) return prev + 1;
      return prev;
    }, 0);

    setFiltersAmount(totalFilters);
  }, [location.search]);

  const clearResults = () => {
    filter(defaultOptions ?? {});
  };

  const sort = (col: TableColumn, dir: List.SortDirection) => {
    filter({ ...options, sort: `${dir === "desc" ? "-" : ""}${col.key}` });
  };

  const changePage = (selectedItem: { selected: number }) => {
    const { selected } = selectedItem;
    filter({ ...options, page: selected });
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const setSearchNameWrapper = (search: string) => {
    setOptions((state) => ({ ...state, page: 1 }));
    setSearchName(search);
  };

  useEffect(() => {
    const filterName = setTimeout(() => {
      filter({ ...options, search: searchName });
    }, 500);

    return () => {
      clearTimeout(filterName);
    };
  }, [searchName]);

  return {
    options,
    setOptions: filter,
    searchName,
    setSearchName: setSearchNameWrapper,
    filterAmount,
    clearResults,
    sort,
    changePage,
  };
};

export const useProjects = (defaultOptions?: GetProjectsOptions) => {
  const { me } = useMe();
  const { org } = useOrg();
  const location = useLocation();

  const { options, setOptions, changePage, sort, searchName, setSearchName } = useProjectOptions({ defaultOptions });
  const [loading, setLoading] = useState(true);
  const [force, setForce] = useState(0);
  const [response, setResponse] = useState<{
    meta?: any;
    projects: Project[];
  }>({ meta: {}, projects: [] });

  const update = (projects: Project[]) => {
    setResponse((prevState) => ({
      ...prevState,
      projects: prevState.projects.map((p) => projects.find(({ id }) => id === p.id) || p),
    }));
  };

  const restore = async (id: string) => {
    if (id) {
      await unArchive({ id });
      setForce(Date.now());
    }
  };

  const archiveProjects = async (projects: Project[]) => {
    if (projects.length) {
      await Promise.all(projects.map((project) => archiveProject(project)));
      setForce(Date.now());
    }
  };

  useEffect(() => {
    const loadProjects = async (user: User) => {
      const { myProjects, ...rest } = options;
      if (myProjects) rest.projectAssignedUserId = me?.id ?? "";

      const [{ meta, data }] = await getProjects({
        ...rest,
        user,
      });

      setResponse({ meta, projects: data.projects });
      setLoading(false);
    };

    if (!location.search) {
      const storedOptions = getDefaultFilterOptions(org?.id);
      delete storedOptions.archived;
      setOptions(storedOptions);
    } else if (me) {
      if (location.search.includes("replace=true")) {
        const params = new URLSearchParams(location.search);
        let newOptions = { page: 1 };
        params.forEach((value, key) => {
          if (key === "replace") return;
          newOptions = { ...newOptions, [key]: value };
        });
        setOptions(newOptions);
        return;
      }

      loadProjects(me);
    }
  }, [location.search, me?.id, force]);

  const { meta } = response;

  return {
    response,
    loading,
    options,
    searchName,
    setSearchName,
    filterAmount: 0,
    currentPage: options.page ?? 1,
    totalPages: Math.ceil(meta.total / meta.perPage) || 1,
    restore,
    update,
    archiveProjects,
    filter: setOptions,
    sort,
    changePage,
    clear: () => ({}),
    setLoading,
  };
};

export const useProjectsMarkers = (defaultOptions?: GetProjectsOptions) => {
  const { me } = useMe();
  const { options, setOptions } = useProjectOptions({ defaultOptions });
  const [response, setResponse] = useState<{
    meta: any;
    data: ProjectMarker[];
  }>({ meta: {}, data: [] });

  useEffect(() => {
    (async () => {
      const { search, myProjects, ...rest } = options;
      if (myProjects) {
        if (me?.positionType === "external") rest.siteAssignedUserId = me.id;
        else rest.projectAssignedUserId = me?.id;
      }
      if (search) rest.name = search;

      const portfolioResponse = await getProjectsMarkers(rest);
      const { meta, data } = portfolioResponse;

      setResponse({ meta, data: data.projectsMarkers });
    })();
  }, [...Object.values(options)]);

  return { response, filter: setOptions };
};

export const useProjectDetails = (forceProject?: Project) => {
  const { projectId } = useParams() as { projectId: string };
  const { me } = useMe();
  const [project, setProject] = useState<Project>(forceProject || ({} as Project));
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  useEffect(() => {
    const loadProject = async () => {
      try {
        const response = await getProject(projectId);
        setProject(response);
      } catch (error: any) {
        if (error instanceof NotFoundError) history.push("/404");
      } finally {
        setLoading(false);
      }
    };

    if (forceProject) {
      setProject(forceProject);
      setLoading(false);
    } else if (me && projectId) loadProject();
  }, [projectId, me]);

  return { id: projectId, project: project as Project, loading };
};

export const useProjectServiceAgreement = (project: Project) => {
  const [serviceDocuments, setServiceDocuments] = useState<ProjectDocument[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { me } = useMe();

  useEffect(() => {
    const loadDocuments = async () => {
      try {
        const docs = await getResourceDocuments({ type: "service_agreement", resourceId: project.id, resourceType: "project" });
        setServiceDocuments(docs);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      } finally {
        setLoading(false);
      }
    };

    if (project.id && me?.id) loadDocuments();
  }, [project.id, me?.id]);

  return {
    serviceDocuments,
    loading,
  };
};

export const useShareProject = (feedback?: Feedback) => {
  const { toastSuccess, toastError } = useToast();
  const [loading, setLoading] = useState(false);

  const shareProject: AssignContactArgs = async ({ project, selectedContacts, message }) => {
    try {
      setLoading(true);
      await invite({
        id: project.id,
        userIds: selectedContacts.map((u) => u.id),
        message,
      });
      if (feedback?.fulfilled) toastSuccess({ message: feedback.fulfilled });
    } catch (e) {
      if (feedback?.rejected) toastError({ message: feedback.rejected });
    } finally {
      setLoading(false);
    }
  };

  return {
    shareProject,
    loading,
  };
};

export const useArchivedCount = (options: GetProjectsOptions) => {
  const [count, setCount] = useState<number>(0);
  const [force, setForce] = useState(0);

  const reload = () => setForce(Date.now);

  useEffect(() => {
    (async () => {
      const [{ meta }] = await getProjects({ ...options, limit: 1, archived: true });
      setCount(meta.total);
    })();
  }, [
    force,
    options?.emptyLocationOnly,
    options?.flagged,
    options?.flagLabelSelectableId,
    options?.id,
    options?.isNotProjectOwnerIds,
    options?.isNotProjectRegions,
    options?.isNotProjectTypeIds,
    options?.isNotRegistryId,
    options?.isNotSiteAssignedUserIds,
    options?.isNotSourceSelectableIds,
    options?.isNotStageTemplateIds,
    options?.isNotStatuses,
    options?.isNotTagSelectableIds,
    options?.myProjects,
    options?.name,
    options?.closedOnly,
    options?.offset,
    options?.page,
    options?.projectAssignedUserId,
    options?.projectOwnerId,
    options?.projectTypeId,
    options?.region,
    options?.registryId,
    options?.search,
    options?.siteAssignedUserId,
    options?.sort,
    options?.sourceSelectableId,
    options?.stageTemplateId,
    options?.status,
    options?.tagId,
    options?.user,
  ]);

  return { count, reload };
};

export const useCoordinates = (location?: ProjectLocation) => {
  // we use Sydney Australia as default
  // const dispatch = useAppDispatch();
  const [lngLat, setLngLat] = useState<number[]>();
  const [useDefault, setUseDefault] = useState(true);

  useEffect(() => {
    if (location) {
      const { latitude, longitude } = location;
      if (longitude && latitude) {
        setLngLat([longitude, latitude]);
        setUseDefault(false);
      } else {
        setLngLat([151.2099, -33.865143]);
        setUseDefault(true);
      }
    }
  }, [location]);

  return { point: lngLat, useDefault };
};

export type UseProjectFormulasResult = {
  formulaGroups: FormulaGroup[] | undefined;
  error: Error | undefined;
  loading: boolean;
  reload: () => void;
};

export const useProjectFormulas = (project: Project): UseProjectFormulasResult => {
  const [formulaGroups, setFormulaGroups] = useState<FormulaGroup[] | undefined>();
  const [error, setError] = useState<Error | undefined>();
  const [loading, setLoading] = useState<boolean>(true);
  const [timestamp, setTimestamp] = useState<number>(Date.now());

  const reload = () => setTimestamp(Date.now());

  useEffect(() => {
    if (project) {
      getProjectFormulaGroups(project.id, !!project.archivedAt)
        .then(setFormulaGroups)
        .catch(setError)
        .finally(() => setLoading(false));
    }
  }, [project, timestamp]);

  return { formulaGroups, error, loading, reload };
};

type UseProjectContractDocumentsResult = {
  contracts: ProjectDocument[];
  loading: boolean;
};

export const useProjectContractDocuments = (project: Project): UseProjectContractDocumentsResult => {
  const [loading, setLoading] = useState(true);
  const [contracts, setContracts] = useState<ProjectDocument[]>([]);

  useEffect(() => {
    if (project.id) {
      getResourceDocuments({ resourceId: project.id, type: "contract" })
        .then(setContracts)
        .finally(() => setLoading(false));
    }
  }, [project]);

  return { loading, contracts };
};

type UseStagesResponse = {
  stages: Stage[];
  loading: boolean;
  error?: Error;
};

export const useStages = (projectId: string): UseStagesResponse => {
  const [stages, setStages] = useState<Stage[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error>();

  useEffect(() => {
    (async () => {
      try {
        const response = await getStages(projectId);
        setStages(response);
      } catch (e) {
        setError(e as Error);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return { stages, loading, error };
};

export const useStageTemplateGroups = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error>();
  const [stageTemplateGroups, setStageTemplateGroups] = useState<StageTemplateGroup[]>([]);

  useEffect(() => {
    (async () => {
      try {
        const groups = await getStageTemplateGroups();
        setStageTemplateGroups(
          groups.sort((a, b) => {
            if (a.projectTypeId === null) return -1;
            if (b.projectTypeId === null) return -1;
            return 1;
          })
        );
      } catch (e) {
        setError(e as Error);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return { error, loading, stageTemplateGroups };
};

// TODO: create a useService hooks to avoid repeating the try catch on a effect
