import { useEffect, useState } from "react";
import { getReportsOverview } from "~/core/reports/services";
import { ReportsOverviewFilters, ReportsOverviewResponse } from "./models";

// Move this to projects
import { User } from "../contacts";
import { getProjects, populateProject, Project } from "../projects";

export const useReportsOverview = (filters: Partial<ReportsOverviewFilters>, reload: number = 0) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<ReportsOverviewResponse>();

  const getOverview = async () => {
    try {
      setLoading(true);
      const response = await getReportsOverview(filters);
      setData(response);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getOverview();
  }, [filters.chartType, filters.period, filters.projectTypeId, filters.region, filters.stageTemplateId, filters.tagId, filters.sourceSelectableId, reload]);

  const { chartData, meta } = data ?? {};

  return {
    data: chartData,
    loading,
    ...(meta && { meta }),
  };
};

type UsePinProjects = (ids: string[], user: User | null) => { loading: boolean; pinProjects: Project[] };

export const usePinProjects: UsePinProjects = (ids, user) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [pinProjects, setPinProjects] = useState<any>();

  const loadPinProjects = async () => {
    if (!user) return;

    setLoading(true);

    const [{ data }, error] = await getProjects({
      id: ids.join(","),
      user,
    });

    if (error) {
      // Do something
    }

    setPinProjects(data.projects.map((p: Project) => populateProject(p)));
    setLoading(false);
  };

  useEffect(() => {
    if (ids.length > 0) loadPinProjects();
  }, [ids]);

  return { loading, pinProjects: pinProjects ?? ([] as Project[]) };
};
