/* eslint-disable max-classes-per-file */
const apiBase = process.env.REACT_APP_API_BASE_URL ?? "";

export function buildAPIURL(pathname: string, queryParams?: any): string {
  const url = new URL(apiBase);
  url.pathname = pathname;
  if (queryParams) {
    Object.keys(queryParams).forEach((key) => {
      const value = queryParams[key];
      if (value) url.searchParams.append(key, value);
    });
  }
  return url.toString();
}

export class NotFoundError extends Error {
  code = 404;
}

export class UnauthorizedError extends Error {
  code = 401;
}

export class ServerError extends Error {
  code = 500;
}
