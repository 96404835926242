import { Selectable } from "~/common/selectables";
import { AssignedUser, Contact, LegacyPositionType, PositionType, positionTypes, User } from "./models";

type DisplayNameType = {
  firstName?: string;
  lastName?: string;
  companyName?: string;
};

export function displayName(name?: DisplayNameType): string {
  if (!name) return "";
  const { firstName = "", lastName = "", companyName = "" } = name;
  if (firstName && lastName) return `${firstName} ${lastName}`;
  if (firstName) return firstName;
  if (lastName) return lastName;
  if (companyName) return companyName;
  return "";
}

export function userPositionType(user?: User, defaultValue = "--"): string {
  if (!user) return defaultValue;
  return (positionTypes as any)[user.positionType] ?? defaultValue;
}

export const bgByPositionType = (user: User, deactivatedColor = true) => {
  let bgColour: string;

  if (deactivatedColor && user.deactivatedAt) {
    return "grey-20";
  }

  switch (user.positionType as LegacyPositionType) {
    case "external":
    case "landholder":
    case "investor":
    case "operator":
      bgColour = "green-100";
      break;
    case "internal":
    case "project_developer":
      bgColour = "orange-100";
      break;
    default:
      bgColour = "white";
      break;
  }

  return bgColour;
};

export const textByPositionType = (user: User | Contact | AssignedUser) => {
  let textColour: string;
  switch (user.positionType as LegacyPositionType) {
    case "external":
    case "landholder":
    case "investor":
    case "operator":
      textColour = "text-green-600";
      break;
    case "internal":
    case "project_developer":
      textColour = "text-yellow-600";
      break;
    default:
      textColour = "text-transparent";
      break;
  }

  return textColour;
};

export const isAdmin = (user?: User): boolean => user?.positionType === "_cecil_admin";

export const isExternal = (user?: User): boolean => user?.positionType === "external" || user?.positionType === "_cecil_admin";

export const isInternal = (user?: User): boolean => user?.positionType === "internal" || user?.positionType === "_cecil_admin";

export const canOrgs = (user?: User): boolean => isAdmin(user) || isInternal(user);

export const canNotifications = (user?: User): boolean => isAdmin(user) || isInternal(user);

export const hasRole = (user: User, role: PositionType): boolean => user?.positionType === role;

export const mapUserTagToSelectableName = (users: User[], userTags: Selectable[]): User[] => {
  return users?.map((u) => ({
    ...u,
    userTags: u.userTags?.map((ut) => ({
      ...ut,
      name: userTags.find((tag) => tag.id === ut.selectableId)?.name ?? "",
    })),
  }));
};
