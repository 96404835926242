const theme = {
  colors: {
    lightBlue: {
      900: "#06314B",
      800: "#084E72",
      700: "#006FA7",
      600: "#008CCE",
      500: "#06AEF1",
      400: "#42CAFF",
      300: "#78D9FF",
      200: "#B8E9FF",
      100: "#DFF3FF",
      50: "#EFF9FF",
    },
    green: {
      900: "#062D0C",
      800: "#15521E",
      700: "#167F23",
      600: "#18A129",
      500: "#24C338",
      400: "#4CDC5D",
      300: "#87EE93",
      200: "#BCF6C2",
      100: "#DCFCDF",
      50: "#F0FDF1",
    },
    grey: {
      900: "#0C0C0B",
      800: "#31302F",
      700: "#545351",
      600: "#777673",
      500: "#9D9B98",
      400: "#C4C2BE",
      300: "#DFDEDA",
      200: "#EFEEEB",
      100: "#F8F7F4",
      50: "#FDFCFA",
    },
    blue: {
      900: "#000044",
      800: "#030368",
      700: "#0E0E9E",
      600: "#2525DC",
      500: "#4B49F6",
      400: "#6F6EFD",
      300: "#9A98FF",
      200: "#C3C3FF",
      100: "#E4E4FF",
      50: "#F7F7FF",
    },
    orange: {
      900: "#461802",
      800: "#79320E",
      700: "#B54E08",
      600: "#DB7104",
      500: "#F49608",
      400: "#FDBA22",
      300: "#FDCF4C",
      200: "#FEE489",
      100: "#FEF2C7",
      50: "#FFFAEB",
    },
    pink: {
      100: "#FF5088",
      75: "#FF7EA7",
      50: "#FFAFC8",
      25: "#FFE3EC",
    },
    purple: {
      900: "#330269",
      800: "#4E168D",
      700: "#6E19D7",
      600: "#8D3DF5",
      500: "#984CFF",
      400: "#B47DFF",
      300: "#D1B0FF",
      200: "#E5D3FF",
      100: "#F1E7FF",
      50: "#F9F4FF",
    },
    red: {
      900: "#500500",
      800: "#91140B",
      700: "#D60E00",
      600: "#F41101",
      500: "#FF3224",
      400: "#FF6358",
      300: "#FF9C95",
      200: "#FFC4C0",
      100: "#FFDFDD",
      50: "#FFF1F0",
    },
    teal: {
      100: "#00A7BD",
      75: "#3FBFD0",
      50: "#89D9E4",
      25: "#DFF4F7",
    },
    yellow: {
      900: "#442604",
      800: "#74490F",
      700: "#A67302",
      600: "#D1A100",
      500: "#F9D500",
      400: "#FFE90D",
      300: "#FFF741",
      200: "#FFFF86",
      100: "#FDFFC1",
      50: "#FFFFE7",
    },
    white: "#FFF",
    transparent: "transparent",
  },
  spacing: {
    0: "0",
    1: "1px",
    2: "4px",
    3: "6px",
    4: "8px",
    5: "12px",
    6: "16px",
    7: "24px",
    8: "36px",
    9: "56px",
    10: "72px",
    11: "88px",
    12: "96px",
  },
  fontFamily: { sans: "Sohne", display: "AeonikFono" },
  fontSize: {
    xs: ["0.74rem", "1.5"],
    sm: ["0.875rem", "1.5"],
    base: ["1rem", "1.574"],
    lg: ["1.143rem", "1.5"],
    xl: ["1.429rem", "1.5"],
    "2xl": ["1.714rem", "1.5"],
    "3xl": ["2.286rem", "1.25"],
    "4xl": ["2.857rem", "1.5"],
  },
  borderRadius: {
    none: "0px",
    sm: "3px",
    DEFAULT: "6px",
    md: "12px",
    lg: "18px",
    full: "9999px",
  },
};

export default theme;
