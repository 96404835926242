import React, { useState } from "react";
import { ToastContextType, ToastNotification, ToastType } from "../models";
import Toast from "./Toast";
import { ToastContext } from "./ToastContext";

const ToastProvider: React.FC = ({ children }) => {
  const [toast, setToast] = useState<ToastNotification>();

  const setMessageType = (type: ToastType) => {
    return (notification: Partial<ToastNotification>) => {
      const alert = { ...notification, type };
      setToast(alert as ToastNotification);
    };
  };

  const closeToastLoading = () => {
    if (toast?.type === "loading") {
      setToast(undefined);
    }
  };

  const contextValue: ToastContextType = {
    toast,
    toastInfo: setMessageType("info"),
    toastSuccess: setMessageType("success"),
    toastWarning: setMessageType("warning"),
    toastError: setMessageType("error"),
    toastSystem: setMessageType("system"),
    toastArchive: setMessageType("archived"),
    toastLoading: setMessageType("loading"),
    toastClose: () => setToast(undefined),
    closeToastLoading,
  };

  return (
    <ToastContext.Provider value={contextValue}>
      {children}
      <Toast />
    </ToastContext.Provider>
  );
};

export default ToastProvider;
